import Container from "@mui/material/Container";
import Typography from "@material-ui/core/Typography";
import { Link, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../AppContext";
import theme from "../../../styles/theme";
import useSWR from "swr";
import { fetcher } from "@nectar/lib/fetcher";
import { ThemeProvider } from "@mui/material/styles";
import { roleplayService } from "../../../services/roleplay.service";
import { Grid } from "@material-ui/core";
import FantasyPreviewCard from "./FantasyPreviewCard";

const FANTASIES_PER_PAGE = 12;

const EmptyState = ({ array, segment }) => {
  if (array?.length > 0) return;

  const emptyStateTextTitle =
    segment == "liked"
      ? "You have no liked fantasies."
      : "You have not created any fantasies.";
  const emptyStateTextDescription =
    segment == "liked"
      ? "Like a fantasy and they should appear here!"
      : " Please create one above!";

  return (
    <div className="text-white text-center mt-8">
      <p className="text-2xl font-bold">{emptyStateTextTitle}</p>
      <p className="text-lg text-white/70">{emptyStateTextDescription}</p>
    </div>
  );
};

const MyFantasiesCard = ({ segment }) => {
  const { user } = useContext(AppContext);

  const [createdFantasies, setCreatedFantasies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = user?.id;

  const {
    data: likedFantasies,
    isLoading: isLoadingLikedFantasies,
    mutate: mutateLikedFantasies,
  } = useSWR(
    user ? roleplayService.getFantasiesForUserUrl(user?.id, "liked") : null,
    fetcher,
  );

  const LikedFantasiesMap = useMemo(() => {
    return likedFantasies?.reduce(
      (obj, item) => ((obj[item.id] = item), obj),
      {},
    );
  }, [likedFantasies]);

  const loadFantasies = async () => {
    try {
      setIsLoading(true);

      const createdPromise = roleplayService
        .getFantasiesForUser(userId, "created", true)
        .catch((error) => {
          console.error("Error fetching created fantasies:", error);
          return null;
        });

      Promise.all([createdPromise]).then(([createdData]) => {
        setCreatedFantasies(createdData);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadFantasies();
  }, [user]);

  const fantasies = segment == "liked" ? likedFantasies : createdFantasies;
  
  return (
    <div className="px-6 mt-2">
      <EmptyState array={fantasies} segment={segment} />
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 pb-8">
        {fantasies &&
          fantasies.map((fantasy, index) => {
            return (
              <FantasyPreviewCard
                LikedFantasiesMap={LikedFantasiesMap}
                isLoadingLikedFantasies={isLoadingLikedFantasies}
                user={user}
                mutateLikedFantasies={mutateLikedFantasies}
                key={fantasy.id}
                fantasy={fantasy}
              />
            );
          })}

        {isLoading &&
          Array.from({ length: 4 }).map((_, i) => {
            return (
              <div
                key={i}
                className="h-[500px] w-full animate-pulse rounded-lg bg-zinc-900"
              />
            );
          })}
      </div>
    </div>
  );
};

export default MyFantasiesCard;
