import React from "react";
import Bullet from "./Bullet";
import heart_logo from "../../assets/heart_logo.png";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import home_hdr from "../../assets/home_hdr.svg";
import home_cust from "../../assets/home_cust.svg";
import home_explore from "../../assets/home_explore.svg";
import home_inspo from "../../assets/home_inspo.svg";

const ICInfo = () => {
  return (
    <div className="container mx-auto md:grid grid-cols-2 text-white font-quicksand py-12 gap-8 justify-items-center items-center px-4">
      {/* Image */}
      <div className="">
        <img
          src="https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/df3d657e-3bd7-4549-916a-662b76cee100/public"
          alt="lorem ipsum"
          className="rounded-2xl"
        />
      </div>
      <div className="mt-4 md:mt-0">
        <div className="flex items-center gap-2">
          <img src={heart_logo} alt="heart logo icon" className="w-5 h-5" />
          <h2 className=" text-xl font-semibold">Image Creator</h2>
        </div>
        <div className="flex items-start flex-col md:flex-row gap-2 md:gap-0">
          <p className="max-w-sm opacity-70 my-1 mr-4">
            Create and edit images quickly and easily.
          </p>
          <Button
            component={Link}
            to="/create"
            variant="contained"
            color="secondary"
          >
            TRY NOW
          </Button>
        </div>

        <div className="flex flex-col gap-4 mt-4">
          <Bullet
            icon={home_explore}
            title={"Find Inspiration"}
            content={"Browse through curated selections."}
          />
          <Bullet
            icon={home_cust}
            title={"Powerful Customization"}
            content={"Any position, any outfit, any time."}
          />
          <Bullet
            icon={home_hdr}
            title="High Resolution"
            content={"Your girl deserves to be seen in HD."}
          />
          <Bullet
            icon={home_inspo}
            title="Smart Recommendations"
            content={"Create what you want more easily."}
          />
        </div>
      </div>
    </div>
  );
};

export default ICInfo;
