import { roleplayService } from "../../services/roleplay.service";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppContext } from "../AppContext";
import PaywallModal from "../auth/PaywallModal";
import { Alert, Snackbar } from "@material-ui/core";
import { userService } from "../../services/user.service";

export const messagesContext = createContext(null);

const MessagesProvider = ({ children }) => {
  const {
    conversations: { conversations },
    refreshConversations,
    user,
    refreshUser,
    showLoginModal,
    setShowLoginModal,
  } = useContext(AppContext);

  const [conversationId, setConversationId] = useState(null);
  const [conversationFantasyId, setConversationFantasyId] = useState(null);
  const [conversationCharacter, setConversationCharacter] = useState(null);
  const [conversationMessages, setConversationMessages] = useState([]);
  const [conversationPhotos, setConversationPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);

  // ------- modals - snackbar
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "error",
    message: "",
  });

  // NUM_MESSAGES = 10
  const MAX_FETCH_MESSAGES = 10;
  const MESSAGE_LIMIT_PAYWALL_SOURCE = "message_limit";

  // const fetchConversationImages = async (username, page = 1) => {
  //   if (!conversations) {
  //     return;
  //   }
  //
  //   let conversation = conversations.find(
  //     (conversation) => conversation.character.username === username,
  //   );
  //
  //   try {
  //     const isInitialLoad = page === 1;
  //
  //     setIsLoading(true);
  //
  //     // get conversation images
  //     const photosResponse = await roleplayService.getConversationImages(
  //       conversation?.id,
  //       true,
  //       page,
  //       MAX_FETCH_MESSAGES,
  //     );
  //
  //     console.log("BE FETCHED PHOTOS: ", photosResponse);
  //
  //     if (isInitialLoad) {
  //       setConversationPhotos(photosResponse.messages);
  //     } else {
  //       console.log("ADDING IN new photos: ", photosResponse.messages);
  //       setConversationPhotos([
  //         ...photosResponse.messages,
  //         ...conversationPhotos,
  //       ]);
  //     }
  //   } catch (error) {
  //     console.log("Failed to fetch images", error);
  //     setSnackbar({
  //       open: true,
  //       severity: "error",
  //       message: "Failed to fetch images",
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // Note: This id passed in is a fantasy id
  const fetchConversation = async (id, page = 1) => {
    if (!conversations) {
      return;
    }

    let conversation = conversations.find(
      (conversation) => conversation.fantasy.id === id,
    );

    if (conversation) {
      setConversationId(conversation.id);
    }

    if (!conversation) {
      try {
        const fantasy = await roleplayService.getFantasyById(id);
        const data = {
          fantasy_id: fantasy.id,
          is_reset: false,
        };

        conversation = await roleplayService.createConversation(data);
        setConversationId(conversation.id);
        refreshConversations(user);
      } catch (error) {
        console.error("Failed to create conversation:", error);
      }
    }

    if (conversationFantasyId !== id) {
      setConversationMessages([]);
    }

    setConversationFantasyId(conversation?.fantasy.id);

    try {
      const isInitialConversation = page === 1;

      setIsLoading(true);

      // get conversation history
      const response = await roleplayService.getConversationHistory(
        conversation?.id,
        true,
        page,
        MAX_FETCH_MESSAGES,
      );

      if (isInitialConversation) {
        setConversationMessages(response.messages);
      } else {
        setConversationMessages([
          ...response.messages,
          ...conversationMessages,
        ]);
      }
    } catch (error) {
      console.log("Failed to fetch conversation", error);
      setSnackbar({
        open: true,
        severity: "error",
        message: "Failed to fetch conversation",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getId = (username) => {
    if (!conversations) {
      return;
    }

    const conversation = conversations.find(
      (conversation) =>
        conversation.fantasy.characters[0].username === username, // TODO expand for later on
    );

    return conversation?.id;
  };

  const getCharacters = (id) => {
    if (!conversations) {
      return;
    }

    const conversation = conversations.find(
      (conversation) => conversation.fantasy.id === id,
    );

    return conversation?.fantasy.characters;
  };

  const getFantasy = (id) => {
    if (!conversations) {
      return;
    }

    const conversation = conversations.find(
      (conversation) => conversation.fantasy.id === id,
    );

    return conversation?.fantasy;
  };

  // TODO: Pass the functionality of MobileMessageBox here

  const sendMessage = async (message, handleScrollDown = () => {}) => {
    if (message.trim() === "") return;

    // Create optimist message
    const optimisticMessage = {
      id: crypto.randomUUID(),
      sender: "{user}",
      message: message,
      timestamp: new Date().toISOString(),
      is_greeting: false,
    };

    // before making changes because the scroll will
    // be made when a change is made to the layout.
    handleScrollDown();
    setConversationMessages([...conversationMessages, optimisticMessage]);

    try {
      setIsLoading(true);
      setIsTyping(true);

      // Send message
      const conversation = conversations.find(
        (conversation) => conversation.fantasy.id === conversationFantasyId,
      );

      if (!conversation) {
        return console.log("No conversation found");
      }

      const response = await roleplayService.sendMessage(conversation.id, {
        message,
      });

      handleScrollDown();
      setConversationMessages([
        ...conversationMessages.filter(
          (message) => message.id !== optimisticMessage.id,
        ),
        {
          id: optimisticMessage.id,
          sender: "{user}",
          message: message,
          timestamp: optimisticMessage.timestamp, // Using the timestamp from the optimistic message
          is_greeting: false,
        },
        {
          id: crypto.randomUUID(),
          sender: "{char}",
          message: response.message,
          timestamp: response.timestamp,
          is_greeting: response.is_greeting || false,
          attachment: response.attachment || null,
        },
      ]);
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        !userService.checkIsLoggedIn()
      ) {
        setShowLoginModal(true);
        return;
      }

      if (error.response && error.response.status === 401) {
        handlePaywallModalOpen(MESSAGE_LIMIT_PAYWALL_SOURCE);
        return;
      }

      console.log("error sending message: ", error);

      setSnackbar({
        open: true,
        severity: "error",
        message: "Failed to send message. Please try again.",
      });

      setConversationMessages((messages) =>
        messages.filter((message) => message.id !== optimisticMessage.id),
      );
    } finally {
      setIsTyping(false);
      setIsLoading(false);
    }
  };

  // const regenerateMessage
  const regenerateMessage = async (handleScrollDown = () => {}) => {
    setIsLoading(true);
    setIsTyping(true);

    handleScrollDown();
    if (conversationMessages.at(-1).sender === "{char}") {
      setConversationMessages((messages) => messages.slice(0, -1));
    }

    const conversation = conversations.find(
      (conversation) => conversation.fantasy.id === conversationFantasyId,
    );

    try {
      const response = await roleplayService.regenerate(conversation.id);
      if (!response) return;

      handleScrollDown();

      setConversationMessages((messages) => [
        ...messages,
        {
          id: crypto.randomUUID(),
          sender: "{char}",
          message: response.message,
          timestamp: response.timestamp,
          is_greeting: response.is_greeting || false,
          attachment: response.attachment || null,
        },
      ]);
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        !userService.checkIsLoggedIn()
      ) {
        setShowLoginModal(true);
        return;
      }

      if (error.response && error.response.status === 401) {
        handlePaywallModalOpen(MESSAGE_LIMIT_PAYWALL_SOURCE);
        return;
      }

      console.log("error sending message: ", error);

      setSnackbar({
        open: true,
        severity: "error",
        message: "Failed to send message. Please try again.",
      });
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!user) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handleClosePaywallModal = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <messagesContext.Provider
      value={{
        conversationId,
        conversationMessages,
        conversationPhotos,
        isLoading,
        isTyping,
        error,
        conversationCharacter,
        fetchConversation,
        // fetchConversationImages,
        getCharacters,
        getId,
        getFantasy,
        MAX_FETCH_MESSAGES,
        sendMessage,
        regenerateMessage,
      }}
    >
      {children}

      {/* Modals - Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {!showLoginModal && showPaywallModal && (
        <PaywallModal
          open={showPaywallModal}
          loggedInUser={user}
          refreshUser={refreshUser}
          onClose={handleClosePaywallModal}
        />
      )}
    </messagesContext.Provider>
  );
};

export default MessagesProvider;
