import React from "react";

export const ProfileStack = ({ characters }) => {
  if (!characters || characters.length === 0) {
    return null;
  }

  return (
    <div className="flex items-center gap-1.5">
      <div className="flex items-center">
        {characters.map((character) => {
          return (
            <img
              key={character.id}
              src={character.profile_pic_url}
              alt={`${character.username} profile`}
              className="w-8 h-8 border border-[#1e1e21] rounded-full -ml-2 first:ml-0"
            />
          );
        })}
      </div>

      <p className="text-white/70">
        {characters.map(character => character.username).join(", ")}
      </p>

    </div>
  );
};

