import React from "react";

import pattern_hero from "../../../assets/pattern-hero-opacity.png";
import clsx from "clsx";
import { buttonVariants } from "@nectar/components/ui/button";
import { Link } from "react-router-dom";
import { UserPlus } from "lucide-react";

const HeroCreateGirl = () => {
  return (
    <div
      className=" w-full bg-zinc-900 rounded-2xl flex bg-opacity-0 md:bg-opacity-100 font-quicksand overflow-hidden relative animate-show-modal"
      style={{
        backgroundImage: `url(${pattern_hero})`,
      }}
    >
      <img
        className="w-full h-full object-cover absolute inset-0 opacity-20 md:hidden"
        src={"https://nectarai.b-cdn.net/images/speeding_ticket.png"}
        alt={`thumbnail image `}
      />
      <div className="flex-1 p-6 md:p-8 relative flex items-start flex-col">
        <h2 className="text-xl md:text-3xl font-semibold">
          Create the <span className="text-primary font-bold">girlfriend</span>{" "}
          of your dreams
        </h2>
        <div className="mt-3 flex-grow overflow-hidden">
          <p className="md:text-lg  text-white/80">
            Your dream girl or waifu is just one click away. Create her face,
            give her a personality, and bring her to life in just seconds for{" "}
            <span className="font-semibold">FREE!</span>.
          </p>
        </div>

        <Link
          to={`/characters/create`}
          className={clsx([buttonVariants(), "gap-1 mt-6"])}
        >
          <UserPlus className="w-5 h-5" />
          Create AI Girlfriend
        </Link>
      </div>
      <div className="flex-1 relative hidden md:block">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-zinc-900 via-zinc-900/60 to-transparent"></div>
          <img
            className="w-full h-full object-cover "
            src={"https://nectarai.b-cdn.net/images/speeding_ticket.png"}
            alt={`thumbnail image `}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroCreateGirl;
