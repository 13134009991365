import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Modal } from "@material-ui/core";
import LoginModal from "../auth/LoginModal";
import PaywallModal from "../auth/PaywallModal";
import { AppContext } from "../AppContext";
import AspectRatioBox from "./AspectRatioBox";
import PromptBox from "./PromptBox";
import HighResBox from "./HighResBox";
import ModelDropDown from "./ModelDropdown";
import { CreateContext } from "../CreateContext";
import { userService } from "../../services/user.service.ts";
import { suggestionService } from "../../services/suggestion.service.ts";
import { Button } from "../ui/button";
import ExtraBoxModal from "./ExtraBoxModal";
import { ScrollBar } from "../ui/scroll-area";
import CharactersSelect from "./CharactersSelect";
import { useLocation } from "react-router-dom";
import { roleplayService } from "@/services/roleplay.service";

// import social_close from "../../assets/social_close.svg";
// import social_shoutout from "../../assets/social_shoutout.svg";
// import SocialPopupModal from "../navigation/SocialPopupModal";

const negativeSuggestionCategories = {}; //{"": ["ugly", "morbid", "bad hands", "jpeg artifacts", "sketch", "poorly drawn"]}

// paywall sources
const CREATE_LIMIT_PAYWALL_SOURCE = "create_limit";
const HIGHRES_PAYWALL_SOURCE = "highres";
const ASPECT_RATIO_SOURCE = "aspect_ratio";
const CUSTOMIZATION_PAYWALL_SOURCE = "customization";
const CHECKPOINT_PAYWALL_SOURCE = "checkpoint";

const CHARACTER_MODEL_TITLE = "Nectar Girlfriend Model";

const CreateCard = () => {
  const {
    user,
    accountStatus,
    refreshUser,
    showLoginModal,
    setShowLoginModal,
  } = useContext(AppContext);

  const {
    ckpts,
    character,
    setActiveTab,
    prompt,
    negativePrompt,
    setPrompt,
    setNegativePrompt,
    create,
    isGenerating,
    selectedCkpt,
    setSelectedCkpt,
    setCharacter,
    setTaggedExtras,
    setSelectedExtras,
  } = useContext(CreateContext);

  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const [categorizedSuggestions, setCategorizedSuggestions] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const characterParam = queryParams.get("character");

  useEffect(() => {
    const fetchCharacter = async () => {
      if (characterParam && accountStatus?.plan_tier > 3) {
        try {
          const fetchedCharacter =
            await roleplayService.getCharacterById(characterParam);
          if (fetchedCharacter) {
            setCharacter(fetchedCharacter);

            const characterModelCkpt = ckpts.find(
              (ckpt) => ckpt.title === CHARACTER_MODEL_TITLE,
            );
            if (characterModelCkpt) {
              setSelectedCkpt(characterModelCkpt);
            }
          }
        } catch (error) {
          console.error("Error fetching character:", error);
          // Handle error appropriately
        }
      }
    };

    fetchCharacter();
  }, [characterParam, accountStatus, ckpts, setCharacter, setSelectedCkpt]);

  useEffect(() => {
    suggestionService.getSuggestions().then((suggestions) => {
      const res = suggestions.reduce((accumulator, suggestionObj) => {
        const { suggestion, category } = suggestionObj;
        if (!accumulator[category]) {
          accumulator[category] = [];
        }
        accumulator[category].push(suggestion);
        return accumulator;
      }, {});
      setCategorizedSuggestions(res);
    });
  }, []);

  useEffect(() => {
    setSelectedExtras([]);
  }, [selectedCkpt]);

  const isCharacterModelSelected = selectedCkpt.title === CHARACTER_MODEL_TITLE;

  const checkAuth = () => {
    if (!userService.checkIsLoggedIn()) {
      setShowLoginModal(true);
      return false;
    }
    return true;
  };

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handleCreate = () => {
    if (!checkAuth()) {
      return;
    }
    if (!accountStatus.unlimited && accountStatus.remaining_generations <= 0) {
      showPaywallWithSource(CREATE_LIMIT_PAYWALL_SOURCE);
      return;
    }
    setActiveTab(1);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "create",
      account_plan: accountStatus?.plan,
      // ... any additional data you want to send
    });
    create();
  };

  const handlePaywallModalOpen = (source) => {
    if (!checkAuth()) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  const handlePromptChange = (newPrompt) => {
    setPrompt(newPrompt);
  };
  const handleNegativePromptChange = (newPrompt) => {
    setNegativePrompt(newPrompt);
  };

  return (
    <>
      {/* Create form */}
      <div className="w-full sticky top-0 overflow-hidden h-full md:h-[calc(100svh)]">
        <div className="w-full h-full overflow-auto relative scrollbar scrollbar-area   border border-r border-zinc-900">
          <ModelDropDown
            onShowPaywall={() => {
              handlePaywallModalOpen(CHECKPOINT_PAYWALL_SOURCE);
            }}
            isCharacterModelSelected={isCharacterModelSelected}
          />

          {isCharacterModelSelected && <CharactersSelect />}

          <div className="p-3 pt-4">
            <PromptBox
              header="PROMPT - Describe your dream girl"
              prompt={prompt}
              suggestionCategories={categorizedSuggestions}
              onPromptChange={handlePromptChange}
            />
          </div>
          <div className="p-3 pb-4">
            <PromptBox
              header="ICKS - What you dislike"
              prompt={negativePrompt}
              suggestionCategories={negativeSuggestionCategories}
              onPromptChange={handleNegativePromptChange}
            />
          </div>
          {!isCharacterModelSelected && (
            <div className="px-3 py-4 border-t border-zinc-800">
              <HighResBox
                onShowPaywall={() => {
                  handlePaywallModalOpen(HIGHRES_PAYWALL_SOURCE);
                }}
              />
            </div>
          )}

          {!isCharacterModelSelected && (
            <div className="px-3 py-4 border-t border-zinc-800">
              <AspectRatioBox
                onShowPaywall={() => {
                  handlePaywallModalOpen(ASPECT_RATIO_SOURCE);
                }}
              />
            </div>
          )}

          {!character?.legacy && (
            <div className="px-3 py-4 border-t border-zinc-800">
              <ExtraBoxModal
                header="CUSTOMIZATIONS - max 3"
                isCharacterModelSelected={isCharacterModelSelected}
                defaultSelectedCategory={!isCharacterModelSelected ? "character" : "outfit"}
                onShowPaywall={() => {
                  handlePaywallModalOpen(CUSTOMIZATION_PAYWALL_SOURCE);
                }}
              />
            </div>
          )}

          <div className="px-3 py-4 border-t border-zinc-800 sticky bottom-0 bg-black/50 backdrop-blur">
            <Button
              size={"lg"}
              className="w-full"
              onClick={handleCreate}
              disabled={
                isGenerating || (isCharacterModelSelected && !character)
              }
            >
              {isGenerating ? "Generating..." : "Create"}
            </Button>
          </div>
        </div>
      </div>

      {!showLoginModal && showPaywallModal && (
        <PaywallModal
          message={
            "Unlock advanced image creator features now🤩! Select a plan below."
          }
          open={showPaywallModal}
          onClose={handlePaywallModalClose}
          refreshUser={refreshUser}
          loggedInUser={user}
        />
      )}
    </>
  );
};

export default CreateCard;
