import React from "react";

const TextPreprocess = ({ preprocess }) => {
  return (
    <>
      {preprocess?.map((text, i) => {
        return (
          <span key={`text-${i}`} className={text.isItalic ? "italic" : " "}>
            {text.content}{" "}
          </span>
        );
      })}
    </>
  );
};

export default TextPreprocess;
