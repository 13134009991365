import React, { useState, useEffect, useRef } from "react";

import Masonry from "react-masonry-css";
import ImageCard from "../images/ImageCard";
import exploreService from "../../services/explore.service";
import { DEFAULT_CATEGORY } from "../../hooks/useCategory";
import { DEFAULT_MODEL } from "../../hooks/useModel";

export const IMAGES_PER_PAGE = 18;
export const PROFILES_PER_PAGE = 12;

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

// https://stackoverflow.com/questions/58341787/intersectionobserver-with-react-hooks
export function useOnScreen(ref, loadExploreImages) {
  const observerRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.1,
    };

    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio >= 0.1) {
        loadExploreImages();
      }
    }, options);

    if (ref.current) {
      observerRef.current.observe(ref.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [ref, loadExploreImages]);
}

const ExploreImagesContainer = ({
  category = DEFAULT_CATEGORY,
  model = null,
}) => {
  const [exploreImages, setExploreImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [initLoaded, setInitLoaded] = useState(false);
  const [pageOrder, setPageOrder] = useState([]);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(false);
  const lastImageRef = useRef(null);

  const isOnScreen = useOnScreen(lastImageRef, () => {
    if (initLoaded && !end && !loadingMore) {
      loadExploreImages(true);
    }
  });

  const loadExploreImages = async (loadMore = false) => {
    try {
      setIsLoading(true);
      setLoadingMore(true);
      if (pageOrder.length > 0) {
        const pageNumber = loadMore ? page + 1 : page;
        if (pageNumber >= pageOrder.length) {
          setEnd(true);
          return;
        }
        const data = await exploreService.getExploreImages(
          category,
          pageOrder[pageNumber],
          IMAGES_PER_PAGE,
          model?.slug || null,
        );
        if (loadMore) {
          if (data.length === 0) {
            setEnd(true);
            return;
          }
          const newImages = data || [];
          const allImages = exploreImages.concat(newImages);
          setExploreImages(allImages);
          setPage(page + 1);
          setEnd(newImages.length < IMAGES_PER_PAGE); // Changed the condition
        } else {
          setExploreImages(data || []);
          setEnd(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setLoadingMore(false);
    }
  };

  // todo: pass to utils
  function getRandomPermutation(n) {
    // Generate an array of n elements
    let arr = Array.from({ length: n }, (_, index) => index + 1);

    // Fisher-Yates Shuffle with a Twist
    const maxDistance = Math.min(60, arr.length - 1); // adjust this to control the shuffling "strength"

    for (let i = arr.length - 1; i > 0; i--) {
      const j =
        Math.max(i - maxDistance, 0) +
        Math.floor(Math.random() * Math.min(maxDistance, i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
    }

    return arr;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    exploreService.getNumExploreImages(category, model?.slug || null).then((res) => {
      if (res > 1) {
        const numPages = Math.ceil(res / IMAGES_PER_PAGE);
        const arr = getRandomPermutation(numPages);
        setPageOrder(arr);
        setExploreImages([]); // Empty the existing image set
        setEnd(false); // Reset end of image list state
        setPage(0); // Reset page count
        setIsLoading(true); // Show loading state
      } else {
        setPageOrder([1]);
        setExploreImages([]); // Empty the existing image set
        setEnd(false); // Reset end of image list state
        setPage(0); // Reset page count
        setIsLoading(true); // Show loading state
      }
    });
  }, [category, model]);

  useEffect(() => {
    if (pageOrder.length > 0) {
      setInitLoaded(true);
      loadExploreImages(false);
    }
  }, [pageOrder]);

  useEffect(() => {
    const debouncedLoadExplore = debounce(() => loadExploreImages(true), 200);
    if (isOnScreen && initLoaded && !end && !loadingMore) {
      debouncedLoadExplore(true);
    }
  }, [isOnScreen, initLoaded, end, loadingMore]);

  const breakpointColumnsObj = {
    default: 6, // number of columns for default minimum viewport widths and above
    1280: 4, // number of columns for viewports widths of 1280px (lg) and above
    960: 3, // number of columns for viewports widths of 960px (md) and above
    600: 2, // number of columns for viewports widths of 600px (sm) and above
    0: 2, // number of columns for the smallest xs screens
  };

  return (
    <div className="">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex gap-4 px-4"
        columnClassName="flex-1 w-full flex flex-col gap-4"
      >
        {exploreImages.map((image, index) => (
          <ImageCard key={index} exploreImage={image} changeUrl={true} />
        ))}

        {isLoading &&
          Array.from({ length: IMAGES_PER_PAGE }).map((_, i) => {
            return (
              <div
                key={i}
                className="aspect-[1/1] animate-pulse rounded-2xl bg-zinc-800"
              ></div>
            );
          })}
      </Masonry>

      {exploreImages.length > 0 && !isLoading && (
        <div ref={lastImageRef} className="min-h-[100px] w-full"></div>
      )}

      {end && (
        <div className="mt-4 flex justify-center">
          <p>No more images to load.</p>
        </div>
      )}
    </div>
  );
};

export default ExploreImagesContainer;
