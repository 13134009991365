import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "@nectar/components/AppContext";
import { Grid, Typography } from "@material-ui/core";
import { userService } from "@nectar/services/user.service";
import {
  TooltipTrigger,
  TooltipContent,
  Tooltip,
} from "@nectar/components/ui/tooltip";
import { Info, Sparkles } from "lucide-react";
import highres_icon from "@nectar/assets/highres_icon.svg";
import "./styles/toggle_slider.css";

const RoleplaySettings = () => {
  const { user, refreshUser } = useContext(AppContext);

  // State for whether roleplay is enhanced or not
  const [isRoleplayEnhanced, setIsRoleplayEnhanced] = useState(
    user?.enhanced_roleplay_enabled || false,
  );

  useEffect(() => {
    setIsRoleplayEnhanced(user?.enhanced_roleplay_enabled || false);
  }, [user]);

  const handleCheckboxChanged = (e) => {
    // Update the user object in the context
    if (!isRoleplayEnhanced) {
      setIsRoleplayEnhanced(true);
      userService.boostRoleplay().then(() => refreshUser());
    } else {
      setIsRoleplayEnhanced(false);
      userService.unboostRoleplay().then(() => refreshUser());
    }
  };

  return user.whitelisted ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/*<Sparkles className="w-4 h-4 mr-1 flex-shrink-0 opacity-70" />*/}
      <Typography variant="h4" style={{ color: "#808080" }}>
        Enhanced Roleplay:
      </Typography>
      <Tooltip>
        <TooltipTrigger>
          <div className="ml-1">
            <Info className="w-5 h-5 text-yellow-200" />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Enable for more intelligent and descriptive responses</p>
        </TooltipContent>
      </Tooltip>
      <label
        className="switch ml-4"
        style={{ display: "flex", alignItems: "center" }}
      >
        <input
          type="checkbox"
          checked={isRoleplayEnhanced}
          onChange={handleCheckboxChanged}
        />
        <span className="slider round" />
      </label>
    </div>
  ) : null;
};

export default RoleplaySettings;
