import React, { useState } from "react";
import Suggestion from "./Suggestion";
import refresh_icon from "../../assets/refresh_icon.svg";
import { RefreshCcw } from "lucide-react";

const SuggestionCategory = ({ title, initialSuggestions, onAddSuggestion }) => {
  const [suggestions, setSuggestions] = useState(initialSuggestions);
  const [cursor, setCursor] = useState(0);

  const handleAddSuggestion = (suggestion) => {
    onAddSuggestion(suggestion);
    const updatedSuggestions = suggestions.filter((s) => s !== suggestion);
    setSuggestions(updatedSuggestions);
  };

  const generateMore = () => {
    setCursor((prevCursor) => {
      if (suggestions.length <= 5) {
        return 0;
      }
      if (suggestions.length > 0) {
        return (prevCursor + 5) % suggestions.length;
      }
      return 0;
    });
  };
  const suggestionSlice =
    suggestions.length <= 5
      ? suggestions
      : cursor + 5 > suggestions.length
      ? suggestions
          .slice(cursor)
          .concat(suggestions.slice(0, (cursor + 5) % suggestions.length))
      : suggestions.slice(cursor, cursor + 5);

  if (suggestions?.length < 0) return null;

  return (
    <div className="mb-3">
      {title && (
        <h3 className="items-center w-full h-auto mb-2 flex-none text-xs text-white/60">
          {title.toUpperCase()}
        </h3>
      )}
      <div className="flex gap-1.5 flex-wrap">
        {suggestionSlice.map((suggestion, index) => (
          <Suggestion
            key={`suggestion-${index}`}
            word={suggestion}
            onAddSuggestion={handleAddSuggestion}
          />
        ))}

        {suggestions?.length > 5 && (
          <button
            className="text-sm px-2 py-0.5 text-white/70 bg-zinc-900 hover:bg-zinc-800 rounded-full flex items-center gap-1.5 "
            onClick={generateMore}
          >
            <RefreshCcw className="w-3 h-3" />
            Generate new ideas
          </button>
        )}
      </div>
    </div>
  );
};

export default SuggestionCategory;
