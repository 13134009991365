import Box from '@mui/material/Box';
import React, {useContext, useState} from "react";
import download_icon from "../../assets/download_icon.svg"
import {userService} from "../../services/user.service";
import {AppContext} from '../AppContext';
import {Modal} from '@material-ui/core';
import DetailedProfilePhotoModal from "../profiles/DetailedProfilePhotoModal"
import LoginModal from "../../components/auth/LoginModal";

const ProfilePhotoCard = ({bundle, profile_photo, isClickable}) => {
    const [showModal, setShowModal] = useState(false);
    const [downloadError, setDownloadError] = useState(null);
    const context = useContext(AppContext)


    const handleClick = () => {
        setShowModal(true);
        context.setHideBottomAppBar(true);
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //     'event': 'detailed_image_expand', // Replace with your custom event name
        //     'image_id': profile_photo.id,
        //     // ... any additional data you want to send
        // });
    };

    const handleModalClose = () => {
        setShowModal(false);
        context.setHideBottomAppBar(false);
    };

    const handleDownloadClick = async () => {
        if (!userService.checkIsLoggedIn()) {
            context.setShowLoginModal(true);
            setShowModal(false);
            context.setUser(null);
            return;
        } else {
            const status = await userService.incrementProfilePhotoDownload(profile_photo.id)
            if (status != 200) {
                setDownloadError("Unable to download the image");
                return;
            } else {
                setDownloadError(null);
                const response = await fetch(profile_photo.image_url);
                const imageBlob = await response.blob();
                const img = new Image();
                img.src = URL.createObjectURL(imageBlob);

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);

                    canvas.toBlob((blob) => {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = `image-${profile_photo.id.substring(0, 8)}.png`; // concatenated version of ID for only first 8 chars

                        // Create a MouseEvent to trigger the download
                        const event = new MouseEvent('click', {
                            view: window,
                            bubbles: true,
                            cancelable: false
                        });

                        link.dispatchEvent(event);
                    }, 'image/png');

                }

            }
        }
    }


    return (
        <Box>
            <div className="relative" onClick={isClickable ? handleClick : null}
                 style={{pointerEvents: isClickable ? "auto" : "none"}}>
                <img
                    src={profile_photo.image_url}
                    className="w-full object-contain rounded-lg"
                />
            </div>
            {showModal && (
                <DetailedProfilePhotoModal
                    bundle={bundle}
                    profilePhoto={profile_photo}
                    closeModal={handleModalClose}
                    onDownloadClick={handleDownloadClick}
                />
            )}
            {downloadError && (
                <div className="mt-4 text-red-600">
                    {downloadError}
                </div>
            )}
        </Box>
    )
}

export default ProfilePhotoCard;