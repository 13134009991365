export const STEPS_REALISTIC = [
  {
    type: "carousel",
    title: "ethnicity",
    label: "Ethnicity",
    options: [
      {
        value: "white",
        label: "White",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/4678b7e1-b93d-4861-e221-d79f16252d00/public",
      },
      {
        value: "hispanic",
        label: "Hispanic",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/515765e1-13b7-4e67-6047-fdcb42875900/public",
      },
      {
        value: "korean",
        label: "Korean",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/5ee6381b-fd85-4fbd-bf83-1ada5af83900/public",
      },
      {
        value: "middle eastern",
        label: "Middle Eastern",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/6540b226-ba38-42aa-f704-d222965e1600/public",
      },
      {
        value: "indian",
        label: "Indian",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/44d75aa6-3cfe-42e3-4a71-32dba89b9e00/public",
      },
      {
        value: "chinese",
        label: "Chinese",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/6142632d-c292-4c7c-945e-46e1adbf8300/public",
      },
      {
        value: "japanese",
        label: "Japanese",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/626be2d3-8d30-4abe-f3fc-a05d4deacc00/public",
      },
      {
        value: "vietnamese",
        label: "Vietnamese",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/6a967f6e-89b5-4a26-9ca0-3bf7c2b65a00/public",
      },
    ],
  },
  {
    type: "grid",
    title: "eyecolor",
    label: "Eye Color",
    columns: 2,
    large_columns: 2,
    options: [
      {
        value: "blue",
        label: "Blue",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/41c29426-feb2-4c14-bc93-72aa4a542d00/public",
      },
      {
        value: "brown",
        label: "Brown",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/8f1db96d-0e91-4917-e9fa-73dafce0bd00/public",
      },
      {
        value: "green",
        label: "Green",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/dfa99b0a-9cf1-444b-87e1-b450da123900/public",
      },
      {
        value: "hazel",
        label: "Hazel",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/6652da7b-a83c-4247-81a1-4a959107da00/public",
      },
    ],
  },
  {
    type: "grid",
    title: "haircolor",
    label: "Hair Color",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "black",
        label: "Black",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/3c76d429-ac11-4c2e-4c0d-b15948c3fa00/public",
      },
      {
        value: "brown",
        label: "Brown",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/0d35ef18-979d-4068-0c18-0f614b160700/public",
      },
      {
        value: "blonde",
        label: "Blonde",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/0a439404-df45-4a54-11ea-f40f5f40d600/public",
      },
      {
        value: "red",
        label: "Red",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/a1484be9-8578-4579-5c58-3594398ca800/public",
      },
      {
        value: "silver, white",
        label: "Silver",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/c0ea35c9-4676-473c-6d92-c1b91e17fb00/public",
      },
      {
        value: "blue",
        label: "Blue",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/38c8ab26-b3e2-4d92-68df-d14ce5258200/public",
      },
    ],
  },
  {
    type: "grid",
    title: "hairlength",
    label: "Hair Length",
    columns: 2,
    large_columns: 2,
    options: [
      {
        value: "pixie cut",
        label: "Pixie",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/1a939256-c65a-407e-3147-c322d71a1b00/public",
      },
      {
        value: "short",
        label: "Short",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/9af8252a-2440-4b53-ff65-dfcedb3a0d00/public",
      },
      {
        value: "medium",
        label: "Medium",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/d8cf02f6-db37-4599-d2d7-6742e4e1ec00/public",
      },
      {
        value: "long",
        label: "Long",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/6f6136a4-dc53-4d31-33d4-74f62ff6e700/public",
      },
    ],
  },
  {
    type: "grid",
    title: "hairstyle",
    label: "Hair Style",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "straight",
        label: "Straight",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/c5255b6f-7169-49f0-fe1f-a28630fce500/public",
      },
      {
        value: "wavy",
        label: "Wavy",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/83f0387a-cda0-41d4-b3ca-cb7d1428fb00/public",
      },
      {
        value: "curly",
        label: "Curly",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/1cb82947-e27f-4516-fbac-b7974f3d1e00/public",
      },
      {
        value: "bangs",
        label: "Bangs",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/7ecf39fe-9e52-482a-c5f7-524d5db9a400/public",
      },
      {
        value: "hairbun",
        label: "Bun",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/55d08999-4823-4d65-af9b-52d7b4ff8b00/public",
      },
      {
        value: "ponytail",
        label: "Ponytail",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/c904ad7b-256e-437f-2868-7a477f78a800/public",
      },
    ],
  },
  {
    type: "grid",
    title: "bodytype",
    label: "Body Type",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "petite",
        label: "Petite",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/5809cc08-32e6-4340-0f61-ce804aa32700/public",
      },
      {
        value: "slim",
        label: "Slim",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/f1c3be19-47a0-418f-7c36-23429a5a1e00/public",
      },
      {
        value: "athletic body",
        label: "Athletic",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/10b0baa9-86c5-47a8-f9d3-7500f50de100/public",
      },
      {
        value: "hourglass_figure",
        label: "Hourglass",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/24f03b3c-b69e-49e3-ebc8-a138411c3000/public",
      },
      {
        value: "curvy",
        label: "Curvy",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/af5b62c0-a6f8-4eda-9070-838c14c43500/public",
      },
      {
        value: "bbw, thick",
        label: "BBW",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/a7dff444-f632-4aca-0b6a-9f32fcd3cf00/public",
      },
    ],
  },
  {
    type: "number",
    title: "age",
    label: "Age",
    min: 18,
    max: 99,
  },
  {
    type: "text",
    title: "name",
    label: "Name",
    rows: 1,
    placeholder: "Give your girl a name!",
  },
  {
    type: "text",
    title: "description",
    label: "Personality",
    rows: 3,
    placeholder: "Describe your girl's personality!",
  },
];
