import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import Container from "@mui/material/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import { Button } from "@nectar/components/ui/button";
import { userService } from "../services/user.service.ts";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../components/AppContext";
import { Grid, Paper } from "@mui/material";
import MyFantasiesCard from "../components/roleplay/fantasy/MyFantasiesCard";
import PaywallModal from "../components/auth/PaywallModal";
import dropdown_lock_icon_white from "../assets/dropdown_lock_icon_white.svg";

const FANTASY_CREATE_PAYWALL_SOURCE = "fantasy_create";

const MyFantasiesPage = () => {
  const {
    user,
    setUser,
    tokenPlans,
    accountStatus,
    setAccountStatus,
    refreshUser,
    messageCredits,
  } = useContext(AppContext);
  const [displayUser, setDisplayUser] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [activeSegmented, setActiveSegmented] = useState("");
  const [showPaywallModal, setShowPaywallModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!userService.checkIsLoggedIn()) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (userService.checkIsLoggedIn() && user && isInitialLoad) {
      setIsInitialLoad(false);
      setActiveSegmented("liked");
      refreshUser();
    }
  }, [user, isInitialLoad]);

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!userService.checkIsLoggedIn()) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  const handleCreateButtonOnclick = () => {
    if (accountStatus?.plan_tier < 2) {
      handlePaywallModalOpen(FANTASY_CREATE_PAYWALL_SOURCE);
    } else {
      navigate("/fantasy/create");
    }
  };

  const renderCreateButton = () => {
    if (accountStatus?.plan_tier < 2) {
      return (
        <div>
          <Button
            className="text-xs"
            onClick={() =>
              handlePaywallModalOpen(FANTASY_CREATE_PAYWALL_SOURCE)
            }
          >
            <img
              src={dropdown_lock_icon_white}
              className="h-5 w-5 ml-auto mr-1"
              alt="dropdown lock icon"
            />
            CREATE FANTASY (PREM. +)
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          className="text-sm text-white"
          onClick={handleCreateButtonOnclick}
        >
          + CREATE FANTASY
        </Button>
      );
    }
  };

  return (
    <div className="bg-black ">
      <div className="flex items-center justify-between gap-2 py-2 px-6 sticky top-[69px] md:top-0 z-10 bg-black/90 backdrop-blur">
        <div className="flex items-center gap-2">
          <button
            onClick={() => setActiveSegmented("liked")}
            className={`rounded-full text-sm px-4 py-2 flex-shrink-0 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
              activeSegmented == "liked"
                ? " text-white"
                : "text-white opacity-60"
            }`}
          >
            Liked
          </button>
          <button
            onClick={() => setActiveSegmented("created")}
            className={`rounded-full text-sm px-4 py-2 flex-shrink-0 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
              activeSegmented == "created"
                ? " text-white"
                : "text-white opacity-60"
            }`}
          >
            Created
          </button>
        </div>
        {renderCreateButton()}
      </div>
      <MyFantasiesCard segment={activeSegmented} />

      {showPaywallModal && (
        <div>
          <PaywallModal
            message={"Create your dream fantasy🔮! Select a plan below:"}
            open={showPaywallModal}
            onClose={handlePaywallModalClose}
            refreshUser={refreshUser}
            loggedInUser={user}
          />
        </div>
      )}
    </div>
  );
};
export default MyFantasiesPage;
