import React, { useEffect, useMemo, useState } from "react";
import { fetcher } from "@nectar/lib/fetcher";
import { roleplayService } from "@nectar/services/roleplay.service";
import { useParams } from "react-router-dom";
import useSWR from "swr";

/**
 * This component is used to create a conversation if it doesn't exist yet
 */
const MessagesBoxWrapper = ({ children, conversations, isLoading, mutate }) => {
  const { id } = useParams();
  const lastIdRef = React.useRef(false);

  const fantasy = useMemo(() => {
    const conversation = conversations.find((c) => c.fantasy?.id === id);
    return conversation?.fantasy || null;
  }, [conversations, id]);

  // This hook and component is optimized to only fetch the conversation once per id
  useEffect(() => {
    if (lastIdRef.current === id) return;
    if (isLoading) return;

    const main = async () => {
      if (fantasy !== null) return;
      const payload = {
        fantasy_id: id,
        is_reset: false,
      };

      await roleplayService.createConversation(payload);
      mutate(roleplayService.getConversationUrl());
    };

    main();
    lastIdRef.current = id;
  }, [id]);

  return (
    <>
      {fantasy ? (
        children
      ) : (
        <div className=" h-full flex justify-center items-center flex-col gap-2">
          <div className="w-8 h-8 border-4 border-zinc-800 border-r-primary-500 rounded-full animate-spin" />
          <p className="text-white/70">Loading Your Dream Fantasy...</p>
        </div>
      )}
    </>
  );
};

export default MessagesBoxWrapper;
