import React, {useState, useEffect} from 'react';

const TypingIndicator = () => {
    const [dots, setDots] = useState('.');

    useEffect(() => {
        const interval = setInterval(() => {
            if (dots.length < 3) {
                setDots(dots + '.');
            } else {
                setDots('.');
            }
        }, 500); // Interval of 500ms

        return () => {
            clearInterval(interval);
        };
    }, [dots]);

    return (
        <div style={{fontSize: '16px', color: 'grey', marginLeft: '10px'}}>
            Typing{dots}
        </div>
    );
};

export default TypingIndicator;
