export class User {
  id: string;
  created_at: Date;
  username: string;
  name: string;
  email: string;
  eth_address: string;
  enhanced_roleplay_enabled: boolean;
  whitelisted: boolean;

  constructor(
    id: string,
    created_at: Date,
    username: string,
    name: string,
    email: string,
    eth_address: string,
    enhanced_roleplay_enabled: boolean,
    whitelisted: boolean,
  ) {
    this.id = id;
    this.username = username;
    this.name = name;
    this.email = email;
    this.eth_address = eth_address;
    this.created_at = created_at;
    this.enhanced_roleplay_enabled = enhanced_roleplay_enabled;
    this.whitelisted = whitelisted;
  }
}

export class UserInfo {
  id: string;
  username: string;

  constructor(id: string, username: string) {
    this.id = id;
    this.username = username;
  }
}
