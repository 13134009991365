import React, { useEffect } from "react";

const useAutoResize = (ref, limit) => {
  const resizeTextArea = () => {
    const input = ref.current;
    if (!input) return;

    input.style.height = "0px"; // Reset the height
    const newHeight =
      limit && input.scrollHeight > limit ? limit : input.scrollHeight;
    input.style.height = `${newHeight}px`;
  };

  useEffect(() => {
    const input = ref.current;
    if (!input || input.nodeName !== "TEXTAREA") return;

    // Attach the event listener for input
    input.addEventListener("input", resizeTextArea);

    // Trigger an initial resize
    resizeTextArea();

    // Cleanup function to remove the event listener
    return () => {
      if (input) {
        input.removeEventListener("input", resizeTextArea);
      }
    };
  }, [ref, limit]);

  return resizeTextArea;
};

export default useAutoResize;
