import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { OFFICIALS_USER_ID_CREATORS } from "@nectar/utils/constants";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

const DiscoverProfileCard = ({ profile }) => {
  const unsupportedProfileIds = new Set([
    // PRODUCTION
    "1947909a-ce56-4e36-9443-db461c9b7c2f", // Sakura
    "185e88d0-2334-4e4e-933b-947339de2c07", // Amelie
    "eeb64f3d-bef0-437e-97e7-d8f87b76f484", // Huang
    "f878bb8e-1ac7-4aa6-b672-92a3389f58b5", // Eunji
    "a5d0f3b4-2224-41f6-a41f-7709503aab1b", // Katie

    // STAGING
    "1947909a-ce56-4e36-9443-db461c9b7c2f", // Sakura
    "185e88d0-2334-4e4e-933b-947339de2c07", // Amelie
    "eeb64f3d-bef0-437e-97e7-d8f87b76f484", // Huang
    "f878bb8e-1ac7-4aa6-b672-92a3389f58b5", // Eunji
    "a5d0f3b4-2224-41f6-a41f-7709503aab1b", // Katie
  ]);
 
  const isUnsupported = !profile.images_enabled
  const isOfficial = OFFICIALS_USER_ID_CREATORS.has(profile.creator_id);

  return (
    <div className="w-full aspect-[9/12] overflow-hidden rounded-lg relative group">
      <img
        className="w-full h-full object-center object-cover"
        src={profile.profile_pic_url}
      />

      <div className="absolute inset-0 bg-gradient-to-t from-black/75 to-black/0 group-hover:via-black/70 flex justify-between flex-col">
        <div className="flex justify-between p-2">
          {isOfficial && (
            <p className="text-sm px-2.5 py-1 bg-primary rounded-full font-semibold">
              Official
            </p>
          )}

          {isUnsupported && (
            <Tooltip>
              <TooltipTrigger>
                <Info className="w-6 h-6 text-amber-400" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-white">
                  Photo Sending Not Yet Available For{" "}
                  {profile.name.split(" ")[0]}
                </p>
              </TooltipContent>
            </Tooltip>
          )}
        </div>

        <div className="p-2 w-full text-left ">
          <p className="font-semibold text-white">{profile.name}</p>
          <p className="text-white/80">@{profile.username}</p>

          <p className=" hidden text-sm group-hover:line-clamp-4 group-hover:block mt-2">
            {profile?.biography || profile?.description}
          </p>
        </div>
      </div>
    </div>
  );
};
export default DiscoverProfileCard;
