import {useState, useEffect, useRef, useContext} from 'react';
import {useParams} from 'react-router-dom';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import {ThemeProvider} from '@mui/material/styles';
import {Grid} from '@mui/material';
import theme from '../styles/theme';
import {profileService} from '../services/profile.service';
import ProfileCard from '../components/profiles/ProfileCard'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import BundleCard from '..//components/profiles/BundleCard';
import {roleplayService} from "../services/roleplay.service";
import MobileProfileCard from '../components/profiles/MobileProfileCard'
import MobileBundleCard from '../components/profiles/MobileBundleCard';


const ExploreProfilesPage = () => {
    const {username} = useParams();
    const [profile, setProfile] = useState(null);


    useEffect(() => {
        // profileService.getProfileByUsername(username).then(setProfile);
        roleplayService.getCharacterByUsername(username).then(setProfile);
    }, [username]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <HelmetProvider>
            <Helmet>
                <title>Nectar AI - @{profile ? profile.username : ''}</title>
                <meta name="description" content={`Hot AI Girls - ${profile ? profile.name : ''}`}/>
            </Helmet>
            <ThemeProvider theme={theme}>
                <div className="parent h-auto bg-black flex -mb-8 min-h-screen" style={{paddingTop: '100px'}}>
                    <Container maxWidth="lg" sx={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                {profile && <MobileProfileCard profile={profile}/>}
                            </Grid>
                            <Grid item xs={9}>
                                {profile && <MobileBundleCard profile={profile}/>}
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </ThemeProvider>
        </HelmetProvider>
    );
}
export default ExploreProfilesPage