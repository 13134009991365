import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { AppContext } from "../AppContext";
import Container from "@mui/material/Container";
import { ExploreImage } from "../../models/explore.image.model";
import { Grid } from "@material-ui/core";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import { Typography } from "@material-ui/core";
import { userService } from "../../services/user.service.ts";
import Masonry from "react-masonry-css";
import Button from "@mui/material/Button";
import ImageCard from "../images/ImageCard";
import { useOnScreen } from "@nectar/hooks/useOnScreen";
import { fetcher } from "@nectar/lib/fetcher";
import useSWRInfinite from "swr/infinite";

const IMAGES_PER_PAGE = 10;

 const getKey = (pageIndex, previousPageData) => {
   if (pageIndex !== 0 && !previousPageData?.length) {
     console.log("No more pages to load");
     return null;
   }
   const url = userService.getLikedImagesURL(pageIndex * IMAGES_PER_PAGE, IMAGES_PER_PAGE);
   return url;
 }

 const MobileLikesCard = () => {
   const { user, setShowLoginModal, likedImages, likesCardImages, setLikesCardImages } = useContext(AppContext);
   const { data, isLoading, setSize, size } = useSWRInfinite(getKey, fetcher);

  const [isVisible, containerRef] = useOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  useEffect(() => {
    if (data) {
      let imageMap = new Map();
      data.flat().forEach((image) => {
        imageMap.set(image.id, { ...image, liked: true });
      });
      setLikesCardImages(imageMap);
    }
  }, [data, setLikesCardImages]);

  const isLoadingMore =
  isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  const isEmpty = data?.[0]?.images?.length === 0;

  const isReachingEnd =
    isEmpty ||
    (data && data[data.length - 1]?.images?.length < IMAGES_PER_PAGE);

  useEffect(() => {
    if (isVisible && !isLoadingMore && !isReachingEnd) {
      setSize((size) => size + 1);
    }
  }, [isVisible, isLoadingMore, isReachingEnd]);

  const handleLoginModalOpen = () => {
    setShowLoginModal(true);
  };

  const breakpointColumnsObj = {
    default: 2, // number of columns for default minimum viewport widths and above
    1280: 2, // number of columns for viewports widths of 1280px (lg) and above
    960: 2, // number of columns for viewports widths of 960px (md) and above
    600: 2, // number of columns for viewports widths of 600px (sm) and above
    0: 1, // number of columns for the smallest xs screens
  };

  return (
    <Container sx={{ maxWidth: "100%" }}>
      <ThemeProvider theme={theme}>
        <div className="bg-black w-full min-h-screen p-4">
          <h1 className="text-white font-quicksand font-normal text-16 leading-20 mb-4">
            MY LIKES
          </h1>

          {!user ? (
            <div className="text-center mt-5">
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  paddingBottom: "20px",
                }}
              >
                <Typography variant="h1">
                  Please log in to see your likes.
                </Typography>
              </div>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleLoginModalOpen}
              >
                Login / Sign Up
              </Button>
            </div>
          ) : (
            <div className="flex flex-wrap gap-4 w-full">
              {likesCardImages === null || likesCardImages?.size > 0 ? (
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="flex gap-4"
                  columnClassName="flex-1 w-full flex flex-col gap-4"
                >
                  {[...likesCardImages?.values()]
                    .map((image) => (
                      <ImageCard exploreImage={image} key={image.id} />
                    ))}
                </Masonry>
              ) : (
                <div style={{ textAlign: "center", color: "white" }}>
                  <Typography variant="h1">
                    You haven't liked anything.
                  </Typography>
                  <Typography variant="h2">
                    When you like a photo, it'll show up here.
                  </Typography>
                </div>
              )}
            </div>
          )}
          {!isLoadingMore && !isReachingEnd && (
            <div ref={containerRef} className="w-full h-[25px] mt-8 "></div>
          )}
        </div>
      </ThemeProvider>
    </Container>
  );
};

export default MobileLikesCard;
