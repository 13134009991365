import React, { useState, useEffect } from "react";
import { Modal, Typography, Box, Checkbox, FormControlLabel, useMediaQuery } from "@material-ui/core";
import theme from "../../styles/theme";

const TipsModal = () => {
  const [showTipsModal, setShowTipsModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const hasOptedOut = localStorage.getItem("hasOptedOutOfTips");
    if (!hasOptedOut) {
      setShowTipsModal(true);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      localStorage.setItem("hasOptedOutOfTips", "true");
      // setShowTipsModal(false);  // Close the modal after user opts out
    } else {
      localStorage.removeItem("hasOptedOutOfTips");
    }
    e.stopPropagation();
  };

  return (
    <Modal open={showTipsModal} onClose={() => setShowTipsModal(false)} onClick={e => e.stopPropagation()}>
      <div className="p-4 rounded-md w-full" style={{
        backgroundColor: "#0F0F0F",
        borderColor: "#FF3131",
        borderWidth: "2px",
        width: "50%",
        textAlign: "center",
        padding: "10px 10px",
        position: "absolute",
        top: "50%",
        left: "25%",
        transform: "translateY(-50%)",
        zIndex: 10001
      }}>
        <Typography variant="h4" className="text-center text-white" sx={{ marginBottom: "10px" }}>
          HELPFUL TIPS:
        </Typography>
        <Typography variant="h5" className="text-center text-white" sx={{ opacity: 0.7 }}>
          For actions, place them in
          asterisks like so:
        </Typography>
        <Typography variant="h5" className="text-center text-white" sx={{ opacity: 0.7 }}>
          *waves hello*
        </Typography>
        <br />
        <Typography variant="h5" className="text-center text-white" sx={{ opacity: 0.7 }}>
          All other text will be interpreted as if you were speaking
          or texting a response. No need for quotations. You can combine them as such:
        </Typography>
        <Typography variant="h5" className="text-center text-white" sx={{ opacity: 0.7, marginBottom: "10px" }}>
          *waves hello* Good morning, love.
        </Typography>
        <br />
        <Typography variant="h5" className="text-center text-white" sx={{ opacity: 0.7, fontWeight: "bold" }}>
          If you don't like the response, please press the refresh icon next to the message to regenerate.
        </Typography>
        <FormControlLabel
          style={{
            color: "white"
          }}
          control={
            <Checkbox
              style={{ color: "white" }}
              onChange={handleCheckboxChange}
            />
          }
          onClick={e => e.stopPropagation()}
          label={<Typography variant={"h5"}>
            {isMobile ? "Don't show anymore" : "Don't show this message anymore"}
          </Typography>
          }
        />
        <Typography
          className="text-center text-white"
          variant="h4"
          onClick={() => setShowTipsModal(false)}
          style={{ cursor: "pointer", marginTop: "10px" }}>
          Dismiss
        </Typography>
      </div>
    </Modal>
  );
};

export default TipsModal;
