import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, DialogTrigger, DialogContent } from "../ui/dialog";
import { Button } from "../ui/button";
import { Edit } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import useAutoResize from "@nectar/hooks/useAutoResize";
import { useForm } from "react-hook-form";
import { AppContext } from "../AppContext";
import { roleplayService } from "@nectar/services/roleplay.service";
import { useToast } from "../ui/use-toast";
import { ToastAction } from "../ui/toast";
import { userService } from "@nectar/services/user.service";
import PaywallModal from "../auth/PaywallModal";
import Tooltip from "@material-ui/core/Tooltip";

const PAYWALL_SOURCE_PROFILE_EDIT = "profile_edit";

const DiscoverProfileEdit = ({ profile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, accountStatus, refreshUser } = useContext(AppContext);
  const [showPaywallModal, setShowPaywallModal] = useState(false);

  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: profile.name,
      description: profile.description,
      isPublic: profile.is_public,
    },
  });

  const { toast } = useToast();

  const [nameInput, descriptionInput, isPublicInput] = watch([
    "name",
    "description",
    "isPublic",
  ]);

  const isSameValues = useMemo(() => {
    return (
      nameInput === profile.name &&
      descriptionInput === profile.description &&
      isPublicInput === profile.is_public
    );
  }, [nameInput, descriptionInput, isPublicInput]);

  const textAreaRef = useRef(null);
  useAutoResize(textAreaRef, 400);

  useEffect(() => {
    if (!isOpen) return;

    handleReset();
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleReset = () => {
    setValue("name", profile.name);
    setValue("description", profile.description);
    setValue("isPublic", profile.is_public);
    clearErrors();

    setShowPaywallModal(false);
  };

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!userService.checkIsLoggedIn()) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  const onSubmit = async ({ isPublic, ...restData }) => {
    if (isSameValues) {
      return setError("root", {
        message: "You haven't changed anything.",
      });
    }

    if (accountStatus?.plan_tier < 3) {
      return setError("root", {
        message: "You need to be a PRO user to edit your profile.",
      });
    }

    try {
      setIsLoading(true);

      const payload = {
        ...restData,
        is_public: isPublic,
      };

      await roleplayService.characterEdit(profile.id, payload);
      toast({
        title: "Profile updated",
        description: "To see the changes, refresh the page.",
        action: (
          <ToastAction
            altText="Refresh page"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh page
          </ToastAction>
        ),
      });
      setIsOpen(false);
    } catch (error) {
      setError("root", {
        message: error.message,
      });
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (user?.id !== profile.creator_id) return null;

  if (accountStatus?.plan_tier < 3) {
    return (
      <>
        <Tooltip title="Edit Character" placement="top">
          <Button
            variant={"secondary"}
            size={"icon"}
            onClick={() => {
              handlePaywallModalOpen(PAYWALL_SOURCE_PROFILE_EDIT);
            }}
            style={{ padding: "1px 1px" }}
          >
            <Edit className="w-4 h-4" />
          </Button>
        </Tooltip>
        {showPaywallModal && (
          <PaywallModal
            message={"Upgrade to a PRO+ plan to edit your girl!"}
            open={showPaywallModal}
            onClose={handlePaywallModalClose}
            refreshUser={refreshUser}
            loggedInUser={user}
          />
        )}
      </>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (isLoading) {
          return;
        }

        setIsOpen(value);
      }}
    >
      <DialogTrigger asChild>
        <Tooltip title="Edit Character" placement="top">
          <Button variant={"secondary"} size={"icon"}>
            <Edit className="w-4 h-4" />
          </Button>
        </Tooltip>
      </DialogTrigger>
      <DialogContent>
        <div className="flex gap-2 items-center">
          <Avatar className="h-12 w-12">
            <AvatarFallback>{profile.username?.slice(0, 2)}</AvatarFallback>
            <AvatarImage src={profile?.profile_pic_url} />
          </Avatar>
          <div className="leading-tight">
            <h4 className="text-sm text-white/70">Edit profile</h4>
            <p className="">@{profile?.username}</p>
          </div>
        </div>

        <form
          className="flex flex-col gap-3 mt-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          {errors?.root && (
            <div className="border bg-destructive p-2">
              <p className="text-destructive-foreground">
                {errors.root?.message}
              </p>
            </div>
          )}

          <div>
            <input
              type="text"
              placeholder="name"
              className="input-control"
              {...register("name", {
                required: {
                  value: true,
                  message: "Name is required.",
                },
                minLength: {
                  value: 1,
                  message: "Name is required.",
                },
              })}
            />
            {errors?.name && (
              <p className="text-sm text-destructive-foreground">
                {errors.name.message}
              </p>
            )}
          </div>
          <div>
            <textarea
              ref={textAreaRef}
              placeholder="description"
              className="input-control min-h-[100px]"
              {...register("description", {
                required: {
                  value: true,
                  message: "Description is required.",
                },
                minLength: {
                  value: 1,
                  message: "Description is required.",
                },
              })}
            />
            {errors?.description && (
              <p className="text-sm text-destructive-foreground">
                {errors.description.message}
              </p>
            )}
          </div>
          <div className="flex gap-2 items-center ">
            <p className="text-white/70">Visible to public?</p>
            <label className="switch">
              <input
                type="checkbox"
                message="Public"
                {...register("isPublic")}
              />
              <span className="slider round" />
            </label>
          </div>

          <div className="flex gap-2">
            <Button
              type="button"
              variant={"secondary"}
              className="flex-1"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button disabled={isSameValues || isLoading} className="flex-1">
              {isLoading ? "Loading..." : "Save"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DiscoverProfileEdit;
