import React, { useContext } from "react";
import { CreateContext } from "../CreateContext";
import theme from "../../styles/theme";
import "./styles/toggle_slider.css";
import highres_icon from "../../assets/highres_icon.svg";
import { AppContext } from "../AppContext";
import { Box, Grid, Button, Typography } from "@material-ui/core";
import { ThemeProvider } from "@mui/material/styles";
import dropdown_lock_icon from "../../assets/dropdown_lock_icon.svg";
import { Lock, SparklesIcon } from "lucide-react";

const HighResBox = ({ onShowPaywall }) => {
  const { accountStatus } = useContext(AppContext);
  const { enableHighRes, setEnableHighRes } = useContext(CreateContext);

  const handleCheckboxChange = (event) => {
    setEnableHighRes(event.target.checked);
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-2 items-center font-semibold">
        <SparklesIcon className="w-4 h-4" />
        <p>HIGH RESOLUTION</p>
      </div>

      {accountStatus?.plan_tier >= 3 ? (
        <label className="switch">
          <input
            type="checkbox"
            checked={enableHighRes}
            onChange={handleCheckboxChange}
          />
          <span className="slider round" />
        </label>
      ) : (
        <button onClick={onShowPaywall} className="text-primary flex gap-2 items-center">
          <Lock className="w-4 h-4 opacity-70" />
          PRO+
        </button>
      )}
    </div>
  );
};

export default HighResBox;
