import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import { Button } from "@material-ui/core";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { userService } from "../../services/user.service";
import Typography from "@material-ui/core/Typography";
import feature_check from "../../assets/feature_check.png";
import Box from "@mui/material/Box";
import PlanButton from "./PlanButton";
import { Check } from "lucide-react";

const PlansCard = ({
  tokenPlans,
  accountStatus,
  refreshUser,
  loggedInUser,
}) => {
  const currPlanPrice =
    tokenPlans.find((p) => p.title === accountStatus?.plan)?.price || 0;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Grid container spacing={2}>
        {tokenPlans.map((plan) => {
          const isCurrentPlan = plan.title === accountStatus?.plan;
          const buttonText =
            plan.title === "Free"
              ? ""
              : isCurrentPlan
              ? "Manage subscription"
              : `${plan.price < currPlanPrice ? "Downgrade" : "Upgrade"} to ${
                  plan.title
                }`;

          const [imageCreatorDesc, roleplayDesc] = plan.description.includes(
            "|",
          )
            ? plan.description.split("|").map((part) => part.trim())
            : [plan.description, ""];
          const descriptionArray = plan.description.split(". ");
          const buttonStyle = {
            alignSelf: "center",
            backgroundColor: plan.price < currPlanPrice ? "#313131" : undefined,
            background: !isCurrentPlan
              ? "linear-gradient(180deg, #8B1B1B 0%, #E33333 92.19%)"
              : undefined,
            borderRadius: !isCurrentPlan ? "8px" : undefined,
            border: !isCurrentPlan ? "1px solid #E42929" : undefined,
            textAlign: "center",
            width: "100%",
            minWidth: "120px",
            minHeight: "50px",
            maxHeight: "50px",
          };

          return (
            <Grid item xs={3} key={plan.id}>
              <Box
                position="relative"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isCurrentPlan && (
                  <Box
                    position="absolute"
                    top="6px"
                    right="6px"
                    bgcolor="#313131"
                    padding="4px"
                    borderRadius="4px"
                    zIndex="tooltip"
                  >
                    <Typography
                      variant="h4"
                      style={{
                        color: "white",
                        fontSize: "12px",
                        opacity: 0.7,
                      }}
                    >
                      CURRENT PLAN
                    </Typography>
                  </Box>
                )}
                <Box
                  style={{
                    backgroundColor: "#171717",
                    padding: 12,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <div>
                    <Typography
                      variant="h2"
                      style={{
                        color: "#FF4343",
                        wordWrap: "break-word",
                        paddingBottom: 8,
                      }}
                    >
                      {plan.title}
                    </Typography>
                    <Typography
                      variant="h1"
                      style={{
                        color: "#E9E9E9",
                        fontSize: 20,
                        wordWrap: "break-word",
                        paddingBottom: 8,
                        fontWeight: "800",
                      }}
                    >
                      ${plan.price}/mo
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{
                        color: "#FFFFFF",
                        opacity: 0.6,
                        wordWrap: "break-word",
                        paddingBottom: 16,
                      }}
                    >
                      {descriptionArray[0] + "."}
                    </Typography>
                    <ul>
                      {imageCreatorDesc && (
                        <>
                          <li
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "#FFFFFF",
                              marginBottom: "4px",
                            }}
                          >
                            Image Creator
                          </li>
                          {imageCreatorDesc.split(".").map((point, index) => {
                            if (index === 0 || !point.trim()) {
                              // Skip the first item or any empty string
                              return null;
                            }
                            return (
                              <li
                                key={index}
                                style={{
                                  color: "#FFFFFF",
                                  wordWrap: "break-word",
                                  fontSize: 12,
                                  paddingBottom: 8,
                                  whiteSpace: "normal",
                                  maxWidth: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ flexShrink: 0 }}>
                                    {" "}
                                    <Check color="#9E77ED" size={15} />
                                  </div>
                                  <div style={{ paddingLeft: 2 }}>{point}</div>
                                </div>
                              </li>
                            );
                          })}
                        </>
                      )}
                      {roleplayDesc && (
                        <>
                          <li
                            style={{
                              fontSize: 14,
                              color: "#FFFFFF",
                              marginBottom: "4px",
                            }}
                          >
                            Roleplay
                          </li>
                          {roleplayDesc.split(".").map(
                            (point, index) =>
                              point.trim() && (
                                <li
                                  key={index}
                                  style={{
                                    color: "#FFFFFF",
                                    wordWrap: "break-word",
                                    fontSize: 12,
                                    paddingBottom: 8,
                                    whiteSpace: "normal",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ flexShrink: 0 }}>
                                      {" "}
                                      <Check color="#9E77ED" size={15} />
                                    </div>
                                    <div style={{ paddingLeft: 2 }}>
                                      {point}
                                    </div>
                                  </div>
                                </li>
                              ),
                          )}
                        </>
                      )}
                    </ul>
                    <br></br>
                  </div>
                  <div>
                    {plan.price != 0 && (
                      <PlanButton
                        planId={plan.whop_plan_id}
                        buttonText={buttonText}
                        buttonStyle={buttonStyle}
                        isCurrentPlan={isCurrentPlan}
                      />
                    )}
                  </div>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PlansCard;
