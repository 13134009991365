export const formatCount = (number) => {
  // Need to be explicit about every range
  if (number < 1000) {
    // If the number is less than 1000, return it as is
    return number.toString();
  } else if (number >= 1000 && number < 10000) {
    // If the number is between 1000 and 9999, inclusive
    const formattedNumber = (number / 1000).toFixed(2);
    return formattedNumber + 'K';
  } else if (number >= 10000 && number < 100000) {
    // If the number is between 10000 and 99999, inclusive
    const formattedNumber = (number / 1000).toFixed(1);
    return formattedNumber + 'K';
  } else if (number >= 100000 && number < 1000000) {
    // If the number is between 100000 and 999999, inclusive
    const formattedNumber = (number / 1000).toFixed(0);
    return formattedNumber + 'K';
  } else {
    // If the number is 1 million or greater
    const formattedNumber = (number / 1000000).toFixed(0);
    return formattedNumber + 'M';
  }
}