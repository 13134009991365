import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import exploreService from "../../services/explore.service";
import { DetailedImageCardModal } from "./DetailedImageCardModal";
import useCategory from "../../hooks/useCategory";
import useModel from "../../hooks/useModel";

export const imagesContext = createContext();

function getViewId(pathname) {
  const regex = /\/view\/([\w-]+)/;
  const match = regex.exec(pathname);
  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

const ImagesProvider = ({ children }) => {
  const [cacheExploreImage, setCacheExploreImage] = useState(null);
  const [exploreImageFocused, setExploreImageFocused] = useState(null);
  const [isExploreImageLoading, setIsExploreImageLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const category = useCategory();
  const model = useModel();

  useEffect(() => {
    const viewId = getViewId(location.pathname);

    if (!viewId) {
      setExploreImageFocused(null);
      return;
    }

    const main = async () => {
      if (isExploreImageLoading) {
        return;
      }

      setExploreImageFocused(null);

      // Fetching viewId
      setIsExploreImageLoading(true);
      const exploreImage = await exploreService.getExploreImage(viewId);
      setIsExploreImageLoading(false);
      if (exploreImage === null) {
        return;
      }

      setExploreImageFocused(exploreImage);
      setCacheExploreImage(exploreImage);
    };

    // Cache image
    if (viewId === cacheExploreImage?.id) {
      setExploreImageFocused(cacheExploreImage);
      return;
    }

    if (
      viewId &&
      (cacheExploreImage === null || cacheExploreImage?.id !== viewId)
    ) {
      main();
    }
  }, [location]);

  const handleCache = (exploreImage) => {
    setCacheExploreImage(exploreImage);
    setExploreImageFocused(exploreImage);
  };

  const handleOpen = (exploreImage, changeUrl) => {
    handleCache(exploreImage);
    if (changeUrl) {
      navigate(`/images/view/${exploreImage.id}?category=${category}&model=${model}`, {
        state: {
          modal: true,
        },
      });
    }
  };

  return (
    <imagesContext.Provider
      value={{
        cacheExploreImage,
        handleCache,
        exploreImageFocused,
        setExploreImageFocused,
        handleOpen,
      }}
    >
      {children}
      {exploreImageFocused && !isExploreImageLoading && (
        <DetailedImageCardModal exploreImage={exploreImageFocused} />
      )}
    </imagesContext.Provider>
  );
};

export default ImagesProvider;
