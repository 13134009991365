import axios, {AxiosRequestConfig} from "axios";
import {Profile} from "../models/profile.model";
import {Bundle, ProfileBundleResponse} from "../models/bundle.model";
import {Helper} from "../utils/helper";


export class ProfileService {

    public async getProfileByUsername(username: string): Promise<Profile | null> {
        try {
            const response = await axios.get<Profile>(`${process.env.REACT_APP_API_URL}/profile/${username}`);
            if (response.status === 200 && response.data) {
                return response.data as Profile;
            }
            return null;

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async getProfileById(profileId: string): Promise<Profile | null> {
        try {
            const response = await axios.get<Profile>(`${process.env.REACT_APP_API_URL}/profile/${profileId}`);
            if (response.status === 200 && response.data) {
                return response.data as Profile;
            }
            return null;

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async getBundleByUsername(username: string): Promise<ProfileBundleResponse | null> {
        try {
            const response = await axios.get<ProfileBundleResponse>(`${process.env.REACT_APP_API_URL}/profile/${username}/bundles`);
            if (response.status === 200 && response.data) {
                return response.data as ProfileBundleResponse;
            }
            return null;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    public async getBundleById(id: string, loggedIn: boolean): Promise<Bundle | null> {
        try {
            let config: AxiosRequestConfig = {};
            if (loggedIn) {
                const token = Helper.getToken();
                config.headers = {
                    'Authorization': `Bearer ${token}`
                };
            }

            const response = await axios.get<Bundle>(`${process.env.REACT_APP_API_URL}/bundle/${id}${loggedIn ? '' : '/preview'}`, config);
            if (response.status === 200 && response.data) {
                return response.data as Bundle;
            }
            return null;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }
}

export const profileService = new ProfileService();