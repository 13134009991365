import React, { useEffect, useState } from "react";

import { Outlet, useLocation } from "react-router-dom";
import { ThemeProvider, useMediaQuery } from "@material-ui/core";

import theme from "../../styles/theme";
import LoginModal from "../auth/LoginModal";
import TermsAndConditionsModal from "../auth/TCModal";
import Sidebar from "../navigation/Sidebar";
import NavBar from "../navigation/NavBar";
import lockBody from "../../utils/lockBody";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useCheckEmailRedirect from "../../hooks/useCheckEmailRedirect";
import useApp from "../../hooks/useApp";
import GlobalToast from "../landing/GlobalToast";

const Layout = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const [showSideBar, setShowSidebar] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(
    JSON.parse(localStorage.getItem("isCollapsed")) || false,
  );
  const check = useCheckEmailRedirect();
  const app = useApp(true);

  const handleOpenSidebar = () => {
    setShowSidebar(true);
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    if (isMobile) {
      handleCloseSidebar();
    }
  }, [location]);

  useEffect(() => {
    if (isMobile && showSideBar) {
      lockBody(true);
      return;
    }
    lockBody(false);
  }, [showSideBar]);

  useEffect(() => {
    localStorage.setItem("isCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  return (
    <ThemeProvider theme={theme}>
      <main>
        {(showSideBar || !isMobile) && (
          <Sidebar
            app={app}
            handleCloseSidebar={handleCloseSidebar}
            isCollapsed={isMobile ? false : isCollapsed}
            toggleCollapse={() => {
              setIsCollapsed((c) => !c);
            }}
          />
        )}
        <div
          className={
            isCollapsed
              ? "md:ml-sidebar-space-collapsed"
              : "md:ml-sidebar-space"
          }
        >
          {/*<GlobalToast />*/}

          {isMobile && <NavBar handleOpenSidebar={handleOpenSidebar} />}

          <Outlet />
        </div>

        {/* Modals */}
        <LoginModal />
        {/* <TermsAndConditionsModal /> */}
        {/* <BottomAppBar/> */}
      </main>
    </ThemeProvider>
  );
};

export default Layout;
