import axios from "axios";
import {AiModel} from "../models/aimodel.model";
import queryString from 'query-string';

interface GetModelsRequest {
    page: number;
    pageSize: number;
    tag?: string;
    category?: string;
}

export class ModelService {

    public async getCheckpoints(request: GetModelsRequest): Promise<AiModel[]> {
        return this.getModels(request, "checkpoints");
    }

    public async getExtras(request: GetModelsRequest): Promise<AiModel[]> {
        return this.getModels(request, "extras");
    }

    private async getModels(request: GetModelsRequest, asset: string): Promise<AiModel[]> {
        try {
            // Create an object with the parameters that have defined values
            const queryParams: { [key: string]: number | string } = {};

            if (request.page !== undefined) {
                queryParams.page = request.page;
            }
            if (request.pageSize !== undefined) {
                queryParams.page_size = request.pageSize;
            }

            if (request.tag !== undefined) {
                queryParams.tag = request.tag;
            }

            if (request.category !== undefined) {
                queryParams.category = request.category;
            }

            // Generate the query string
            const queryStringValue = queryString.stringify(queryParams);
            const response = await axios.get<AiModel[]>(
                `${process.env.REACT_APP_API_URL}/${asset}${queryStringValue ? '?' + queryStringValue : ''}`
            );

            if (response.status === 200 && response.data) {
                const models = response.data.map((item: AiModel) => {
                    return item as AiModel;
                });
                return models;
            }
            return [];

        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}


export const modelService = new ModelService();