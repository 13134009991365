import React, { useState, useEffect } from "react";
import { userService } from "../services/user.service.ts";
import { roleplayService } from "../services/roleplay.service";

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  // const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState(null);
  const [likedImages, setLikedImages] = useState(new Map());
  const [likesCardImages, setLikesCardImages] = useState(new Map());
  const [ownedCkpts, setOwnedCkpts] = useState([]);
  const [ownedExtras, setOwnedExtras] = useState([]);
  const [accountStatus, setAccountStatus] = useState(null);
  const [eligible, setEligible] = useState(null);
  const [messageCredits, setMessageCredits] = useState(null);
  const [tokenPlans, setTokenPlans] = useState([]);
  const [hideBottomAppBar, setHideBottomAppBar] = useState(false);
  const [conversations, setConversations] = useState([]);
  // const [userPlan, setUserPlan] = useState(null);

  const userAgreed = sessionStorage.getItem("userAgreed");
  const [focusedImage, setFocusedImage] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showTcModal, setShowTcModal] = useState(
    !userService.checkIsLoggedIn() && userAgreed !== "true",
  );
  const [downloadError, setDownloadError] = useState(null);

  const loadLikes = async () => {
    try {
      const data = await userService.getLikedImageIds();
      if (data) {
        let imageMap = new Map();
        data.forEach((imageid) => {
          imageMap.set(imageid, { id: imageid, liked: true });
        });
        setLikedImages(imageMap);
      }
    } catch (error) {
      console.log(error.message ?? "Could not fetch liked images");
      throw error;
    }
  };

  const getUpdatedImage = (image) => {
    const cur = likedImages.get(image.id);
    if (cur) {
      if (cur.liked) {
        return cur;
      }
      return { ...cur, liked: true, likes: cur.likes + 1 };
    } else {
      if (image.liked) {
        return { ...image, liked: false, likes: image.likes - 1 };
      }
      return image;
    }
  };

  const handleToggleLike = async (image) => {
    if (!userService.checkIsLoggedIn()) {
      setShowLoginModal(true);
      setUser(null);
      return;
    }
    const cur = likedImages.get(image.id);

    if (!cur) {
      // add like
      setLikedImages((prevLikedImages) =>
        new Map(prevLikedImages).set(image.id, {
          id: image.id,
          liked: image.liked,
        }),
      );

      setLikesCardImages((prevLikesCardImages) =>
        new Map(prevLikesCardImages).set(image.id, {
          ...image,
          liked: image.liked,
          likes: image.likes,
        }),
      );

      await userService.likeImage(image.id);
      return;
    }

    // remove like
    setLikedImages((prevLikedImages) => {
      const newMap = new Map(prevLikedImages);
      newMap.delete(image.id);
      return newMap;
    });

    setLikesCardImages((prevLikedImages) => {
      const newMap = new Map(prevLikedImages);
      newMap.delete(image.id);
      return newMap;
    });

    await userService.likeImage(image.id);
  };

  const handleDownload = async (image) => {
    if (!userService.checkIsLoggedIn()) {
      setShowLoginModal(true);
      setUser(null);
      return;
    } else {
      const status = await userService.incrementGenerationDownload(image.id);
      if (status !== 200) {
        setDownloadError("Unable to download the image");
        return;
      } else {
        setDownloadError(null);
        const response = await fetch(image.image_url);
        const imageBlob = await response.blob();
  
        // Create a link element for download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(imageBlob);
        link.download = `image-${image.id.substring(0, 8)}.png`; // Use first 8 chars of ID for filename
  
        // Append link to the body (make it non-visible)
        link.style.display = 'none';
        document.body.appendChild(link);
  
        // Manually trigger a click on the link
        link.click();
  
        // Clean up: remove the link from the DOM and revoke the blob URL after a delay
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        }, 100);
      }
    }
  };
  

  const refreshEligibility = () => {
    userService.checkCreateCharEligibility().then((res) => setEligible(res));
  };

  const refreshUser = () => {
    userService.getUserProfile().then((user) => {
      setUser(user);
    });
    refreshAccountStatus();
    refreshTokenPlans();
    refreshMessageCredits();
    refreshEligibility();
    // refreshOwnedCheckpoints();
    // refreshOwnedExtras();
  };

  // const refreshOwnedExtras = () => {
  //     userService.getOwnedExtras().then((res) => setOwnedExtras(res));
  // }
  //
  // const refreshOwnedCheckpoints = () => {
  //     userService.getOwnedCheckpoints().then((res) => setAccountStatus(res));
  // }

  const refreshAccountStatus = () => {
    userService.getAccountStatus().then((res) => setAccountStatus(res));
  };

  const refreshConversations = (user) => {
    roleplayService
      .getConversations(user.id)
      .then((res) => {
        // Filter conversations for those with only fantasies
        const filteredConversations = res.conversations.filter(
          (convo) => convo.fantasy !== null,
        );
        setConversations({ ...res, conversations: filteredConversations });
      })
      .catch((error) => {
        console.error("Failed to get conversations:", error);
      });
  };

  const refreshMessageCredits = () => {
    userService.getMessageCredits().then((res) => setMessageCredits(res));
  };

  const refreshTokenPlans = () => {
    userService.getTokenPlans().then((res) => setTokenPlans(res));
  };

  useEffect(() => {
    if (!userService.checkIsLoggedIn()) {
      // login prompt
      setConversations([]);
      return;
    }
    if (user === null) {
      setConversations([]);
      refreshUser();
      return;
    }
    setShowLoginModal(false);
    loadLikes();
    userService.getOwnedCheckpoints().then((result) => {
      setOwnedCkpts(result);
    });

    userService.getOwnedExtras().then((result) => {
      setOwnedExtras(result);
    });
    refreshAccountStatus();
    refreshTokenPlans();
    refreshMessageCredits();
    refreshEligibility();
    refreshConversations(user);
  }, [user]);

  useEffect(() => {
    refreshTokenPlans();
  }, []);

  return (
    <AppContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        user,
        setUser,
        likedImages,
        setLikedImages,
        likesCardImages,
        setLikesCardImages,
        loadLikes,
        ownedCkpts,
        ownedExtras,
        tokenPlans,
        accountStatus,
        messageCredits,
        eligible,
        setEligible,
        setMessageCredits,
        conversations,
        setConversations,
        setAccountStatus,
        refreshUser,
        refreshAccountStatus,
        refreshTokenPlans,
        refreshMessageCredits,
        refreshConversations,
        refreshEligibility,
        hideBottomAppBar,
        setHideBottomAppBar,
        focusedImage,
        setFocusedImage,
        showLoginModal,
        setShowLoginModal,
        showTcModal,
        setShowTcModal,
        handleToggleLike,
        getUpdatedImage,
        downloadError,
        handleDownload,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
