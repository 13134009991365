import React from "react";
import { Link, useLocation } from "react-router-dom";
import { APPS } from "../../hooks/useApp";
import { ArrowLeftRight } from "lucide-react";
import clsx from "clsx";

const AppSwitcher = ({ isCollapsed, app }) => {
  const isImageApp = app?.id === APPS?.IMAGES.id;
  const location = useLocation();

  if (!app) return null;
  if (location.pathname === "/") {
    return null;
  }
  return (
    <Link
      to={isImageApp ? APPS.ROLEPLAY.paths[0] : APPS.IMAGES.paths[0]}
      className="p-3 w-full rounded capitalize font-quicksand flex gap-2 items-center text-white/70 bg-zinc-900 hover:text-white"
    >
      <ArrowLeftRight
        className={clsx([
          "opacity-60 hover:opacity-100",
          isCollapsed ? "w-5 h-5" : "h-4 w-4",
        ])}
      />
      {!isCollapsed && (
        <span>{isImageApp ? APPS.ROLEPLAY.name : APPS.IMAGES.name}</span>
      )}
    </Link>
  );
};

export default AppSwitcher;
