import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";

import ImageCard from "../images/ImageCard";
import { CreateContext } from "../CreateContext";
import { Typography } from "@material-ui/core";
import "./styles/history.css";
import Masonry from "react-masonry-css";
import GeneratingCard from "./GeneratingCard";

const MINIMUM_CARDS = 3;

const HistoryCard = () => {
  const { generations, isGenerating } = useContext(CreateContext);
  const breakpointColumnsObj = {
    default: 3,
    1280: 2,
    960: 1,
  };

  if (generations?.length <= 0 && !isGenerating) {
    return (
      <div className="p-4 pl-0">
        <div className="text-center">
          <h1 className="text-lg  font-semibold">
            You have no creations for this session.
          </h1>
          <h2 className="text-white/70">
            When you create a photo, it'll show up here.
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="py-4">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex gap-4 "
        columnClassName="flex-1 w-full flex flex-col gap-4"
      >
        {/* generation */}
        {isGenerating && <GeneratingCard />}

        {/* generations */}
        {generations?.map((image) => {
          return (
            <div key={image.id} className="w-full">
              <ImageCard exploreImage={image} />
            </div>
          );
        })}

        {generations.length < MINIMUM_CARDS &&
          Array.from({ length: MINIMUM_CARDS - (isGenerating ? 1 : 0) }).map(
            (_, i) => {
              return <div key={i} />;
            },
          )}
      </Masonry>
    </div>
  );
};

export default HistoryCard;
