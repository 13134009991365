import React, { useContext, useEffect } from "react";

import { CreateContext } from "../CreateContext";
import ImageCard from "../images/ImageCard";
import GeneratingCard from "./GeneratingCard";

const MobileHistoryCard = () => {
  const { generations, isGenerating } = useContext(CreateContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full h-full overflow-auto scrollbar p-4">
      <h1 className="text-white font-quicksand mb-4 font-semibold">
        MY CREATIONS
      </h1>

      <div className="flex flex-col gap-4">
        {isGenerating && <GeneratingCard />}
        {generations.map((image, index) => {
          return (
            <div key={image.id} className="w-full">
              <ImageCard exploreImage={image} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileHistoryCard;
