"use client";

import React from "react";
import CopyButton from "./CopyButton";

const LinkButton = ({ exploreImage }) => {
  const { host, protocol } = window.location;

  return (
    <CopyButton
      label="share"
      content={`${protocol}//${host}/images/view/${exploreImage.id}`}
    />
  );
};

export default LinkButton;