import React from "react";


import Banner from "../components/landing/Banner";
import ICDetail from "../components/landing/ICDetail";
import RoleplayDetail from "../components/landing/RoleplayDetail";
import ICInfo from "../components/landing/ICInfo";
import RoleplayInfo from "../components/landing/RoleplayInfo";
import { ThemeProvider } from "@material-ui/core";
import theme from "../styles/theme";
import useUTM from "../hooks/useUTM";

const HomePage = () => {
  
  const utm = useUTM();

  return (
    <ThemeProvider theme={theme}>
      <div className="min-h-screen bg-black -mb-8">
        <Banner />
        <ICDetail />
        <RoleplayDetail />
        <ICInfo />
        <RoleplayInfo />
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
