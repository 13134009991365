import React from "react";
import { jsx } from "@emotion/react";
import { MessageFragment } from "@nectar/models/message.model";

export class Helper {
  public static getToken(): string | undefined {
    const cookie = document.cookie;
    const cookies = cookie.split(";");
    for (const c of cookies) {
      const parts = c.split("=");
      const key = parts[0].trim();
      if (key === "token") {
        return parts[1];
      }
    }
    return undefined;
  }

  public static getTokenExpiry(): string | undefined {
    const cookie = document.cookie;
    const cookies = cookie.split(";");
    for (const c of cookies) {
      const parts = c.split("=");
      const key = parts[0].trim();
      if (key === "token_expiry") {
        return parts[1];
      }
    }
    return undefined;
  }

  // private static capitalizeFirstLetter(string: string): string {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  public static preprocessMessage(
    message: string,
    characterName: string,
    userName: string = "Anon",
  ): MessageFragment[] {
    if (typeof message !== "string") {
      return [];
    }

    let processedMessage = message.trim(); // remove starting and ending whitespace
    processedMessage = processedMessage.replace(/^\s+|\s+$/g, "");

    // Replace {char} with the first word of profile.name
    if (processedMessage.includes("{char}")) {
      const firstName = characterName.split(" ")[0];
      processedMessage = processedMessage.replace(/{char}/g, firstName);
    }

    // Replace {user} or Anon with the first word of user.name
    if (
      processedMessage.includes("{user}") ||
      processedMessage.includes("Anon")
    ) {
      const firstName = userName.split(" ")[0];
      processedMessage = processedMessage.replace(/{user}|Anon/g, firstName);
    }

    const fragments: string[] = processedMessage
      .split(/(\*.*?\*)|(".*?")/g)
      .filter((fragment) => fragment && fragment.trim() !== "") as string[];
    const structuredFragments: MessageFragment[] = [];

    fragments.forEach((fragment) => {
      if (fragment.startsWith("*") && fragment.endsWith("*")) {
        structuredFragments.push({
          content: fragment.slice(1, -1),
          isItalic: true,
        });
      } else if (fragment.startsWith('"') && fragment.endsWith('"')) {
        structuredFragments.push({
          content: fragment.slice(1, -1),
          isItalic: false,
        });
      } else {
        structuredFragments.push({
          content: fragment,
          isItalic: false,
        });
      }
    });

    structuredFragments.forEach((fragment) => {
      fragment.content = fragment.content.replace(
        /{char}/g,
        characterName.split(" ")[0],
      );
      fragment.content = fragment.content.replace(
        /{user}/g,
        userName.split(" ")[0],
      );
    });

    return structuredFragments;
  }

  public static preprocessDescription(description: string): string[] {
    let processedDescription = description.replace(/{char}'s/g, "");
    return processedDescription.split("\n");
  }

  public static parseDialogues(dialogueString: string): {
    [key: string]: string;
  } {
    // Split the string by "{char}:" and "{user}:"
    const dialogueEntries = dialogueString
      .split(/(?:\{char\}:|\{user\}:)/)
      .filter(Boolean);

    // Convert array into an object with string keys and values
    return dialogueEntries.reduce<{ [key: string]: string }>(
      (acc, curr, index) => {
        acc[`dialogue-${index + 1}`] = curr.trim();
        return acc;
      },
      {},
    );
  }
}
