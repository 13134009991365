import React, { useContext } from "react";

import LikeButton from "./LikeButton";
import TierIcon from "./TierIcon";
import { imagesContext } from "./ImagesContext";

const ImageCard = ({ exploreImage, changeUrl = false }) => {
  const imageCtx = useContext(imagesContext);

  return (
    <article className="group relative overflow-hidden rounded-2xl font-quicksand text-white">
      <img
        src={exploreImage.image_url}
        alt={exploreImage.prompt}
        onClick={() => {
          imageCtx.handleOpen(exploreImage, changeUrl);
        }}
        className="w-full cursor-pointer"
      />

      {/* overlay */}
      <div className="pointer-events-none absolute inset-0 flex flex-col justify-between p-4 transition-colors ease-in group-hover:bg-black/40 ">
        <div className="flex justify-between">
          {exploreImage.tier_title !== "Free" ? (
            <TierIcon tier={exploreImage.tier_title} />
          ) : (
            <span />
          )}

          <LikeButton exploreImage={exploreImage} />
        </div>
        <div className="pointer-events-auto hidden group-hover:block">
          <p className="line-clamp-3 text-sm text-white ">
            {exploreImage.prompt}
          </p>
        </div>
      </div>
    </article>
  );
};

export default ImageCard;
