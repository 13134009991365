import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { AppContext } from "../AppContext";
import { Helper } from "../../utils/helper";
import ImageCard from "../images/ImageCard";
import { ExploreImage } from "../../models/explore.image.model";
import { Grid } from "@material-ui/core";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import { Typography } from "@material-ui/core";
import { userService } from "../../services/user.service.ts";
import { useOnScreen } from "@nectar/hooks/useOnScreen";
import { fetcher } from "@nectar/lib/fetcher";
import Masonry from "react-masonry-css";
import "./styles/masonry.css";
import useSWRInfinite from "swr/infinite";

const IMAGES_PER_PAGE = 10;

const getKey = (pageIndex, previousPageData) => {
  if (pageIndex !== 0 && !previousPageData?.length) {
    console.log("No more pages to load");
    return null;
  }
  const url = userService.getLikedImagesURL(
    pageIndex * IMAGES_PER_PAGE,
    IMAGES_PER_PAGE,
  );
  return url;
};

const LikesCard = () => {
  const { likedImages, likesCardImages, setLikesCardImages } =
    useContext(AppContext);
  const { data, isLoading, setSize, size } = useSWRInfinite(getKey, fetcher);

  const [isVisible, containerRef] = useOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  useEffect(() => {
    if (data) {
      let imageMap = new Map();
      data.flat().forEach((image) => {
        imageMap.set(image.id, { ...image, liked: true });
      });
      setLikesCardImages(imageMap);
    }
  }, [data, setLikesCardImages]);

  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  const isEmpty = data?.[0]?.images?.length === 0;

  const isReachingEnd =
    isEmpty ||
    (data && data[data.length - 1]?.images?.length < IMAGES_PER_PAGE);

  console.log(data && data[data.length - 1]?.images?.length < IMAGES_PER_PAGE);

  useEffect(() => {
    if (isVisible && !isLoadingMore && !isReachingEnd) {
      setSize((size) => size + 1);
    }
  }, [isVisible, isLoadingMore, isReachingEnd]);

  const breakpointColumnsObj = {
    default: 4, // number of columns for default minimum viewport widths and above
    1280: 2, // number of columns for viewports widths of 1280px (lg) and above
    960: 1, // number of columns for viewports widths of 960px (md) and above
  };

  if (!userService.checkIsLoggedIn()) {
    return null;
  }

  if (likesCardImages?.size <= 0) {
    return (
      <div className="py-4">
        <div className="text-center">
          <h1 className="text-lg  font-semibold"> You have no likes. </h1>
          <h2 className="text-white/70">
            {" "}
            When you like a photo, it'll show up here.{" "}
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="py-4">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex gap-4 "
        columnClassName="flex-1 w-full flex flex-col gap-4"
      >
        {[...likesCardImages?.values()].map((image) => {
          return <ImageCard exploreImage={image} key={image.id} />;
        })}
      </Masonry>

      {!isLoadingMore && !isReachingEnd && (
        <div ref={containerRef} className="w-full h-[25px] mt-8 "></div>
      )}
    </div>
  );
};

export default LikesCard;
