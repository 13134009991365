import React, { useState, useContext, useEffect } from "react";

import { PersonStanding, Shirt, Smile, Star, Wrench } from "lucide-react";
import { AppContext } from "../AppContext";
import { CreateContext } from "../CreateContext";
import ExtraItem from "./ExtraItem";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { cn } from "@nectar/lib/utils";
import { Search } from "lucide-react";

const EXTRA_CATEGORIES = [
  {
    title: "FACES",
    value: "character",
    icon: <Smile className="w-4 h-4 shrink-0" />,
  },
  {
    title: "OUTFITS",
    value: "outfit",
    icon: <Shirt className="w-4 h-4 shrink-0" />,
  },
  {
    title: "POSES",
    value: "pose",
    icon: <PersonStanding className="w-4 h-4 shrink-0" />,
  },
  {
    title: "EFFECTS",
    value: "effect",
    icon: <Star className="w-4 h-4 shrink-0" />,
  },
];

//  -- ALERT --
// this method uses recursion to render itself inside the modal.
// keep that in mind in case you modify it to avoid an infinite loop.

const ExtraBoxModal = ({
  onShowPaywall,
  isRecursive,
  isCharacterModelSelected,
  defaultSelectedCategory = EXTRA_CATEGORIES[0].value,
}) => {
  const { ownedExtras } = useContext(AppContext);
  const {
    character,
    selectedCkpt,
    extras,
    selectedExtras,
    setSelectedExtras,
    taggedExtras,
    setTaggedExtras,
    searchInput,
    setSearchInput,
  } = useContext(CreateContext);

  const [selectedCategory, setSelectedCategory] = useState(
    defaultSelectedCategory,
  );

  // for better legibility
  const onModal = isRecursive;

  useEffect(() => {
    setSelectedCategory(defaultSelectedCategory);
  }, [selectedCkpt]);

  // reset search state when modal is closed
  useEffect(() => {
    return () => {
      if (!onModal) return;

      handleDialogClose();
    };
  }, []);

  const countTaggedExtras = () => {
    return taggedExtras.reduce((counts, extra) => {
      counts[extra.category] = (counts[extra.category] || 0) + 1;
      return counts;
    }, {});
  };

  const handleDialogClose = () => {
    // Reset search input state
    setSearchInput("");
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    const newTaggedExtras = getTaggedExtras();
    setTaggedExtras(newTaggedExtras);

    if (onModal) return;
    setSelectedExtras([...selectedExtras]);
  }, [selectedCkpt, extras, ownedExtras, searchInput, character]);

  const isLocked = (extra) => {
    return (
      !ownedExtras.some((e) => e.id === extra.id) && extra.tier_title !== "Free"
    );
  };

  const tierOrder = {
    Ultimate: 4,
    Pro: 3,
    Premium: 2,
    Free: 1,
  };

  const sortedExtras = extras?.toSorted((a, b) => {
    const aLocked = isLocked(a) ? 1 : 0;
    const bLocked = isLocked(b) ? 1 : 0;

    // If both extras are either locked or unlocked, sort by tier
    if (aLocked === bLocked) {
      // Use the tierOrder dictionary to get a numerical value for each tier
      const aTier = tierOrder[a.tier_title];
      const bTier = tierOrder[b.tier_title];
      // Subtract to get the sort order
      return bTier - aTier;
    }

    return aLocked - bLocked;
  });

  const getTaggedExtras = () => {
    const ckptTag = selectedCkpt.tags;
    const isAnime =
      (ckptTag ? ckptTag.includes("anime") : false) ||
      character?.category === "anime";
    const isCharRealistic = character?.category === "realistic";
    const isRealistic = !isAnime && !isCharRealistic;
    const taggedExtras = sortedExtras
      ? sortedExtras.filter((extra) => {
          const title = extra.title.toLowerCase();
          const tags = extra.tags?.map((tag) => tag.toLowerCase()) || [];

          const search = searchInput.toLowerCase().split(" ");

          const titleMatches = search.some((keyword) =>
            title.includes(keyword),
          );
          const tagMatches = tags.some((tag) =>
            search.some((keyword) => tag.includes(keyword)),
          );

          return (
            ((isAnime && extra.tags?.includes("anime")) ||
              (isRealistic && extra.tags?.includes("realistic")) ||
              (isCharRealistic &&
                extra.tags?.includes("character_realistic"))) &&
            (titleMatches || tagMatches)
          );
        })
      : [];
    return taggedExtras;
  };

  return (
    <div>
      {onModal && (
        <div className="relative mb-3 h-fit">
          <span className="absolute inset-y-0  flex items-center pl-3 pointer-events-none">
            <Search className="w-4 h-4 md:h-5 md:w-5" />
          </span>
          <input
            value={searchInput}
            onChange={handleSearch}
            type="text"
            className="border focus:border-gray-300 text-slate-200 transition-all duration-500  bg-transparent rounded-md pl-8 md:pl-10 py-2 w-[90%] lg:w-96 focus:outline-none text-sm md:text-base"
            placeholder="Search faces, outfits, poses & effects"
          />
        </div>
      )}

      <div className="flex justify-between items-center mb-3">
        {/* header */}
        <div className="flex items-center gap-2 font-semibold ">
          <Wrench className="w-4 h-4" />
          <h4>CUSTOMIZATIONS - Max 3</h4>
        </div>

        {!isRecursive && (
          <Dialog>
            <DialogTrigger asChild>
              <button className="text-white/70 hover:text-white">
                See all
              </button>
            </DialogTrigger>
            <DialogContent className="max-w-[1000px] h-full md:w-full max-h-[80svh] md:max-h-[70svh] overflow-auto scrollbar flex flex-col">
              <ExtraBoxModal
                onShowPaywall={onShowPaywall}
                isRecursive={true}
                defaultSelectedCategory={selectedCategory}
              />
            </DialogContent>
          </Dialog>
        )}
      </div>

      {/* selected content */}
      {selectedExtras?.length > 0 && (
        <div
          className={cn(
            "grid grid-cols-3 gap-3 mb-2",
            onModal && "grid-cols-3 md:grid-cols-8 gap-4",
          )}
        >
          {selectedExtras?.map((extraSelectedId) => {
            const selectedExtra = taggedExtras?.find(
              (e) => e.id === extraSelectedId,
            );

            // Only render ExtraItem if selectedExtra exists
            return selectedExtra ? (
              <div className="animate-show-modal" key={selectedExtra.id}>
                <ExtraItem extra={selectedExtra} isSelected={true} />
              </div>
            ) : null;
          })}
        </div>
      )}

      {/* tabs */}
      <div className="relative w-full h-[44px]">
        <div className="absolute inset-0 items-start flex gap-1 w-full overflow-x-auto scrollbar">
          {EXTRA_CATEGORIES.filter((category) => {
            if (isCharacterModelSelected && category.value === "character")
              return false;

            return true;
          }).map((category, i) => {
            const isActive = selectedCategory === category.value;
            const count = countTaggedExtras()[category.value] || 0;

            return (
              <button
                key={category.value}
                onClick={() => setSelectedCategory(category.value)}
                className="pr-3 pl-2 rounded-full py-1 bg-transparent text-sm hover:text-white disabled:bg-zinc-700 disabled:text-white text-white/70 flex gap-1 items-center whitespace-nowrap"
                disabled={isActive}
              >
                {category.icon}
                {category.title} {onModal ? `(${count})` : ""}
              </button>
            );
          })}
        </div>
      </div>

      {/* content */}
      <div
        className={cn(
          "grid grid-cols-3 gap-3 mt-2",
          onModal && "grid-cols-3 md:grid-cols-8 gap-4",
        )}
      >
        {taggedExtras
          .reduce((prev, curr) => {
            let taggedExtrasFiltered = [...prev];
            if (curr.category === selectedCategory) {
              if (!onModal && taggedExtrasFiltered?.length >= 3) {
                return taggedExtrasFiltered;
              }

              taggedExtrasFiltered.push(curr);
            }

            return taggedExtrasFiltered;
          }, [])
          .map((extra) => {
            const isSelected = selectedExtras.includes(extra.title);
            const shouldDim = selectedExtras.length === 3 && !isSelected;

            return (
              <div
                key={extra.id}
                className={`animate-show-modal ${
                  shouldDim ? "opacity-50" : ""
                }`}
              >
                <ExtraItem
                  extra={extra}
                  isSelected={isSelected}
                  onShowPaywall={onShowPaywall}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ExtraBoxModal;
