import React, { useContext } from "react";

import user_default from "../../assets/user_default.png";
import discord from "../../assets/discord.svg";
import reddit from "../../assets/reddit.svg";
import twitter from "../../assets/twitter.svg";
import { AppContext } from "../AppContext";
import { Link } from "react-router-dom";

const NavbarUser = () => {
  const appCtx = useContext(AppContext);

  return (
    <div className="text-white font-quicksand flex gap-3 items-center ">
      <a href="https://twitter.com/TryNectarAI" target="_blank">
        <img src={twitter} alt="twitter icon" className="w-6 h-6" />
      </a>

      <a href="https://reddit.com/r/NectarAI/" target="_blank">
        <img src={reddit} alt="reddit icon" className="w-6 h-6" />
      </a>

      <a href="https://discord.gg/4u2jFcz5VN" target="_blank">
        <img src={discord} alt="discord icon" className="w-6 h-6" />
      </a>
      <div className="w-0.5 h-5 bg-zinc-800" />
      {!!appCtx.user ? (
        <Link to={"/profile"}>
          <img src={user_default} alt="user icon default" className="w-8 h-8" />
        </Link>
      ) : (
        <button
          onClick={() => {
            appCtx.setShowLoginModal(true);
          }}
          className="bg-primary-500 py-1.5 px-4 rounded text-white font-semibold outline-none"
        >
          Login
        </button>
      )}
    </div>
  );
};

export default NavbarUser;
