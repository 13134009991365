"use client";

import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import useFantasyCategory from "@nectar/hooks/useFantasyCategory";
import FantasyTabsDropdown from "./FantasyTabsDropdown";
import { useMediaQuery } from "@material-ui/core";
import theme from "../../../../styles/theme";

const SORT = {
  MESSAGES: "messages",
  LIKES: "likes",
};

const textForTab = (value) => {
  if (value == "likes") {
    return "Likes";
  }

  return "Message Count";
};

const FantasyTabs = ({ sort: sortSelected, setSort }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const categorySelected = useFantasyCategory();

  const handleSelect = (f) => {
    setSort(f);
  };

  useEffect(() => {
    setSort(null);
  }, [categorySelected]);

  useEffect(() => {
    setSort(SORT.MESSAGES);
  }, [categorySelected]);

  return (
    <nav className="flex gap-4 items-center ">
      {/* dropdown */}
      <FantasyTabsDropdown />
      {/* divider */}
      <div className="w-0.5 h-[20px] bg-zinc-800" />

      <div className="flex flex-grow items-center gap-1.5  overflow-x-auto">
        {!isMobile ? (
          <p className="font-quicksand text-white/70 text-sm">Sort By: </p>
        ) : null}
        {Object.entries(SORT).map(([key, value]) => {
          const isActive = value === sortSelected;

          return (
            <button
              key={key}
              onClick={() => handleSelect(value)}
              className={`rounded-full text-sm pl-3 pr-4 py-2 flex-shrink-0 font-quicksand transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
                isActive ? "text-white" : "text-white opacity-60"
              }`}
            >
              {textForTab(value)}
            </button>
          );
        })}
      </div>
    </nav>
  );
};

export default FantasyTabs;
