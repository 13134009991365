import React from "react";
import Bullet from "./Bullet";
import heart_logo from "../../assets/heart_logo.png";
import home_fantasy from "../../assets/home_fantasy.svg";
import home_images from "../../assets/home_images.svg";
import home_girls from "../../assets/home_girls.svg";

const RoleplayInfo = () => {
  return (
    <div className="container mx-auto px-4 flex flex-col-reverse md:grid grid-cols-2 text-white font-quicksand py-12 gap-8 justify-items-center items-center pb-28">
      {/* Image */}

      <div className="">
        <div className="flex items-center gap-2">
          <img src={heart_logo} alt="heart logo icon" className="w-4 h-4" />
          <h2 className=" text-xl font-semibold">Roleplay</h2>
        </div>
        <p className=" max-w-sm opacity-70 mt-1">
          An immersive roleplay experience designed to bring fantasies to life.
        </p>

        <div className="flex flex-col gap-4 mt-4">
          <Bullet
            icon={home_girls}
            title={"Quality Girls"}
            content={"Choose or create your AI Girlfriend."}
          />
          <Bullet
            icon={home_images}
            title="Personalized Images"
            content={"Receive photo messages in stunning HD."}
          />
          <Bullet
            icon={home_fantasy}
            title="Dream Fantasies"
            content={"Select or create unfiltered scenarios."}
          />
        </div>
      </div>
      <div className="">
        <img
          src="/roleplay-info2.png"
          alt="roleplay info"
          className="rounded-2xl"
        />
      </div>
    </div>
  );
};

export default RoleplayInfo;
