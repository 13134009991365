import React from "react";
import grey_like_icon from "../../assets/grey_like_icon.svg";
import { Heart } from "lucide-react";

const GeneratingCard = () => {
  return (
    <div className="aspect-[1/1] bg-zinc-800 w-full animate-pulse rounded-2xl flex justify-center items-center flex-col gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-8 h-8 text-white/40 "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          className="animate-stroke-dash"
          style={{
            strokeDasharray: "70",
            animation: "strokeDash 2s linear infinite",
          }}
          d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
        />
      </svg>

      <p className="text-white/70">Generating Image...</p>
    </div>
  );
};

export default GeneratingCard;
