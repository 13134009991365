import React, { useContext } from "react";
import pattern_hero from "../../../assets/pattern-hero-opacity.png";
import { Helper } from "../../../utils/helper";
import { AppContext } from "../../../components/AppContext";
import TextPreprocess from "../../../utils/TextPreprocess";
import { Button, buttonVariants } from "@nectar/components/ui/button";
import { Link } from "react-router-dom";
import clsx from "clsx";

const RoleplayBannerContent = ({ fantasy }) => {
  const { description, title, thumbnail_image_url, characters } = fantasy;
  const { user } = useContext(AppContext);

  return (
    <div
      className=" w-full h-roleplay-banner bg-zinc-900 rounded-2xl flex font-quicksand overflow-hidden relative animate-show-modal"
      style={{
        backgroundImage: `url(${pattern_hero})`,
      }}
    >
      <div className="flex-1 p-8 relative flex items-start flex-col">
        <h2 className="text-lg md:text-2xl">{title}</h2>
        <div className="mt-2 flex-grow overflow-hidden">
          <p className="text-xs md:text-base line-clamp-3 text-white/80">
            {
              <TextPreprocess
                preprocess={Helper.preprocessMessage(
                  description,
                  characters[0].name,
                  user?.username || "user",
                )}
              />
            }
          </p>
        </div>

        <Link
          to={`/messages/${fantasy.id}`}
          className={clsx([buttonVariants(), "mt-2"])}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="btn-icon"
          >
            <path
              fillRule="evenodd"
              d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
              clipRule="evenodd"
            />
          </svg>
          Start now
        </Link>
      </div>
      <div className="flex-1 relative ">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-zinc-900 via-zinc-900/60 to-transparent"></div>
          <img
            className="w-full h-full object-cover "
            src={thumbnail_image_url}
            alt={`thumbnail image ${title}`}
          />
        </div>
      </div>
    </div>
  );
};

export default RoleplayBannerContent;
