import axios from "axios";
import { User, UserInfo } from "../models/user.model";
import { TokenPlan } from "../models/tokenplan.model";
import { Helper } from "../utils/helper";
import { AppContext } from "../components/AppContext";
import { useContext } from "react";
import { ExploreImage } from "../models/explore.image.model";
import { AiModel } from "../models/aimodel.model";
import { AccountStatus } from "../models/account_status.model";
import {
  SIWEProvider,
  SIWEConfig,
  ConnectKitProvider,
  SIWESession,
} from "connectkit";
import { SiweMessage } from "siwe";
import { WhopCheckout } from "../models/whop_checkout.model";
import Cookies from "universal-cookie";
import { MessagePack } from "../models/message_pack";
import { MessageCredits } from "@nectar/models/message_credits.model";

export class UserService {
  public checkIsLoggedIn(): boolean {
    return Helper.getToken() !== undefined;
  }

  public async siweGetNonce(): Promise<string> {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/siwe/nonce`,
        {},
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
    return "";
  }

  private async login(
    credential: string,
    loginType: string,
    affiliate: string,
  ): Promise<User> {
    try {
      // use axios to post user to server
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          login_type: loginType,
          affiliate: affiliate,
        },
        {
          headers: {
            Authorization: `Bearer ${credential}`,
          },
        },
      );

      if (response.status === 200) {
        // store token in cookies with expiration date
        const dt = new Date(response.data.expiry * 1000);
        document.cookie = `token=${
          response.data.token
        }; expires=${dt.toUTCString()}; path=/`;
        // document.cookie = `token_expiry=${response.data.expiry}; expires=${dt.toUTCString()}`;
        // sessionStorage.setItem("user", JSON.stringify(response.data.user));

        // TODO do we need to add some SIWE Session here?
      }
      // map response to user
      const user = new User(
        response.data.user.id,
        response.data.user.created_at,
        response.data.user.username,
        response.data.user.name,
        response.data.user.email,
        response.data.user.eth_address,
        response.data.user.enhanced_roleplay_enabled,
        response.data.user.whitelisted,
      );

      return user;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async loginWithGoogle(credential: string): Promise<User> {
    const cookies = new Cookies();
    const utmSource = cookies.get("utmSource");
    const utmMedium = cookies.get("utmMedium");
    const utmCampaign = cookies.get("utmCampaign");

    const affiliate_code =
      utmSource === "affiliate" && utmMedium === "referral"
        ? utmCampaign || ""
        : "";
    return await userService.login(credential, "GOOGLE", affiliate_code);
  }

  public async loginWithStytch(credential: string): Promise<User> {
    const cookies = new Cookies();
    const utmSource = cookies.get("utmSource");
    const utmMedium = cookies.get("utmMedium");
    const utmCampaign = cookies.get("utmCampaign");

    const affiliate_code =
      utmSource === "affiliate" && utmMedium === "referral"
        ? utmCampaign || ""
        : "";
    return await userService.login(credential, "STYTCH", affiliate_code);
  }

  public logout(): void {
    // remove token from local storage
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userSiweSession");

    // Clear the token and token_expiry cookies
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    document.cookie = `token_expiry=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }

  public async getUserInfo(user_id: string): Promise<UserInfo> {
    try {
      // use axios to post user to server
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${user_id}/profile`,
        {},
      );

      const userInfo = new UserInfo(response.data.id, response.data.username);

      return userInfo;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Get user from session storage
   * @returns User
   */
  // public getUser(): User | null {
  //     // get user from local storage
  //     const json = sessionStorage.getItem("user");

  //     if (json === null)
  //         return null;

  //     return JSON.parse(json) as User;
  // }

  /**
   * Get User Profile from Server
   * @returns User
   */
  public async getUserProfile(): Promise<User> {
    try {
      const credential = Helper.getToken();

      // use axios to post user to server
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/profile`,
        {
          headers: {
            Authorization: `Bearer ${credential}`,
          },
        },
      );

      const user = new User(
        response.data.id,
        response.data.created_at,
        response.data.username,
        response.data.name,
        response.data.email,
        response.data.eth_address,
        response.data.enhanced_roleplay_enabled,
        response.data.whitelisted,
      );

      return user;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getOwnedCheckpoints(): Promise<AiModel[]> {
    return this.getOwnedModels("checkpoints");
  }

  public async getOwnedExtras(): Promise<AiModel[]> {
    return this.getOwnedModels("extras");
  }

  public async incrementGenerationDownload(
    generation_uuid: string,
  ): Promise<number | null> {
    try {
      const token = Helper.getToken();

      const response = await axios.post<number>(
        `${process.env.REACT_APP_API_URL}/user/generation/download/${generation_uuid}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.status;
      }
      return null;
    } catch (error) {
      console.log("error incrementing generation downloads: ", error);
      throw error;
    }
  }

  public async getAccountStatus(): Promise<AccountStatus | null> {
    try {
      const token = Helper.getToken();

      const response = await axios.get<AccountStatus>(
        `${process.env.REACT_APP_API_URL}/user/account/status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.data as AccountStatus;
      }
      return null;
    } catch (error) {
      console.log("error getting account status: ", error);
      throw error;
    }
  }

  public async getMessageCredits(): Promise<MessageCredits | null> {
    try {
      const token = Helper.getToken();

      const response = await axios.get<MessageCredits>(
        `${process.env.REACT_APP_CHAT_API_URL}/messages/credits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.data as MessageCredits;
      }
      return null;
    } catch (error) {
      console.log("error obtaining user message credits: ", error);
      throw error;
    }
  }

  public async incrementProfilePhotoDownload(
    profile_photo_uuid: string,
  ): Promise<number | null> {
    try {
      const token = Helper.getToken();

      const response = await axios.post<number>(
        `${process.env.REACT_APP_API_URL}/bundle/photo/download/${profile_photo_uuid}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.status;
      }
      return null;
    } catch (error) {
      console.log("error incrementing profile photo downloads: ", error);
      throw error;
    }
  }

  public getLikedImagesURL(offset: number = 0, pageSize: number = 10) {
    console.log("GetLikedImagesIsCalled");
    const endpoint = `/user/likes?offset=${offset}&page_size=${pageSize}`;
    return `${process.env.REACT_APP_API_URL}${endpoint}`;
  }

  public async getLikedImageIds(): Promise<string[]> {
    try {
      const token = Helper.getToken();

      const response = await axios.get<string[]>(
        `${process.env.REACT_APP_API_URL}/user/likes/ids`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.data.map((item: string) => {
          return item as string;
        });
      }
      return [];
    } catch (error) {
      console.error("error getting liked images: ", error);
      throw error;
    }
  }

  public async likeImage(imageId: string): Promise<void> {
    try {
      const token = Helper.getToken();

      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/image/${imageId}/like/toggle`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.error("error liking image: ", error);
      throw error;
    }
  }

  public async getTokenPlans(): Promise<TokenPlan[]> {
    try {
      const token = Helper.getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/plans`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        // // Filter out the 'Free' plan
        // const filteredPlans = response.data.filter(
        //   (plan: TokenPlan) => plan.title !== "Free",
        // );
        //
        // return filteredPlans;
        return response.data;
      }
      return [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getMessagePacks(): Promise<MessagePack[]> {
    try {
      const token = Helper.getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/message-packs`,
        {},
      );

      if (response.status === 200 && response.data) {
        return response.data.map((item: MessagePack) => {
          return item as MessagePack;
        });
      }
      return [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async logBetaSignup(email: string): Promise<void> {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/signup/beta`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status !== 200) {
        throw new Error("Failed to store email in the backend");
      }
    } catch (error) {
      console.error("Error sending email to backend:", error);
    }
  }

  private async getOwnedModels(asset: string): Promise<AiModel[]> {
    try {
      const token = Helper.getToken();

      const response = await axios.get<AiModel[]>(
        `${process.env.REACT_APP_API_URL}/user/owned/${asset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.data.map((item: AiModel) => {
          return item as AiModel;
        });
      }
      return [];
    } catch (error) {
      console.error(`error getting owned ${asset}: `, error);
      throw error;
    }
  }

  public async getWhopCheckout(
    plan_id: string,
    redirect_url: string,
  ): Promise<WhopCheckout | null> {
    try {
      const cookies = new Cookies();
      const token = Helper.getToken();
      const utmSource = cookies.get("utmSource");
      const utmMedium = cookies.get("utmMedium");
      const utmCampaign = cookies.get("utmCampaign");

      const affiliate_code =
        utmSource === "affiliate" && utmMedium === "referral"
          ? utmCampaign || ""
          : "";

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/whop/checkout`,
        {
          plan_id: plan_id, // pass the plan_id here
          redirect_url: redirect_url,
          affiliate_code: affiliate_code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.data as WhopCheckout;
      }
      return null;
    } catch (error) {
      console.error(`error getting checkout session: `, error);
      throw error;
    }
  }

  public async editField(
    userId: string,
    field: string,
    newValue: string,
  ): Promise<User | null> {
    try {
      const token = Helper.getToken();
      const requestBody = {
        user: {
          id: userId,
          [field]: newValue,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/profile/${field}/edit`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.data as User;
      }
      return null;
    } catch (error) {
      console.error("error editing field: ", error);
      throw error;
    }
  }

  public async boostRoleplay(): Promise<void> {
    try {
      const token = Helper.getToken();

      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/roleplay/boost`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.error("error boosting roleplay: ", error);
      throw error;
    }
  }

  public async unboostRoleplay(): Promise<void> {
    try {
      const token = Helper.getToken();

      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/roleplay/unboost`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.error("error unboosting roleplay: ", error);
      throw error;
    }
  }

  public async checkCreateCharEligibility(): Promise<boolean> {
    try {
      const token = Helper.getToken();

      const response = await axios.get<boolean>(
        `${process.env.REACT_APP_CHAT_API_URL}/characters/create/eligible`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("error checking character creation eligibility: ", error);
      throw error;
    }
  }
}

export const userService = new UserService();
