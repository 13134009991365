import React, { useContext } from "react";

import { AppContext } from "../AppContext";

import SidebarLink from "./SidebarLink";
import AppSwitcher from "./AppSwitcher";
import SidebarSocial from "./SidebarSocial";

import { APPS } from "../../hooks/useApp";

import {
  Rocket,
  PlusSquare,
  Heart,
  Newspaper,
  LogIn,
  ChevronRight,
  ChevronLeft,
  Image,
  Users,
  HelpCircle,
  X,
  User,
  MessageSquare,
  Sparkles,
  UserSquareIcon,
  Users2,
} from "lucide-react";
import SidebarHeader from "./SidebarHeader";

const Sidebar = ({
  handleCloseSidebar,
  isCollapsed = true,
  app,
  toggleCollapse = () => {},
}) => {
  const appCtx = useContext(AppContext);

  const navItems = {
    "/images": {
      icon: <Rocket className="w-full h-full" />,
      text: "Explore",
      isPrivate: false,
    },
    "/create": {
      icon: <PlusSquare className="w-full h-full" />,
      text: "Create",
      isPrivate: false,
    },
    "/nudes": {
      icon: <Heart className="w-full h-full" />,
      text: "Nudes",
      isPrivate: false,
    },
    "/roleplay": {
      icon: <Rocket className="w-full h-full" />,
      text: "explore",
      path: "/roleplay",
      isPrivate: false,
    },
    "/fantasies": {
      icon: <Sparkles className="w-full h-full" />,
      text: "Fantasies",
      path: "/fantasies",
      isPrivate: false,
    },
    "/messages": {
      icon: <MessageSquare className="w-full h-full" />,
      text: "Messages",
      path: "/messages",
      isPrivate: true,
    },

    "/user/girls": {
      icon: <Users2 className="w-full h-full" />,
      text: "My Girls",
      path: "/user/girls",
      isPrivate: true,
    },
    "/user/fantasies": {
      icon: <UserSquareIcon className="w-full h-full" />,
      text: "My Fantasies",
      path: "/user/fantasies",
      isPrivate: true,
    },
  };

  return (
    <aside
      className={`h-[100svh] inset-x-0 w-[100svw] bg-black fixed z-30 md:border-r border-zinc-900 p-4 flex flex-col justify-between transition-[weight] ease-in ${
        isCollapsed ? "md:w-sidebar-collapsed items-center" : " md:w-sidebar"
      }`}
    >
      {/* Header */}
      <div className={`${isCollapsed ? "flex flex-col items-center" : ""}`}>
        <header className="flex justify-between">
          <SidebarHeader isCollapsed={isCollapsed} />

          {/* close | mobile */}
          <button
            onClick={handleCloseSidebar}
            className="text-white opacity-60 active:opacity-100 hover:opacity-100 p-1 md:hidden"
          >
            <X className="w-6 h-6" />
          </button>

          {/* collapse desktop */}
          {!isCollapsed && (
            <button
              onClick={() => {
                toggleCollapse();
              }}
              className="p-1 hidden md:block"
            >
              <ChevronLeft className="w-5 h-5 opacity-70" />
            </button>
          )}
        </header>

        {/* nav */}
        <nav className="text-white font-quicksand mt-4 ">
          {/* App paths */}
          {app?.paths.map((path) => {
            const item = navItems?.[path] || null;

            if (!item) return null;
            if (item.isPrivate && !appCtx.user) return null;

            return (
              <SidebarLink
                key={path}
                text={item?.text || "undefined"}
                icon={item?.icon}
                path={path}
                isCollapsed={isCollapsed}
              />
            );
          })}

          {/* home paths */}
          {!app && (
            <>
              <SidebarLink
                text={APPS.IMAGES.name}
                path={APPS.IMAGES.paths[0]}
                isCollapsed={isCollapsed}
                icon={<Image className="w-full h-full" />}
              />
              <SidebarLink
                text={APPS.ROLEPLAY.name}
                path={APPS.ROLEPLAY.paths[0]}
                isCollapsed={isCollapsed}
                icon={<Users className="w-full h-full" />}
              />
            </>
          )}
        </nav>
      </div>

      {isCollapsed && (
        <button
          onClick={() => {
            toggleCollapse();
          }}
          className="p-1"
        >
          <ChevronRight className="w-5 h-5 opacity-70" />
        </button>
      )}

      <div className={`flex flex-col  ${isCollapsed ? "gap-2" : "gap-2"}`}>
        <div className="mt-auto">
          {!!appCtx.user && (
            <SidebarLink
              text={"profile"}
              path={"/profile"}
              icon={<User className="w-full h-full" />}
              isCollapsed={isCollapsed}
            />
          )}
          <SidebarLink
            text={"guides"}
            path={"/guides"}
            icon={<Newspaper className="w-full h-full" />}
            isCollapsed={isCollapsed}
          />
          <SidebarLink
            text={"about"}
            icon={<HelpCircle className="w-full h-full" />}
            path={"/about"}
            isCollapsed={isCollapsed}
          />
        </div>
        <SidebarSocial isCollapsed={isCollapsed} />
        {!appCtx.user && (
          <button
            onClick={() => {
              appCtx.setShowLoginModal(true);
            }}
            className={`p-3 w-full rounded capitalize font-quicksand flex gap-2 items-center bg-primary font-semibold text-primary-foreground`}
          >
            <LogIn className={!isCollapsed ? "w-4 h-4" : "w-5 h-5"} />
            {!isCollapsed && <span>Login</span>}
          </button>
        )}
        <AppSwitcher app={app} isCollapsed={isCollapsed} />
      </div>
    </aside>
  );
};
export default Sidebar;
