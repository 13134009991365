import { useLocation } from "react-router-dom";
import useCategory, { CATEGORIES, DEFAULT_CATEGORY } from "./useCategory";

export const MODELS = {
  [CATEGORIES.ANIME]: {
    ALL: "hentai",
    ULTIMATE_HENTAI: "ultimate-hentai",
  },
  [CATEGORIES.REALISTIC]: {
    ALL: "realistic",
    D2_5: "2-5d",
    ULTIMATE_ASIAN: "ultimate-asian",
    ULTIMATE_REALISTIC: "ultimate-realistic",
  },
};

export const DEFAULT_MODEL = MODELS[DEFAULT_CATEGORY].ALL;


/**
 * @deprecated
 * @returns 
 */
const useModel = () => {
  const { search } = useLocation();
  const category = useCategory();
  const searchModel = new URLSearchParams(search).get("model") || "";

  const model = Object.values(MODELS[category]).includes(searchModel)
    ? searchModel
    : MODELS[category].ALL;

  return model;
};

export default useModel;
