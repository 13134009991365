import Grid from '@mui/material/Grid';
import React, {useContext, useEffect, useState, useRef} from "react";
import {AppContext} from "../AppContext";
import theme from "../../styles/theme";
import {ThemeProvider} from '@mui/material/styles';
import {
  Avatar,
  Divider,
  TextField,
  Fab,
  Box,
  Typography, useMediaQuery,
} from '@material-ui/core'
import send_icon_disabled from '../../assets/send_arrow_disabled.svg'
import send_icon_enabled from '../../assets/send_arrow_blue.svg'
import refresh_icon from "../../assets/refresh_icon.svg"
import arrow_backward from "../../assets/arrow_backward_ios.svg"
import {roleplayService} from "../../services/roleplay.service";
import {Snackbar, Alert} from '@mui/material'
import {Helper} from '../../utils/helper'
import TypingIndicator from './TypingIndicator';
import PaywallModal from '../auth/PaywallModal';


const NUM_MESSAGES = 10
const MESSAGE_LIMIT_PAYWALL_SOURCE = "message_limit"

/**
 * @deprecated use components/messages/MessageBox.js instead
 */

const MobileMessageBox = ({profile, conversation, closeModal}) => {
  const {user, showLoginModal, setShowLoginModal, refreshUser} = useContext(AppContext)

  const [messageContent, setMessageContent] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(user));
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingPreviousMessages, setIsFetchingPreviousMessages] = useState(false);
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [shouldScrollBottom, setShouldScrollBottom] = useState(false);
  const messageContainerRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [showPaywallModal, setShowPaywallModal] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "error",
    message: ''
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false
    });
  };

  useEffect(() => {
    setIsLoggedIn(Boolean(user));
  }, [user]);


  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'show_paywall',
      'paywall_source': source,
      // ... any additional data you want to send
    });
  }

  const handlePaywallModalOpen = (source) => {
    if (!isLoggedIn) {
      return;
    }
    showPaywallWithSource(source);
  }

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  }

  const fetchConversationHistory = async (pageNum = 1) => {
    try {
      // fetch if parameters are met
      setIsLoading(true);
      const response = await roleplayService.getConversationHistory(conversation.id, isLoggedIn, pageNum);
      if (response && response.messages) {
        if (pageNum === 1) { // fetch first page of messages
          setMessages(response.messages);
          setShouldScrollBottom(true);
        } else { // fetch previous messages
          setMessages(prevMessages => [...response.messages, ...prevMessages]);
          setShouldScrollBottom(false);
        }
        return response.messages;
      }
      return [];
    } catch (error) {
      console.error("Error fetching conversation history:", error);
      setSnackbar({
        open: true,
        severity: "error",
        message: "Error fetching messages. Please try again."
      });
    } finally {
      setIsFetchingPreviousMessages(false);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (conversation) {
      const fetchInitialMessages = async (currentPage) => {
        const fetchedMessages = await fetchConversationHistory(currentPage);

        if (fetchedMessages.length > 0 &&
          messageContainerRef.current.scrollHeight <= messageContainerRef.current.clientHeight) {
          // Fetch the next page if conditions are met
          fetchInitialMessages(currentPage + 1);
        } else {
          // Update the state with the final page number
          setPage(currentPage);
        }
      };

      // Start fetching from the first page
      fetchInitialMessages(page);
    }
  }, [profile]);


  const handleScroll = async (e) => {
    const top = e.target.scrollTop === 0;
    if (top && messages.length === page * NUM_MESSAGES) {

      setIsFetchingPreviousMessages(true);

      // Adjust the scroll position based on page number
      const fraction = page / (page + 1);
      const newScrollPosition = messageContainerRef.current.scrollHeight * fraction;

      await fetchConversationHistory(page + 1);
      setPage(prevPage => prevPage + 1);
      messageContainerRef.current.scrollTop = newScrollPosition;
    }
  };


  // TODO maybe use this?
  // useEffect(() => {
  //     const isLoggedIn = userService.checkIsLoggedIn();
  //     if (isLoggedIn && isInitialLoad) {
  //         setIsInitialLoad(false);
  //         refreshUser();
  //     }
  // }, [user]);

  const handleSendMessage = async () => {

    if (!isLoggedIn) {
      setShowLoginModal(true);
      return;
    }

    if (messageContent.trim() !== "") {
      const optimisticMessage = {
        id: new Date().getTime(),  // Temporary ID for the optimistic message
        sender: "{user}",
        message: messageContent,
        timestamp: new Date().toISOString(),
        is_greeting: false
      };
      setMessages(prevMessages => [...prevMessages, optimisticMessage]);
      setShouldScrollBottom(true);  // Add this line
      setIsLoading(true);

      try {
        setIsTyping(true);  // Set typing indicator to true after sending the message

        // Call the API to send the message
        const response = await roleplayService.sendMessage(conversation.id, {
          message: messageContent
        }, isLoggedIn); // Assuming loggedIn is available in your component


        if (response) {
          // Update the messages state with the actual response from the backend
          setShouldScrollBottom(true);
          setMessages(prevMessages => {
            // Remove the optimistic message and add the user's message and the character's response
            return [
              ...prevMessages.filter(msg => msg.id !== optimisticMessage.id),
              {
                id: optimisticMessage.id,
                sender: "{user}",
                message: messageContent,
                timestamp: optimisticMessage.timestamp,  // Using the timestamp from the optimistic message
                is_greeting: false
              },
              {
                sender: "{char}",
                message: response.message,
                timestamp: response.timestamp,
                is_greeting: response.is_greeting || false
              }
            ];
          });
        }
        setIsTyping(false);  // Set typing indicator to false after receiving the response
        setMessageContent(''); // Clear the input field

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setIsTyping(false);
          handlePaywallModalOpen(MESSAGE_LIMIT_PAYWALL_SOURCE);
          return;
        }
        console.error("Error sending message:", error);
        setSnackbar({
          open: true,
          severity: "error",
          message: "Error sending message. Please try again."
        });
        setMessages(prevMessages => prevMessages.filter(msg => msg.id !== optimisticMessage.id));
      }
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) { // Check if the pressed key is 'Enter' and shift is not being held down
      event.preventDefault(); // Prevent newline
      handleSendMessage();  // Call your message sending function
    }
  };


  const handleRegenerate = async () => {
    setIsLoading(true);
    setSnackbar({
      open: true,
      severity: "info",
      message: "Regenerating message..."
    });

    // Check if the last message is from the character
    if (messages[messages.length - 1].sender === "{char}") {
      // Remove the last message from the local state
      setMessages(prevMessages => prevMessages.slice(0, -1));
    }
    setIsTyping(true);

    try {
      // try regenerating
      const response = await roleplayService.regenerate(conversation.id);

      if (response) {

        // Add the new message to the local state
        setMessages(prevMessages => [...prevMessages, {
          sender: "{char}",
          message: response.message,
          timestamp: response.timestamp,
          is_greeting: false
        }]);
        setIsTyping(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsTyping(false);
        handlePaywallModalOpen(MESSAGE_LIMIT_PAYWALL_SOURCE);
        return;
      }

      console.error("Error regenerating message:", error);
      setSnackbar({
        open: true,
        severity: "error",
        message: "Error regenerating message. Please try again."
      });
    }
    setIsLoading(false);
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
  }

  useEffect(() => {
    if (shouldScrollBottom) {
      scrollToBottom();
      setShouldScrollBottom(false);  // Reset the state after scrolling
    }
  }, [shouldScrollBottom]);


  return (
    <ThemeProvider theme={theme}>
      <div className="fixed inset-0 z-50 flex flex-col justify-between overflow-y-auto bg-black"
           style={{alignItems: 'flex-start'}}>

        {/*======== HEADER CARD ============*/}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{paddingTop: '12px', paddingBottom: '12px', width: '100%'}}
        >
          <img src={arrow_backward} onClick={closeModal} alt="back" style={{marginLeft: '10px'}}/>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Avatar
              src={profile.profile_pic_url}
              alt={profile.username}
              sx={{
                height: '36px',
                width: '36px',
                border: '2px solid white',
                marginRight: '15px'
              }}
            />
            <Box>
              <Box display="flex" alignItems="center">
                <Typography variant="h3" style={{color: 'white'}}>
                  {profile.name}
                </Typography>
              </Box>
              <Typography variant="h5" style={{color: 'white', opacity: 0.7}}>
                @{profile.username}
              </Typography>
            </Box>
          </Box>
          {/* This is a placeholder to push the central content to the center */}
          <div style={{width: '24px'}}></div>

        </Box>

        {/*======== MESSAGES BOX ============*/}

        <Divider sx={{
          backgroundColor: "#343434",
        }}/>
        <div style={{flexGrow: 1, overflowY: 'auto', width: '100%'}} onScroll={handleScroll}
             ref={messageContainerRef}>
          {
            (messages || []).length === 0 ? (
              <div style={{textAlign: 'center', padding: '20px', color: 'white'}}>
                No messages yet.
              </div>
            ) : (
              (messages
                .filter(message => message.message && message.message.trim().length > 0) // Exclude messages with empty content
                .map((message, idx, allMessages) => {
                  const isLastMessage = idx === allMessages.length - 1;
                  const messageFragments = Helper.preprocessMessage(message.message, profile.name);
                  return (
                    <div key={idx}
                         style={{
                           width: '100%',
                           textAlign: message.sender === "{user}" ? 'right' : 'left',
                           padding: '10px 10px',
                           display: message.sender === "{user}" ? '' : 'flex',
                           // color: message.is_greeting ? 'white' : undefined
                         }}>
                      {message.is_greeting ? (
                        <Box p="5%">
                          <Typography variant="h5" style={{color: 'white', opacity: 0.7}}>
                            {messageFragments.map((fragment, fIdx) =>
                              <React.Fragment key={fIdx}>
                                {fragment.isItalic ?
                                  <i>{fragment.content}</i> :
                                  <span>{fragment.content}</span>
                                }
                                <br/>
                              </React.Fragment>
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <div
                          style={{
                            backgroundColor: message.sender === "{user}" ? '#008BF0' : '#E9E9EB',
                            borderRadius: '15px',
                            padding: '10px',
                            display: 'inline-block',
                            maxWidth: '65%',
                            textAlign: 'left'

                          }}>
                          {messageFragments.map((fragment, fIdx) =>
                            <React.Fragment key={fIdx}>
                              {fragment.isItalic ?
                                <i>{fragment.content}</i> :
                                <span>{fragment.content}</span>
                              }
                              <br/>
                              {fIdx !== messageFragments.length - 1 && <br/>}
                            </React.Fragment>
                          )}
                        </div>
                      )}
                      {message.sender !== '{user}' && message.is_greeting === false && idx === allMessages.length - 1 &&
                        <img src={refresh_icon} alt="Refresh"
                             onClick={handleRegenerate}
                             style={{marginLeft: '10px', verticalAlign: 'middle'}}/>
                      }
                      {message.is_greeting && isLastMessage &&
                        <Box style={{
                          width: '50%',
                          textAlign: 'center',
                          padding: '10px 10px',
                          position: 'absolute',
                          top: '50%',
                          left: '25%',
                          transform: 'translateY(-50%)',
                          color: 'white',
                          opacity: 0.7
                        }}>
                          <Typography variant="h4">
                            HELPFUL TIPS:
                          </Typography>
                          <Typography variant="h5">
                            For actions, place them in
                            asterisks like so:
                          </Typography>
                          <Typography variant="h5">
                            *waves hello*
                          </Typography>
                          <br/>
                          <Typography variant="h5">
                            All other text will be interpreted as if you were speaking
                            or texting a response. No need for quotations. You can combine
                            them as such:
                          </Typography>
                          <Typography variant="h5">
                            *waves hello* Good morning, love.
                          </Typography>
                          <br/>
                          <Typography variant="h5" className="text-center text-white"
                                      sx={{opacity: 0.7, fontWeight: 'bold'}}>
                            If you don't like the response, please press the refresh icon
                            next to the message to regenerate.
                          </Typography>
                        </Box>
                      }
                    </div>
                  )
                }))
            )

          }
          {isTyping && <TypingIndicator sx={{padding: '10px'}}/>}
          <div ref={messagesEndRef}/>
        </div>

        {/*======== TEXTING BOX ============*/}

        <Divider sx={{
          backgroundColor: "#343434",
        }}/>
        <Grid container style={{padding: '10px'}} alignItems="center" justifyContent="center">
          <div style={{flexGrow: 1, paddingRight: '5px'}}>
            <TextField
              label="Message"
              fullWidth
              value={messageContent}
              multiline
              maxLength={500}
              sx={{backgroundColor: '#171717',}}
              onChange={(e) => {
                // don't exceed 500 chars
                if (e.target.value.length <= 500) {
                  setMessageContent(e.target.value);
                }
              }}
              onKeyDown={handleKeyDown}
              InputLabelProps={{
                style: {color: '#B0B0B0'}
              }}
              InputProps={{
                style: {color: 'white'}
              }}
              disabled={isLoading}
            />
          </div>
          <div>
            <Fab color="primary" aria-label="add"
                 onClick={handleSendMessage}
                 sx={{
                   height: '24px',
                   width: '24px',
                   minHeight: '24px',
                   minWidth: '24px'
                 }}
                 disabled={!messageContent || isLoading}
            >
              {messageContent && !isLoading ? <img src={send_icon_enabled} alt="send arrow"/> :
                <img src={send_icon_disabled} alt="send arrow disabled"/>}
            </Fab>
          </div>
        </Grid>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        {!showLoginModal && showPaywallModal && (
          <div>
            <PaywallModal open={showPaywallModal} onClose={handlePaywallModalClose}
                          refreshUser={refreshUser}
                          loggedInUser={user}/>
          </div>
        )}
      </div>
    </ThemeProvider>
  )

}

export default MobileMessageBox;