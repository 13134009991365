import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../components/AppContext";
import { exploreService } from "../../services/explore.service";
import Container from "@mui/material/Container";
import { CircularProgress, Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import { userService } from "../../services/user.service";
import { Link } from "react-router-dom";
import ExploreProfileCard from "../profiles/ExploreProfileCard";
import { PROFILES_PER_PAGE } from "../../pages/MobilePhotosetPage";
import { profileService } from "../../services/profile.service";
import PhotosetBundleCard from "../profiles/PhotosetBundleCard";
import Button from "@mui/material/Button";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { roleplayService } from "../../services/roleplay.service";

const MobileNudesCard = () => {
  const [exploreProfiles, setExploreProfiles] = useState([]);
  const [characters, setCharacters] = useState([]);
  const {
    user,
    setUser,
    refreshUser,
    accountStatus,
    setAccountStatus,
    setShowLoginModal,
  } = useContext(AppContext);
  const [fetchedBundles, setFetchedBundles] = useState([]);
  const [bundleLoading, setBundleLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const ownedBundleIds = useMemo(
    () => accountStatus?.owned_bundle_ids,
    [accountStatus],
  );

  const handleLoginModalOpen = () => {
    setShowLoginModal(true);
  };

  let lastProfileId = null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadProfiles = async () => {
    try {
      setIsLoading(true);
      const data = await roleplayService.getCharacters(
        "official",
        1,
        PROFILES_PER_PAGE,
      );
      if (data) {
        setCharacters(data.characters);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadProfiles();
  }, []);

  useEffect(() => {
    const fetchBundles = async () => {
      try {
        setBundleLoading(true);
        const fetched = [];
        for (const bundleId of accountStatus?.owned_bundle_ids) {
          // const bundle = await profileService.getBundleById(bundleId, userService.checkIsLoggedIn());
          const bundle = await roleplayService.getBundleById(
            bundleId,
            userService.checkIsLoggedIn(),
          );
          if (bundle) {
            fetched.push(bundle);
          }
        }
        fetched.sort((a, b) => {
          // First, sort by profile_id
          if (a.profile_id !== b.profile_id) {
            // If profile_ids are not the same, sort by their name
            return a.profile.name.localeCompare(b.profile.name);
          } else {
            // If profile_ids are the same, then sort by price
            return b.price - a.price;
          }
        });

        setFetchedBundles(fetched);
      } catch (error) {
        console.error("Error fetching bundles: ", error);
      } finally {
        setBundleLoading(false);
      }
    };
    if (ownedBundleIds) {
      fetchBundles();
    }
  }, [ownedBundleIds]);

  return (
    <Container sx={{ maxWidth: "100%" }}>
      <ThemeProvider theme={theme}>
        <div className="bg-black w-full min-h-screen p-4 mb-20">
          <Grid>
            <h1 className="text-white font-quicksand font-normal text-16 leading-20 mb-4">
              MY NUDES
            </h1>
            {user && bundleLoading && <CircularProgress color="secondary" />}
            {user && userService.checkIsLoggedIn() ? (
              accountStatus &&
              accountStatus.owned_bundle_ids &&
              accountStatus.owned_bundle_ids.length > 0 &&
              fetchedBundles ? (
                fetchedBundles.map((bundle, bundleIndex) => {
                  // const showProfileName = bundle.profile_id !== lastProfileId;
                  // lastProfileId = bundle.profile_id;

                  return (
                    <Grid key={bundleIndex}>
                      <PhotosetBundleCard showProfileName="" bundle={bundle} />
                    </Grid>
                  );
                })
              ) : (
                <Typography
                  variant="h3"
                  align="left"
                  sx={{
                    color: "white",
                    marginLeft: "14px",
                    paddingTop: "100px",
                  }}
                >
                  No purchased nudes.
                </Typography>
              )
            ) : (
              <div className="text-center mt-5">
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                    paddingBottom: "20px",
                  }}
                >
                  <Typography variant="h1">
                    Please log in to see your nudes.
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleLoginModalOpen}
                >
                  Login / Sign Up
                </Button>
              </div>
            )}
          </Grid>
          <Grid>
            <Typography
              variant="h1"
              sx={{ color: "white", paddingTop: "10px", marginLeft: "14px" }}
            >
              Featured Babes
            </Typography>
            <Typography
              variant="h4"
              sx={{ color: "white", paddingBottom: "10px", marginLeft: "14px" }}
            >
              Our highest quality girls, curated just for you.
            </Typography>
            {isLoading && <CircularProgress color="secondary" />}
            {characters &&
              characters.map((profile, index) => (
                <Link
                  to={`/profile/${profile.username}`}
                  onClick={() =>
                    sessionStorage.setItem("visitedProfile", "true")
                  }
                  key={index}
                >
                  <ExploreProfileCard profile={profile} />
                </Link>
              ))}
          </Grid>
        </div>
      </ThemeProvider>
    </Container>
  );
};

export default MobileNudesCard;
