import { Delete, X } from "lucide-react";
import React, { useEffect, useRef } from "react";
import user_default from "@nectar/assets/user_default.png";
import useAutoResize from "@nectar/hooks/useAutoResize";

const FCDialogExampleItem = ({
  index,
  dialogues,
  setDialogues,
  isLastedDialog,
  isCharacterDialog,
  handleRemoveLastDialog,
  handleAddDialog,
  characterSelected,
}) => {
  const inputRef = useRef(null);
  useAutoResize(inputRef, 1000);

  if (dialogues[index] === undefined) return null;

  return (
    <div className="bg-zinc-800 rounded px-4 py-3 flex gap-2">
      <div className="flex items-start gap-2">
        <img
          src={
            isCharacterDialog
              ? characterSelected?.profile_pic_url || user_default
              : user_default
          }
          className="w-6 h-6 rounded-full object-cover"
          alt="character selected"
        />
        {isCharacterDialog ? "Character" : "User"}
      </div>

      <textarea
        value={dialogues[index]}
        id={index}
        ref={inputRef}
        onChange={(e) =>
          setDialogues({ ...dialogues, [index]: e.target.value })
        }
        onKeyDown={(e) => {
          // if shift + enter, add new line on desktop
          if (e.key === "Enter" && e.shiftKey) {
            return;
          }

          if (e.key === "Enter" && isLastedDialog) {
            e.preventDefault();
            handleAddDialog();
          }
        }}
        rows={1}
        placeholder="what happens next?"
        className="flex-grow bg-transparent outline-none resize-none "
      />

      {isLastedDialog && (
        <button
          onClick={() => handleRemoveLastDialog()}
          type="button"
          className="p-1 h-fit"
        >
          <X className="w-4 h-4 text-white/70" />
        </button>
      )}
    </div>
  );
};

export default FCDialogExampleItem;
