import React, {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import {useParams} from 'react-router-dom';
import {ThemeProvider, Typography} from '@material-ui/core';
import frontMatter from "front-matter";
import theme from "../../styles/theme";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {HelmetProvider, Helmet} from "react-helmet-async";


function Post() {
    const {guideName} = useParams();
    const [markdownContent, setMarkdownContent] = useState('');
    const [metadata, setMetadata] = useState({});


    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/guides/${guideName}.md`)
            .then(response => response.text())
            .then(text => {
                const {attributes, body} = frontMatter(text)
                setMarkdownContent(body);
                setMetadata(attributes);
            })
    }, [guideName]);

    return (
        <ThemeProvider theme={theme}>
            <HelmetProvider>
                <div className="parent h-auto bg-black flex -mb-8 min-h-screen">
                    <Helmet>
                        <title>{"Nectar - " + metadata.title}</title>
                        <meta name="description" content={metadata.description}/>
                    </Helmet>
                    <Container sx={{width: {xs: '80%', sm: '60%'}, paddingBottom: '120px'}}>
                        <Box sx={{
                            '& img': {
                                maxWidth: {xs: '100%', sm: '60%'}
                            }
                        }}>
                            <ReactMarkdown components={{
                                h1: ({node, ...props}) => <Typography variant="h1" color="white" {...props}
                                                                      style={{
                                                                          fontSize: {xs: '2em', sm: '48px'},
                                                                          paddingTop: '32px',
                                                                          paddingBottom: '32px'
                                                                      }}/>,
                                h2: ({node, ...props}) => <Typography variant="h2" color="white" {...props}
                                                                      style={{
                                                                          fontSize: {xs: '1.5em', sm: '30px'},
                                                                          fontWeight: '600',
                                                                          paddingTop: '24px',
                                                                          paddingBottom: '24px'
                                                                      }}/>,
                                h3: ({node, ...props}) => <Typography variant="h3" color="white" {...props}
                                                                      style={{
                                                                          fontSize: {xs: '1.2em', sm: '24px'},
                                                                          fontWeight: '600',
                                                                          paddingTop: '24px',
                                                                          paddingBottom: '24px'
                                                                      }}/>,
                                h4: ({node, ...props}) => <Typography variant="h4" color="white" {...props}
                                                                      style={{
                                                                          fontSize: {xs: '1em', sm: '1em'}
                                                                      }}/>,
                                p: ({node, ...props}) => <Typography variant="body1" color="white" {...props}
                                                                     style={{
                                                                         fontSize: {xs: '0.8em', sm: '18px'},
                                                                         lineHeight: '28px',
                                                                         fontWeight: '400'
                                                                     }}/>,
                                blockquote: ({node, ...props}) => <Typography variant="body1"
                                                                              {...props}
                                                                              style={{
                                                                                  color: '#FFF',
                                                                                  textAlign: 'center',
                                                                                  fontFamily: 'Inter',
                                                                                  fontSize: {xs: '1.2em', sm: '24px'},
                                                                                  fontStyle: 'italic',
                                                                                  fontWeight: '700',
                                                                                  lineHeight: '36px'
                                                                              }}/>,
                                a: ({node, ...props}) => <a
                                    style={{textDecoration: 'underline', color: 'white'}} {...props} />,

                                img: ({node, ...props}) => <div
                                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img {...props}
                                         style={{
                                             height: "auto",
                                             paddingTop: '24px',
                                             paddingBottom: '24px'
                                         }}/>
                                </div>
                            }}>
                                {markdownContent}
                            </ReactMarkdown>
                        </Box>
                    </Container>
                </div>
            </HelmetProvider>
        </ThemeProvider>
    );
}

export default Post;
