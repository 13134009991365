import React, { useState } from "react";
import FCDialogExampleItem from "./FCDialogExampleItem";

const FantasyCreateDialogExample = ({
  dialogues,
  setDialogues,
  characterSelected,
}) => {
  const dialogKeys = Object.keys(dialogues || {});

  const handleAddDialogue = () => {
    if (dialogKeys.length > 20) return;

    setDialogues({
      ...dialogues,
      [`dialogue-${dialogKeys.length + 1}`]: "",
    });

    // focus on the last input
    setTimeout(() => {
      const input = document.getElementById(
        `dialogue-${dialogKeys.length + 1}`,
      );
      input.focus();
    }, 100);
  };

  const handleRemoveLastDialog = () => {
    if (dialogKeys.length === 1) {
      setDialogues({
        "dialogue-1": "",
      });
      return;
    }

    const newDialogues = { ...dialogues };
    delete newDialogues[`dialogue-${dialogKeys.length}`];
    setDialogues(newDialogues);
  };

  return (
    <div>
      <h3 className="mb-1 ">Fantasy Dialogue</h3>
      <p className="text-sm text-white/70 mb-4">
        Tell your fantasy here! The character goes first. Actions/Descriptions
        should be surrounded by asterisks(*) and dialogue in quotes (" ").
        Reference the other party using {"{char}"} and {"{user}"}. Ex: "Hey{" "}
        {"{char}"}, I missed you so much."
      </p>
      {/* <FCDialogExampleItem key={0} dialogues={dialogues} setDialogues={setDialogues} /> */}
      <div className="flex flex-col gap-2">
        {Object.keys(dialogues).map((key, index) => {
          return (
            <FCDialogExampleItem
              key={key}
              index={key}
              isCharacterDialog={index % 2 === 0}
              isLastedDialog={index === Object.keys(dialogues).length - 1}
              handleRemoveLastDialog={handleRemoveLastDialog}
              dialogues={dialogues}
              handleAddDialog={handleAddDialogue}
              setDialogues={setDialogues}
              characterSelected={characterSelected}
            />
          );
        })}
      </div>

      {dialogKeys.length < 20 && (
        <button
          onClick={handleAddDialogue}
          type="button"
          className="btn justify-center border border-zinc-700 w-full mt-2 hover:btn-secondary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span> ADD DIALOGUE</span>
        </button>
      )}
    </div>
  );
};

export default FantasyCreateDialogExample;
