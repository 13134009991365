import { ThemeProvider, Typography } from "@material-ui/core";
import Container from "@mui/material/Container";
import GuideCard from "../components/guides/GuideCard";
import Grid from "@mui/material/Grid";
import theme from "../styles/theme";
import React from "react";

function GuidesPage() {
  // Remember the public URL is just a /
  const guides = [
    "/guides/How to Create Your Fantasy with Nectar Roleplay.md",
    "/guides/How to Use Nectar AI Roleplay.md",
    "/guides/Make Your First AI Girl with Nectar.md",
    "/guides/A Guide to Writing Prompts.md",
  ];

  const blogs = [
    "/guides/AI Porn.md",
    "/guides/AI Sexting.md",
    "/guides/How AI Models Revolutionize Everyday Life.md",
    "/guides/The Entertainment Revolution.md",
    "/guides/The Rise of Realism in AI Chatbots.md",
    "/guides/What are AI Chatbots.md",
    "/guides/Finding Love in Digital Spaces.md",
    "/guides/The Health Benefits of AI Romantic Partners.md",
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className="parent h-auto bg-black flex -mb-8 min-h-screen">
        <Container sx={{ width: "80%" }}>
          <Typography
            variant="h1"
            align="left"
            sx={{ color: "white", paddingTop: "32px", paddingBottom: "32px" }}
          >
            Guides
          </Typography>
          <Typography
            variant="h2"
            sx={{ color: "white", marginBottom: "1rem" }}
          >
            Highlights From Us
          </Typography>

          <Typography
            className="description text-white"
            sx={{ opacity: "0.7", marginBottom: "1rem" }}
          >
            The latest guides and tutorials to help you navigate your journey.{" "}
          </Typography>

          <div className="guides-list">
            <Grid container spacing={3} className="flex flex-wrap">
              {guides.map((guidePath, index) => (
                <Grid item xs={12} sm={6} md={4} className="flex">
                  <GuideCard
                    key={index}
                    filePath={guidePath}
                    className="flex flex-grow"
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          <Typography
            variant="h2"
            sx={{ color: "white", marginBottom: "1rem", paddingTop: "32px" }}
          >
            General AI Updates
          </Typography>
          <Typography
            className="description text-white"
            sx={{ opacity: "0.7", marginBottom: "1rem" }}
          >
            Blog posts to learn more about new advancements in AI.{" "}
          </Typography>

          <div className="guides-list">
            <Grid container spacing={3} className="flex flex-wrap">
              {blogs.map((guidePath, index) => (
                <Grid item xs={12} sm={6} md={4} className="flex">
                  <GuideCard
                    key={index}
                    filePath={guidePath}
                    className="flex flex-grow"
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default GuidesPage;
