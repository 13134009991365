import React, { useContext } from "react";
import { cn } from "@nectar/lib/utils";
import {
  Lock,
  RectangleHorizontal,
  RectangleVertical,
  Square,
} from "lucide-react";

import { AppContext } from "../AppContext";
import { Button } from "@nectar/components/ui/button";
import { CreateContext } from "../CreateContext";

import landscape_icon from "../../assets/landscape_icon.svg";
import square_icon from "../../assets/square_icon.svg";
import portrait_icon from "../../assets/portrait_icon.svg";

const AspectRatioBox = ({ onShowPaywall }) => {
  const { accountStatus } = useContext(AppContext);
  const { aspectRatio, setAspectRatio } = useContext(CreateContext);

  const handleSquare = (event) => {
    setAspectRatio("");
  };

  const handlePortrait = (event) => {
    event.stopPropagation();
    if (accountStatus?.plan_tier > 3) {
      setAspectRatio("portrait");
    } else {
      onShowPaywall();
      return;
    }
  };

  const handleLandscape = (event) => {
    event.stopPropagation();
    if (accountStatus?.plan_tier > 3) {
      setAspectRatio("landscape");
    } else {
      onShowPaywall();
      return;
    }
  };

  const AspectRatioButton = ({ type, x, y, handler }) => {
    const iconMap = {
      "": <Square className="h-5 w-5 mr-2" />,
      landscape: <RectangleHorizontal className="h-5 w-5 mr-2" />,
      portrait: <RectangleVertical className="h-5 w-5 mr-2" />,
    };

    const icon = iconMap[type];

    const isSelected = aspectRatio === type;

    return (
      <Button
        variant={"outline"}
        disabled={isSelected}
        onClick={handler}
        className={cn(
          isSelected
            ? "disabled:opacity-100 border-primary text-primary"
            : "text-white/70",
        )}
      >
        {icon}
        <span>
          {x}:{y}
        </span>
      </Button>
    );
  };

  return (
    <div
      className={cn(
        accountStatus?.plan_tier > 3 ? "" : "flex justify-between items-center",
      )}
    >
      <div className="flex gap-2 items-center">
        <Square className="h-4 w-4" />
        <h4 className="uppercase font-semibold">DIMENSIONS</h4>
      </div>
      {accountStatus?.plan_tier > 3 ? (
        <div className="flex gap-2 mt-3">
          <AspectRatioButton type="" x={1} y={1} handler={handleSquare} />
          <AspectRatioButton
            type="portrait"
            x={2}
            y={3}
            handler={handlePortrait}
          />
          <AspectRatioButton
            type="landscape"
            x={3}
            y={2}
            handler={handleLandscape}
          />
        </div>
      ) : (
        <button
          onClick={() => onShowPaywall()}
          className="flex gap-2  items-center text-primary"
        >
          <Lock className="w-4 h-4 opacity-70" />
          ULTIMATE
        </button>
      )}
    </div>
  );
};

export default AspectRatioBox;
