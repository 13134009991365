"use client";

import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import useCategory from "../../hooks/useCategory";
import ExploreTabsDropdown from "./ExploreTabsDropdown";
import useModel, { MODELS } from "../../hooks/useModel";
import { AppContext } from "../AppContext";
import { CreateContext } from "../CreateContext";

const ExploreTabs = ({ model: modelSelected, setModel }) => {
  const categorySelected = useCategory();
  const { ckpts } = useContext(CreateContext);

  const handleSelect = (m) => {
    setModel(m);
  };

  useEffect(() => {
    setModel(null);
  }, [categorySelected])
  

  return (
    <div className="sticky top-[65px] md:top-0 z-20 flex justify-start gap-2  py-3  px-4 pointer-events-none bg-gradient-to-b bg-black/80 backdrop-blur text-white">
      <nav className="flex pointer-events-auto gap-4 items-center  w-full">
        {/* dropdown */}
        <ExploreTabsDropdown />
        {/* divider */}
        <div className="w-0.5 h-3/4 bg-zinc-800" />

        <div className="flex flex-grow items-center gap-1.5  overflow-x-auto">
          <p className="font-quicksand text-white/70 text-sm">Models: </p>
          <button
            onClick={() => handleSelect(null)}
            className={`rounded-full text-sm px-4 py-2 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
              modelSelected === null ? " text-white" : "text-white opacity-60"
            }`}
          >
            All
          </button>
          {ckpts
            .filter((model) => model.tags.includes(categorySelected))
            .map((model) => {
              const isActive = model.id === modelSelected?.id;

              return (
                <button
                  key={model.id}
                  onClick={() => handleSelect(model)}
                  className={`rounded-full text-sm pl-3 pr-4 py-2 flex-shrink-0 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
                    isActive ? " text-white" : "text-white opacity-60"
                  }`}
                >
                  <img
                    className="w-4 h-4 rounded-full shrink-0"
                    src={model.image_urls[0]}
                    alt={model.title}
                  />
                  {model.title
                    .split(" ")
                    .filter(
                      (word) =>
                        !["nectar", "model"].includes(word.toLowerCase()),
                    )
                    .join(" ")}
                </button>
              );
            })}
        </div>
      </nav>
    </div>
  );
};

export default ExploreTabs;
