import React from "react";
import CopyButton from "./CopyButton";

const DetailedImageCardSection = ({ title = "Lorem", content = "" }) => {
  return (
    <div className="mb-4">
      <h2 className="text-white/70">{title}</h2>
      <p className="mb-2">{content}</p>

      <CopyButton label={`Copy ${title}`} content={content} />
    </div>
  );
};

export default DetailedImageCardSection;