import React, { useContext, useState } from "react";
import { Button } from "../ui/button";
import { ImagePlus } from "lucide-react";
import { AppContext } from "../AppContext";
import { userService } from "@nectar/services/user.service";
import PaywallModal from "../auth/PaywallModal";
import { useNavigate } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const PAYWALL_SOURCE_PROFILE_CREATE = "profile_create";

const DiscoverProfileCreate = ({ profile }) => {
  const { user, accountStatus, refreshUser } = useContext(AppContext);
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const navigate = useNavigate();

  const handleCreateClick = () => {
    if (accountStatus?.plan_tier > 3) {
      navigate(`/create?character=${profile.id}`);
    } else {
      handlePaywallModalOpen(PAYWALL_SOURCE_PROFILE_CREATE);
    }
  };

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!userService.checkIsLoggedIn()) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  if (user?.id !== profile.creator_id) return null;
  if (profile.images_enabled == false) return null;

  return (
    <>
      <Tooltip title="Customize in Image Creator" placement="top">
        <Button
          variant={"secondary"}
          size={"icon"}
          onClick={handleCreateClick}
          style={{ padding: "1px 1px" }}
        >
          <ImagePlus className="w-4 h-4" />
        </Button>
      </Tooltip>
      {showPaywallModal && (
        <PaywallModal
          message={`Upgrade to an ULT plan to customize ${profile.name} in our Image Creator!`}
          open={showPaywallModal}
          onClose={handlePaywallModalClose}
          refreshUser={refreshUser}
          loggedInUser={user}
        />
      )}
    </>
  );
};

export default DiscoverProfileCreate;
