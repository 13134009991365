import React from "react";

// import { Avatar } from "@material-ui/core";
import { Helper } from "../../utils/helper";

import refresh_icon from "../../assets/refresh_icon.svg";
import clsx from "clsx";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { RefreshCcw } from "lucide-react";

const MessagesBubble = ({
  isUserAuthor,
  message,
  timestamp,
  character,
  isTyping = false,
  isLatest = false,
  regenerateMessage,
  user,
}) => {
  const messageProcessed = Helper.preprocessMessage(
    message,
    character.name,
    user?.name || "user",
  );
  return (
    <div
      className={`flex gap-2 ${isUserAuthor ? "justify-end" : "justify-start"}`}
    >
      {!isUserAuthor && (
        <Avatar className="w-8 h-8">
          <AvatarImage src={character.profile_pic_url} />
          <AvatarFallback> {character?.username.slice(0, 2)} </AvatarFallback>
        </Avatar>
      )}

      <div
        className={clsx([
          "max-w-[60%] rounded-2xl p-3",
          isUserAuthor ? "bg-blue-600" : "bg-zinc-800",
          isTyping &&
            isLatest &&
            !isUserAuthor &&
            "animate-pulse text-zinc-400",
        ])}
      >
        {messageProcessed.map((fragment, i) => {
          return (
            <React.Fragment key={i}>
              {fragment.isItalic ? (
                <span className="italic opacity-60">{fragment.content} </span>
              ) : (
                <span>
                  {fragment.content}
                  {"  "}{" "}
                </span>
              )}
            </React.Fragment>
          );
        })}
      </div>

      {isLatest &&
        !isUserAuthor &&
        !isTyping &&
        regenerateMessage !== undefined && (
          <button
            onClick={() => {
              regenerateMessage();
            }}
            className="self-end p-2 border rounded-full border-zinc-700 bg-zinc-800 hover:bg-zinc-700"
          >
            <RefreshCcw className="w-4 h-4 text-white/70" />
          </button>
        )}
    </div>
  );
};

export default MessagesBubble;
