import axios from "axios";
import { ExploreImage } from "../models/explore.image.model";
import { Helper } from "../utils/helper";


export class GenerateService {

    public async create(prompt: string, negative_prompt: string, model: string, extras: string[], enable_highres: boolean, aspect_ratio: string, character_id: string | null, category: string | null): Promise<ExploreImage> {
        try {
            const token = Helper.getToken();
            if (!character_id) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/generate`, {
                    "prompt": prompt,
                    "negative_prompt": negative_prompt,
                    "model": model,
                    "extras": extras,
                    "enable_highres": enable_highres,
                    "aspect_ratio": aspect_ratio,
                }, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                return response.data as ExploreImage;
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/character/generate`, {
                    "prompt": prompt,
                    "negative_prompt": negative_prompt,
                    "model": model,
                    "extras": extras,
                    // "enable_highres": enable_highres,
                    // "aspect_ratio": aspect_ratio,
                    "character_id": character_id,
                    "category": category,
                }, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                return response.data as ExploreImage;
            }

        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
export const generateService = new GenerateService();