import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFantasyCategory, { FANTASY_CATEGORIES } from "@nectar/hooks/useFantasyCategory";

const FantasyTabsDropdown = () => {
  const navigate = useNavigate();
  const category = useFantasyCategory();

  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggle = () => {
    setShowDropdown((d) => !d);
  };

  const handleSelect = (category) => {
    navigate(`/fantasies?category=${category}`);
    handleClose();
  };

  const handleClose = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.classList.contains("fantasy-tab-option")) return;
      handleClose();
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [showDropdown]);

  return (
    <div className="relative font-quicksand">
      <button
        onClick={() => handleToggle()}
        className="rounded-full text-sm pl-4 pr-3 py-2 bg-zinc-800 flex gap-1 items-center text-primary-500 fantasy-tab-option capitalize"
      >
        {category}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-4 h-4 pointer-events-none ${ showDropdown ? "rotate-180": ""}`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
      {showDropdown && (
        <div className="absolute bg-zinc-800 rounded-2xl overflow-hidden mt-1  w-[120px] ">
            <button
            disabled={category === FANTASY_CATEGORIES.ALL}
            onClick={() => handleSelect(FANTASY_CATEGORIES.ALL)}
            className={`text-sm w-full p-3 fantasy-tab-option block capitalize hover:bg-zinc-700 opacity-70 disabled:opacity-100 hover:opacity-100 disabled:hover:bg-transparent`}
          >
            {FANTASY_CATEGORIES.ALL}
          </button>
          <button
            disabled={category === FANTASY_CATEGORIES.OFFICIAL}
            onClick={() => handleSelect(FANTASY_CATEGORIES.OFFICIAL)}
            className={`text-sm w-full p-3 fantasy-tab-option block capitalize hover:bg-zinc-700 opacity-70 disabled:opacity-100 hover:opacity-100 disabled:hover:bg-transparent`}
          >
            {FANTASY_CATEGORIES.OFFICIAL}
          </button>
          <button
            disabled={category === FANTASY_CATEGORIES.COMMUNITY}
            onClick={() => handleSelect(FANTASY_CATEGORIES.COMMUNITY)}
            className={`text-sm w-full p-3 fantasy-tab-option block capitalize hover:bg-zinc-700 opacity-70 disabled:opacity-100 hover:opacity-100 disabled:hover:bg-transparent`}
          >
            {FANTASY_CATEGORIES.COMMUNITY}
          </button>
        </div>
      )}
    </div>
  );
};

export default FantasyTabsDropdown;
