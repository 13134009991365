import { userService } from "@nectar/services/user.service";
import React, { useEffect, useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "@nectar/components/AppContext";

const useToken = () => {
  const location = useLocation();
  const context = useContext(AppContext); // Move useContext to the top level of your hook

  const queries = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  );

  useEffect(() => {
    let token = queries.get("login_token");

    if (token) {
      const fetchUserProfile = async () => {
        const dt = new Date(Date.now() + 500000000 * 1000);
        console.log("expiry time: ", dt);

        // Setting the token in cookies
        document.cookie = `token=${token}; expires=${dt.toUTCString()}; path=/`;

        try {
          // Getting the user profile
          const user = await userService.getUserProfile();
          sessionStorage.setItem("user", JSON.stringify(user));
          context.setUser(user);
          // Handle the user data as needed
        } catch (error) {
          console.error("Error fetching user profile:", error);
          // Handle error as needed
        }
      };

      fetchUserProfile();
    }
  }, [queries, context]);

  // Consider returning a meaningful value
  return true;
};

export default useToken;
