import React, {useContext, useEffect, useState, useRef} from 'react';
import {Box, Card, CardContent, CardHeader, Avatar, Typography, Button} from '@material-ui/core';
import verified from '../../assets/verified.svg'
import chat_icon from '../../assets/chat_unfilled.svg'
import {useLocation} from "react-router-dom";

const ExploreProfileCard = ({profile}) => {

    const totalFreePhotos = profile.bundles
        ? profile.bundles.reduce((sum, bundle) => {
            if (bundle.price === 0) {
                return sum + bundle.profile_photos.length
            }
            return sum;
        }, 0)
        : 0;


    return (
        <Card className="w-full"
              sx={{
                  position: 'relative',
                  backgroundColor: "black",
                  borderColor: "#212121",
                  borderWidth: '1px',
                  marginBottom: "10px"
              }}>
            <Box
                className="w-full h-40 bg-center bg-cover"
                sx={{
                    backgroundImage: `url(${profile.banner_pic_url})`,
                    backgroundSize: 'cover',
                    position: 'relative'

                }}
            >
                <Box
                    className="w-full h-16"
                    sx={{
                        backgroundColor: '#212121',
                        opacity: 0.3,
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 10
                    }}
                />
                <Avatar
                    src={profile.profile_pic_url}
                    alt={profile.username}
                    sx={{
                        position: 'absolute',
                        bottom: 5,
                        left: 8,
                        height: '52px',
                        width: '52px',
                        border: '2px solid white'
                    }}
                />
                <Box sx={{
                    position: 'absolute',
                    bottom: 15,
                    left: 70,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: 'auto',
                }}>
                    <div style={{display: 'flex', alignItems: 'center', margin: 0, padding: 0}}>
                        <Typography variant="h4" style={{color: 'white', marginBottom: 0}}>
                            {profile.name}
                        </Typography>
                        {/*<img src={verified} alt="Verified" style={{marginLeft: '2px', width: '14px', height: '14px'}}/>*/}
                    </div>
                    <Typography variant="h5" style={{color: 'white'}}>
                        @{profile.username}
                    </Typography>
                </Box>
                <Box style={{
                    display: 'flex', alignItems: 'center', position: 'absolute',
                    bottom: 20,
                    right: 10,
                }}>
                    <img src={chat_icon} alt="Chat Icon"/>
                </Box>
            </Box>
        </Card>
    )

}
export default ExploreProfileCard;