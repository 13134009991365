import TextPreprocess from "@nectar/utils/TextPreprocess";
import { formatDate } from "@nectar/utils/formatDate";
import { Helper } from "@nectar/utils/helper";
import React from "react";
import { Link, useParams } from "react-router-dom";

import { ChevronRight } from "lucide-react";

const MessagesInboxItem = ({ conversation, user }) => {
  const params = useParams();
  const matchConversation = params?.id === conversation.fantasy.id;

  return (
    <Link
      to={`/messages/${conversation.fantasy.id}`}
      className={`flex w-full items-center gap-2 rounded text-start overflow-hidden ${
        matchConversation
          ? "bg-zinc-900 ring-1 ring-zinc-800"
          : "hover:bg-zinc-800 "
      }`}
    >
      <img
        src={conversation.fantasy.thumbnail_image_url}
        className="w-12 h-12 object-cover flex-shrink-0 rounded ml-2 "
        alt="inbox item"
      />

      <div className="flex-grow overflow-hidden p-2">
        {/* Header inbox item */}
        <div className="flex items-center gap-2 ">
          <p className="flex-grow overflow-hidden font-medium text-ellipsis whitespace-nowrap">
            {conversation.fantasy.title}
          </p>

          <ChevronRight className="w-5 h-5 opacity-70 flex-shrink-0" />
        </div>
        {/* Content */}
        <p className="overflow-hidden text-sm text-white text-ellipsis whitespace-nowrap opacity-70">
          {conversation.last_message.attachment ? (
            <>
              <i>
                {conversation.fantasy.characters[0]?.name.split(" ")[0]} sent
                you a photo.
              </i>
            </>
          ) : (
            <TextPreprocess
              preprocess={Helper.preprocessMessage(
                conversation.last_message.message,
                conversation.fantasy.characters[0].name,
                user?.name || "user",
              )}
            />
          )}
        </p>
      </div>
    </Link>
  );
};

export default MessagesInboxItem;
