import React, { useState, useEffect, useContext } from "react";
import { Snackbar } from "@material-ui/core";
import { AppContext } from "../AppContext";
import { userService } from "../../services/user.service";

import filled_like_icon from "../../assets/filled_like_icon.svg";
import like_icon from "../../assets/like_icon.svg";

const LikeButton = ({ exploreImage }) => {
  const { handleToggleLike, likedImages, setShowLoginModal } =
    useContext(AppContext);

  const [isLiked, setIsLiked] = useState(false);
  const [showIsLikedSnackbar, setShowIsLikedSnackbar] = useState(false);
  const [likes, setLikes] = useState(exploreImage?.likes || 0);

  useEffect(() => {
  
    const liked = !!likedImages.get(exploreImage.id);

    if (liked && isLiked) {
      return;
    }

    if (isLiked && !liked) {
      setLikes(exploreImage.likes);
      setIsLiked(false);
    }

    if (liked && !isLiked) {
      setLikes(exploreImage.likes + 1);
      setIsLiked(true);
    }
  }, [likedImages]);

  const handleLike = async () => {
    if (!userService.checkIsLoggedIn()) {
      setShowLoginModal(true);
      return;
    }

    setLikes((_likes) => _likes + (isLiked ? -1 : 1));
    setIsLiked((_isLiked) => !_isLiked);
    await handleToggleLike(exploreImage);
    setShowIsLikedSnackbar(true);
  };

  if (!exploreImage) return;

  return (
    <>
      <button
        aria-label="button like"
        className="pointer-events-auto flex items-center  gap-2"
        onClick={handleLike}
      >
        <img
          src={isLiked ? filled_like_icon : like_icon}
          className="inline-block h-5 w-5"
          alt="Like icon"
        />
        <p className={"text-sm text-white"}>{likes}</p>
      </button>

      <Snackbar
        open={showIsLikedSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setShowIsLikedSnackbar(false);
        }}
        message={isLiked ? "Photo added to Likes" : "Photo removed from Likes"}
      />
    </>
  );
};

export default LikeButton;
