import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import { Button } from "@material-ui/core";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { userService } from "../../services/user.service";
import Typography from "@material-ui/core/Typography";
import feature_check from "../../assets/feature_check.png";
import Box from "@mui/material/Box";
import PlanButton from "./PlanButton";
import { Check } from "lucide-react";

const PacksCard = () => {
  const [messagePacks, setMessagePacks] = useState(null);

  useEffect(() => {
    userService
      .getMessagePacks()
      .then((data) => {
        setMessagePacks(data);
      })
      .catch((error) => {
        console.error("Error fetching message packs:", error);
      });
  }, []);

  return (
    <Box sx={{
      width: "100%",
    }} display="flex" flexDirection="column" alignItems="center">
      <Grid container spacing={2}>
        {messagePacks?.map((pack) => {
          const buttonText =
            "PURCHASE " +
            pack.title.split(" ")[0] +
            " " +
            pack.title.split(" ")[2];
          const descriptionArray = pack.description.split(". ");
          const buttonStyle = {
            alignSelf: "center",
            backgroundColor: "#FF4343",
            background: "linear-gradient(180deg, #8B1B1B 0%, #E33333 92.19%)",
            borderRadius: "8px",
            border: "1px solid #E42929",
            textAlign: "center",
            width: "100%",
            minHeight: "50px",
            maxHeight: "50px",
          };

          return (
            <Grid item xs={6} key={pack.id}>
              <Box
                position="relative"
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    backgroundColor: "#171717",
                    padding: 16,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <div>
                    <Typography
                      variant="h2"
                      style={{
                        color: "#FF4343",
                        wordWrap: "break-word",
                        paddingBottom: 8,
                      }}
                    >
                      {pack.title}
                    </Typography>
                    <Typography
                      variant="h1"
                      style={{
                        color: "#E9E9E9",
                        fontSize: 20,
                        wordWrap: "break-word",
                        paddingBottom: 8,
                        fontWeight: "800",
                      }}
                    >
                      ${pack.price}
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{
                        color: "#FFFFFF",
                        opacity: 0.6,
                        wordWrap: "break-word",
                        paddingBottom: 16,
                      }}
                    >
                      {descriptionArray[0] + "."}
                    </Typography>
                    <ul>
                      {descriptionArray.slice(1).map((point, index) => (
                        <li
                          key={index}
                          style={{
                            fontSize: 16,
                            paddingBottom: 8,
                            color: "#FFFFFF",
                            wordWrap: "break-word",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flexShrink: 0 }}>
                              {" "}
                              <Check color="#9E77ED" size={20} />
                            </div>
                            <div style={{ paddingLeft: 2 }}>{point}</div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <br></br>
                  </div>
                  <div>
                    <PlanButton
                      planId={pack.whop_plan_id}
                      buttonText={buttonText}
                      buttonStyle={buttonStyle}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PacksCard;
