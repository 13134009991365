import React, { useContext, useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";

import { Helper } from "@nectar/utils/helper";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import DiscoverProfileEdit from "./DiscoverProfileEdit";
import { GenerateSeedFantasyData } from "@nectar/utils/seedFantasy";
import { roleplayService } from "@nectar/services/roleplay.service";
import { useToast } from "../ui/use-toast";
import { userService } from "@nectar/services/user.service";
import PaywallModal from "../auth/PaywallModal";
import DiscoverProfileCreate from "@/components/profiles/DiscoverProfileCreate";
import { Typography } from "@material-ui/core";

const PAYWALL_SOURCE_CREATE_FANTASY = "create_fantasy";

const DiscoverProfileCardWrapper = ({ children, profile }) => {
  const FantasyPerProfile = {
    "f878bb8e-1ac7-4aa6-b672-92a3389f58b5": [
      "c8e2b823-e175-416c-b624-848fcecbaff4",
      "c66f5f8d-d1e6-426d-b297-f196f6057e17",
    ],
    "f6252e0b-e0f9-460e-8b64-817825eb68f1": [
      "16403d34-3030-4e90-8886-a25dd313d68e",
      "9ea95de6-ba6e-4646-95dc-6fb5b91389c7",
    ],
    "eeb64f3d-bef0-437e-97e7-d8f87b76f484": [
      "2c5948be-b94d-4a73-9885-03bfdcc55d12",
      "7d8670b7-962a-4a01-ab93-0aa44f96b5b5",
    ],
    "e27a5ca0-21f1-4f8b-8b31-9e34e4ccc3ca": [
      "ae9ece17-dc85-4551-9001-102c561e1df6",
    ],
    "c83b3273-1737-4403-a3d3-751af4ae7b57": [
      "9f751d9f-dab5-444c-ad47-658bdd7d7ec5",
      "a67670fe-777d-4d29-bd86-57c87f89efed",
    ],
    "c637fa04-61d5-489d-88b1-4c9dda25e398": [
      "b25518d2-19c9-48e8-a0e1-3f2dbe8267e6",
    ],
    "c1b7af7c-4a90-44f7-9c71-7e06b2d5f1a8": [
      "52682588-1d7d-4cfc-8943-b5fdb0c7cc84",
      "c7a6c12c-4665-4ea5-87e6-8083702bccae",
    ],
    "a5d0f3b4-2224-41f6-a41f-7709503aab1b": [
      "4c042adb-4a93-4d3d-8bb3-7e1230738817",
      "9438dc96-e305-4853-916e-d9cb00d9ee4b",
    ],
    "79cdba12-dacd-4975-809a-2ed2c4a8ba51": [
      "c876cab8-d93c-4d5a-aba1-c33aa0e6c86a",
    ],
    "20bace3a-a255-4314-a541-cf65a44e9631": [
      "b4d3c1e4-5fa6-450a-a7bf-465758b5e9ca",
      "a99d7e74-c53b-44d9-9d3d-b394af51c8d2",
    ],
    "1947909a-ce56-4e36-9443-db461c9b7c2f": [
      "711ea509-c2f9-49d8-945d-d7562e7cd4b2",
      "223f8f60-15c4-4176-9916-0730fca333b6",
    ],
    "185e88d0-2334-4e4e-933b-947339de2c07": [
      "70df0505-6aa7-4015-bc70-79fed9f81ee5",
    ],
    "09f00385-5655-44c7-9503-b87935326f0e": [
      "ae094e1e-1204-4b01-9615-8bab444ece79",
    ],
  };

  const descriptionProcessed = Helper.preprocessDescription(
    profile.description,
  );

  const navigate = useNavigate();
  const [loadingStartChat, setLoadingStartChat] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    setShowLoginModal,
    accountStatus,
    refreshUser,
    showLoginModal,
  } = useContext(AppContext);
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const { toast } = useToast();

  const handleChatStart = async () => {
    try {
      const fantasyIds = FantasyPerProfile?.[profile.id];

      if (fantasyIds) {
        const fantasyId =
          fantasyIds[Math.floor(Math.random() * fantasyIds.length)];
        navigate(`/messages/${fantasyId}`);
      } else {
        const fantasyData = GenerateSeedFantasyData(
          profile.category,
          profile.name,
          profile.id,
          profile.profile_pic_url,
        );
        let response;
        setLoadingStartChat(true);
        if (accountStatus.plan_tier >= 2) {
          // premium+ use default endpoint
          response = await roleplayService.fantasyCreate(fantasyData);
        } else {
          response = await roleplayService.defaultFantasyCreate({
            category: profile.category,
            character_ids: [profile.id],
            character_name: profile.name,
            thumbnail_img_url: profile.profile_pic_url,
            from_custom_create: false,
          });
        }
        if (response && response.id) {
          setTimeout(() => {
            navigate(`/messages/${response.id}`);
            setLoadingStartChat(false);
          }, 1000);
        } else {
          console.error("Fantasy creation failed: Invalid response");
          toast({
            variant: "destructive",
            title: "Something went wrong",
            description: "Failed to start chat. Please try again.",
          });
        }
      }
    } catch (error) {
      console.error("Error creating fantasy:", error);
    } finally {
    }
  };

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!userService.checkIsLoggedIn()) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(value) => {
          if (loadingStartChat || showPaywallModal) {
            return;
          }
          setIsOpen(value);
        }}
      >
        <DialogTrigger asChild>
          <div className="hover:cursor-pointer">{children}</div>
        </DialogTrigger>
        <DialogContent className="gap-0">
          <div className="flex gap-3 items-center">
            <Avatar className="w-16 h-16">
              <AvatarImage
                src={profile.profile_pic_url}
                alt={profile.username}
                className="object-cover"
              />
              <AvatarFallback>{profile.username} </AvatarFallback>
            </Avatar>

            <div className="leading-tight">
              <h4 className="text-white text-lg font-semibold">
                {profile.name}
              </h4>
              <div className="flex items-center gap-4">
                <h5 className="text-white/70">@{profile.username} </h5>
                {profile?.biography && (
                  <Link to={`/profile/${profile.username}`}>
                    <h4 style={{ color: "#FF4343" }}>View Photos 👀</h4>
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h4 className="text-white text-lg font-semibold ">About Me</h4>
            {/* <p className="text-white/70 mt-2">{}</p> */}
            {descriptionProcessed.map((text, index) => {
              return (
                <p key={index} className="text-white/70 mt-1">
                  {text}
                </p>
              );
            })}
          </div>

          <div className="mt-6 flex w-full gap-2">
            {/* Setup the variant to secundary in create fantasy
             button if uncomment the start chat button */}

            {user ? (
              <>
                {accountStatus?.plan_tier >= 2 ? (
                  <Button
                    className="flex-1"
                    asChild
                    variant={"secondary"}
                    style={{ padding: "1px 1px" }}
                  >
                    <Link to={`/fantasy/create?char=${profile.id}`}>
                      Create Fantasy ✨
                    </Link>
                  </Button>
                ) : (
                  <Button
                    className="flex-1"
                    variant={"secondary"}
                    onClick={() => {
                      handlePaywallModalOpen(PAYWALL_SOURCE_CREATE_FANTASY);
                    }}
                    style={{ padding: "1px 1px" }}
                  >
                    Create Fantasy ✨
                  </Button>
                )}

                <Button
                  className="flex-1"
                  onClick={() => handleChatStart()}
                  style={{ padding: "1px 1px" }}
                >
                  {loadingStartChat ? "Loading..." : "Start Chat 💬"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => setShowLoginModal(true)}
                  className="flex-1"
                  variant={"secondary"}
                  style={{ padding: "1px 1px" }}
                >
                  Create Fantasy ✨
                </Button>
                <Button
                  onClick={() => setShowLoginModal(true)}
                  className="flex-1"
                  style={{ padding: "1px 1px" }}
                >
                  Start Chat 💬
                </Button>
              </>
            )}
            <DiscoverProfileCreate profile={profile} />
            <DiscoverProfileEdit profile={profile} />
          </div>
        </DialogContent>
      </Dialog>
      {!showLoginModal && showPaywallModal && (
        <PaywallModal
          message={
            "You need to be a premium member to create a fantasy. Upgrade now to start creating!"
          }
          open={showPaywallModal}
          onClose={handlePaywallModalClose}
          refreshUser={refreshUser}
          loggedInUser={user}
        />
      )}
    </>
  );
};

export default DiscoverProfileCardWrapper;
