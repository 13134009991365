// import { useSearchParams } from "next/navigation";
import { useLocation } from "react-router-dom";

export const FANTASY_CATEGORIES = {
  ALL: "all",
  OFFICIAL: "official",
  COMMUNITY: "community",
};

export const DEFAULT_FANTASY_CATEGORY = FANTASY_CATEGORIES.ALL;

const useFantasyCategory = () => {
  const { search } = useLocation();
  const searchCategory = new URLSearchParams(search).get("category") || "";

  const category = Object.values(FANTASY_CATEGORIES).includes(searchCategory)
    ? searchCategory
    : DEFAULT_FANTASY_CATEGORY;

  return category;
};

export default useFantasyCategory;