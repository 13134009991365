import React, { useState, useEffect, useContext } from "react";
import CreateCard from "../components/generation/CreateCard";
import HistoryCard from "../components/generation/HistoryCard";
import LikesCard from "../components/generation/LikesCard";

import { useSearchParams } from "react-router-dom";
import { exploreService } from "../services/explore.service";

import { CreateContext } from "../components/CreateContext";
import { AppContext } from "../components/AppContext";

import { Helmet } from "react-helmet-async";

const CreateSegment = {
  CREATIONS: "creations",
  LIKES: "likes",
};

const CreatePage = () => {
  const {
    ckpts,
    extras,
    setPrompt,
    setActiveTab,
    setNegativePrompt,
    setGenerations,
    selectedCkpt,
    setSelectedCkpt,
    isLoadingFields,
    setIsLoadingFields,
    setEnableHighRes,
  } = useContext(CreateContext);
  const { ownedCkpts, ownedExtras } = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeSegmented, setActiveSegmented] = useState("");

  useEffect(() => {
    setIsLoadingFields(true);
    setActiveSegmented(CreateSegment.CREATIONS);
    if (ckpts?.length === 0) return;
    const exploreImageId = searchParams.get("id");
    if (exploreImageId) {
      setActiveTab(0);
      window.scrollTo(0, 0);
      exploreService.getExploreImage(exploreImageId).then((result) => {
        if (result !== null) {
          setGenerations([result]); // this prefills the image for remixing
          setPrompt(result.prompt);
          setNegativePrompt(result.negative_prompt);
          setEnableHighRes(false);
          const match = ckpts.find((ckpt) => ckpt.id === result.checkpoint?.id);
          const ownedMatch = ownedCkpts.find(
            (ckpt) => ckpt.id === result.checkpoint?.id,
          );
          if (ownedMatch || match.tier_title === "Free") {
            setSelectedCkpt(match);
          } else {
            const realistic = ckpts.find(
              (ckpt) => ckpt.title === "Nectar Asian Model",
            );
            setSelectedCkpt(realistic || ckpts[0]);
          }
        }
        setIsLoadingFields(false);
      });
    } else {
      setSelectedCkpt(ckpts[0]);
      setGenerations([]);
      setPrompt("");
      setNegativePrompt("");
      setIsLoadingFields(false);
    }
  }, [searchParams, ckpts]);

  const CreatePageTabs = () => (
    <div className="flex items-center justify-between gap-2  top-[69px] md:top-0 z-10 bg-black/90 backdrop-blur">
      <div className="flex items-center gap-2">
        <button
          onClick={() => setActiveSegmented(CreateSegment.CREATIONS)}
          className={`rounded-full text-sm px-4 py-2 flex-shrink-0 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
            activeSegmented == CreateSegment.CREATIONS
              ? " text-white"
              : "text-white opacity-60"
          }`}
        >
          My Creations
        </button>
        <button
          onClick={() => setActiveSegmented(CreateSegment.LIKES)}
          className={`rounded-full text-sm px-4 py-2 flex-shrink-0 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
            activeSegmented == CreateSegment.LIKES
              ? " text-white"
              : "text-white opacity-60"
          }`}
        >
          My Likes
        </button>
      </div>
    </div>
  );

  if (isLoadingFields) {
    return (
      <>
        <Helmet>
          <title>Nectar AI - Create</title>
          <link rel="canonical" href={"https://trynectar.ai/create"} />
        </Helmet>
        <div className="min-h-[100svh] w-full flex justify-center items-center flex-col gap-2">
          <div className="w-8 h-8 border-4 border-r-primary rounded-full animate-spin"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Nectar AI - Create</title>
        <link rel="canonical" href={"https://trynectar.ai/create"} />
      </Helmet>
      <div className="bg-black flex ">
        <div className="w-full md:max-w-sm lg:max-w-md">
          <CreateCard />
        </div>

        <div className="bg-black  w-full h-auto pt-4 px-4">
          <CreatePageTabs />
          {activeSegmented == CreateSegment.CREATIONS ? (
            <HistoryCard />
          ) : (
            <LikesCard />
          )}
        </div>
      </div>
    </>
  );
};

export default CreatePage;
