import React, { useContext, useMemo } from "react";
import { Heart } from "lucide-react";
import { useToast } from "@nectar/components/ui/use-toast";
import { roleplayService } from "@nectar/services/roleplay.service";
import clsx from "clsx";
import { userService } from "@nectar/services/user.service";
import { AppContext } from "@nectar/components/AppContext";
import { formatCount } from "@nectar/utils/formatCount";

const FantasyPreviewCardLike = ({
  fantasy,
  LikedFantasiesMap,
  isLoadingLikedFantasies,
  mutateLikedFantasies,
}) => {
  const { toast } = useToast();

  const { setShowLoginModal } = useContext(AppContext);
  const likedFantasy = LikedFantasiesMap?.[fantasy.id];
  const isLiked = !!likedFantasy;

  const handleToggleLike = async (event) => {
    event.stopPropagation();
    if (!userService.checkIsLoggedIn()) {
      setShowLoginModal(true);
      return;
    }

    try {
      mutateLikedFantasies(
        isLiked
          ? [
              ...Object.values(LikedFantasiesMap).filter(
                (f) => f.id !== fantasy.id,
              ),
            ]
          : [
              ...Object.values(LikedFantasiesMap),
              { ...fantasy, likes_count: fantasy.likes_count + 1 },
            ],
        {
          revalidate: false,
        },
      );

      const liked = !!(await roleplayService.fantasyToggleLike(fantasy.id));

      toast({
        description: liked
          ? "Fantasy added to Likes! "
          : "Fantasy removed from Likes.",
      });
    } catch (error) {
      toast({
        title: "Like",
        description: "Something went wrong, please try again later.",
      });
    }
  };

  const formattedLikesCount = formatCount(
    isLiked ? likedFantasy?.likes_count : fantasy.likes_count,
  );
  return (
    <button
      disabled={isLoadingLikedFantasies}
      className={clsx([
        isLoadingLikedFantasies && "animate-pulse",
        "flex flex-row items-center justify-center p-1 rounded-full bg-zinc-800 px-4 hover:bg-zinc-700 transition ease-out outline-none",
      ])}
      onClick={(event) => handleToggleLike(event)}
    >
      <Heart className={clsx(["w-4 h-4 mr-1 ", isLiked && "fill-white"])} />
      {formattedLikesCount}
    </button>
  );
};

export default FantasyPreviewCardLike;
