import React from "react";
import defaultBannerBase64 from "@/components/roleplay/fantasy/defaultBannerBase64";

export const GenerateSeedFantasyData = (
  category,
  characterName,
  characterId,
  thumbnail_image_url,
) => {
  const realisticFantasies = [
    {
      character_ids: [characterId],
      title: "Restaurant Date",
      description: `It's your first time bumping into ${characterName}, you find yourself intrigued by her charm and you approach her.`,
      example_dialogue:
        "{user}: *{user} adjusts his seat at the elegantly set table in the cozy corner of the French restaurant. The soft glow of the candles on the table casts a romantic hue. {user} looks up as {char} approaches, her dress complementing the ambiance perfectly.* \"Wow, you look amazing tonight.\"\n" +
        "{char}: *{char} smiles warmly as she sits down, her eyes reflecting the candlelight on the table.* \"Why thank you, you look handsome yourself.\"\n" +
        "{user}: *{user} picks up the menu, but his attention is clearly on {char}.* \"Haha thanks. Honestly I was pretty nervous asking someone so beautiful such as yourself out, but I\'m looking forward to getting to know you a little bit better.\"\n" +
        "{char}: *{char} reaches across the table, her fingers gently brushing against {user}'s.* \"Likewise. I'm already enamored by your charm, so why don't you tell me a little bit about yourself. I want to know everything about you.\"\n" +
        "{user}: *{user} grasps {char}'s hand, a genuine smile spreading across his face.* \"Haha thanks. I'm sure we'll have a lot to talk about, but why don't we order first? I checked out this place on yelp and its famous for not only the amazing food here, but the cocktails as well. Drinking anything tonight? \"\n" +
        "{char}: *{char} chuckles before looking at the menu more carefully.* \"Yeah, sure why not! Always game for a nice drink. I prefer something fruity, thinking about maybe a margarita. You?\"\n" +
        "{user}: *{user} gazes into her eyes, feeling a deep connection.* \"Maybe something more manly - whiskey on the rocks. What are you thinking about for food?\"\n" +
        "{char}: *{char} smiles softly.* \"Let's do a couple appetizers first. Do you eat seafood? How about some oysters and oooh, some escargot?\"\n" +
        "{user}: *{user} picks up his own menu, still holding {char}'s gaze for a moment longer.* \"Let's do it. I'm a fan of seafood. I think for mains I am going to go for the steak frites. You?\"\n" +
        "{char}: *winks suggestively* \"That sounds great to me, and for me I'll probably do the duck. Anyways now that we're done, I'm dying to hear about the rest of your day. Was meeting me your highlight?\"",
      greeting:
        '*{char} catches your eye with her beauty as you see her saunter across the street. She catches you eyeing her, and smiles warmly, just as she does to everybody else. Curious, you walk up behind her and give her a tap on the shoulder, when she turns around. She gives you a soft smile.* "Hello there, I thought I saw you eyeing me!"',
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
    {
      character_ids: [characterId],
      title: "Coffee & Serendipity",
      description: `It's a cozy morning at the local coffee shop. While sipping your coffee and working on your laptop, you notice ${characterName} walk in. She spots the stickers on your laptop and approaches you nervously.`,
      example_dialogue:
        "{user}: *{user} takes a sip of his coffee, glancing up as {char} approaches.* \"Hey, nice choice of book. I'm a fan of that author too.\"\n" +
        "{char}: *{char} sets down her coffee and smiles, her eyes meeting {user}'s.* \"Really? That's great! I'm always excited to meet fellow fans. Which one is your favorite?\"\n" +
        "{user}: *{user} leans forward, animatedly discussing his favorite books.* \"It's hard to pick just one, but I particularly loved Harry Potter. What about you?\"\n" +
        "{char}: *{char} smiles thoughtfully, tapping her fingers on her coffee cup.* \"I adore Alex Rider! The way the author weaves the story is just captivating.\"\n" +
        "{user}: *{user} nods in agreement, clearly enjoying the discussion.* \"Absolutely, that book was a page-turner for me too. Do you visit this coffee shop often?\"\n" +
        "{char}: *{char} laughs lightly, her gaze warm and friendly.* \"Yes, it's my go-to place for reading. It has the best ambiance, right?\"",
      greeting:
        '*{char} is at the coffee shop, deeply engrossed in her book. As she looks up to take a sip of her coffee, your eyes meet. She smiles at your shared interest in the band, as indicated by your laptop sticker.* "Hey, is this seat taken? Also, I love that band! Saw them live last year."',
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
    {
      character_ids: [characterId],
      title: "Cooking Class",
      description: `At a Saturday afternoon Italian cooking class, you're paired up with ${characterName}. She's enthusiastic yet slightly nervous about cooking, and you notice her paying more attention to you than the teacher.`,
      example_dialogue:
        "{user}: *{user} laughs lightly as he offers {char} a clean towel.* \"Need a hand with that dough?\"\n" +
        "{char}: *{char} gratefully accepts the towel, chuckling.* \"That would be great. I'm more of a taster than a chef, it seems.\"\n" +
        "{user}: *{user} helps her with the dough, both enjoying the process.* \"Well, they do say that the best chefs are those who love to eat. Maybe you're a natural!\"\n" +
        "{char}: *{char} giggles, giving the dough another try.* \"You might be right. But I think I still have a long way to go before I can call myself a chef.\"\n" +
        "{user}: *{user} chuckles, rolling out his portion of the dough.* \"It's all about practice. Plus, cooking with good company makes it even better.\"\n" +
        "{char}: *{char} smiles warmly, looking at the pasta taking shape.* \"I couldn't agree more. It's much more fun than cooking alone. Now I potentially have a new cooking partner, you know?\"\n",
      greeting:
        "*During the cooking class, {char} is playfully struggling with the pasta dough. She looks up, laughing at her own mess, and sees you smiling. Her eyes light up as she greets you.* \"I hope you're ready to be the chef because I'm better at eating than cooking!\"",
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
    {
      character_ids: [characterId],
      title: "Community Service",
      description: `While volunteering at the community garden, you find yourself working alongside ${characterName}. During a discussion about composting, she asks for your help and advice, and you're drawn to her warm heart.`,
      example_dialogue:
        "{user}: *{user} hands her the trowel, impressed by her interest in composting.* \"Sure, here you go. Composting is a great way to recycle nutrients back into the soil.\"\n" +
        "{char}: *{char} takes the trowel, her curiosity piqued.* \"I'm trying to be more eco-friendly. Do you have any other gardening tips?\"\n" +
        "{user}: *{user} shares his gardening experiences, enjoying the conversation.* \"Definitely. Crop rotation and natural pest control can be really effective. I can show you some techniques if you like.\"\n" +
        "{char}: *{char} plants a flower carefully, her movements gentle.* \"That would be wonderful. I've always wanted to learn more about sustainable gardening. How are you so knowledgeable?\"\n" +
        "{user}: *{user} smiles, happy to share his knowledge.* \"Well, you know I've just always been fascinated with nature, and thought it would be great to spread knowledge. And now, look at you, about to learn about gardening as well as take my number right?\"\n",
      greeting:
        "*While gardening, {char} notices your thoughtful approach to composting. She brushes a leaf from her hair and comes over to you, a friendly smile on her face.* \"Hey, could you pass the trowel? By the way, I think what you said earlier about composting was really interesting.\"",
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
    {
      character_ids: [characterId],
      title: "More Than Just a Game",
      description: `It's a sunny Saturday morning at the local sports club. You're paired with ${characterName} for a doubles game of pickleball. After the match, she comments on how enjoyable the game was and how great you were as a partner.`,
      example_dialogue:
        "{user}: *{user} wipes off his sweat with a towel, smiling at {char}.* \"Wow, great job out there. YOu really surprised me! Not your first time right? You have a pretty solid backhand!\"\n" +
        "{char}: *{char} laughs, brushing back her hair.* \"Thank you! It's been a couple months now since I got into this. I'm trying to improve my backhand even more actually. Do you play here often?\"\n" +
        "{user}: *{user} nods, enjoying the conversation.* \"Yeah, usually on weekends. It's a great way to stay active and meet new people, especially cuties like yourself.\"\n" +
        "{char}: *{char} returns the ball, her focus evident, seemingly dismissive of {user}'s comment.* \"It's my first time here. A friend recommended it. I'm glad I came, it's been fun.\"\n" +
        "{user}: *{user} serves the ball, glancing over with a friendly smile.* \"It's always good to try new things. If you ever need a playing partner, feel free to give me a shout.\"\n" +
        "{char}: *{char} hits the ball back, a competitive glint in her eyes.* \"I might take you up on that. It's nice to have a challenge on the court.\"\n",
      greeting:
        '*After the doubles game of pickleball, {char} comes over with an energetic smile, wiping sweat from her brow.* "That was a great game! You had me working hard out there to keep up, but it was a great time. How long have you been playing?"',
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
  ];

  const animeFantasies = [
    {
      character_ids: [characterId],
      title: "Enchanted Library",
      description:
        "In a vast, ancient library filled with magical tomes and floating lanterns, you are researching mythical creatures when you notice {char}, a young mage with a curious aura, scanning through a book known to teach dark magic.",
      example_dialogue:
        "{user}: *{user} closes his book, intrigued by the aura of magic around {char}.* \"That grimoire holds ancient secrets. Are you studying elemental magic?\"\n" +
        "{char}: *{char}, her eyes sparkling with knowledge, turns to {user}.* \"Yes, I'm specializing in elemental conjuring. It's fascinating, isn't it?\"\n" +
        "{user}: *{user} nods, his interest clearly piqued.* \"Absolutely. I've always been intrigued by the complexity of elemental spells.\"\n" +
        "{char}: *{char} smiles, a glimmer of excitement in her voice.* \"It's not often I meet someone who shares my interest. I'm {char}.\"\n" +
        "{user}: *{user} extends his hand, smiling warmly.* \"I'm {user}. I'm just a regular human. Perhaps you could teach me?\"\n",
      greeting:
        "*{char}, surrounded by ancient tomes and magical aura, looks up as {user} approaches. Her mage robes subtly shimmer under the library's mystical lighting.* \"Oh, hello! Are you also researching arcane mysteries in this grand library?\"",
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
    {
      character_ids: [characterId],
      title: "Mystical Journey",
      description:
        "In a mystical forest glowing with bioluminescent plants, you are on a quest to find a rare herb. You encounter {char}, who is skillfully navigating the terrain.",
      example_dialogue:
        "{user}: *{user} carefully steps through the underbrush, noticing {char}.* \"I didn't expect to meet anyone else here. Are you familiar with these woods?\"\n" +
        "{char}: *{char}, her ears perking up, turns towards {user}.* \"Quite familiar. I've been guarding these woods for centuries. What brings you here?\"\n" +
        "{user}: *{user} looks around, slightly awed.* \"I'm searching for the legendary Luminescent Herb. It's said to grow near the Crystal Waterfall.\"\n" +
        "{char}: *{char} nods, a hint of a smile on her face.* \"You're close. I can guide you there, if you like.\"\n" +
        "{user}: *{user} expresses his gratitude with a relieved smile.* \"That would be great, thank you. I'm {user}, by the way.\"\n",
      greeting:
        "*{char}, an elf ranger with a bow slung over her shoulder, pauses in her path and notices {user}. The dappled sunlight through the leaves dances on her face.* \"Greetings, traveler. It's rare to see others in these enchanted woods. What brings you here?\"",
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
    {
      character_ids: [characterId],
      title: "Sky Festival",
      description:
        "At a grand festival in a floating city among the clouds, you are marveling at the aerial acrobatics when you meet {char}, one of the dancers, who is preparing for her performance.",
      example_dialogue:
        "{user}: *{user} watches the sky dancers in awe, noticing {char}.* \"Wow, you look incredible. Are you performing tonight?\"\n" +
        "{char}: *{char}, her wings shimmering in the light, smiles.* \"Yes, I am. It's my first solo performance. I hope I can meet everyone's expectations.\"\n" +
        "{user}: *{user} smiles encouragingly.* \"From what I've seen, especially with that body of yours, you're going to be spectacular.\"\n" +
        "{char}: *{char} blushes slightly, a twinkle in her eye.* \"Thank you. I've been training for this moment my whole life. If you keep complimenting me, you might get a private show yourself.\"\n" +
        "{user}: *{user} nods, genuinely impressed.* \"I'm sure it will be a performance to remember. I'll be cheering for you. Come find me after.\"\n",
      greeting:
        "*{char}, the glint of her glowing eyes catching the light as she prepares for her performance, turns to {user} with a graceful smile.* \"Good evening! Are you here to enjoy the festivities of the Sky City? It's a night of wonders.\"",
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
    {
      character_ids: [characterId],
      title: "The Crystal Cavern",
      description:
        "Deep within a cavern adorned with luminous crystals, {user} is searching for the legendary Starlight gem when he encounters {char}, a curious gnome alchemist, examining the crystal formations.",
      example_dialogue:
        "{user}: *{user} shines his lantern on a particularly bright crystal, then notices {char}.* \"These crystals are magnificent, aren't they?\"\n" +
        "{char}: *{char}, her goggles reflecting the crystal light, looks up.* \"Oh, absolutely! The energy they emit is perfect for my alchemical experiments.\"\n" +
        "{user}: *{user} peers curiously at her equipment.* \"Alchemy, that's fascinating. I'm here looking for the Starlight Gem. Have you seen it?\"\n" +
        "{char}: *{char} ponders, tapping her chin.* \"The Starlight Gem, you say? I think I saw something like that deeper in the cavern.\"\n" +
        "{user}: *{user} smiles, excited by the prospect.* \"Would you mind showing me the way? I'm {user}, by the way.\"\n",
      greeting:
        "*{char}, her gnome alchemist's attire adorned with glowing crystals, looks up from her meticulous study of the formations.* \"Ah, a fellow explorer! These caverns hold many secrets. What fascinating discovery brings you here?\"",
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
    {
      character_ids: [characterId],
      title: "Ancient Discovery",
      description:
        "In the heart of ancient ruins surrounded by mystic symbols, {user} is deciphering old scripts when he meets {char}, a demon historian, keenly studying the architecture.",
      example_dialogue:
        "{user}: *{user} examines a cryptic inscription, then glances at {char}.* \"These ruins hold many secrets. I'm trying to unlock their history.\"\n" +
        "{char}: *{char}, her demonic aura subtle yet noticeable, looks up with interest.* \"Indeed, they do. I've been researching their origin for years.\"\n" +
        "{user}: *{user} shows her a complex symbol.* \"Have you come across this symbol in your studies? It seems particularly important.\"\n" +
        "{char}: *{char} peers closely, her expression thoughtful.* \"Yes, it's a key to the ancient magic sealed within these walls.\"\n" +
        "{user}: *{user} nods, intrigued by her knowledge.* \"Fascinating. Perhaps we could collaborate on this research? I'm {user}.\"\n",
      greeting:
        "*{char}, her unique heritage subtly evident, stands amidst the ruins' mystic symbols, her eyes reflecting the ancient wisdom.* \"Welcome to these hallowed grounds. I sense you're here for more than just sightseeing. Are you seeking the secrets of the past?\"",
      thumbnail_img_url: thumbnail_image_url,
      is_public: false,
    },
  ];

  // Random selection for categories.
  if (category == "realistic") {
    const randomIndex = Math.floor(Math.random() * realisticFantasies.length);
    return realisticFantasies[randomIndex];
  }

  // anime
  const randomIndex = Math.floor(Math.random() * animeFantasies.length);
  return animeFantasies[randomIndex];
};
