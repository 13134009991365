
import React, { useEffect, useRef } from "react";
import lockBody from "../../utils/lockBody"

const Modal = ({ children, handleClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    lockBody(true);
    return () => {
      lockBody(false);
    };
  }, []);

  const handleClick = (e) => {
    if (e.target !== modalRef.current) {
      return;
    }

    handleClose();
  };

  return (
    <div
      onClick={handleClick}
      ref={modalRef}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 px-4">
      {children}
    </div>
  );
};

export default Modal;