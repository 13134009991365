import React from "react";
import warning_icon from "@/assets/warning_icon.svg";
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
} from "../ui/alert-dialog";

const ResetMessagesModal = ({ onConfirm }) => {
  return (
    <AlertDialogContent>
      <div className="mx-auto flex items-center justify-center">
        {/* Warning Icon */}
        <img src={warning_icon} alt="warning" />
      </div>
      <h3 className="text-lg leading-6 font-medium text-white text-center">
        Warning: Resetting a conversation will clear your messages and photos.
      </h3>
      <div className="mt-2">
        <p className="text-white/70 text-center">
          Your existing conversation for this fantasy will start over, and you
          will no longer have access to photos or messages that were sent.
        </p>
      </div>

      <div className="flex gap-4 mt-2  items-center">
        <AlertDialogCancel className="flex-1 m-0">Cancel</AlertDialogCancel>
        <AlertDialogAction className="flex-1 m-0" onClick={onConfirm}>
          I understand, proceed.
        </AlertDialogAction>
      </div>
    </AlertDialogContent>
  );
};

export default ResetMessagesModal;
