import React, { useContext } from "react";

const DetailedImageCardImage = ({ exploreImage }) => {
  return (
    <div className="w-full flex-1">
      <img
        src={exploreImage.image_url}
        alt={exploreImage.prompt}
        className="mb-0 w-full rounded-2xl md:mb-6"
      />
    </div>
  );
};

export default DetailedImageCardImage;
