import React, { useState, useEffect, useContext, useRef } from "react";
import ProfilePhotoCard from "./ProfilePhotoCard";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { userService } from "../../services/user.service";
import { profileService } from "../../services/profile.service";
import LoginModal from "../auth/LoginModal";
import { AppContext } from "../AppContext";

const MobileBundleCard = ({ profile }) => {
  const [selectedBundle, setSelectedBundle] = useState(null);
  const {
    user,
    setUser,
    refreshUser,
    accountStatus,
    setAccountStatus,
    setShowLoginModal,
  } = useContext(AppContext);
  const [fetchedBundles, setFetchedBundles] = useState([]);
  const [bundleLoading, setBundleLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // We need this to close the modal after logging in
  useEffect(() => {
    const isLoggedIn = userService.checkIsLoggedIn();
    if (isLoggedIn && isInitialLoad) {
      setIsInitialLoad(false);
      refreshUser();
    }
  }, [user]);

  const handleClick = async (event, bundle) => {
    event.stopPropagation();
    if (!userService.checkIsLoggedIn()) {
      setShowLoginModal(true);
      setUser(null);
      return;
    } else {
      setSelectedBundle(bundle);
      let res = await userService.getWhopCheckout(
        bundle.whop_plan_id,
        window.location.href,
      );
      if (res !== null) {
        // await refreshUser();
        window.location.href = res.purchase_url;
      }
    }
  };

  useEffect(() => {
    const fetchBundles = async () => {
      try {
        setBundleLoading(true);
        const fetched = await Promise.all(
          profile.bundle_ids.map((bundle) => {
            // previews will be for paid bundles the user doesn't own or if the user is not logged in
            return profileService.getBundleById(
              bundle,
              userService.checkIsLoggedIn(),
            );
          }),
        );

        // Sort the fetched bundles
        fetched.sort((a, b) => {
          const aIsUnlocked =
            a.price === 0 ||
            (accountStatus && accountStatus.owned_bundle_ids?.includes(a.id));
          const bIsUnlocked =
            b.price === 0 ||
            (accountStatus && accountStatus.owned_bundle_ids?.includes(b.id));
          if (aIsUnlocked === bIsUnlocked) {
            // If both are unlocked or locked, sort by price
            return a.price - b.price;
          } else {
            // If one is unlocked and the other is not, the unlocked one comes first
            return aIsUnlocked ? -1 : 1;
          }
        });

        setFetchedBundles(fetched);
      } catch (error) {
        console.error("Error fetching bundles: ", error);
      } finally {
        setBundleLoading(false);
      }
    };
    fetchBundles();
  }, [profile.bundles, accountStatus?.owned_bundle_ids]);

  return (
    <Box sx={{ flexDirection: "column", px: 2.5, mt: 4, mb: 6 }}>
      <Grid container spacing={3} sx={{ px: 2 }}>
        {fetchedBundles &&
          fetchedBundles.map((bundle, bundleIndex) => (
            <Grid
              container
              spacing={2}
              key={bundleIndex}
              style={{ paddingLeft: 20 }}
            >
              <Grid item xs={12} style={{ paddingLeft: 0 }}>
                <Typography
                  variant="h2"
                  style={{ color: "white", paddingBottom: "4px" }}
                >
                  {bundle.title}
                </Typography>
                <Typography
                  variant="h4"
                  style={{ color: "white", opacity: 0.7, paddingBottom: "4px" }}
                >
                  {bundle.description}
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    color: "white",
                    opacity: 0.7,
                    paddingBottom: "20px",
                  }}
                >
                  {bundle.profile_photos ? bundle.profile_photos.length : 0}{" "}
                  photos
                </Typography>
                <div style={{ position: "relative" }}>
                  <Grid container spacing={2}>
                    {bundle.profile_photos &&
                      bundle.profile_photos.map((photo, photoIndex) => {
                        const isLocked =
                          bundle.price > 0 &&
                          (!accountStatus ||
                            !accountStatus.owned_bundle_ids?.includes(
                              bundle.id,
                            ));

                        // If the bundle is locked and this photo's index is 6 or higher, do not render a card for it
                        if (isLocked && photoIndex >= 4) {
                          return null;
                        }
                        return (
                          <Grid
                            key={photoIndex}
                            item
                            xs={6}
                            sx={{ paddingBottom: "24px" }}
                          >
                            <ProfilePhotoCard
                              bundle={bundle}
                              profile_photo={photo}
                              isClickable={!isLocked}
                            />
                          </Grid>
                        );
                      })}
                    {bundle.price > 0 &&
                      (!accountStatus ||
                        !accountStatus.owned_bundle_ids?.includes(
                          bundle.id,
                        )) && (
                        <div
                          style={{
                            position: "absolute",
                            width: "auto",
                            minWidth: "100%",
                            height: "auto",
                            minHeight: "100%",
                            left: "0px",
                            background: "rgba(255, 255, 255, 0.3)",
                            border: "0.563312px solid rgba(255, 255, 255, 0.6)",
                            backdropFilter: "blur(8px)",
                            borderRadius: "4.50649px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(event) => handleClick(event, bundle)}
                          >
                            Unlock {bundle.profile_photos?.length || 0} photos
                            for ${bundle.price.toFixed(2)}
                          </Button>
                        </div>
                      )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          ))}
      </Grid>
      {bundleLoading && (
        <Grid item xs={12} className="flex justify-center mt-4">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </Box>
  );
};
export default MobileBundleCard;
