import React from 'react';

const CharacterSelectGrid = ({ options, value, onChange, columns, largeColumns }) => {
  const handleOptionClick = (optionValue) => {
    onChange(optionValue);
  };
  const gridClasses = `grid grid-cols-${columns} lg:grid-cols-${largeColumns} gap-4 px-6 pb-8`;

  return (
    <div className="flex justify-center items-center w-full"> {/* Centering the grid */}
      <div className={`grid ${
        columns === 1 ? 'grid-cols-1' :
        columns === 2 ? 'grid-cols-2' :
        columns === 3 ? 'grid-cols-3' : 
        columns === 4 ? 'grid-cols-4' : 
        // ... continue for all possible values
        'grid-cols-1' // default value
        } lg:grid-cols-${largeColumns} gap-2 px-1 pb-1`}>
        {options.map((option) => (
          <div
            key={option.value}
            className={`cursor-pointer rounded-lg p-2 ${
              option.value === value ? 'ring-2 ring-offset-2 ring-offset-black ring-primary' : ''
            }`}
            onClick={() => handleOptionClick(option.value)}
          >
            {option.image ? (
            <img
                className="w-auto max-w-full bg-zinc-900 object-cover object-center rounded-lg"
                src={option.image}
                alt={option.label}
            />
            ) : (
            <div className="w-auto max-w-full bg-zinc-900 rounded-lg" />
            )}

            <div className="text-center mt-2">{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CharacterSelectGrid;
