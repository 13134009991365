import React, { useState, useEffect, useContext } from "react";
import { roleplayService } from "@/services/roleplay.service";
import { AppContext } from "@/components/AppContext";
import { userService } from "@/services/user.service";
import { AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import { Avatar } from "@/components/ui/avatar";
import { Heart, Play, Link, Pencil } from "lucide-react";
import { formatCount } from "@/utils/formatCount";
import { useNavigate } from "react-router-dom";
import { useToast } from "@nectar/components/ui/use-toast";
import clsx from "clsx";
import official from "@/assets/official.svg";
import TextPreprocess from "@nectar/utils/TextPreprocess";
import { Helper } from "@nectar/utils/helper";

const FantasyDetailForm = ({ fantasyId }) => {
  const [fantasy, setFantasy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLikedFantasies, setIsLoadingLikedFantasies] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [author, setAuthor] = useState(null);
  const { user, setShowLoginModal } = useContext(AppContext);
  const [likedFantasies, setLikedFantasies] = useState(null);
  const officialIds = new Set([
    // PRODUCTION
    "ba3ea829-7ce6-4987-9006-05bcdc7c56f5",

    // STAGING
    "50e4290b-4b1b-4315-8cc4-92afe2fe38a1",
  ]);
  const [isOfficial, setIsOfficial] = useState(false);

  const navigate = useNavigate();
  const { toast } = useToast();

  const handleLoginModalOpen = () => {
    setShowLoginModal(true);
  };

  // ============ Button Functionality ============
  // Chat
  const chat = async (fantasyId) => {
    if (!user) {
      handleLoginModalOpen();
      return;
    }

    navigate(`/messages/${fantasyId}`);
  };

  // Edit (this realistically should never show when not logged in)
  const edit = async (fantasyId) => {
    if (!user) {
      handleLoginModalOpen();
      return;
    }

    navigate(`/fantasy/${fantasyId}/edit`);
  };

  // Likes
  const handleToggleLike = async () => {
    if (!userService.checkIsLoggedIn()) {
      setShowLoginModal(true);
      return;
    }

    try {
      const liked = !!(await roleplayService.fantasyToggleLike(fantasy.id));
      // Update the local UI state to reflect the like status
      setIsLiked(liked);
      // Adjust the local fantasy like count
      setFantasy((prev) => ({
        ...prev,
        likes_count: liked ? prev.likes_count + 1 : prev.likes_count - 1,
      }));

      toast({
        description: liked
          ? "Fantasy added to Likes!"
          : "Fantasy removed from Likes.",
      });
    } catch (error) {
      toast({
        title: "Like ",
        description: "Something went wrong, please try again later.",
      });
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        toast({
          description: "Link copied to clipboard!",
        });
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  // ============ Fetching ============
  // Fantasy + Author
  useEffect(() => {
    const fetchFantasyAndAuthor = async () => {
      if (!fantasyId) return;
      setIsLoading(true);
      try {
        const fetchedFantasy = await roleplayService.getFantasyById(fantasyId);
        setFantasy(fetchedFantasy);

        if (user?.id === fetchedFantasy.user_id) {
          setIsAuthor(true);
          setAuthor(user.username);
        } else {
          try {
            const fetchedAuthorInfo = await userService.getUserInfo(
              fetchedFantasy.user_id,
            );
            setAuthor(fetchedAuthorInfo.username);
            setIsAuthor(false);
            setIsOfficial(officialIds.has(fetchedFantasy.user_id));
          } catch (error) {
            console.error("Error fetching author info:", error);
            // Handle author fetch error
          }
        }
      } catch (error) {
        // Handle error, e.g. set an error state or log to console
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (fantasyId) {
      fetchFantasyAndAuthor();
    }
  }, [fantasyId, user]);

  // User Likes
  useEffect(() => {
    if (user && fantasyId) {
      setIsLoadingLikedFantasies(true);
      const fetchUserLikedFantasies = async () => {
        try {
          const likedFantasies = await roleplayService
            .getFantasiesForUser(user.id, "liked", true)
            .catch((error) => {
              console.error("Error fetching liked fantasies:", error);
              return null;
            });

          // Check if the current fantasy is liked by the user
          const isCurrentFantasyLiked = likedFantasies.some(
            (f) => f.id === fantasyId,
          );
          setIsLiked(isCurrentFantasyLiked);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingLikedFantasies(false);
        }
      };
      fetchUserLikedFantasies();
    } else {
      setIsLoadingLikedFantasies(false);
      setIsLiked(false);
    }
  }, [user, fantasyId]);

  const handleNavigateToRoleplay = (event) => {
    event.preventDefault();
    navigate("/roleplay");
  };

  // ============ Rendering ============
  if (isLoading || !fantasy || isLoadingLikedFantasies) {
    return (
      <div className="pt-12">
        {/* spinner */}
        <div className="h-8 w-8 border-4 rounded-full border-zinc-800 border-r-primary animate-spin mx-auto" />
      </div>
    );
  }

  if (!fantasy.is_public && !isAuthor) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div>
          Access Denied. This fantasy is private. Click{" "}
          <a
            href="/roleplay"
            onClick={handleNavigateToRoleplay}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            here
          </a>{" "}
          to return to Roleplay.
        </div>
      </div>
    );
  }

  return (
    <div className="text-white font-quicksand w-full max-w-2xl mx-auto pt-8 px-6">
      <img
        className="h-[300px] w-full object-contain rounded mb-4"
        src={fantasy.thumbnail_image_url}
        alt="fantasy thumbnail"
      />

      <div className="flex items-center justify-center text-center mb-2">
        <h1 className="text-xl font-semibold">{fantasy.title}</h1>
        {isOfficial && (
          <img
            src={official}
            alt="Official Nectar AI Fantasy"
            className="w-6 h-6 ml-2"
          />
        )}
      </div>

      <div className="flex justify-center items-center text-center mb-4">
        <h1 className="text-lg font-semibold text-center opacity-70 mr-2">
          Starring:
        </h1>
        <Avatar className="w-6 h-6 bg-zinc-800 flex justify-center items-center mr-2">
          <AvatarImage src={fantasy.characters[0].profile_pic_url} />
          <AvatarFallback>
            {fantasy.characters[0].username.slice(0, 2)}
          </AvatarFallback>
        </Avatar>
        <h1 className="text-lg font-semibold text-center opacity-70">
          {fantasy.characters[0].username}
        </h1>
      </div>
      <h2 className="text-md font-quicksand text-center mb-4 opacity-70">
        Created by {author}
      </h2>

      {/*Stats, Like, Copy Link */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="flex flex-row items-center justify-center text-white/70">
            <Play className="w-4 h-4 mr-1 fill-current" />
            {formatCount(fantasy?.messages_count)} Plays
          </div>
          <button
            disabled={isLoadingLikedFantasies}
            className={clsx([
              isLoadingLikedFantasies && "animate-pulse",
              "flex flex-row items-center justify-center p-1 rounded-full bg-zinc-800 px-4 hover:bg-zinc-700 transition ease-out outline-none",
            ])}
            onClick={() => handleToggleLike()}
          >
            <Heart
              className={clsx(["w-4 h-4 mr-1 ", isLiked && "fill-white"])}
            />
            {formatCount(fantasy?.likes_count)}
          </button>
        </div>
        <div className="flex items-center text-white/70">
          <button
            onClick={handleCopyLink}
            className="flex items-center justify-center p-1 rounded-full px-4 bg-zinc-800 hover:bg-zinc-700 transition ease-out outline-none"
          >
            <Link className="w-4 h-4 mr-1" />
            Share
          </button>
        </div>
      </div>

      {/*  Description*/}
      <h1 className="text-lg font-semibold justify-left mb-2">Description:</h1>
      <h2 className="text-md font-quicksand justify-left mb-4 opacity-70">
        <TextPreprocess
          preprocess={Helper.preprocessMessage(
            fantasy.description,
            fantasy.characters[0].name || "character",
            user?.username || "user",
          )}
        />
      </h2>

      {/*  Chat + Edit */}
      <div className="flex justify-between items-center mb-4">
        {/* Chat Button */}
        <button
          onClick={() => chat(fantasyId)}
          className="btn btn-primary w-1/2 mt-2 justify-center py-3 text-white"
        >
          Start
        </button>

        {/*  Edit Button for Author only */}
        {isAuthor && (
          <div className="flex items-center text-white/70">
            <button
              onClick={() => edit(fantasyId)}
              className="flex items-center justify-center p-1 rounded-full px-4 bg-zinc-800 hover:bg-zinc-700 transition ease-out outline-none"
            >
              <Pencil className="w-4 h-4 mr-1" />
              Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FantasyDetailForm;
