import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import frontMatter from 'front-matter';
import {Box, Card, CardContent, ThemeProvider, Typography} from '@material-ui/core';
import theme from '../../styles/theme'
import read_more_icon from '../../assets/read_more_icon.svg'


const GuideCard = ({filePath}) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        fetch(filePath)
            .then(res => res.text())
            .then(text => {
                const {attributes} = frontMatter(text);
                setTitle(attributes.title);
                setDescription(attributes.description);
                setImage(attributes.image);
            });
    }, [filePath]);

    return (
        <ThemeProvider theme={theme}>
            <Card className="flex flex-col w-full h-full"
                  sx={{position: 'relative', backgroundColor: "black", borderColor: "#212121", borderWidth: '1px'}}>

                <Box
                    className="w-full h-32 bg-cover bg-center"
                    sx={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        position: 'relative',
                    }}
                />
                <CardContent className="flex-grow flex flex-col">
                    <Box>
                        <Typography variant="h3" sx={{color: "white", paddingBottom: "8px", fontWeight: "bold"}}
                                    component="div">
                            <Link to={"/guides/" + title}>{title}</Link>
                        </Typography>
                        <Typography variant="body2" sx={{color: "white", opacity: 0.7, paddingBottom: "24px"}}>
                            {description}
                        </Typography>
                    </Box>
                    <Box
                        className="flex-grow"/>
                    <Box>
                        <Typography variant="body1" sx={{color: "white", paddingBottom: "8px"}}>
                            <Link to={"/guides/" + title} className="flex items-center hover:underline">
                                Read More
                                <img className="w-2 ml-2" src={read_more_icon} alt="Read More Icon"/>
                            </Link>
                        </Typography>

                    </Box>
                </CardContent>
            </Card>
        </ThemeProvider>
    );

}
export default GuideCard
