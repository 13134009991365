import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { AppContext } from "../AppContext";
import { userService } from "../../services/user.service";
import { useLocation } from "react-router-dom";
import { Sparkles } from "lucide-react";

const PlanButton = ({ planId, buttonText, buttonStyle, isCurrentPlan }) => {
  const location = useLocation();
  const {
    user,
    accountStatus,
    refreshUser,
    showLoginModal,
    setShowLoginModal,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  // If the button text contains the word 'Downgrade', do not render the button.
  if (buttonText.includes("Downgrade")) {
    return null;
  }

  const checkAuth = () => {
    if (!userService.checkIsLoggedIn()) {
      setShowLoginModal(true);
      return false;
    }
    return true;
  };

  const handleClick = async (e) => {
    e.stopPropagation();
    if (!checkAuth()) {
      return;
    }
    if (isCurrentPlan) {
      // await userService.cancelSub
      // reload
      window.open("https://whop.com/orders/products", "_blank");
      return;
    }
    setLoading(true);
    let res = await userService.getWhopCheckout(planId, window.location.href);
    if (res !== null) {
      // Redirect to the checkout page
      window.location.href = res.purchase_url;
      setLoading(false);
    } else {
      // Handle the case where res is null
      // You might want to set loading to false here too
      setLoading(false);
    }
  };

  // The button shows a spinner while loading, otherwise it shows 'Upgrade'
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        <CircularProgress size={40} color="secondary" />
      ) : (
        <Button
          variant={isCurrentPlan ? "text" : "contained"}
          color="secondary"
          size="small"
          onClick={handleClick}
          style={{
            ...buttonStyle,
            color: buttonText === "CANCELLED" ? "#FE2929" : undefined,
          }}
        >
          {buttonText.includes("Upgrade") && (
            <Sparkles className="w-4 h-4 mr-1" />
          )}
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default PlanButton;
