import React, { useContext, useRef, useState } from "react";
import { messagesContext } from "./MessagesContext";
import { useMediaQuery } from "@material-ui/core";
import theme from "../../styles/theme";
import send_icon_disabled from "../../assets/send_arrow_disabled.svg";
import send_arrow_blue from "../../assets/send_arrow_blue.svg";
import { ArrowUp } from "lucide-react";
import { Button } from "../ui/button";

const MessagesForm = ({ handleSendMessage }) => {
  const formRef = useRef(null);
  const messageCtx = useContext(messagesContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [messageContent, setMessageContent] = useState("");

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    if (messageCtx.isLoading) return;
    //  messageCtx.sendMessage(messageContent, handleScrollDown);
    handleSendMessage(messageContent);
    setMessageContent("");
  };

  const handleKeyDown = (e) => {
    if (!formRef) {
      return;
    }

    // On desktop, when Enter key is pressed without Shift key, submit the message
    if (!isMobile && e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
      return;
    }

    // On mobile, when Enter key is pressed, allow the default behavior (newline)
    if (isMobile && e.keyCode === 13) {
      return; // Exit the function without doing anything else
    }

    // On desktop, when Enter key is pressed with Shift key, allow the default behavior (newline)
    if (!isMobile && e.keyCode === 13 && e.shiftKey) {
      return; // Exit the function without doing anything else
    }

    const textArea = e.target;
    formRef.current.style.height = "auto";
    const scrollHeight = textArea.scrollHeight;
    formRef.current.style.height = scrollHeight + "px";
  };

  // I don't disable the input when is loading because on mobiles the
  // keyboard is minimized and it damages the experience a little bit
  return (
    <div className="flex-shrink-0 w-full px-4 py-4 ">
      <form
        onSubmit={handleSubmit}
        ref={formRef}
        className="relative flex items-end w-full rounded bg-zinc-800"
      >
        <textarea
          rows="1"
          type="text"
          placeholder="Write a message"
          onChange={(e) => {
            if (messageContent.length < 500) {
              setMessageContent(e.target.value);
            }
          }}
          value={messageContent}
          onKeyDown={handleKeyDown}
          className="flex-grow h-full px-4 py-3 overflow-y-hidden bg-transparent outline-none resize-none"
        ></textarea>
        <Button
          size={"icon"}
          variant={"secondary"}
          disabled={messageCtx.isLoading || messageContent.length === 0}
          className="m-1 bg-blue-500 hover:bg-blue-600 disabled:bg-zinc-700 "
        >
          <ArrowUp className="w-4 h-4" />
        </Button>
      </form>
    </div>
  );
};

export default MessagesForm;
