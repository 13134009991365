import React, { useContext, useState } from "react";
import { AppContext } from "../AppContext";
import { Snackbar } from "@material-ui/core";
import download_icon from "../../assets/download_icon.svg";
import { userService } from "../../services/user.service";

const DownloadButton = ({ exploreImage }) => {
  const appCtx = useContext(AppContext);
  const [showIsDownloadSnackbar, setShowIsDownloadSnackbar] = useState(false);

  const handleClick = async () => {
    if (!userService.checkIsLoggedIn()) {
      appCtx.setShowLoginModal(true);
      return;
    }

    setShowIsDownloadSnackbar(true);
    await appCtx.handleDownload(exploreImage);
  };

  return (
    <>
      <button
        className="flex items-center gap-2 rounded-full bg-zinc-800 px-4 py-2.5 font-quicksand"
        onClick={handleClick}
      >
        <img src={download_icon} className={"h-4 w-4"} alt="Download icon" />
        <span>Download</span>
      </button>

      <Snackbar
        open={showIsDownloadSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setShowIsDownloadSnackbar(false);
        }}
        message={"downloading..."}
      />
    </>
  );
};

export default DownloadButton;
