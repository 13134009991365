import React from "react";

const CharacterSelectText = ({
  title,
  label,
  value,
  onChange,
  rows,
  placeholder,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="w-full">
      <p className="mb-2 text-muted-foreground">{label}</p>

      {rows === 1 ? (
        <input
          type="text"
          className="input-control"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      ) : (
        <textarea
          placeholder={placeholder}
          rows={rows}
          className="input-control"
          value={value}
          onChange={handleChange}
        ></textarea>
      )}
    </div>
  );
};

export default CharacterSelectText;
