import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Grid, CircularProgress } from "@mui/material";
import ExploreProfileCard from "../components/profiles/ExploreProfileCard";
import { exploreService } from "../services/explore.service.ts";
import { AppContext } from "../components/AppContext";
import { Link } from "react-router-dom";
import { userService } from "../services/user.service";
import Box from "@mui/material/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MobileLikesCard from "../components/generation/MobileLikesCard";
import MobileNudesCard from "../components/images/MobileNudesCard";

export const PROFILES_PER_PAGE = 20;

const MobilePhotosetPage = () => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { user, setUser, refreshUser, accountStatus, setAccountStatus } =
    useContext(AppContext);
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    window.scrollTo(0, 0);
  };

  // We need this to close the modal after logging in
  useEffect(() => {
    const isLoggedIn = userService.checkIsLoggedIn();
    if (isLoggedIn && isInitialLoad) {
      setIsInitialLoad(false);
      refreshUser();
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const visitedProfile = sessionStorage.getItem("visitedProfile");
    if (visitedProfile) {
      setTabValue(1);
      sessionStorage.removeItem("visitedProfile");
    }
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Nectar - My Nudes</title>
        <meta name="description" content="Your private AI girl collection." />
      </Helmet>
      {/* Nudes Page */}
      <div className="bg-black -mb-8 min-h-screen">
        <ThemeProvider theme={theme}>
          <div className="h-full w-full flex flex-col items-stretch">
            <Box
              sx={{
                width: "100%",
                borderBottom: "1px solid #212121",
                borderTop: "1px solid #212121",
                paddingRight: "12px",
                paddingLeft: "12px",
                position: "sticky",
                top: "55px",
                backgroundColor: "black",
                zIndex: 3,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                TabIndicatorProps={{
                  style: {
                    display: "flex",
                    borderRadius: "100px",
                    height: "38px",
                    backgroundColor: "#343434",
                    marginBottom: "5px",
                  },
                }}
              >
                <Tab
                  label="LIKES"
                  className={`text-gray-700 font-quicksand ${
                    tabValue === 0 ? "opacity-100" : "opacity-70"
                  } z-10`}
                  style={{
                    color: "white",
                    textTransform: "none",
                    flex: "1",
                    fontSize: "14px",
                    justifyContent: "center",
                  }}
                />
                <Tab
                  label="NUDES"
                  className={`text-gray-700 font-quicksand ${
                    tabValue === 1 ? "opacity-100" : "opacity-70"
                  } z-10`}
                  style={{
                    color: "white",
                    textTransform: "none",
                    flex: "1",
                    fontSize: "14px",
                    justifyContent: "center",
                  }}
                />
              </Tabs>
            </Box>
            {tabValue === 0 && (
              <div className="flex-grow">
                <MobileLikesCard />
              </div>
            )}
            {tabValue === 1 && (
              <div className="flex-grow">
                <MobileNudesCard />
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
    </HelmetProvider>
  );
};

export default MobilePhotosetPage;
