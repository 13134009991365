import { GoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = ({ onLogin, theme, shape, text }) => {
    return (
        <GoogleLogin
            onSuccess={(response) => {
                onLogin(response)
            }}
            onError={() => { console.log('User rejected the request'); }}
            text={text}
            useOneTap={false}
            auto_select={true}
            shape={shape}
            size='large'
            logo_alignment='left'
            theme={theme}
        />
    );
}

export default GoogleLoginButton;