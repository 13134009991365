import React, { useEffect, useState } from "react";
import DiscoverProfileCard from "../profiles/DiscoverProfileCard";
import { roleplayService } from "../../services/roleplay.service";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const RoleplayDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const main = async () => {
      try {
        setIsLoading(true);
        const response = await roleplayService.getCharacters("official", 1, 4);
        setData(response.characters);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    main();
  }, []);

  return (
    <div className="text-white font-quicksand bg-[#0C0C0C] px-4">
      <div className="container mx-auto py-12 text-center md:text-left">
        <h2 className="text-xl font-semibold">
          Roleplay with whoever, however.
        </h2>
        <p className="max-w-lg opacity-70 mt-1 mb-4">
          Dive into your fantasies or discover your next girlfriend. Receive
          customized photos, go on dates, and flirt to your heart's content.{" "}
        </p>

        <Button
          component={Link}
          to="/roleplay"
          variant="contained"
          color="secondary"
        >
          Start now
        </Button>

        <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mt-6">
          {data &&
            data.map((profile, i) => {
              return (
                <Link
                  to={`/profile/${profile.username}`}
                  className={i !== 0 ? "hidden md:block" : ""}
                  onClick={() =>
                    sessionStorage.setItem("visitedProfile", "true")
                  }
                  key={profile.id}
                >
                  <DiscoverProfileCard profile={profile} />
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RoleplayDetail;
