import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const ICDetail = () => {
  return (
    <div className="text-white font-quicksand container mx-auto py-16 md:grid  grid-cols-12 gap-6 px-4">
      <div className="col-start-1 col-end-3 relative hidden md:block">
        <div className=" absolute inset-0 flex flex-col gap-6 overflow-hidden">
          <div className="absolute inset-x-0 top-0 h-[200px] bg-gradient-to-b from-black" />
          <div className="absolute inset-x-0 bottom-0 h-[200px] bg-gradient-to-t from-black" />

          <img
            src="https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/fb8441b1-b0f5-4e21-478d-664e4df83200/public"
            alt="xd 1"
            className="rounded-2xl"
          />
          <img
            src="https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/bf62f684-4fff-46af-6de6-408a307f9d00/public"
            alt="xd 1"
            className="rounded-2xl"
          />
          <img
            src="https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/85dea60a-e16e-421c-cdd7-b5208191cf00/public"
            alt="xd 1"
            className="rounded-2xl"
          />
        </div>
      </div>

      {/* image */}
      <div className="col-start-3 col-end-11 flex flex-col items-center">
        <h2 className="text-center text-xl font-semibold">
          Image Creator allows you to create your dream girl in seconds.
        </h2>
        <p className="text-center max-w-sm opacity-70 mt-2">
          Easily choose different outfits, effects, and ethnicities with the
          click of a button.
        </p>

        <img
          src="https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/229de2fe-b900-49d4-b706-d8cd52d06200/public"
          alt="background create"
          className="w-full aspect-[16/9] object-cover object-center  rounded-2xl mt-4 mb-4"
        />

        <Button
          component={Link}
          to="/create"
          variant="contained"
          color="secondary"
        >
          GENERATE NOW
        </Button>
      </div>

      <div className="col-start-11 col-end-13 relative hidden md:block">
        <div className=" absolute inset-0 flex flex-col gap-6 overflow-hidden">
          <div className="absolute inset-x-0 top-0 h-[200px] bg-gradient-to-b from-black" />
          <div className="absolute inset-x-0 bottom-0 h-[200px] bg-gradient-to-t from-black" />

          <img
            src="https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/408252c5-f989-44da-eff2-793c1c56da00/public"
            alt="xd 1"
            className="rounded-2xl"
          />
          <img
            src="https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/22dfe2db-601c-4a7e-c3b2-d0a295715600/public"
            alt="xd 1"
            className="rounded-2xl"
          />
          <img
            src="https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/9f59bd74-e0cf-4dd4-d9e8-93700364e400/public"
            alt="xd 1"
            className="rounded-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default ICDetail;
