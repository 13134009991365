import React, {useEffect} from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ProfilePhotoCard from "../../components/profiles/ProfilePhotoCard";
import {Avatar, useMediaQuery} from "@material-ui/core";
import theme from "../../styles/theme";
import Carousel from 'react-material-ui-carousel'


const PhotosetBundleCard = ({showProfileName, bundle}) => {

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid item xs={12}>
            {/*{showProfileName &&*/}
            {/*    <Box display='flex' alignItems='center' sx={{paddingTop: '20px', marginLeft: '14px'}}>*/}
            {/*        <Avatar*/}
            {/*            src={bundle.character.profile_pic_url}*/}
            {/*            alt={bundle.character.username}*/}
            {/*            sx={{*/}
            {/*                position: 'relative',*/}
            {/*                height: '48px',*/}
            {/*                width: '48px',*/}
            {/*                border: '2px solid white',*/}
            {/*                marginRight: '14px'*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        <Typography variant="h2" style={{*/}
            {/*            color: 'white',*/}
            {/*        }}>*/}
            {/*            {bundle.character.name}*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*}*/}
            <Typography variant="h3" style={{
                color: 'white',
                opacity: 0.7,
                marginLeft: '14px',
                paddingBottom: '20px',
                paddingTop: '20px'
            }}>
                {bundle.title}
            </Typography>
            <div style={{position: 'relative'}}>
                <Grid item xs={12}>
                    {isMobile ?
                        <Carousel autoPlay={false} activeIndicatorIconButtonProps={{
                            style: {
                                color: '#FF4343'
                            }
                        }}>
                            {bundle.profile_photos && bundle.profile_photos.map((photo, photoIndex) => (
                                <div key={photoIndex}>
                                    <ProfilePhotoCard bundle={bundle}
                                                      profile_photo={photo}
                                                      isClickable={true}
                                    />
                                </div>
                            ))}
                        </Carousel>
                        :
                        <Grid container spacing={2}>
                            {bundle.profile_photos && bundle.profile_photos.map((photo, photoIndex) => (
                                <Grid key={photoIndex} item xs={6}>
                                    <ProfilePhotoCard bundle={bundle}
                                                      profile_photo={photo}
                                                      isClickable={true}
                                                      sx={{paddingBottom: '10px'}}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Grid>

            </div>
        </Grid>
    )
}
export default PhotosetBundleCard