import React, { useContext, useEffect, useState } from "react";

import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

import CharacterSelectCarousel from "@nectar/components/roleplay/character/CharacterSelectCarousel";
import CharacterSelectGrid from "@nectar/components/roleplay/character/CharacterSelectGrid";
import CharacterSelectNumber from "@nectar/components/roleplay/character/CharacterSelectNumber";
import CharacterSelectText from "@nectar/components/roleplay/character/CharacterSelectText";
import { STEPS_REALISTIC } from "@/utils/stepsRealistic";
import { STEPS_ANIME } from "@/utils/stepsAnime";
import { Button } from "@nectar/components/ui/button";
import { Progress } from "@nectar/components/ui/progress";
import { roleplayService } from "@nectar/services/roleplay.service";
import { AppContext } from "@nectar/components/AppContext";
import { useToast } from "@nectar/components/ui/use-toast";
import { GenerateSeedFantasyData } from "@/utils/seedFantasy";
import { useMediaQuery } from "@material-ui/core";
import theme from "@/styles/theme";
import { userService } from "@/services/user.service";
import PaywallModal from "@/components/auth/PaywallModal";
import { cn } from "@nectar/lib/utils";

let OFFSET = 0;
const REROLL_PAYWALL_SOURCE = "reroll_character";
const FANTASY_PAYWALL_SOURCE = "fantasy_create";
const FANTASY_PRIVATE = "fantasy_private";

const PRELIM_STEPS = [
  {
    type: "grid",
    title: "category",
    label: "Category",
    columns: 2,
    large_columns: 2,
    options: [
      {
        value: "realistic",
        label: "Realistic",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/d2d17f5b-bfd8-40d3-ec5e-d730e968e400/public",
      },
      {
        value: "anime",
        label: "Anime",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/c467ab52-3acd-4d9f-2afa-c0c362eb5c00/public",
      },
    ],
  },
];

export const CharacterCreatePage = () => {
  const navigate = useNavigate();
  const {
    user,
    accountStatus,
    setShowLoginModal,
    refreshUser,
    eligible,
    refreshEligibility,
  } = useContext(AppContext);
  const [step, setStep] = useState(0);
  const [category, setCategory] = useState(PRELIM_STEPS[0].options[0].value);
  const [steps, setSteps] = useState(PRELIM_STEPS);
  const [StepsStates, setStepsStates] = useState({});
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const [paywallMessage, setPaywallMessage] = useState("");

  // States for characteristics
  const [ethnicity, setEthnicity] = useState("");
  const [race, setRace] = useState("");
  const [skintone, setSkintone] = useState("");
  const [eyeColor, setEyeColor] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [hairLength, setHairLength] = useState("");
  const [hairStyle, setHairStyle] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [age, setAge] = useState("");
  const [name, setName] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [description, setDescription] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isCharacterSaved, setIsCharacterSaved] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [completeData, setCompleteData] = useState(null);
  const { toast } = useToast();

  useEffect(() => {
    if (category === "realistic") {
      setSteps([...PRELIM_STEPS, ...STEPS_REALISTIC]);
      setCategory("realistic");
    } else if (category === "anime") {
      setSteps([...PRELIM_STEPS, ...STEPS_ANIME]);
      setCategory("anime");
    }
  }, [category]);

  // Update characteristics based on steps
  useEffect(() => {
    OFFSET = category === "anime" ? 1 : 0;
    if (steps.length > 1) {
      if (category === "anime") {
        setRace(steps[1]?.options[0]?.value);
        setSkintone(steps[2]?.options[0]?.value);
      } else {
        setEthnicity(steps[1]?.options[0]?.value);
      }
      setEyeColor(steps[2 + OFFSET]?.options[0]?.value);
      setHairColor(steps[3 + OFFSET]?.options[0]?.value);
      setHairLength(steps[4 + OFFSET]?.options[0]?.value);
      setHairStyle(steps[5 + OFFSET]?.options[0]?.value);
      setBodyType(steps[6 + OFFSET]?.options[0]?.value);
      setAge(steps[7 + OFFSET]?.min);
    }
  }, [steps]);

  // toast

  useEffect(() => {
    setShowLoginModal(!user);
  }, [user]);

  useEffect(() => {
    // last step
    if (step === steps.length - 1) {
      refreshEligibility();
    }
  }, [step]);

  useEffect(() => {
    const updatedStepsStates = {
      category: {
        value: category,
        onChange: setCategory,
      },
      ...(category === "anime"
        ? {
            race: {
              value: race,
              onChange: setRace,
            },
            skintone: {
              value: skintone,
              onChange: setSkintone,
            },
          }
        : {
            ethnicity: {
              value: ethnicity,
              onChange: setEthnicity,
            },
          }),
      eyecolor: {
        value: eyeColor,
        onChange: setEyeColor,
      },
      haircolor: {
        value: hairColor,
        onChange: setHairColor,
      },
      hairlength: {
        value: hairLength,
        onChange: setHairLength,
      },
      hairstyle: {
        value: hairStyle,
        onChange: setHairStyle,
      },
      bodytype: {
        value: bodyType,
        onChange: setBodyType,
      },
      age: {
        value: age,
        onChange: setAge,
      },
      is_public: {
        value: isPublic,
        onChange: setIsPublic,
      },
      name: {
        value: name,
        onChange: setName,
      },
      description: {
        value: description,
        onChange: setDescription,
      },
    };

    setStepsStates(updatedStepsStates);
  }, [
    category,
    race,
    skintone,
    ethnicity,
    eyeColor,
    hairColor,
    hairLength,
    hairStyle,
    bodyType,
    age,
    name,
    description,
  ]);

  const showPaywallWithSource = (source) => {
    let message = "";
    if (source === FANTASY_PAYWALL_SOURCE) {
      message = "Create your dream fantasy on any plan!🔮";
    } else {
      message = "Not in love with your girl? Reroll appearances on Pro+! 🎲";
    }
    setPaywallMessage(message);
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!userService.checkIsLoggedIn()) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  const handlePublicToggle = (event) => {
    if (accountStatus?.plan_tier === 0) {
      handlePaywallModalOpen(FANTASY_PRIVATE);
      return;
    }

    setIsPublic(event.target.checked);
  };

  const validateStep = (stepValue) => {
    if (steps[stepValue].type === "carousel") {
      if (StepsStates[steps[stepValue].title]?.value === "") {
        toast({
          description: "Please select an option.",
          variant: "destructive",
        });

        return false;
      }
    }

    if (steps[stepValue].type === "text") {
      if (StepsStates[steps[stepValue].title]?.value.trim() === "") {
        toast({
          description: "Please enter a value.",
          variant: "destructive",
        });
        return false;
      }
    }

    if (steps[stepValue].type === "number") {
      if (StepsStates[steps[stepValue].title]?.value < steps[stepValue].min) {
        toast({
          description: `Please enter a value greater than ${steps[stepValue].min}.`,
          variant: "destructive",
        });
        return false;
      }

      if (StepsStates[steps[stepValue].title]?.value > steps[stepValue].max) {
        toast({
          description: `Please enter a value less than ${steps[stepValue].max}.`,
          variant: "destructive",
        });
        return false;
      }

      if (StepsStates[steps[stepValue].title]?.value === "") {
        toast({
          description: `Please enter a value.`,
          variant: "destructive",
        });
        return false;
      }
    }

    return true;
  };

  const nextStep = () => {
    if (!validateStep(step)) {
      return;
    }

    const nextStepValue = step === steps.length - 1 ? 0 : step + 1;
    setStep(nextStepValue);
  };

  const backStep = () => {
    setStep((prev) => {
      if (prev === 0) {
        return prev;
      }
      return prev - 1;
    });
  };

  const handleSaveCharacter = async () => {
    try {
      setIsLoading(true);
      // api call

      const data = {
        category: category,
        description: description,
        name: name,
        ethnicity: ethnicity,
        race: race,
        skin_tone: skintone,
        age: age,
        eye_color: eyeColor,
        hair_color: hairColor,
        hair_style: hairLength + ", " + hairStyle,
        body_type: bodyType,
        profile_pic_url: completeData?.profile_pic_url,
        seed: completeData?.seed,
        is_public: isPublic,
      };
      const response = await roleplayService.createCharacter(data);
      setCompleteData(response);
      const successTitle =
        category === "realistic" ? "Girl saved!" : "Waifu saved!";

      toast({
        variant: "positive",
        title: successTitle,
        description: `${name} was saved successfully.`,
        style: { backgroundColor: "green" },
      });
      setIsCharacterSaved(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreatePreview = async () => {
    // validations

    for (let i = 0; i < steps.length; i++) {
      if (!validateStep(i)) {
        setStep(i);
        return;
      }
    }

    // validate min number
    if (age < steps[7 + OFFSET].min) {
      setStep(7 + OFFSET);
      return;
    }

    if (name.trim() === "") {
      setStep(8 + OFFSET);
      return;
    }

    if (description.trim() === "") {
      setStep(9 + OFFSET);
      return;
    }

    const data = {
      category: category,
      description: description,
      name: name,
      ethnicity: ethnicity,
      race: race,
      skin_tone: skintone,
      age: age,
      eye_color: eyeColor,
      hair_color: hairColor,
      hair_style: hairLength + ", " + hairStyle,
      body_type: bodyType,
      is_public: isPublic, // Use default value for previews.
    };

    try {
      setIsLoading(true);
      setIsComplete(false);
      // api call
      const response = await roleplayService.createCharacterPreview(data);
      setCompleteData(response);
      refreshEligibility();
    } catch (error) {
    } finally {
      setIsComplete(true);
      setIsLoading(false);
    }
  };

  const handleRerollClick = async () => {
    if (eligible) {
      handleCreatePreview();
    } else {
      handlePaywallModalOpen(REROLL_PAYWALL_SOURCE);
    }
  };

  const handleChatStart = async () => {
    try {
      if (completeData) {
        const fantasyData = GenerateSeedFantasyData(
          completeData.category,
          completeData.name,
          completeData.id,
          completeData.profile_pic_url,
        );
        let response;
        if (accountStatus.plan_tier >= 2) {
          // premium+ use default endpoint
          response = await roleplayService.fantasyCreate(fantasyData);
        } else {
          response = await roleplayService.defaultFantasyCreate({
            category: completeData.category,
            character_ids: [completeData.id],
            character_name: completeData.name,
            thumbnail_img_url: completeData.profile_pic_url,
            from_custom_create: true,
          });
        }
        if (response && response.id) {
          setTimeout(() => {
            navigate(`/messages/${response.id}`);
          }, 1000);
        } else {
          console.error("Fantasy creation failed: Invalid response");
          toast({
            variant: "destructive",
            title: "Something went wrong",
            description: "Failed to start chat. Please try again.",
          });
        }
      } else {
        console.error("Data is not complete.");
      }
    } catch (error) {
      console.error("Error creating fantasy:", error);
    }
  };

  return (
    <div className="min-h-[calc(100svh-69px)]  md:min-h-[100svh] flex flex-col max-w-2xl mx-auto px-6  gap-2">
      <div className="flex-shrink-0 pt-4">
        <h1 className="text-lg text-center font-semibold">Create Your Girl</h1>
        <Progress
          value={isComplete ? 100 : (step / steps.length) * 100}
          className="h-[0.5rem] mt-3"
        />
        <h2 className="notranslate text-center mt-2">
          {!isComplete &&
            isLoading &&
            `Bringing your ${
              category === "realistic" ? "girl" : "waifu"
            } to life... This may take up to 1 minute.`}
          {isComplete &&
            !isLoading &&
            !isCharacterSaved &&
            `Dream ${category === "realistic" ? "girl" : "waifu"} created!`}
          {isComplete &&
            !isLoading &&
            isCharacterSaved &&
            `Dream ${category === "realistic" ? "girl" : "waifu"} saved!`}
          {!isComplete && !isLoading && steps[step].label}

          {/* Error message appears if an error occurs*/}
          {!isComplete &&
            !isLoading &&
            !steps[step] &&
            toast({
              variant: "destructive",
              title: "Something went wrong",
              description: "Please refresh and try again.",
            })}
        </h2>
      </div>

      {!isComplete && isLoading && (
        <div className="flex-grow flex animate-show-modal">
          <div className="flex-grow flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-4 border-muted border-t-primary" />
          </div>
        </div>
      )}

      {/* Final Presentation */}
      {isComplete && !isLoading && completeData?.profile_pic_url && (
        <div className="flex-grow flex flex-col items-center justify-center animate-show-modal">
          <img
            src={completeData.profile_pic_url}
            alt={`Profile of ${name}`}
            className="w-full h-auto object-cover rounded-lg"
          />
          <h2 className="mt-4 text-xl font-semibold">{name}</h2>{" "}
          <p className="mt-2 text-center text-sm text-gray-600">
            {description}
          </p>{" "}
        </div>
      )}

      {!isComplete && !isLoading && (
        <div
          key={`container-${step}`}
          className="flex-grow flex animate-show-modal"
        >
          {steps[step].type === "carousel" && (
            <CharacterSelectCarousel
              key={`carousel-${step}`}
              options={steps[step].options}
              value={StepsStates[steps[step].title].value}
              onChange={StepsStates[steps[step].title].onChange}
            />
          )}

          {steps[step].type === "grid" && (
            <CharacterSelectGrid
              key={`grid-${step}`}
              options={steps[step].options}
              value={StepsStates[steps[step].title]?.value}
              onChange={StepsStates[steps[step].title]?.onChange}
              columns={steps[step].columns}
              largeColumns={steps[step].large_columns}
            />
          )}

          {steps[step].type === "text" && (
            <CharacterSelectText
              key={`text-${step}`}
              value={StepsStates[steps[step].title]?.value}
              onChange={StepsStates[steps[step].title]?.onChange}
              {...steps[step]}
            />
          )}

          {steps[step].type === "number" && (
            <CharacterSelectNumber
              key={`number-${step}`}
              value={StepsStates[steps[step].title]?.value}
              onChange={StepsStates[steps[step].title]?.onChange}
              {...steps[step]}
            />
          )}
        </div>
      )}

      {!isLoading && isComplete && !isCharacterSaved && (
        <>
          <div className="flex text-white items-center align-center gap-4 w-full text-md">
            <div>
              <p>Visible to Public?</p>
              {accountStatus?.plan_tier === 0 && (
                <p className="text-sm text-white/70">
                  Privacy options are available on PRO+ plans.
                </p>
              )}
            </div>
            <label
              className={cn(
                "switch",
                accountStatus?.plan_tier === 0 && "opacity-60",
              )}
            >
              <input
                type="checkbox"
                checked={isPublic}
                onChange={handlePublicToggle}
                message="Public"
              />
              <span className="slider round" />
            </label>
          </div>
          <div className="flex-wrap pb-8 flex gap-2">
            <Button
              onClick={() => {
                setIsComplete(false);
              }}
              variant={"outline"}
              size={"icon"}
              className="shrink-0"
            >
              <ChevronLeft />
            </Button>
            <Button
              onClick={handleRerollClick}
              variant={"secondary"}
              className="flex-1"
            >
              {isMobile ? "🎲 Reroll" : "🎲 Reroll appearance"}
            </Button>
            <Button onClick={handleSaveCharacter} className="flex-1">
              Save {name.split(" ")[0]}!
            </Button>
          </div>
        </>
      )}
      {!isLoading && !isComplete && (
        <div className="flex-shrink-0 pb-8 flex gap-2">
          {step > 0 && (
            <Button
              onClick={backStep}
              variant={"outline"}
              size={"icon"}
              className="shrink-0"
            >
              <ChevronLeft />
            </Button>
          )}

          {step < steps.length - 1 ? (
            <Button onClick={nextStep} className="w-full flex-grow">
              Continue
            </Button>
          ) : (
            <Button onClick={handleCreatePreview} className="w-full flex-grow">
              Complete
            </Button>
          )}
        </div>
      )}
      {isCharacterSaved && (
        <div className="flex-wrap pb-8 flex gap-2 justify-center">
          <Button
            onClick={() => {
              if (accountStatus?.plan_tier < 2) {
                handlePaywallModalOpen(FANTASY_PAYWALL_SOURCE);
              } else {
                navigate(`/fantasy/create?char=${completeData?.id}`);
              }
            }}
            className="flex-1"
            style={{ backgroundColor: "#313131" }}
          >
            {isMobile
              ? "Create Fantasy ✨"
              : `Create Fantasy with ${completeData.name} ✨`}
          </Button>
          <Button onClick={() => handleChatStart()} className="flex-1">
            {isMobile ? "Chat now 💬" : `Chat with ${completeData.name} 💬`}
          </Button>
        </div>
      )}
      {showPaywallModal && (
        <div>
          <PaywallModal
            message={paywallMessage}
            open={showPaywallModal}
            onClose={handlePaywallModalClose}
            refreshUser={refreshUser}
            loggedInUser={user}
          />
        </div>
      )}
    </div>
  );
};
