import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import CreateCard from "../components/generation/CreateCard";
import MobileHistoryCard from "../components/generation/MobileHistoryCard";
import MobileLikesCard from "../components/generation/MobileLikesCard";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ExploreImage } from "../models/explore.image.model";
import { useSearchParams } from "react-router-dom";
import { exploreService } from "../services/explore.service";
import { generateService } from "../services/generate.service";
import { CreateContext } from "../components/CreateContext";
import { AppContext } from "../components/AppContext";
import { Grid, CircularProgress } from "@material-ui/core";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import { Helmet } from "react-helmet-async";
import { cn } from "@nectar/lib/utils";

const MobileCreatePage = () => {
  const {
    activeTab,
    setActiveTab,
    ckpts,
    extras,
    setPrompt,
    setNegativePrompt,
    setGenerations,
    selectedCkpt,
    setSelectedCkpt,
    isLoadingFields,
    setIsLoadingFields,
    setEnableHighRes,
  } = useContext(CreateContext);
  const { ownedCkpts, ownedExtras } = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setIsLoadingFields(true);
    if (ckpts?.length === 0) return;
    const exploreImageId = searchParams.get("id");
    if (exploreImageId) {
      setActiveTab(0);
      window.scrollTo(0, 0);
      exploreService.getExploreImage(exploreImageId).then((result) => {
        if (result !== null) {
          setGenerations([result]); // this prefills the image for remixing
          setPrompt(result.prompt);
          setNegativePrompt(result.negative_prompt);
          setEnableHighRes(false);
          const match = ckpts.find((ckpt) => ckpt.id === result.checkpoint?.id);
          const ownedMatch = ownedCkpts.find(
            (ckpt) => ckpt.id === result.checkpoint?.id,
          );
          if (ownedMatch || match.tier_title === "Free") {
            setSelectedCkpt(match);
          } else {
            const realistic = ckpts.find(
              (ckpt) => ckpt.title === "Nectar Asian Model",
            );
            setSelectedCkpt(realistic || ckpts[0]);
          }
        }
        setIsLoadingFields(false);
      });
    } else {
      setSelectedCkpt(ckpts[0]);
      setGenerations([]);
      setPrompt("");
      setNegativePrompt("");
      setIsLoadingFields(false);
    }
  }, [searchParams, ckpts]);

  if (isLoadingFields) {
    return (
      <>
        <Helmet>
          <title>Nectar AI - Create</title>
          <link rel="canonical" href={"https://trynectar.ai/create"} />
        </Helmet>
        <div className="min-h-[100svh] w-full flex justify-center items-center flex-col gap-2">
          <div className="w-8 h-8 border-4 border-r-primary rounded-full animate-spin"></div>
        </div>
      </>
    );
  }

  return (
    <div className=" w-full flex flex-col">
      <div className="w-full border-b border-zinc-900 px-3 h-[50px] bg-black/80 backdrop-blur">
        <Tabs
          value={activeTab}
          onChange={handleChange}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          TabIndicatorProps={{
            style: {
              display: "flex",
              borderRadius: "100px",
              height: "38px",
              backgroundColor: "#343434",
              marginBottom: "5px",
            },
          }}
        >
          <Tab
            label="CREATE"
            className={`text-gray-700 font-quicksand ${
              activeTab === 0 ? "opacity-100" : "opacity-70"
            } z-10`}
            style={{
              color: "white",
              textTransform: "none",
              flex: "1",
              fontSize: "14px",
              justifyContent: "center",
            }}
          />
          <Tab
            label="CREATIONS"
            className={`text-gray-700 font-quicksand ${
              activeTab === 1 ? "opacity-100" : "opacity-70"
            } z-10`}
            style={{
              color: "white",
              textTransform: "none",
              flex: "1",
              fontSize: "14px",
              justifyContent: "center",
            }}
          />
        </Tabs>
      </div>

      <div className="h-[calc(100svh-69px-50px)]">
        <div className={cn("h-full", activeTab !== 0 && "hidden")}>
          <CreateCard />
        </div>
        <div className={cn("h-full", activeTab !== 1 && "hidden")}>
          <MobileHistoryCard />
        </div>
      </div>
    </div>
  );
};

export default MobileCreatePage;
