import { cn } from "@nectar/lib/utils";
import { X } from "lucide-react";
import React from "react";

const CharactersContainerFilterItem = ({
  value,
  title,
  isSelected,
  handleClick,
}) => {
  return (
    <button
      onClick={() => handleClick(value)}
      className={cn(
        "rounded-full text-sm px-4 py-2 flex-shrink-0 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-1 items-center capitalize",
        isSelected ? " text-white" : "text-white opacity-60",
      )}
    >
      {title}
      {isSelected && <X className="w-4 h-4" />}
    </button>
  );
};

export default CharactersContainerFilterItem;
