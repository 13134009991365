import React from "react";
import { Button } from "@nectar/components/ui/button";

import { Heart } from "lucide-react";
import DropdownMenuDemo from "@nectar/components/ui/demo/DropdownMenuDemo";

const ComponentsPage = () => {
  return (
    <div className="container mx-auto pt-4">
      <h2 className="text-xl mb-2">Buttons</h2>
      <div className="flex gap-2 items-center mb-2">
        <h3 className="text-muted-foreground">Variants</h3>
        <Button>default</Button>
        <Button variant="secondary">secondary</Button>
        <Button variant="outline">outline</Button>
        <Button variant="ghost">ghost</Button>
        <Button variant="link">link</Button>
        <Button variant="destructive">destructive</Button>
      </div>
      <div className="flex gap-2 items-center mb-2">
        <h3 className="text-muted-foreground">Size</h3>
        <Button>  default</Button>
        <Button size="sm">small</Button>
        <Button size="lg">long</Button>
        <Button size="icon"><Heart className="w-4 h-4" /></Button>
      </div>

      <h2 className="text-xl mb-2 ">Dropdown menu</h2>
      <DropdownMenuDemo />
    </div>
  );
};

export default ComponentsPage;
