import React from "react";
import DetailedImageCardContent from "./DetailedImageCardContent";
import DetailedImageCardImage from "./DetailedImageCardImage";
import { Helmet, HelmetProvider } from "react-helmet-async";

const DetailedImageCard = ({ exploreImage, href }) => {
  return (
    <HelmetProvider>
      <Helmet>
      <title>{`Nectar - AI ${
        exploreImage.checkpoint?.title?.split(" ")[1]
      } Porn`}</title>
      <meta name="description" content={exploreImage.prompt || ""} />
      <meta
        property="og:title"
        content={`Nectar - AI ${
          exploreImage.checkpoint?.title?.split(" ")[1]
          } Porn`}
        />
        <meta property="og:description" content={exploreImage.prompt || ""} />
        <meta property="og:image" content={exploreImage.image_url || ""} />
      </Helmet>

      <div className="flex h-full w-full flex-col gap-4 rounded-2xl bg-zinc-900 p-6 pb-0 font-quicksand md:flex-row">
        <DetailedImageCardImage exploreImage={exploreImage} />
        <DetailedImageCardContent exploreImage={exploreImage} />
      </div>
    </HelmetProvider>
  );
};

export default DetailedImageCard;
