import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import Container from "@mui/material/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import { Button } from "@material-ui/core";
import { userService } from "../services/user.service.ts";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../components/AppContext";
import { Grid, Paper } from "@mui/material";
import MobilePlansCard from "../components/auth/MobilePlansCard";
import MobilePacksCard from "../components/auth/MobilePacksCard";
import RoleplaySettings from "../components/roleplay/RoleplaySettings";
import { useToast } from "@/components/ui/use-toast";
import { Info, Pencil, Save, X } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const MobileProfilePage = () => {
  const {
    user,
    setUser,
    tokenPlans,
    accountStatus,
    setAccountStatus,
    refreshUser,
    messageCredits,
  } = useContext(AppContext);
  const [displayUser, setDisplayUser] = useState(null);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [editStates, setEditStates] = useState({
    username: {
      isEditing: false,
      value: user?.username || "",
    },
    name: {
      isEditing: false,
      value: user?.name || "",
    },
  });

  const navigate = useNavigate();
  const { toast } = useToast();

  const handleEditClick = (field) => {
    setEditStates((prevEditStates) => ({
      ...prevEditStates,
      [field]: {
        ...prevEditStates[field],
        isEditing: true,
        value: "",
      },
    }));
  };

  const handleCancelClick = (field) => {
    setEditStates((prevEditStates) => ({
      ...prevEditStates,
      [field]: {
        ...prevEditStates[field],
        isEditing: false,
      },
    }));
  };

  const handleSaveClick = async (field, newValue) => {
    console.log("new value");
    if (!newValue || !newValue.trim()) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "The field cannot be empty.",
        style: { backgroundColor: "red" },
      });
      return;
    }

    try {
      await userService.editField(user?.id, field, newValue);
      setEditStates((prevEditStates) => {
        const newState = {
          ...prevEditStates,
          [field]: {
            ...prevEditStates[field],
            isEditing: false,
            value: newValue,
          },
        };
        return newState;
      });
      toast({
        variant: "positive",
        title: `${field} updated!`,
        description: `${field} was updated successfully.`,
        style: { backgroundColor: "green" },
      });
      refreshUser();
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      toast({
        variant: "destructive",
        title: "Something went wrong",
        description: "Failed to update field. Please try again.",
      });
    }
  };

  const handleChange = (field, value) => {
    setEditStates((prevEditStates) => ({
      ...prevEditStates,
      [field]: {
        ...prevEditStates[field],
        value: value,
      },
    }));
  };

  useEffect(() => {
    if (!userService.checkIsLoggedIn()) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userService.checkIsLoggedIn() && user && isInitialLoad) {
      setIsInitialLoad(false);
      refreshUser();
      setDisplayUser(user.email ? user.email : user.eth_address);
    }
  }, [user, isInitialLoad]);

  useEffect(() => {
    if (tokenPlans) {
      setLoadingPlans(false);
    }
  }, [tokenPlans]);

  useEffect(() => {
    if (accountStatus) {
      setLoadingStatus(false);
    }
  }, [accountStatus]);

  // Construct the subscription display based on whether email or ETH address is present
  const displaySub = accountStatus?.plan
    ? "Nectar " + accountStatus.plan
    : "Nectar Free";
  const displayMessageCredits =
    (messageCredits?.subscription_credits_remaining || 0) +
    (messageCredits?.add_on_credits_remaining || 0);

  const handleLogout = () => {
    userService.logout();
    // Update the context to be logged out
    setUser(null);
    setAccountStatus(null);

    // Refresh the page after logout
    navigate("/", { replace: true });
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        className="bg-black -mb-8 min-h-screen"
        style={{ textAlign: "left", paddingBottom: "40px" }}
      >
        {!loadingPlans && userService.checkIsLoggedIn() && user && (
          <Container sx={{ width: "90%" }}>
            <br />
            <Typography
              variant="h1"
              style={{ color: "white", paddingBottom: 16 }}
            >
              My Profile
            </Typography>
            <Typography
              variant="h3"
              style={{
                color: "#808080",
                paddingBottom: 32,
                wordWrap: "break-word",
              }}
            >
              Manage your profile and subscription preferences.
            </Typography>
            <Divider style={{ backgroundColor: "#5A595D" }}></Divider>
            <Typography
              variant="h2"
              style={{ color: "white", paddingTop: 32, paddingBottom: 32 }}
            >
              Profile Information
            </Typography>
            <div className="bg-neutral-900 p-4">
              <Typography
                variant="h4"
                style={{ color: "#808080", paddingBottom: 4 }}
              >
                Logged in as:{" "}
                <span style={{ color: "#E9E9E9", wordWrap: "break-word" }}>
                  {displayUser}
                </span>
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4" style={{ color: "#808080" }}>
                  Username:{" "}
                </Typography>
                {editStates.username.isEditing ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <textarea
                      rows="1"
                      type="text"
                      placeholder="username"
                      onChange={(e) => handleChange("username", e.target.value)}
                      value={editStates.username.value}
                      className="flex-grow h-full ml-2 overflow-y-hidden bg-zinc-800 outline-none resize-none"
                    ></textarea>
                    <button onClick={() => handleCancelClick("username")}>
                      <X
                        className="w-4 h-4"
                        style={{ color: "white", opacity: 0.7 }}
                      />
                    </button>

                    <button
                      className="ml-2"
                      onClick={() =>
                        handleSaveClick("username", editStates.username.value)
                      }
                    >
                      <Save
                        className="w-4 h-4"
                        style={{ color: "white", opacity: 0.7 }}
                      />
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <Typography style={{ color: "#E9E9E9", marginLeft: "4px" }}>
                      {" "}
                      {user?.username}
                    </Typography>
                    <Button
                      onClick={() => handleEditClick("username")}
                      style={{ marginLeft: "10px" }}
                    >
                      <Pencil
                        className="w-4 h-4"
                        style={{ color: "white", opacity: 0.7 }}
                      />
                    </Button>
                  </div>
                )}
              </div>
              <Typography variant="h4" style={{ color: "#808080" }}>
                <Button
                  color="secondary"
                  onClick={handleLogout}
                  style={{ padding: "0px 0px" }}
                >
                  Log out
                </Button>
              </Typography>
            </div>
            <>
              <Typography
                variant="h2"
                style={{ color: "white", paddingTop: 32, paddingBottom: 32 }}
              >
                Roleplay Settings
              </Typography>
              <div
                className="bg-neutral-900 p-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {/* Name */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h4" style={{ color: "#808080" }}>
                    Name:{" "}
                  </Typography>
                  <Tooltip>
                    <TooltipTrigger>
                      <div className="ml-1">
                        <Info className="w-5 h-5 text-yellow-200" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>How girls refer to you</p>
                    </TooltipContent>
                  </Tooltip>
                  {editStates.name.isEditing ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <textarea
                        rows="1"
                        type="text"
                        placeholder="name"
                        onChange={(e) => handleChange("name", e.target.value)}
                        value={editStates.name.value}
                        className="flex-grow h-full ml-2 overflow-y-hidden bg-zinc-800 outline-none resize-none"
                      ></textarea>
                      <button onClick={() => handleCancelClick("name")}>
                        <X
                          className="w-4 h-4"
                          style={{ color: "white", opacity: 0.7 }}
                        />
                      </button>
                      <button
                        onClick={() =>
                          handleSaveClick("name", editStates.name.value)
                        }
                        style={{ marginLeft: "10px" }}
                      >
                        <Save
                          className="w-4 h-4"
                          style={{ color: "white", opacity: 0.7 }}
                        />
                      </button>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <Typography
                        style={{ color: "#E9E9E9", marginLeft: "4px" }}
                      >
                        {" "}
                        {user?.name}
                      </Typography>
                      <Button onClick={() => handleEditClick("name")}>
                        <Pencil
                          className="w-4 h-4"
                          style={{ color: "white", opacity: 0.7 }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
                <RoleplaySettings />
              </div>
            </>

            <Typography
              variant="h2"
              style={{ color: "white", paddingTop: 32, paddingBottom: 32 }}
            >
              Subscription Plan
            </Typography>
            <div className="bg-neutral-900 p-4">
              <Typography variant="h4" style={{ color: "#808080" }}>
                Current Subscription:{" "}
                <span style={{ color: "#E9E9E9" }}>
                  {!loadingStatus ? displaySub : "Loading..."}
                </span>
              </Typography>
              <Typography variant="h4" style={{ color: "#E9E9E9" }}>
                {accountStatus?.subscription_status === "ACTIVE_CANCELLED" && (
                  <>
                    <br />
                    Your subscription benefits will last until the end of the
                    billing period.
                  </>
                )}
              </Typography>
            </div>
            <Typography
              variant="h4"
              style={{
                color: "#E9E9E9",
                opacity: "0.7",
                paddingTop: "12px",
                paddingBottom: "12px",
              }}
            >
              Benefits in higher tiers include all benefits from lower tiers.
            </Typography>
            {tokenPlans.length >= 3 ? (
              <MobilePlansCard
                tokenPlans={tokenPlans}
                accountStatus={accountStatus}
                refreshUser={refreshUser}
                loggedInUser={user}
              />
            ) : (
              <Typography
                variant="h4"
                style={{
                  color: "#E9E9E9",
                  opacity: 0.5,
                  wordWrap: "break-word",
                }}
                gutterBottom
              >
                {"Loading Plans..."}
              </Typography>
            )}
            <Typography
              variant="h2"
              style={{ color: "white", paddingTop: 32, paddingBottom: 32 }}
            >
              Message Pack Upgrades
            </Typography>
            <div className="bg-neutral-900 p-4">
              <Typography variant="h4" style={{ color: "#808080" }}>
                Message Credits:{" "}
                <span style={{ color: "#E9E9E9" }}>
                  {!loadingStatus
                    ? displayMessageCredits >= 100000
                      ? "Unlimited"
                      : displayMessageCredits
                    : "Loading..."}
                </span>
              </Typography>
            </div>
            <br></br>
            <MobilePacksCard />
          </Container>
        )}
        {loadingPlans && (
          <Typography
            variant="h4"
            style={{ color: "#E9E9E9", opacity: 0.5, wordWrap: "break-word" }}
            gutterBottom
          >
            {"Loading Profile..."}
          </Typography>
        )}
      </div>
    </ThemeProvider>
  );
};
export default MobileProfilePage;
