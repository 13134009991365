import React, { useState, useEffect, useRef } from "react";
import SuggestionCategory from "./SuggestionCategory";
import { ChevronDown, ChevronUp, Text } from "lucide-react";

const NUM_PREVIEW_CATEGORIES = 2;

function autoExpand(textarea) {
  textarea.style.height = "auto";
  textarea.style.height = `${textarea.scrollHeight}px`;
}

const PromptBox = ({
  prompt,
  header,
  suggestionCategories,
  onPromptChange,
}) => {
  // TODO: Generate more will add more suggestions
  // const [suggestions, setSuggestions] = useState(initialSuggestions);
  // const [prompt, setPrompt] = useState(prompt);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [categories, setCategories] = useState([]);
  const textareaRef = useRef();

  useEffect(() => {
    if (textareaRef.current) {
      autoExpand(textareaRef.current);
    }
  }, [prompt]);

  useEffect(() => {
    setCategories(Object.entries(suggestionCategories));
  }, [suggestionCategories]);

  const handleAddSuggestion = (suggestion) => {
    const updatedPrompt = prompt ? prompt + ", " + suggestion : suggestion;
    // setPrompt(updatedPrompt);
    onPromptChange(updatedPrompt);
  };

  return (
    <div className="">
      <div className="mb-3 flex gap-2 items-center">
        <Text  className="w-4 h-4 " />
        <h4 className="font-semibold ">{header}</h4>
      </div>
      <div className="w-full h-auto mb-3">
        <textarea
          type="text"
          ref={textareaRef}
          value={prompt}
          onChange={(event) => {
            const newPrompt = event.target.value;
            // setPrompt(newPrompt);
            onPromptChange(newPrompt);
          }}
          className="w-full min-h-[6rem] h-auto flex flex-col resize-none font-quicksand overflow-hidden text-white rounded border-0 p-2 outline-none bg-zinc-800"
          placeholder="Enter your prompt here"
        />
      </div>

      {categories?.length <= NUM_PREVIEW_CATEGORIES ? (
        categories.map(([title, suggestions]) => (
          <SuggestionCategory
            title={title}
            initialSuggestions={suggestions}
            onAddSuggestion={handleAddSuggestion}
            key={title}
          />
        ))
      ) : (
        <>
          {categories
            .slice(0, NUM_PREVIEW_CATEGORIES)
            .map(([title, suggestions]) => (
              <SuggestionCategory
                title={title}
                initialSuggestions={suggestions}
                onAddSuggestion={handleAddSuggestion}
                key={title}
              />
            ))}

          <div className="w-full h-auto font-quicksand  text-white/60 cursor-pointer">
            <button
              className="flex justify-between font-medium text-sm mb-2 w-full items-center"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? "SHOW MORE" : "SHOW LESS"}

              {isCollapsed ? (
                <ChevronDown className="w-4 h-4" />
              ) : (
                <ChevronUp className="w-4 h-4" />
              )}
            </button>

            {/* Categories */}
            {!isCollapsed &&
              categories
                .slice(NUM_PREVIEW_CATEGORIES)
                .map(([title, suggestions]) => (
                  <SuggestionCategory
                    title={title}
                    initialSuggestions={suggestions}
                    onAddSuggestion={handleAddSuggestion}
                    key={title}
                  />
                ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PromptBox;
