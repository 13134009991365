import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* FAQ Page */}
      <ThemeProvider theme={theme}>
        <div className="parent h-auto bg-black flex -mb-8 min-h-screen">
          <Container sx={{ width: "80%" }}>
            <br></br>
            <Typography variant="h1" align="left" sx={{ color: "white" }}>
              About Nectar
            </Typography>
            <br></br>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                textDecoration: "underline",
                marginBottom: "1rem",
              }}
            >
              What is Nectar?
            </Typography>
            <Typography variant="h3" sx={{ color: "white" }}>
              Nectar is an generative AI platform that offers two products: (1)
              Image Creator and (2) Roleplay.
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "white", marginBottom: "1rem" }}
            >
              Image Creator is a powerful generative AI tool to create photos,
              while Roleplay allows you to explore different fantasies and
              create your own custom character. Our generation times for both
              text and language are one of the fastest in the industry.
            </Typography>
            <br></br>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                textDecoration: "underline",
                marginBottom: "1rem",
              }}
            >
              What are Nectar Subscriptions and Message Packs?
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "white", marginBottom: "1rem" }}
            >
              Nectar Subscriptions are monthly subscriptions that offer a
              exclusive benefits for our image and roleplay products including
              HD generations and access to different models. Message Packs are a
              la carte purchases to increase the messaging limit.
            </Typography>
            <br></br>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                textDecoration: "underline",
                marginBottom: "1rem",
              }}
            >
              What can I do on Nectar?
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "white", marginBottom: "1rem" }}
            >
              Using our Image Creator, you can make AI images that you want to
              see and stunning definition. Any generations of content on this
              website that resemble real people are purely coincidental. Our
              Roleplay product allows you to dive headfirst into fantasies as
              well as create your own custom character.
            </Typography>
            <br></br>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                textDecoration: "underline",
                marginBottom: "1rem",
              }}
            >
              What is Nectar's privacy policy?
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "white", marginBottom: "1rem" }}
            >
              See our privacy policy{" "}
              <a
                href="/privacy"
                style={{ textDecoration: "underline", color: "white" }}
              >
                here
              </a>
              .
            </Typography>
            <br></br>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                textDecoration: "underline",
                marginBottom: "1rem",
              }}
            >
              What is Nectar's terms of service?
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "white", marginBottom: "1rem" }}
            >
              See our terms of service{" "}
              <a
                href="/tos"
                style={{ textDecoration: "underline", color: "white" }}
              >
                here
              </a>
              .
            </Typography>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
}

export default AboutPage;
