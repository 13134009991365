import React from "react";
import { Link } from "react-router-dom";

import heart_logo from "../../assets/heart_logo.png";
import useApp from "../../hooks/useApp";
import NavbarUser from "./NavbarUser";

const NavBar = ({ handleOpenSidebar }) => {
  const app = useApp();

  return (
    <div className="w-full px-4 py-4 sticky top-0 z-20 bg-black border-b border-zinc-900 text-white font-quicksand flex justify-between h-[69px]">
      {/* left */}
      <div className="flex gap-2">
        <button onClick={handleOpenSidebar} className="p-1 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-6 h-6"
          >
            <path
              fill-rule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <Link
          className="flex items-center gap-1"
          to={app ? app?.paths[0] : "/"}
        >
          <img src={heart_logo} alt="heart logo icon" className="w-8 h-8" />
          <div className={`leading-[0.5] ${app ? "mt-1" : ""}`}>
            <p className="font-quicksand  font-bold text-white">Nectar</p>
            {app && (
              <p className="opacity-70 font-quicksand text-sm font-bold text-white capitalize">
                {app.name}
              </p>
            )}
          </div>
        </Link>
      </div>

      {/* right  */}
      <NavbarUser />
    </div>
  );
};

export default NavBar;
