import React, { useEffect, useMemo } from "react";
import MessagesPhotoGridHeader from "./MessagesPhotoGridHeader";
import { roleplayService } from "../../services/roleplay.service";

import useSWRInfinite from "swr/infinite";
import { fetcher } from "@nectar/lib/fetcher";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { useOnScreen } from "@nectar/hooks/useOnScreen";

const IMAGES_PER_PAGE = 2;

const MessagesPhotoGrid = ({ conversation, showGallery, setShowGallery }) => {
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;

    const url = roleplayService.getConversationImagesUrl(
      conversation.id,
      pageIndex + 1,
      10,
    );

    return url;
  };

  const { data, isLoading, size, setSize } = useSWRInfinite(getKey, fetcher);

  const photos = useMemo(
    () => data?.map((data) => data.messages).flat() || [],
    [data],
  );

  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  const isEmpty = data?.[0]?.messages?.length === 0;

  const isReachingEnd =
    isEmpty ||
    (data && data[data.length - 1]?.messages?.length < IMAGES_PER_PAGE);

  const [isVisible, containerRef] = useOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  useEffect(() => {
    if (isVisible) {
      setSize((s) => s + 1);
    }
  }, [isVisible]);

  // Utils and handlers
  const formatDate = (timestamp) => {
    const now = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds(),
      ),
    );

    const messageDate = new Date(timestamp);
    const diffInDays = Math.floor((now - messageDate) / (1000 * 60 * 60 * 24));

    if (diffInDays === 0) {
      return "Today";
    } else if (diffInDays === 1) {
      return "Yesterday";
    } else if (
      now.getUTCMonth() === messageDate.getUTCMonth() &&
      now.getUTCFullYear() === messageDate.getUTCFullYear()
    ) {
      return new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        messageDate,
      );
    } else {
      return messageDate.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
    }
  };

  const groupPhotosByDate = (photos) => {
    return photos.reduce((acc, photo) => {
      const dateKey = formatDate(photo.timestamp);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(photo);
      return acc;
    }, {});
  };

  const groupedPhotos = useMemo(() => {
    return groupPhotosByDate(
      photos
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .reverse(),
    );
  }, [photos]);

  return (
    <div className="w-full h-full flex-grow flex-col flex">
      <MessagesPhotoGridHeader
        onClose={() => {
          setShowGallery(false);
        }}
      />
      <div className="flex flex-col flex-grow overflow-y-scroll">
        {isEmpty && (
          <p className="text-white/70 p-4">
            No photos yet. Request a photo to get started!
          </p>
        )}

        {Object.keys(groupedPhotos).map((date, idx) => {
          const datePhotos = groupedPhotos[date];

          return (
            <div key={date} className="p-4">
              <h4 className="font-semibold capitalize">{date}</h4>
              <div className="w-full grid grid-cols-2 gap-4 mt-2">
                {datePhotos?.map((photo) => {
                  return (
                    <Dialog key={photo.timestamp}>
                      <DialogTrigger>
                        <img
                          src={photo.attachment}
                          alt={`photo ${date}`}
                          className="aspect-[1/1] object-cover rounded"
                        />
                      </DialogTrigger>
                      <DialogContent>
                        <img
                          src={photo.attachment}
                          alt={`expanded ${date}`}
                          className="rounded "
                        />
                      </DialogContent>
                    </Dialog>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {/* Trigger  */}
      {!isReachingEnd && !isLoadingMore && (
        <div ref={containerRef} className="w-full h-[25px] "></div>
      )}
    </div>
  );
};

export default MessagesPhotoGrid;
