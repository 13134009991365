import React from "react";
import fire_icon from "../../assets/fire_icon.svg";

const TierIcon = ({ tier }) => {
  return (
    <div
      className={`group/tier rounded-full p-1 flex items-center gap-1 pointer-events-auto ${
        tier == "Pro" ? "bg-[#FF4444]" : "bg-[#4743FF]"
      }`}
    >
      <img src={fire_icon} className="h-6 w-6" alt="Fire icon" />
      <span className=" hidden group-hover/tier:block pr-2 whitespace-nowrap font-quicksand">
        Nectar {tier}
      </span>
    </div>
  );
};

export default TierIcon;
