import React from "react";

// social assets
import discord from "../../assets/discord.svg";
import reddit from "../../assets/reddit.svg";
import twitter from "../../assets/twitter.svg";

const SidebarSocial = ({ isCollapsed }) => {
  return (
    <div className={`flex  items-center ${ isCollapsed ? "flex-col gap-4 mb-2" : "flex-row gap-2"}`}>
      <a href="https://twitter.com/TryNectarAI" target="_blank">
        <img
          src={twitter}
          alt="twitter icon"
          className="w-6 h-6 opacity-60 hover:opacity-100"
        />
      </a>

      <a href="https://reddit.com/r/NectarAI/" target="_blank">
        <img
          src={reddit}
          alt="reddit icon"
          className="w-6 h-6  opacity-60 hover:opacity-100"
        />
      </a>

      <a href="https://discord.gg/4u2jFcz5VN" target="_blank">
        <img
          src={discord}
          alt="discord icon"
          className="w-6 h-6  opacity-60 hover:opacity-100"
        />
      </a>
    </div>
  );
};

export default SidebarSocial;
