import React, { useState } from "react";
import { Snackbar } from "@mui/material";
import copy_icon from "../../assets/copy_icon.svg";

const CopyButton = ({ label = "Copy", secondaryLabel = "Copied", content }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(content);
    setIsCopied(true);
  };

  return (
    <>
      <button
        className="flex items-center gap-2 rounded-full bg-zinc-800 px-4 py-2.5 font-quicksand"
        onClick={handleClick}
      >
        <img
          src={copy_icon}
          className={"h-4 w-4"}
          alt="Link icon"
        />
        <span className="capitalize">
          {" "}
          {!isCopied ? label : secondaryLabel}{" "}
        </span>
      </button>
      <Snackbar
        open={isCopied}
        autoHideDuration={2000}
        onClose={() => {
          setIsCopied(false);
        }}
        message="Copied to clipboard"
      />
    </>
  );
};

export default CopyButton;
