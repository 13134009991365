import React from "react";
import { useParams } from "react-router-dom";
import FantasyDetailForm from "@/components/roleplay/fantasy/FantasyDetailForm";

export const DetailedFantasyPage = () => {
  const { id } = useParams();

  return (
    <div>
      <FantasyDetailForm fantasyId={id} />
    </div>
  );
};
