import CharactersContainer from "@nectar/components/roleplay/character/CharactersContainer";
import React from "react";
import { Helmet } from "react-helmet-async";

const MyGirlsPage = () => {
  return (
    <>
      <Helmet>
        <title>Nectar - My Girls </title>
        <meta name="description" content="Yours Girls in one place." />
      </Helmet>
      <div>
        <CharactersContainer showDropdown={false} showAllGirlsTab={false}  />
      </div>
    </>
  );
};

export default MyGirlsPage;
