import React from "react";
import heart_logo from "../../assets/heart_logo.png";
import useApp from "@nectar/hooks/useApp";
import { Link } from "react-router-dom";

const SidebarHeader = ({ isCollapsed }) => {

  const app = useApp();

  return (
    <Link className="flex items-center gap-2" to={"/"}>
      <img src={heart_logo} alt="heart logo icon" className="w-8 h-8" />
      {!isCollapsed && (
        <div className={`leading-[0.5] ${app ? "-mt-2" : ""}`}>
          <p className="font-quicksand text-lg font-bold text-white">Nectar</p>
          {app && (
            <p className="opacity-70 font-quicksand text-md font-bold text-white capitalize">
              {app.name}
            </p>
          )}
        </div>
      )}
    </Link>
  );
};

export default SidebarHeader;
