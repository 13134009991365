import axios from "axios";
import { ExploreImage } from "../models/explore.image.model";
import { Profile } from "../models/profile.model";
import { LikeStatus } from "@/models/like.model";
import { Helper } from "@/utils/helper";

export class ExploreService {
  public async getExploreImages(
    category: string,
    page: number,
    pageSize: number,
    modelSlug?: string,
  ): Promise<ExploreImage[]> {
    try {
      const route = category ? `explore/images/${category}` : `explore/images`;
      const response = await axios.get<ExploreImage[]>(
        `${
          process.env.REACT_APP_API_URL
        }/${route}?page=${page}&page_size=${pageSize}${
          modelSlug ? `&model=${modelSlug}` : ""
        }`,
      );
      if (response.status === 200 && response.data) {
        const exploreImages = response.data.map((item: ExploreImage) => {
          return item as ExploreImage;
        });
        return exploreImages;
      }
      return [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getExploreProfiles(
    page: number,
    pageSize: number,
  ): Promise<Profile[]> {
    try {
      const response = await axios.get<Profile[]>(
        `${process.env.REACT_APP_API_URL}/explore/profiles?page=${page}&page_size=${pageSize}`,
      );
      if (response.status === 200 && response.data) {
        const profiles = response.data.map((item: Profile) => {
          return item as Profile;
        });
        return profiles;
      }
      return [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getExploreImage(
    exploreImageId: string,
  ): Promise<ExploreImage | null> {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/explore/image/${exploreImageId}`,
      );
      if (response.status === 200 && response.data) {
        return response.data as ExploreImage;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getNumExploreImages(
    category: string,
    modelSlug?: string,
  ): Promise<number | null> {
    try {
      const route = category ? `explore/images/${category}` : `explore/images`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${route}/count${
          modelSlug ? `?model=${modelSlug}` : ""
        }`,
      );
      if (response.status === 200 && response.data) {
        return response.data.count as number;
      }
      return null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getLikeStatus(
    exploreImageId: string,
  ): Promise<LikeStatus | null> {
    try {
      const token = Helper.getToken();
      const response = await axios.get<LikeStatus>(
        `${process.env.REACT_APP_API_URL}/user/image/${exploreImageId}/like/status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 && response.data) {
        return response.data;
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public getLikeStatusUrl(exploreImageId: string) {
    const endpoint = `//user/image/${exploreImageId}/like/status`;
    return `${process.env.REACT_APP_API_URL}${endpoint}`;
  }
}

export const exploreService = new ExploreService();
export default exploreService;
