import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import { Button, CardContent } from "@material-ui/core";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { userService } from "../../services/user.service";
import Typography from "@material-ui/core/Typography";
import feature_check from "../../assets/feature_check.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Card } from "@material-ui/core";
import PlanButton from "./PlanButton";
import Alert from "@mui/material/Alert";
import { Check } from "lucide-react";

const MobilePacksCard = () => {
  const [messagePacks, setMessagePacks] = useState(null);

  useEffect(() => {
    userService
      .getMessagePacks()
      .then((data) => {
        setMessagePacks(data);
      })
      .catch((error) => {
        console.error("Error fetching message packs:", error);
      });
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Grid container spacing={2}>
        {messagePacks?.map((pack) => {
          const buttonText =
            "PURCHASE " +
            pack.title.split(" ")[0] +
            " " +
            pack.title.split(" ")[2];
          const buttonStyle = {
            alignSelf: "center",
            backgroundColor: "#FF4343",
            background: "linear-gradient(180deg, #8B1B1B 0%, #E33333 92.19%)",
            borderRadius: "8px",
            border: "1px solid #E42929",
            textAlign: "center",
            width: "100%",
          };
          const descriptionArray = pack.description.split(". ");

          return (
            <Grid item xs={12} key={pack.id}>
              <Card style={{ backgroundColor: "#171717" }}>
                <Box position="relative">
                  <CardContent>
                    <div>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#FF4343",
                          wordWrap: "break-word",
                        }}
                      >
                        {pack.title}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: "#E9E9E9",
                          wordWrap: "break-word",
                          paddingBottom: 4,
                          fontWeight: "800",
                        }}
                      >
                        ${pack.price}
                      </Typography>
                      <ul>
                        {descriptionArray.slice(1).map((point, index) => (
                          <li
                            key={index}
                            style={{
                              fontSize: "12px",
                              color: "#808080",
                              wordWrap: "break-word",
                              paddingBottom: 4,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flexShrink: 0 }}>
                                {" "}
                                <Check color="#9E77ED" size={15} />
                              </div>
                              <div style={{ paddingLeft: 2 }}>{point}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <PlanButton
                        planId={pack.whop_plan_id}
                        buttonText={buttonText}
                        buttonStyle={buttonStyle}
                      />
                    </div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MobilePacksCard;
