import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Button,
  Modal,
} from "@material-ui/core";
import verified from "../../assets/verified.svg";
import { AppContext } from "../AppContext";
import { userService } from "../../services/user.service";
import LoginModal from "../auth/LoginModal";
import Divider from "@material-ui/core/Divider";
import expand_arrow from "../../assets/expand_arrow.svg";
import collapse_arrow from "../../assets/collapse_arrow.svg";
import MobileMessageBox from "../../components/roleplay/MobileMessageBox";
import { roleplayService } from "../../services/roleplay.service";
import back_arrow from "../../assets/arrow_backward_ios.svg";
import { Helper } from "../../utils/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";

const FantasyPerProfile = {
  "f878bb8e-1ac7-4aa6-b672-92a3389f58b5": [
    "c8e2b823-e175-416c-b624-848fcecbaff4",
    "c66f5f8d-d1e6-426d-b297-f196f6057e17",
  ],
  "f6252e0b-e0f9-460e-8b64-817825eb68f1": [
    "16403d34-3030-4e90-8886-a25dd313d68e",
    "9ea95de6-ba6e-4646-95dc-6fb5b91389c7",
  ],
  "eeb64f3d-bef0-437e-97e7-d8f87b76f484": [
    "2c5948be-b94d-4a73-9885-03bfdcc55d12",
    "7d8670b7-962a-4a01-ab93-0aa44f96b5b5",
  ],
  "e27a5ca0-21f1-4f8b-8b31-9e34e4ccc3ca": [
    "ae9ece17-dc85-4551-9001-102c561e1df6",
  ],
  "c83b3273-1737-4403-a3d3-751af4ae7b57": [
    "9f751d9f-dab5-444c-ad47-658bdd7d7ec5",
    "a67670fe-777d-4d29-bd86-57c87f89efed",
  ],
  "c637fa04-61d5-489d-88b1-4c9dda25e398": [
    "b25518d2-19c9-48e8-a0e1-3f2dbe8267e6",
  ],
  "c1b7af7c-4a90-44f7-9c71-7e06b2d5f1a8": [
    "52682588-1d7d-4cfc-8943-b5fdb0c7cc84",
    "c7a6c12c-4665-4ea5-87e6-8083702bccae",
  ],
  "a5d0f3b4-2224-41f6-a41f-7709503aab1b": [
    "4c042adb-4a93-4d3d-8bb3-7e1230738817",
    "9438dc96-e305-4853-916e-d9cb00d9ee4b",
  ],
  "79cdba12-dacd-4975-809a-2ed2c4a8ba51": [
    "c876cab8-d93c-4d5a-aba1-c33aa0e6c86a",
  ],
  "20bace3a-a255-4314-a541-cf65a44e9631": [
    "b4d3c1e4-5fa6-450a-a7bf-465758b5e9ca",
    "a99d7e74-c53b-44d9-9d3d-b394af51c8d2",
  ],
  "1947909a-ce56-4e36-9443-db461c9b7c2f": [
    "711ea509-c2f9-49d8-945d-d7562e7cd4b2",
    "223f8f60-15c4-4176-9916-0730fca333b6",
  ],
  "185e88d0-2334-4e4e-933b-947339de2c07": [
    "70df0505-6aa7-4015-bc70-79fed9f81ee5",
  ],
  "09f00385-5655-44c7-9503-b87935326f0e": [
    "ae094e1e-1204-4b01-9615-8bab444ece79",
  ],
};

const MobileProfileCard = ({ profile }) => {
  const navigate = useNavigate();
  const {
    user,
    setUser,
    refreshUser,
    accountStatus,
    setAccountStatus,
    setShowLoginModal,
  } = useContext(AppContext);
  const [conversation, setConversation] = useState(null);
  const [unpurchasedBundles, setUnpurchasedBundles] = useState([]);
  const userInitialized = useRef(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (user && !userInitialized.current) {
      refreshUser();
      userInitialized.current = true;
    }
  }, [user]);

  const handleLoginModalOpen = () => {
    setShowLoginModal(true);
  };

  const hideMessageBox = () => {
    // setCurrentProfile(profile); // change to something that is kept in account status state
    setShowMessageBox(false);
  };

  const chat = async () => {
    if (!user) {
      handleLoginModalOpen();
      return;
    }

    const fantasyIds = FantasyPerProfile?.[profile.id];
    
    if (!fantasyIds) {
      navigate(`/fantasy/create?char=${profile.id}`);
     return;
    }

    const fantasyId = fantasyIds[Math.floor(Math.random() * fantasyIds.length)];

    navigate(`/messages/${fantasyId}`);
  };

  const handleToggleDescription = () => {
    setIsExpanded((prev) => !prev);
  };

  //START ==================== The below is for when we add photos back in ====================
  const totalPhotos = profile.bundles
    ? profile.bundles.reduce((sum, bundle) => {
        const nonPreviewPhotos = bundle.profile_photos.filter(
          (photo) => !photo.preview,
        ).length;
        return sum + nonPreviewPhotos;
      }, 0)
    : 0;

  useEffect(() => {
    const ownedBundleIds = accountStatus?.owned_bundle_ids ?? [];

    if (profile.bundles) {
      let newTotalPrice = 0;
      const newUnpurchasedBundles = profile.bundles
        .filter((bundle) => {
          const isUnpurchased =
            !ownedBundleIds.includes(bundle.id) && bundle.price !== 0;
          if (isUnpurchased) newTotalPrice += bundle.price;
          return isUnpurchased;
        })
        .map((bundle) => bundle);

      setUnpurchasedBundles(newUnpurchasedBundles);
    } else {
      setUnpurchasedBundles([]);
    }
  }, [accountStatus, profile]);

  //END ==================== The above is for when we add photos back in ====================

  return (
    // TODO remove padding once alert is gone
    <Box sx={{ flexDirection: "column", p: 1 }}>
      <Card
        className="w-full"
        sx={{
          position: "relative",
          backgroundColor: "black",
          marginTop: "0px",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <img
              src={back_arrow}
              alt="backwards arrow"
              style={{ height: "20px", marginLeft: "16px" }}
              onClick={() => window.history.back()} // This makes the browser go back to the previous page
            />
          </Box>

          {/* Centered Username */}
          <Box flexGrow={1} display="flex" justifyContent="center">
            <Typography
              variant="h2"
              style={{ color: "white", fontWeight: "800" }}
            >
              {profile.username}
            </Typography>
          </Box>

          {/* Placeholder for symmetry */}
          <Box style={{ width: "20px" }}></Box>
        </Box>
        <CardHeader
          sx={{
            marginTop: "0px",
          }}
          avatar={
            <Avatar
              src={profile.profile_pic_url}
              alt={profile.username}
              sx={{
                height: "64px",
                width: "64px",
                border: "2px solid white",
              }}
            />
          }
          subheader={
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <Box>
                <Button
                  onClick={() => chat(profile)}
                  variant="contained"
                  color="secondary"
                >
                  Chat with {profile.name.split(" ")[0]}
                </Button>
              </Box>
              {/*{unpurchasedBundles.length === 0 && (*/}
              {/*    <Typography color="secondary">*/}
              {/*        All bundles purchased!*/}
              {/*    </Typography>*/}
              {/*)}*/}
            </Box>
          }
        />
      </Card>
      <Card
        className="w-full"
        sx={{ position: "relative", backgroundColor: "black", px: 2 }}
      >
        <Box>
          <Box
            sx={{ display: "flex", alignItems: "left", marginBottom: "12px" }}
          >
            <Typography variant="h3" style={{ color: "white" }}>
              {profile.name}
            </Typography>
          </Box>
          <Typography
            variant="h4"
            color="textSecondary"
            component="p"
            style={{ color: "white", opacity: 0.7, paddingBottom: "12px" }}
          >
            {profile.biography}
            {isExpanded && (
              <>
                <br />
                <br />
                {Helper.preprocessDescription(profile.description).map(
                  (descFragment, index) => (
                    <React.Fragment key={index}>
                      {descFragment}
                      <br />
                      <br />
                    </React.Fragment>
                  ),
                )}
              </>
            )}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            onClick={handleToggleDescription}
          >
            <Typography
              variant="h5"
              color="#E9E9E9"
              style={{ marginRight: "12px" }}
            >
              {isExpanded ? "SEE LESS" : "SEE MORE"}
            </Typography>
            <img
              src={isExpanded ? collapse_arrow : expand_arrow}
              alt={isExpanded ? "Collapse Arrow" : "Expand Arrow"}
              style={{ height: "12px", width: "12px" }}
            />
          </Box>
        </Box>
      </Card>
      <Box sx={{ px: 2 }}>
        <Divider
          style={{
            backgroundColor: "#FFFFFF",
            opacity: "50%",
            height: "1px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
      </Box>
      {showMessageBox && (
        <Modal
          open={showMessageBox}
          onClose={hideMessageBox}
          style={{
            zIndex: 10000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100vw",
              height: "100vh",
            }}
          >
            <MobileMessageBox
              profile={profile}
              conversation={conversation}
              closeModal={hideMessageBox}
            />
          </div>
        </Modal>
      )}
    </Box>
  );
};
export default MobileProfileCard;
