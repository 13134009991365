export const STEPS_ANIME = [
  {
    type: "carousel",
    title: "race",
    label: "Race",
    options: [
      {
        value: "waifu",
        label: "Human",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/ccbd7c2a-d850-41a4-b9c3-e25e73469200/public",
      },
      {
        value: "elf",
        label: "Elf",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/379b98da-476d-4d4f-91cf-c7a5bf661800/public",
      },
      {
        value: "devil",
        label: "Devil",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/9f291d8f-919a-4031-578b-2ba9bba0a200/public",
      },
      {
        value: "angel",
        label: "Angel",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/446bb675-01db-4c03-9889-c8f5d766fa00/public",
      },
      {
        value: "witch",
        label: "Witch",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/ab7d00f3-93e2-46ba-7414-9edd2bb51300/public",
      },
      {
        value: "cyborg",
        label: "Cyborg",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/afce3712-3bf6-4f4f-af88-58591b3a2400/public",
      },
    ],
  },
  {
    type: "grid",
    title: "skintone",
    label: "Skin Tone",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "pale",
        label: "Pale",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/7c49c1ec-565e-4ac9-24b3-d7fa44938800/public",
      },
      {
        value: "fair",
        label: "Normal",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/06aff07e-7816-456a-c440-927e0351e100/public",
      },
      {
        value: "tan",
        label: "Tan",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/ad4e1fa8-51be-4138-33f1-3591def00700/public",
      },
    ],
  },
  {
    type: "grid",
    title: "eyecolor",
    label: "Eye Color",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "black",
        label: "Black",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/c2d2d953-ab19-4370-efda-d8a1be55f100/public",
      },
      {
        value: "brown",
        label: "Brown",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/7b8733c8-4831-4f7c-6a20-425a721a9e00/public",
      },
      {
        value: "yellow",
        label: "Yellow",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/5fcc79ef-b9e6-4e89-16d1-ad07d0af0500/public",
      },
      {
        value: "blue",
        label: "Blue",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/6e058126-db89-40ed-9bb4-0c560f741900/public",
      },
      {
        value: "purple",
        label: "Purple",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/f36df02e-2e30-44a9-cfe0-6821c9b70e00/public",
      },
      {
        value: "red",
        label: "Red",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/de951c6c-bb7a-47dd-4f6a-33275c27b400/public",
      },
    ],
  },
  {
    type: "grid",
    title: "haircolor",
    label: "Hair Color",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "black",
        label: "Black",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/1a955360-6155-4395-0c87-c3a74d970100/public",
      },
      {
        value: "brown",
        label: "Brown",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/ded5eceb-3ca0-4400-dfde-413ada3dc600/public",
      },
      {
        value: "blonde",
        label: "Blonde",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/71917c7c-008b-4611-bb59-e43f71691a00/public",
      },
      {
        value: "pink",
        label: "Pink",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/6842e5dd-c7f2-44d9-29b4-04cb9644bb00/public",
      },
      {
        value: "purple",
        label: "Purple",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/c346b91b-25be-454f-5305-4cf04cbf5100/public",
      },
      {
        value: "silver",
        label: "Silver",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/ea0f67ed-a0b9-4d25-a868-31773a471e00/public",
      },
    ],
  },
  {
    type: "grid",
    title: "hairlength",
    label: "Hair Length",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "short hair",
        label: "Short",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/b1bb8cf5-dbee-45b7-2035-9c2899966200/public",
      },
      {
        value: "medium hair",
        label: "Medium",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/c1d1304a-3ddf-4a42-6997-529ca674c100/public",
      },
      {
        value: "long hair",
        label: "Long",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/74fe364c-fbfe-4486-8eba-cb7bc6c6ec00/public",
      },
    ],
  },
  {
    type: "grid",
    title: "hairstyle",
    label: "Hair Style",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "straight",
        label: "Straight",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/c07f7a00-e2e4-4d06-fe71-6d7142f69600/public",
      },
      {
        value: "wavy",
        label: "Wavy",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/44b0d66c-bb37-47d0-0184-a99acae27600/public",
      },
      {
        value: "twintails",
        label: "Twintails",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/133f8362-d537-4057-40ba-ad8ea1d36900/public",
      },
      {
        value: "bangs",
        label: "Bangs",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/09a17a84-236a-4d38-4619-756a8ac22800/public",
      },
      {
        value: "bobcut",
        label: "Bobcut",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/992f6690-3908-4922-a013-60d133a59700/public",
      },
      {
        value: "ponytail",
        label: "Ponytail",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/667c6f61-dc98-4693-9aaa-e4e34fd21400/public",
      },
    ],
  },
  {
    type: "grid",
    title: "bodytype",
    label: "Body Type",
    columns: 3,
    large_columns: 3,
    options: [
      {
        value: "petite",
        label: "Petite",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/07e8d35a-34f1-44f1-5f81-f8ed03352600/public",
      },
      {
        value: "athletic",
        label: "Athletic",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/d4eb640a-0933-4385-8861-34bef43a3400/public",
      },
      {
        value: "hourglass figure",
        label: "Hourglass",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/944cbc5e-6417-4515-56a2-ff39e430eb00/public",
      },
      {
        value: "busty",
        label: "Busty",
        image:
          "https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/07bb94f9-43d6-4491-bff2-bf447e552a00/public",
      },
    ],
  },
  {
    type: "number",
    title: "age",
    label: "Age",
    min: 18,
    max: 99,
  },
  {
    type: "text",
    title: "name",
    label: "Name",
    rows: 1,
    placeholder: "Give your waifu a name!",
  },
  {
    type: "text",
    title: "description",
    label: "Personality",
    rows: 3,
    placeholder: "Describe your waifu's personality!",
  },
];
