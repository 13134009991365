import React from "react";
import CharactersContainer from "@nectar/components/roleplay/character/CharactersContainer";
import RpHero from "@nectar/components/roleplay/hero/RpHero";
import HeroCreateGirl from "@nectar/components/roleplay/character/HeroCreateGirl";
import { Helmet } from "react-helmet-async";

const CharactersPage = () => {
  return (
    <>
      <Helmet>
        <title>Nectar - Roleplay</title>
        <meta
          name="description"
          content="Create the AI Girlfriend of your dreams."
        />
      </Helmet>
      <main className="pt-4">
        <div className="pb-1 px-6 max-w-[1920px] mx-auto">
          <HeroCreateGirl />
        </div>

        <CharactersContainer showDropdown={true} showOwnGirlsTab={false} />
      </main>
    </>
  );
};

export default CharactersPage;
