import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import { Button, CardContent } from "@material-ui/core";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { userService } from "../../services/user.service";
import Typography from "@material-ui/core/Typography";
import feature_check from "../../assets/feature_check.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Card } from "@material-ui/core";
import PlanButton from "./PlanButton";
import Alert from "@mui/material/Alert";
import { Check } from "lucide-react";

const MobilePlansCard = ({
  tokenPlans,
  accountStatus,
  refreshUser,
  loggedInUser,
}) => {
  const currPlanPrice =
    tokenPlans.find((p) => p.title === accountStatus?.plan)?.price || 0;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Grid container spacing={2}>
        {tokenPlans.map((plan) => {
          const isCurrentPlan = plan.title === accountStatus?.plan;
          const buttonText =
            plan.title === "Free"
              ? ""
              : isCurrentPlan
              ? "Manage subscription"
              : `${plan.price < currPlanPrice ? "Downgrade" : "Upgrade"} to ${
                  plan.title
                }`;

          const [imageCreatorDesc, roleplayDesc] = plan.description.includes(
            "|",
          )
            ? plan.description.split("|").map((part) => part.trim())
            : [plan.description, ""];
          const buttonStyle = {
            alignSelf: "center",
            backgroundColor: plan.price < currPlanPrice ? "#313131" : undefined,
            background: !isCurrentPlan
              ? "linear-gradient(180deg, #8B1B1B 0%, #E33333 92.19%)"
              : undefined,
            borderRadius: !isCurrentPlan ? "8px" : undefined,
            border: !isCurrentPlan ? "1px solid #E42929" : undefined,
            textAlign: "center",
            width: "100%",
          };
          const descriptionArray = plan.description.split(". ");

          return (
            <Grid item xs={12} key={plan.id}>
              <Card style={{ backgroundColor: "#171717" }}>
                <Box position="relative">
                  {isCurrentPlan && (
                    <Box
                      position="absolute"
                      top="6px"
                      right="6px"
                      bgcolor="#313131"
                      padding="4px"
                      borderRadius="4px"
                    >
                      <Typography
                        variant="h4"
                        style={{
                          color: "white",
                          fontSize: "12px",
                          opacity: 0.7,
                        }}
                      >
                        CURRENT PLAN
                      </Typography>
                    </Box>
                  )}
                  <CardContent>
                    <div>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#FF4343",
                          wordWrap: "break-word",
                        }}
                      >
                        {plan.title}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: "#E9E9E9",
                          wordWrap: "break-word",
                          paddingBottom: 4,
                          fontWeight: "800",
                        }}
                      >
                        ${plan.price}/month
                      </Typography>
                      <ul>
                        {imageCreatorDesc && (
                          <>
                            <li
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#FFFFFF",
                                marginBottom: "4px",
                              }}
                            >
                              Image Creator
                            </li>
                            {imageCreatorDesc.split(".").map((point, index) => {
                              if (index === 0 || !point.trim()) {
                                // Skip the first item or any empty string
                                return null;
                              }
                              return (
                                <li
                                  key={index}
                                  style={{
                                    color: "#FFFFFF",
                                    wordWrap: "break-word",
                                    fontSize: 12,
                                    paddingBottom: 4,
                                    whiteSpace: "normal",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ flexShrink: 0 }}>
                                      {" "}
                                      <Check color="#9E77ED" size={15} />
                                    </div>
                                    <div style={{ paddingLeft: 2 }}>
                                      {point}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </>
                        )}
                        {roleplayDesc && (
                          <>
                            <li
                              style={{
                                fontSize: 14,
                                color: "#FFFFFF",
                                marginBottom: "4px",
                              }}
                            >
                              Roleplay
                            </li>
                            {roleplayDesc.split(".").map(
                              (point, index) =>
                                point.trim() && (
                                  <li
                                    key={index}
                                    style={{
                                      color: "#FFFFFF",
                                      wordWrap: "break-word",
                                      fontSize: 12,
                                      paddingBottom: 4,
                                      whiteSpace: "normal",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ flexShrink: 0 }}>
                                        {" "}
                                        <Check color="#9E77ED" size={15} />
                                      </div>
                                      <div style={{ paddingLeft: 2 }}>
                                        {point}
                                      </div>
                                    </div>
                                  </li>
                                ),
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                    <div>
                      {plan.price != 0 && (
                        <PlanButton
                          planId={plan.whop_plan_id}
                          buttonText={buttonText}
                          buttonStyle={buttonStyle}
                          isCurrentPlan={isCurrentPlan}
                        />
                      )}
                    </div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MobilePlansCard;
