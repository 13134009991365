import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import back_arrow from "../../assets/arrow_backward_ios.svg";
import MessagesInboxItem from "./MessagesInboxItem";

const MessagesInbox = ({ conversations = [], isLoading, user }) => {
  // const params = useParams();
  const navigation = useNavigate();

  return (
    <aside className="h-full w-[100vw] flex-shrink-0 overflow-y-auto border-r border-zinc-900  p-4 font-quicksand sm:w-[250px] lg:w-[300px] mb-16">
      <header className="flex items-center gap-2">
        <Link to={"/roleplay"} className="md:hidden">
          <img
            src={back_arrow}
            alt="backwards arrow"
            className="w-4"
            onClick={() => {
              navigation("/roleplay");
            }}
          />
        </Link>
        <h1 className="font-semibold text-xl">Chats</h1>
      </header>

      <div className="flex flex-col gap-2.5 pb-24 mt-4">
        {isLoading && <p className="text-white/70">Loading...</p>}

        {!isLoading && conversations?.length === 0 && (
          <p className="text-white/70">
            Once you start a conversation, they will appear here.{" "}
          </p>
        )}

        {conversations
          ?.toSorted((a, b) => {
            return (
              new Date(b.last_message.timestamp) -
              new Date(a.last_message.timestamp)
            );
          })
          ?.map((conversation) => {
            return (
              <MessagesInboxItem
                key={conversation.id}
                conversation={conversation}
                user={user}
              />
            );
          })}
      </div>
    </aside>
  );
};

export default MessagesInbox;
