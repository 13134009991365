import { Helper } from "@nectar/utils/helper";
import axios from "axios";

export const fetcher = (url) => {
  const token = Helper.getToken();

  return axios
    .get(url, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    })
    .then((res) => {
      return res.data;
    });
};
