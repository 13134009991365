import axios, { AxiosRequestConfig } from "axios";
import { Helper } from "../utils/helper";
import { Character, CharactersResponse } from "../models/character.model";
import { Bundle } from "../models/bundle.model";
import {
  Conversation,
  ConversationCreateRequest,
} from "../models/conversation.model";
import { MessageRequest, MessageResponse } from "../models/message.model";
import { FantasiesResponse, Fantasy } from "@nectar/models/fantasy.model";

export class RoleplayService {
  private shuffleArray<T>(array: T[]): T[] {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  private async sendGetRequest<T>(
    endpoint: string,
    loggedIn: boolean = false,
  ): Promise<T | null> {
    try {
      const config: AxiosRequestConfig = {
        headers: loggedIn
          ? { Authorization: `Bearer ${Helper.getToken()}` }
          : {},
      };

      const response = await axios.get<T>(
        `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`,
        config,
      );
      if (response.status === 200 && response.data) {
        return response.data as T;
      }
      return null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async sendPostRequest<T>(
    endpoint: string,
    data: any,
  ): Promise<T | null> {
    try {
      const config: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${Helper.getToken()}` },
      };

      const response = await axios.post<T>(
        `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`,
        data,
        config,
      );
      if (response.status === 401) {
        throw new Error("Unauthorized"); // Throw a specific error for 401
      }

      if (response.status === 200 && response.data) {
        return response.data as T;
      }
      return null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // ================ GET REQUESTS =====================

  // ================ FANTASY REQUESTS =====================

  public async getFantasies(
    type: string,
    page: number = 1,
    pageSize: number = 10,
  ): Promise<FantasiesResponse | null> {
    const endpoint = `/fantasy?type=${type}&page=${page}&page_size=${pageSize}`;
    return this.sendGetRequest<FantasiesResponse>(endpoint);
  }

  public async getFantasyById(id: string): Promise<Fantasy | null> {
    const endpoint = `/fantasy/${id}`;
    return this.sendGetRequest<Fantasy>(endpoint);
  }

  public async getFantasiesForUser(
    userId: string,
    type: string,
    loggedIn: boolean,
  ): Promise<FantasiesResponse | null> {
    const endpoint = `/fantasy/user/${userId}?type=${type}`;
    return this.sendGetRequest<FantasiesResponse>(endpoint, loggedIn);
  }

  public getFantasiesForUserUrl(userId: string, type: string) {
    const endpoint = `/fantasy/user/${userId}?type=${type}`;
    return `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`;
  }

  public getFantasiesUrl(
    type: string,
    page: number = 1,
    pageSize: number = 10,
    category: string,
    sortBy: string,
    search: string,
  ) {
    const endpoint = `/fantasy?type=${type}&page=${page}&page_size=${pageSize}&category=${category}&sort_by=${sortBy}&search=${search}`;
    return `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`;
  }

  // ================ CHARACTER REQUESTS =====================
  public async getCharacters(
    category: string = "all",
    page: number = 1,
    pageSize: number = 10,
  ): Promise<CharactersResponse | null> {
    const endpoint = `/characters?category=${category}&page=${page}&page_size=${pageSize}`;
    const response = await this.sendGetRequest<CharactersResponse>(endpoint);

    if (response && response.characters) {
      response.characters = this.shuffleArray(response.characters);
    }

    return response;
  }

  public async getCharacterById(id: string): Promise<Character | null> {
    const endpoint = `/characters/${id}`;
    return this.sendGetRequest<Character>(endpoint);
  }

  public getCharactersUrl(
    category: string = "all",
    page: number,
    pageSize: number,
    search: string,
    options: {
      model: "anime" | "realistic" | "all";
      race?: string | null;
    } = {
      model: "all",
      race: null,
    },
  ) {
    const endpoint = `/characters?category=${category}&page=${page}&page_size=${pageSize}&search=${
      search || ""
    }&model=${options?.model || "all"}${
      options.race ? `&race=${options.race}` : ""
    }`;

    return `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`;
  }

  public async getCharacterByUsername(
    username: string,
  ): Promise<CharactersResponse | null> {
    const endpoint = `/characters/u/${username}`;
    return this.sendGetRequest<CharactersResponse>(endpoint);
  }

  public getCharacterByUserUrl(
    page: number,
    pageSize: number,
    search: string,
    options: {
      model: "anime" | "realistic" | "all";
      race?: string | null;
    } = {
      model: "all",
      race: null,
    },
  ) {
    const endpoint = `/user/characters?page=${page}&page_size=${pageSize}&search=${
      search || ""
    }&model=${options?.model || "all"}${
      options.race ? `&race=${options.race}` : ""
    }`;

    return `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`;
  }

  // ================ BUNDLE REQUESTS =====================
  public async getBundleById(
    id: string,
    loggedIn: boolean,
  ): Promise<Bundle | null> {
    const endpoint = `/bundle/${id}${loggedIn ? "" : "/preview"}`;
    return this.sendGetRequest<Bundle>(endpoint, loggedIn);
  }

  // ================ CONVERSATION REQUESTS =====================

  public async getConversations(loggedIn: boolean): Promise<any> {
    const endpoint = `/conversations`;
    return this.sendGetRequest(endpoint, loggedIn);
  }

  public getConversationsUrl() {
    const endpoint = `/conversations`;
    return `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`;
  }

  public async getConversation(
    id: string,
    loggedIn: boolean,
  ): Promise<Conversation | null> {
    const endpoint = `/conversations/${id}`;
    return this.sendGetRequest<Conversation>(endpoint, loggedIn);
  }

  public getConversationUrl(id: string) {
    const endpoint = `/conversations/${id}`;
    return `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`;
  }

  public async getConversationImages(
    id: string,
    loggedIn: boolean,
    page: number = 1,
    pageSize: number = 10,
  ): Promise<any> {
    const endpoint = `/conversations/${id}/images?page=${page}&page_size=${pageSize}`;
    return this.sendGetRequest<MessageResponse[]>(endpoint, loggedIn);
  }

  public getConversationImagesUrl(id: string, page: number, pageSize: number) {
    const endpoint = `/conversations/${id}/images?page=${page}&page_size=${pageSize}`;
    return `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`;
  }

  public async getConversationHistory(
    id: string,
    loggedIn: boolean,
    page: number = 1,
    pageSize: number = 10,
  ): Promise<any> {
    const endpoint = `/conversations/${id}/history?page=${page}&page_size=${pageSize}`;
    return this.sendGetRequest(endpoint, loggedIn);
  }

  public getConversationHistoryUrl(id: string, page: number, pageSize: number) {
    const endpoint = `/conversations/${id}/history?page=${page}&page_size=${pageSize}`;
    return `${process.env.REACT_APP_CHAT_API_URL}${endpoint}`;
  }

  // ================= POST REQUESTS ===================
  public async createConversation(
    data: ConversationCreateRequest,
  ): Promise<Conversation | null> {
    const endpoint = "/conversations/create";
    return this.sendPostRequest<Conversation>(endpoint, data);
  }

  public async fantasyCreate(data: any): Promise<Fantasy> {
    const endpoint = "/fantasy/create";
    return this.sendPostRequest<any>(endpoint, data);
  }

  public async defaultFantasyCreate(data: any): Promise<Fantasy> {
    const endpoint = "/fantasy/create/default";
    return this.sendPostRequest<any>(endpoint, data);
  }

  public async fantasyEdit(id: string, data: any): Promise<Fantasy> {
    const endpoint = `/fantasy/${id}/edit`;
    return this.sendPostRequest<any>(endpoint, data);
  }

  public async characterEdit(id: string, data: any): Promise<any> {
    const endpoint = `/character/${id}/edit`;
    return this.sendPostRequest<any>(endpoint, data);
  }

  public async sendMessage(
    conversationId: string,
    data: MessageRequest,
  ): Promise<MessageResponse | null> {
    const endpoint = `/conversations/${conversationId}/message`;
    return this.sendPostRequest<MessageResponse>(endpoint, data);
  }

  public async regenerate(
    conversationId: string,
  ): Promise<MessageResponse | null> {
    return this.sendPostRequest<MessageResponse>(
      `/conversations/${conversationId}/regenerate`,
      {},
    );
  }

  // @app.post("/fantasy/{id:uuid}/like")
  public async fantasyToggleLike(fantasyId: string): Promise<boolean | null> {
    return this.sendPostRequest<boolean>(`/fantasy/${fantasyId}/like`, {});
  }

  // preview
  public async createCharacterPreview(data: any): Promise<any> {
    data["profile_pic_url"] = null;
    data["seed"] = 0;
    return this.sendPostRequest<any>(`/characters/preview`, data);
  }

  // create character
  public async createCharacter(data: any): Promise<any> {
    return this.sendPostRequest<any>(`/characters/create`, data);
  }
}

export const roleplayService = new RoleplayService();
