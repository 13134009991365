import React from "react";
import sparkles from "../../assets/sparkles.svg";

const DetailedImageCardExtra = ({ exploreImage }) => {
  return (
    <div className="pb-4">
      <h2 className="mb-2 text-white/70 ">Extras</h2>

      {exploreImage.high_res && (
        <div className="flex items-center gap-2 mb-2">
          <div className="flex h-12 w-12 items-center justify-center rounded bg-zinc-800">
            <img
              src={sparkles}
              alt="sparkles icon"
              className="h-5 w-5 opacity-60"
            />
          </div>
          <p>High Resolution</p>
        </div>
      )}

      {exploreImage.extras?.map((extra) => {
        return (
          <div key={extra.id} className="flex items-center gap-2 mb-2">
            <img
              src={extra.image_urls[0]}
              alt={`image ${extra.title}`}
              className="h-12 w-12 rounded object-cover object-center"
            />
            <p>{extra.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default DetailedImageCardExtra;
