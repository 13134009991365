import FantasyCreateForm from "../components/roleplay/fantasy/FantasyCreateForm";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { roleplayService } from "@/services/roleplay.service";

export const FantasyEditPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [fantasy, setFantasy] = useState(null);

  useEffect(() => {
    const loadFantasy = async () => {
      setIsLoading(true);
      try {
        const fetchedFantasy = await roleplayService.getFantasyById(id);
        setFantasy(fetchedFantasy);
      } catch (error) {
        console.error("Failed to fetch fantasy", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadFantasy();
  }, [id]);
  return (
    <div>
      <FantasyCreateForm isEdit={true} fantasyData={fantasy} />
    </div>
  );
};
