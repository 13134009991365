import React, { useEffect, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "../../../components/AppContext";
import { formatCount } from "@nectar/utils/formatCount";
import { ProfileStack } from "../ProfileStack";
import TextPreprocess from "../../../utils/TextPreprocess";
import { Helper } from "../../../utils/helper";
import FantasyPreviewCardLike from "./FantasyPreviewCardLike";
import { Play, BadgeCheck } from "lucide-react";
import official from "@/assets/official.svg";

const FantasyPreviewCard = ({
  fantasy,
  LikedFantasiesMap,
  isLoadingLikedFantasies,
  mutateLikedFantasies,
  user,
}) => {
  const officialIds = new Set([
    // PRODUCTION
    "ba3ea829-7ce6-4987-9006-05bcdc7c56f5",

    // STAGING
    "50e4290b-4b1b-4315-8cc4-92afe2fe38a1",
  ]);
  const navigate = useNavigate();
  const { setShowLoginModal } = useContext(AppContext);
  const isOfficial = officialIds.has(fantasy.user_id);

  const chat = async (event, fantasy) => {
    event.stopPropagation();

    if (!user) {
      handleLoginModalOpen();
      return;
    }
    navigate(`/messages/${fantasy.id}`);
  };

  const handleLoginModalOpen = () => {
    setShowLoginModal(true);
  };

  return (
    <div className="w-full h-full flex flex-col  overflow-hidden text-white rounded-lg group bg-zinc-900 font-quicksand">
      {/* Header */}
      <Link
        to={`/fantasy/${fantasy.id}`}
        className="relative flex h-[250px] w-full items-center justify-center bg-zinc-800 object-cover object-center"
      >
        {isOfficial && (
          <div className="absolute top-0 left-0 m-2">
            <img
              src={official}
              alt="Official Nectar AI Fantasy"
              className="w-6 h-6"
            />
          </div>
        )}
        <img
          src={fantasy.thumbnail_image_url}
          alt={`${fantasy.title} thumbnail`}
          className="object-cover object-center w-full h-full"
        />
      </Link>

      <div className="p-4 flex flex-col  flex-grow">
        <Link to={`/fantasy/${fantasy.id}`}>
          <h2 className="text-white font-bold mb-1 text-lg">{fantasy.title}</h2>
        </Link>

        {fantasy.characters.length > 0 && (
          <ProfileStack characters={fantasy.characters} />
        )}

        <Link
          to={`/fantasy/${fantasy.id}`}
          className="text-white/70 mt-2 text-ellipsis line-clamp-3 flex-grow "
        >
          <TextPreprocess
            preprocess={Helper.preprocessMessage(
              fantasy.description,
              fantasy.characters?.[0]?.name || "character",
              user?.username || "user",
            )}
          />
        </Link>

        <div className="mt-4">
          <div className="flex justify-between items-center gap-1.5">
            <div className="flex flex-row items-center justify-center p-1  text-white/70">
              <Play className="w-4 h-4 mr-1 fill-current" />
              {formatCount(fantasy.messages_count)}
            </div>
            <FantasyPreviewCardLike
              LikedFantasiesMap={LikedFantasiesMap}
              isLoadingLikedFantasies={isLoadingLikedFantasies}
              mutateLikedFantasies={mutateLikedFantasies}
              fantasy={fantasy}
            />
            {}
          </div>
          <button
            onClick={(event) => chat(event, fantasy)}
            className="btn btn-secondary w-full mt-2 justify-center py-3"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default FantasyPreviewCard;
