import { Button } from "@nectar/components/ui/button";
import { Minus, Plus } from "lucide-react";
import React from "react";

const CharacterSelectNumber = ({
  title,
  label,
  placeholder,
  value,
  onChange,
  min,
  max,
}) => {
  const handleChange = (e) => {
    if (e.target.value > max) {
      onChange(max);
      return;
    }

    onChange(e.target.value);
  };

  const handleIncrement = () => {
    handleChange({ target: { value: value + 1 } });
  };

  const handleDecrement = () => {
    handleChange({ target: { value: value - 1 } });
  };

  return (
    <div className="w-full">
      <p className="mb-2 text-muted-foreground">{label}</p>
      <div className="flex gap-2">
        <Button
          disabled={value <= min}
          onClick={handleDecrement}
          variant="secondary"
          size="icon"
          className="shrink-0 h-12 w-12"
        >
          <Minus />
        </Button>

        <div className="w-full">
          <input
            type="number"
            min={min}
            max={max}
            className="input-control"
            value={value}
            onChange={handleChange}
          />
          <p className="text-sm text-muted-foreground">
            {" "}
            The value must be greater than {min}{" "}
          </p>
        </div>
        <Button
          onClick={handleIncrement}
          variant="secondary"
          size="icon"
          className="shrink-0 h-12 w-12"
        >
          <Plus />
        </Button>
      </div>
    </div>
  );
};

export default CharacterSelectNumber;
