function TosPage() {
  return (
    <>
      <div className="bg-black min-h-screen max-w-screen justify-content center">
        {/* <!-- Terms of Service Page --> */}
        <div className="page-header">
          <div className="overlay d-flex flex-column justify-content-center align-items-center">
            <h1 className="title text-white max-w-screen-lg mx-auto">
              Terms and Conditions / Terms of Service.
            </h1>
          </div>
        </div>
        {/* <!-- Terms of Service --> */}
        <div className="col-md-12 d-flex flex-row justify-content-center align-items-left">
          <div className="col-md-8 d-flex flex-column justify-content-center align-items-left pt-4 max-w-screen-lg mx-auto">
            <h1 className="title text-white">
              Nectar AI, LLC Website Terms of Use
            </h1>
            <p className="description text-white">
              Version 1.0. Last Revised on November 13, 2023
            </p>
            <p className="text-regular text-white">
              The website located at www.trynectar.ai (the “Site”) is a
              copyrighted work belonging to Nectar AI, LLC (“Company”, “us”,
              “our”, and “we”). Certain features of the Site may be subject to
              additional guidelines, terms, or rules, which will be posted on
              the Site in connection with such features. All such additional
              terms, guidelines, and rules are incorporated by reference into
              these Terms. Subscriptions refer to the services or access to the
              Service offered on a subscription basis by Nectar AI to You. These
              Terms of Use (these “Terms”) set forth the legally binding terms
              and conditions that govern your use of the Site. By accessing or
              using the Site, you are accepting these Terms (on behalf of
              yourself or the entity that you represent), and you represent and
              warrant that you have the right, authority, and capacity to enter
              into these Terms (on behalf of yourself or the entity that you
              represent). You may not access or use the Site or accept the Terms
              if you are not at least 18 years old. By continuing to access
              either products (Roleplay/Image Creator) or anything provided by
              Company on Site, you automatically accept these terms. If you do
              not agree with all of the provisions of these Terms, do not access
              and/or use the Site.{" "}
            </p>
            <p className="text-regular text-white mb-5">
              Please be aware that section 10.2 of THESE TERMS CONTAINS
              PROVISIONS GOVERNING HOW DISPUTES THAT YOU AND WE HAVE AGAINST
              EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY
              DISPUTES THAT AROSE OR WERE ASSERTED PRIOR TO THE EFFECTIVE DATE
              OF YOUR ACCEPTANCE OF THESE TERMS. IN PARTICULAR, IT CONTAINS AN
              ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE
              DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL
              ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT: (1)
              YOU WILL ONLY BE PERMITTED TO PURSUE DISPUTES OR CLAIMS AND SEEK
              RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR
              CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING;
              AND (2) YOU ARE WAIVING YOUR RIGHT TO PURSUE DISPUTES OR CLAIMS
              AND SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL.
            </p>
            <p className="description text-white">Accounts</p>
            <p className="text-regular text-white">
              Account Creation. In order to use certain features of the Site,
              you must register for an account (“Account”) and provide certain
              information about yourself as prompted by the account registration
              form. You represent and warrant that: (a) all required
              registration information you submit is truthful and accurate; (b)
              you will maintain the accuracy of such information. You may delete
              your Account at any time, for any reason, by following the
              instructions on the Site. Company may suspend or terminate your
              Account in accordance with Section 8.{" "}
            </p>
            <p className="text-regular text-white mb-5">
              Account Responsibilities. You are responsible for maintaining the
              confidentiality of your Account login information and are fully
              responsible for all activities that occur under your Account. You
              agree to immediately notify Company of any unauthorized use, or
              suspected unauthorized use of your Account or any other breach of
              security. Company cannot and will not be liable for any loss or
              damage arising from your failure to comply with the above
              requirements.
            </p>
            <p className="description text-white">Access to the Site</p>
            <p className="text-regular text-white">
              License. Subject to these Terms, Company grants you a
              non-transferable, non-exclusive, revocable, limited license to use
              and access the Site solely for your own personal, noncommercial
              use.{" "}
            </p>
            <p className="text-regular text-white">
              Certain Restrictions. The rights granted to you in these Terms are
              subject to the following restrictions: (a) you shall not license,
              sell, rent, lease, transfer, assign, distribute, host, or
              otherwise commercially exploit the Site, whether in whole or in
              part, or any content displayed on the Site; (b) you shall not
              modify, make derivative works of, disassemble, reverse compile or
              reverse engineer any part of the Site; (c) you shall not access
              the Site in order to build a similar or competitive website,
              product, or service; and (d) except as expressly stated herein, no
              part of the Site may be copied, reproduced, distributed,
              republished, downloaded, displayed, posted or transmitted in any
              form or by any means. Unless otherwise indicated, any future
              release, update, or other addition to functionality of the Site
              shall be subject to these Terms. All copyright and other
              proprietary notices on the Site (or on any content displayed on
              the Site) must be retained on all copies thereof.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Modification. Company reserves the right, at any time, to modify,
              suspend, or discontinue the Site (in whole or in part) with or
              without notice to you. You agree that Company will not be liable
              to you or to any third party for any modification, suspension, or
              discontinuation of the Site or any part thereof.
            </p>
            <br></br>
            <p className="text-regular text-white">
              No Support or Maintenance. You acknowledge and agree that Company
              will have no obligation to provide you with any support or
              maintenance in connection with the Site.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Ownership. Excluding any User Content that you may provide
              (defined below), you acknowledge that all the intellectual
              property rights, including copyrights, patents, trade marks, and
              trade secrets, in the Site and its content are owned by Company or
              Company's suppliers (”Creators”). Neither these Terms (nor your
              access to the Site) transfers to you or any third party any
              rights, title or interest in or to such intellectual property
              rights, except for the limited access rights expressly set forth
              in Section 2.1. Company and its suppliers reserve all rights not
              granted in these Terms. There are no implied licenses granted
              under these Terms.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Feedback. If you provide Company with any feedback or suggestions
              regarding the Site (“Feedback”), you hereby assign to Company all
              rights in such Feedback and agree that Company shall have the
              right to use and fully exploit such Feedback and related
              information in any manner it deems appropriate. Company will treat
              any Feedback you provide to Company as non-confidential and
              non-proprietary. You agree that you will not submit to Company any
              information or ideas that you consider to be confidential or
              proprietary.
            </p>
            <br></br>
            <p className="description text-white">User Content</p>
            <p className="text-regular text-white">
              User Content. “User Content” means any and all information and
              content that a user submits to, or uses with, the Site (e.g.,
              content in the user's profile or postings). You are solely
              responsible for your User Content. You assume all risks associated
              with use of your User Content, including any reliance on its
              accuracy, completeness or usefulness by others, or any disclosure
              of your User Content that personally identifies you or any third
              party. You hereby represent and warrant that your User Content
              does not violate our Acceptable Use Policy (defined in Section
              3.3). You may not represent or imply to others that your User
              Content is in any way provided, sponsored or endorsed by Company.
              Since you alone are responsible for your User Content, you may
              expose yourself to liability if, for example, your User Content
              violates the Acceptable Use Policy. Company is not obligated to
              backup any User Content, and your User Content may be deleted at
              any time without prior notice. You are solely responsible for
              creating and maintaining your own backup copies of your User
              Content if you desire.
            </p>
            <p className="text-regular text-white">
              License. You hereby grant (and you represent and warrant that you
              have the right to grant) to Company an irrevocable, nonexclusive,
              royalty-free and fully paid, worldwide license to reproduce,
              distribute, publicly display and perform, prepare derivative works
              of, incorporate into other works, and otherwise use and exploit
              your User Content, and to grant sublicenses of the foregoing
              rights, solely for the purposes of including your User Content in
              the Site. You hereby irrevocably waive (and agree to cause to be
              waived) any claims and assertions of moral rights or attribution
              with respect to your User Content.
            </p>
            <br></br>
            <p className="text-regular text-white">Subscriptions</p>
            <p className="text-regular text-white">Subscription Period</p>
            <p className="text-regular text-white">
              {" "}
              The Service or some parts of the Service are available only with a
              paid Subscription. You will be billed in advance on a recurring
              and periodic basis (such as daily, weekly, monthly or annually),
              depending on the type of Subscription plan you select when
              purchasing the Subscription. At the end of each period, Your
              Subscription will automatically renew under the exact same
              conditions unless You cancel it or Nectar cancels it.{" "}
            </p>
            <br></br>
            <p className="text-regular text-white">
              Subscription Cancellations
            </p>
            <p className="text-regular text-white">
              You may cancel Your Subscription renewal either through the
              profile settings page or by contacting Nectar. You will not
              receive a refund for the fees You already paid for Your current
              Subscription period and You will be able to access the Service
              until the end of Your current Subscription period.
            </p>
            <br></br>
            <p className="text-regular text-white">Rate Limits</p>
            <p className="text-regular text-white">
              Nectar AI reserves the right to implement rate limits for users of
              the Service, both Pro and Free, in order to maintain the
              functionality and performance of the site. These rate limits may
              include restrictions on the frequency and volume of requests made
              to the Service and may be adjusted from time to time at the sole
              discretion of Nectar AI. Users agree to abide by any rate limits
              imposed and acknowledge that exceeding such limits may result in
              temporary or permanent suspension of their access to the Service.
            </p>
            <br></br>
            <p className="text-regular text-white">Billing</p>
            <p className="text-regular text-white">
              You shall provide Nectar with accurate and complete Stripe account
              and/or payment information. You shall promptly update your Stripe
              account and other information, including your email address and
              payment information, so that we can complete your transactions and
              contact you as needed.
            </p>
            <br></br>
            <p className="text-regular text-white">Fee Changes</p>
            <p className="text-regular text-white">
              Nectar, in its sole discretion and at any time, may modify the
              Subscription fees. Any Subscription fee change will become
              effective at the end of the then-current Subscription period.
              Nectar will provide You with reasonable prior notice of any change
              in Subscription fees to give You an opportunity to terminate Your
              Subscription before such change becomes effective. Your continued
              use of the Service after the Subscription fee change comes into
              effect constitutes Your agreement to pay the modified Subscription
              fee amount.
            </p>
            <br></br>
            <p className="text-regular text-white">Refunds</p>
            Except when required by law, paid Subscription fees are
            non-refundable.
            <p className="text-regular text-white">
              Certain refund requests for Subscriptions may be considered by the
              Company on a case-by-case basis and granted at the sole discretion
              of Nectar AI.{" "}
            </p>
            <br></br>
            <p className="description text-white">Acceptable Use Policy</p>
            <p className="text-regular text-white">
              Acceptable Use Policy. The following terms constitute our
              “Acceptable Use Policy”: You agree not to use the Site to collect,
              upload, transmit, display, or distribute any User Content (i) that
              violates any third-party right, including any copyright,
              trademark, patent, trade secret, moral right, privacy right, right
              of publicity, or any other intellectual property or proprietary
              right, (ii) that is unlawful, harassing, abusive, tortious,
              threatening, harmful, invasive of another's privacy, vulgar,
              defamatory, false, intentionally misleading, trade libelous,
              pornographic (defined as real persons or similarity to), obscene,
              patently offensive, promotes racism, bigotry, hatred, or physical
              harm of any kind against any group or individual or is otherwise
              objectionable, (iii) that is harmful to minors in any way, or (iv)
              that is in violation of any law, regulation, or obligations or
              restrictions imposed by any third party. In addition, you agree
              not to: (i) upload, transmit, or distribute to or through the Site
              any computer viruses, worms, or any software intended to damage or
              alter a computer system or data; (ii) send through the Site
              unsolicited or unauthorized advertising, promotional materials,
              junk mail, spam, chain letters, pyramid schemes, or any other form
              of duplicative or unsolicited messages, whether commercial or
              otherwise; (iii) use the Site to harvest, collect, gather or
              assemble information or data regarding other users, including
              e-mail addresses, without their consent; (iv) interfere with,
              disrupt, or create an undue burden on servers or networks
              connected to the Site, or violate the regulations, policies or
              procedures of such networks; (v) attempt to gain unauthorized
              access to the Site (or to other computer systems or networks
              connected to or used together with the Site), whether through
              password mining or any other means; (vi) harass or interfere with
              any other user's use and enjoyment of the Site; or (vi) use
              software or automated agents or scripts to produce multiple
              accounts on the Site, or to generate automated searches, requests,
              or queries to (or to strip, scrape, or mine data from) the Site
              (provided, however, that we conditionally grant to the operators
              of public search engines revocable permission to use spiders to
              copy materials from the Site for the sole purpose of and solely to
              the extent necessary for creating publicly available searchable
              indices of the materials, but not caches or archives of such
              materials, subject to the parameters set forth in our robots.txt
              file).
            </p>
            <br></br>
            <p className="text-regular text-white">
              Enforcement. We reserve the right (but have no obligation) to
              review, refuse and/or remove any User Content in our sole
              discretion, and to investigate and/or take appropriate action
              against you in our sole discretion if you violate the Acceptable
              Use Policy or any other provision of these Terms or otherwise
              create liability for us or any other person. Such action may
              include removing or modifying your User Content, terminating your
              Account in accordance with Section 8, and/or reporting you to law
              enforcement authorities.
            </p>
            <p className="text-regular text-white">
              Indemnification. You agree to indemnify and hold Company (and its
              officers, employees, and agents) harmless, including costs and
              attorneys' fees, from any claim or demand made by any third party
              due to or arising out of (a) your use of the Site, (b) your
              violation of these Terms, (c) your violation of applicable laws or
              regulations or (d) your User Content. Company reserves the right,
              at your expense, to assume the exclusive defense and control of
              any matter for which you are required to indemnify us, and you
              agree to cooperate with our defense of these claims. You agree not
              to settle any matter without the prior written consent of Company.
              Company will use reasonable efforts to notify you of any such
              claim, action or proceeding upon becoming aware of it.
            </p>
            <br></br>
            <p className="description text-white">
              Third-Party Links & Ads; Other Users
            </p>
            <p className="text-regular text-white">
              Third-Party Links & Ads. The Site may contain links to third-party
              websites and services, and/or display advertisements for third
              parties (collectively, “Third-Party Links & Ads”). Such
              Third-Party Links & Ads are not under the control of Company, and
              Company is not responsible for any Third-Party Links & Ads.
              Company provides access to these Third-Party Links & Ads only as a
              convenience to you, and does not review, approve, monitor,
              endorse, warrant, or make any representations with respect to
              Third-Party Links & Ads. You use all Third-Party Links & Ads at
              your own risk, and should apply a suitable level of caution and
              discretion in doing so. When you click on any of the Third-Party
              Links & Ads, the applicable third party's terms and policies
              apply, including the third party's privacy and data gathering
              practices. You should make whatever investigation you feel
              necessary or appropriate before proceeding with any transaction in
              connection with such Third-Party Links & Ads.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Other Users. Each Site user is solely responsible for any and all
              of its own User Content. Since we do not control User Content, you
              acknowledge and agree that we are not responsible for any User
              Content, whether provided by you or by others. We make no
              guarantees regarding the accuracy, currency, suitability,
              appropriateness, or quality of any User Content. Your interactions
              with other Site users are solely between you and such users. You
              agree that Company will not be responsible for any loss or damage
              incurred as the result of any such interactions. If there is a
              dispute between you and any Site user, we are under no obligation
              to become involved.
            </p>
            <p className="text-regular text-white">
              Release. You hereby release and forever discharge the Company (and
              our officers, employees, agents, successors, and assigns) from,
              and hereby waive and relinquish, each and every past, present and
              future dispute, claim, controversy, demand, right, obligation,
              liability, action and cause of action of every kind and nature
              (including personal injuries, death, and property damage), that
              has arisen or arises directly or indirectly out of, or that
              relates directly or indirectly to, the Site (including any
              interactions with, or act or omission of, other Site users or any
              Third-Party Links & Ads). IF YOU ARE A CALIFORNIA RESIDENT, YOU
              HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH
              THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO
              CLAIMS WHICH THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR
              SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
              RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
              AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
            </p>
            <br></br>
            <p className="text-regular text-white">
              Disclaimers. THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE”
              BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND
              ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
              IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
              ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS)
              MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL
              BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
              BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER
              HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES
              ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE
              LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST
              USE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME
              JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED
              WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Limitation on Liability. TO THE MAXIMUM EXTENT PERMITTED BY LAW,
              IN NO EVENT SHALL COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR
              ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF
              PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES
              ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR
              INABILITY TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE
              IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
              RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR
              LOSS OF DATA RESULTING THEREFROM. TO THE MAXIMUM EXTENT PERMITTED
              BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
              OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO
              THESE TERMS (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
              OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY
              US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL
              NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO
              LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.
              SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
              LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
              LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Term and Termination. Subject to this Section, these Terms will
              remain in full force and effect while you use the Site. We may
              suspend or terminate your rights to use the Site (including your
              Account) at any time for any reason at our sole discretion,
              including for any use of the Site in violation of these Terms.
              Upon termination of your rights under these Terms, your Account
              and right to access and use the Site will terminate immediately.
              You understand that any termination of your Account may involve
              deletion of your User Content associated with your Account from
              our live databases. Company will not have any liability whatsoever
              to you for any termination of your rights under these Terms,
              including for termination of your Account or deletion of your User
              Content. Even after your rights under these Terms are terminated,
              the following provisions of these Terms will remain in effect:
              Sections 2.2 through 2.6, Section 3 and Sections 4 through 10.
            </p>
            <br></br>
            <p className="description text-white">Copyright Policy.</p>
            <br></br>
            <p className="text-regular text-white">
              Company respects the intellectual property of others and asks that
              users of our Site do the same. In connection with our Site, we
              have adopted and implemented a policy respecting copyright law
              that provides for the removal of any infringing materials and for
              the termination, in appropriate circumstances, of users of our
              online Site who are repeat infringers of intellectual property
              rights, including copyrights. If you believe that one of our users
              is, through the use of our Site, unlawfully infringing the
              copyright(s) in a work, and wish to have the allegedly infringing
              material removed, the following information in the form of a
              written notification (pursuant to 17 U.S.C. § 512(c)) must be
              provided to our designated Copyright Agent:
            </p>
            <br></br>
            <ul className="text-regular text-white">
              <li>your physical or electronic signature;</li>
              <li>
                identification of the copyrighted work(s) that you claim to have
                been infringed;
              </li>
              <li>
                identification of the material on our services that you claim is
                infringing and that you request us to remove;
              </li>
              <li>
                sufficient information to permit us to locate such material;
              </li>
              <li>your address, telephone number, and e-mail address;</li>
              <li>
                a statement that you have a good faith belief that use of the
                objectionable material is not authorized by the copyright owner,
                its agent, or under the law; and;
              </li>
              <li>
                a statement that the information in the notification is
                accurate, and under penalty of perjury, that you are either the
                owner of the copyright that has allegedly been infringed or that
                you are authorized to act on behalf of the copyright owner.
              </li>
            </ul>
            <p className="text-regular text-white">
              Please note that, pursuant to 17 U.S.C. § 512(f), any
              misrepresentation of material fact (falsities) in a written
              notification automatically subjects the complaining party to
              liability for any damages, costs and attorney's fees incurred by
              us in connection with the written notification and allegation of
              copyright infringement.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Here at Nectar AI, LLC. (“Nectar AI”, “Nectar”, “we” or “us”), we
              respect the copyright rights of others, take claims of copyright
              infringement seriously and expect our users to do the same. In
              accordance with the Digital Millennium Copyright Act of 1998, 17
              U.S.C. § 512 (the “DMCA”), we have implemented a policy to respond
              to notices of alleged copyright infringement that we receive.
            </p>
            <br></br>
            <p className="description text-white">
              Reporting Claims of Copyright Infringement:
            </p>
            <br></br>
            <p className="text-regular text-white">
              If you believe that any content or materials on the Nectar website
              at www.trynectar.ai or on any associated Nectar mobile
              applications (collectively, “Website”), and any other services or
              resources of Nectar that are accessed or enabled via the Website
              (collectively with the Website, the “Services”) infringe your
              copyright, you may request removal of such content and materials
              (or access to them) from the Services by submitting written
              notification to our copyright agent designated below. This written
              notice of alleged copyright infringement (“DMCA Notice”) must
              include substantially the following:
            </p>
            <br></br>
            <ul className="text-regular text-white">
              <li>
                A physical or electronic signature of a person authorized to act
                on behalf of the copyright owner.
              </li>
              <li>
                Identification and description of the copyrighted work or works
                that you claim has been infringed.
              </li>
              <li>
                Identification of the content or material on the Services that
                you claim to be infringing and that is to be removed or access
                to which is to be disabled, and information reasonably
                sufficient to permit us to locate where the content and material
                resides on the Services.
              </li>
              <li>
                Information reasonably sufficient to permit us to contact you,
                including at least your name, address and telephone number, and,
                if available, an email address.
              </li>
              <li>
                A written statement by you that you have a good faith belief
                that use of the content or material on the Services in the
                manner you allege to be infringing is not authorized by the
                copyright owner, its agent, or the law.
              </li>
              <li>
                A written statement by you, made under penalty of perjury, that
                the information in your DMCA Notice is accurate, and that you
                are the copyright owner or authorized to act on behalf of the
                copyright owner.
              </li>
            </ul>
            <p className="text-regular text-white">
              Please send all DMCA Notices to our designated agent (“Designated
              Agent”) at the following email address: trynectarai@gmail.com
            </p>
            <br></br>
            <p className="text-regular text-white">
              Please do not send other inquires or information to our Designated
              Agent. Absent prior express permission, our Designated Agent is
              not authorized to accept or waive service of formal legal process,
              and any agency relationship beyond that required to accept valid
              DMCA Notices is expressly disclaimed.
            </p>
            <br></br>
            <p className="text-regular text-white">
              If you fail to comply with all the requirements for your DMCA
              Notice, that DMCA Notice may not be effective. Please be aware
              that under Section 512(f) of the DMCA, any person who knowingly
              materially misrepresents that material or activity is infringing
              may be subject to liability.
            </p>
            <br></br>
            <p className="description text-white">Take Down Procedures:</p>
            <br></br>
            <p className="text-regular text-white">
              Upon receipt of a valid and complete DMCA Notice, we will take
              actions to remove and/or disable access to the allegedly
              infringing content or material and promptly notify the user who
              posted, uploaded or otherwise provided the content or material
              that we have removed or disabled access to such content or
              material. We will also take reasonable steps to notify or contact
              the user or member who posted, uploaded or provided the content or
              material of the notice and takedown. Such user or member may avail
              themselves of the counter notification procedures below which may
              result in the restoration of the content or material in question.
            </p>
            <br></br>
            <p className="description text-white">
              Counter Notification Procedures:
            </p>
            <br></br>
            <p className="text-regular text-white">
              If you, as a user, member or other person whose content or
              material has been alleged to be infringing (“Content Provider”),
              believe that the content or material you posted has been wrongly
              removed or disabled due to a mistake or misidentification, you may
              file a counter-notice with us (a “Counter Notice”). To be
              effective, a Counter Notice must be a written communication that
              includes the following:
            </p>
            <br></br>
            <ul className="text-regular text-white">
              <li>
                A physical or electronic signature of the Content Provider.
              </li>
              <li>
                Identification of the content or material that has been removed
                or to which access has been disabled and the location within the
                Services at which the content or material appeared before it was
                removed or access to it was disabled.
              </li>
              <li>
                A statement under penalty of perjury that the Content Provider
                has a good faith belief that the content or material was removed
                or disabled as a result of mistake or misidentification.
              </li>
              <li>
                Information reasonably sufficient to permit us to contact you,
                including at least your name, address and telephone number, and,
                if available, an email address.
              </li>
              <li>
                A written statement from the Content Provider that the Content
                Provider consents to the jurisdiction of Federal District Court
                for the judicial district in which the address is located, or if
                the Content Provider's address is outside of the United States,
                for any judicial district in which Nectar AI may be found, and
                that the Content Provider will accept service of process from
                the person who provided the applicable DMCA Notice or an agent
                of such person.
              </li>
            </ul>
            <p className="text-regular text-white">
              Please send any Counter Notice to our Designated Agent listed
              above.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Upon receipt of a valid and complete Counter Notice, we will
              forward a copy of the Counter Notice to the original complaining
              party and may replace or restore access to the content or material
              within 10-14 business days after receipt of the Counter Notice,
              unless the copyright owner files a lawsuit against the Content
              Provider with respect to the allegedly infringing content or
              material (or Nectar or its Designated Agent receives notice of
              such a lawsuit).
            </p>
            <br></br>
            <p className="text-regular text-white">
              Please be aware that if you knowingly materially misrepresent that
              material or activity on the Services was removed or disabled by
              mistake or misidentification, you may be held liable for damages
              (including costs and attorneys' fees) under Section 512(f) of the
              DMCA.
            </p>
            <br></br>
            <p className="description text-white">Repeat Infringers:</p>
            <br></br>
            <p className="text-regular text-white">
              It is Nectar's policy to terminate or suspend membership
              privileges and accounts of any user or member who repeatedly
              infringes copyright rights in its use of the Services and/or to
              remove, delete and/or disable all content and materials posted,
              uploaded or provided by such user or member. We reserve the right
              at any time to disable access to, or remove any content or
              material or activity accessible on or from the Services claimed to
              be infringing of any intellectual property or proprietary rights
              or based on facts or circumstances from which infringing activity
              is apparent.
            </p>
            <br></br>
            <p className="description text-white">Modifications to Policy:</p>
            <br></br>
            <p className="text-regular text-white">
              Nectar reserves the right to modify, alter or add to this DMCA
              Notice and Takedown Section above, and all affected persons should
              regularly check back to stay current on any such changes. The
              designated Copyright Agent for Company is:
            </p>
            <br></br>
            <p className="text-regular text-white">
              Designated Agent: Nectar AI
            </p>
            <br></br>
            <p className="text-regular text-white">
              Address of Agent: Contact Us at Email for Address
            </p>
            <br></br>
            <p className="text-regular text-white">
              Email: trynectarai@gmail.com
            </p>
            <br></br>
            <p className="description text-white">General</p>
            <br></br>
            <p className="text-regular text-white">
              Changes. These Terms are subject to occasional revision, and if we
              make any substantial changes, we may notify you by sending you an
              e-mail to the last e-mail address you provided to us (if any),
              and/or by prominently posting notice of the changes on our Site.
              You are responsible for providing us with your most current e-mail
              address. In the event that the last e-mail address that you have
              provided us is not valid, or for any reason is not capable of
              delivering to you the notice described above, our dispatch of the
              e-mail containing such notice will nonetheless constitute
              effective notice of the changes described in the notice. Continued
              use of our Site following notice of such changes shall indicate
              your acknowledgement of such changes and agreement to be bound by
              the terms and conditions of such changes.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Dispute Resolution. Please read this Section 10.2 (sometimes
              referred to herein as this “Arbitration Agreement”) carefully. It
              is part of your contract with Company and affects your rights. It
              contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS
              ACTION WAIVER.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Applicability of Arbitration Agreement. All claims and disputes
              (excluding claims for injunctive or other equitable relief as set
              forth below) in connection with these Terms or the use of any
              product or service provided by the Company that cannot be resolved
              informally or in small claims court shall be resolved by binding
              arbitration on an individual basis under the terms of this
              Arbitration Agreement. Unless otherwise agreed to, all arbitration
              proceedings shall be held in English. This Arbitration Agreement
              applies to you and the Company, and to any subsidiaries,
              affiliates, agents, employees, predecessors in interest,
              successors, and assigns, as well as all authorized or unauthorized
              users or beneficiaries of services or goods provided under these
              Terms.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Notice Requirement and Informal Dispute Resolution. Before either
              party may seek arbitration, the party must first send to the other
              party a written Notice of Dispute (“Notice”) describing the nature
              and basis of the claim or dispute, and the requested relief. A
              Notice to the Company should be sent to: trynectarai@gmail.com.
              After the Notice is received, you and the Company may attempt to
              resolve the claim or dispute informally. If you and the Company do
              not resolve the claim or dispute within thirty (30) days after the
              Notice is received, either party may begin an arbitration
              proceeding. The amount of any settlement offer made by any party
              may not be disclosed to the arbitrator until after the arbitrator
              has determined the amount of the award, if any, to which either
              party is entitled.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Arbitration Rules. The Federal Arbitration Act governs the
              interpretation and enforcement of this Arbitration Agreement. The
              arbitration will be conducted by JAMS, an established alternative
              dispute resolution provider. Disputes involving claims,
              counterclaims, or request for relief under $250,000, not inclusive
              of attorneys' fees and interest, shall be subject to JAMS's most
              current version of the Streamlined Arbitration Rules and
              procedures available at
              http://www.jamsadr.com/rules-streamlined-arbitration/; all other
              disputes shall be subject to JAMS's most current version of the
              Comprehensive Arbitration Rules and Procedures, available at
              http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS's
              rules are also available at www.jamsadr.com or by calling JAMS at
              800-352-5267. If JAMS is not available to arbitrate, the parties
              will select an alternative arbitral forum. Each party shall bear
              its own costs (including attorney's fees) and disbursements
              arising out of the arbitration and shall pay an equal share of the
              fees and costs of the ADR Provider. You may choose to have the
              arbitration conducted by telephone, based on written submissions,
              or in person in the country where you live or at another mutually
              agreed location. Any judgment on the award rendered by the
              arbitrator may be entered in any court of competent jurisdiction.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Authority of Arbitrator. The arbitrator shall have exclusive
              authority to (i) determine the scope and enforceability of this
              Arbitration Agreement and (ii) resolve any dispute related to the
              interpretation, applicability, enforceability or formation of this
              Arbitration Agreement including, but not limited to, any assertion
              that all or any part of this Arbitration Agreement is void or
              voidable. The arbitration will decide the rights and liabilities,
              if any, of you and Company. The arbitration proceeding will not be
              consolidated with any other matters or joined with any other cases
              or parties. The arbitrator shall have the authority to grant
              motions dispositive of all or part of any claim. The arbitrator
              shall have the authority to award monetary damages and to grant
              any non-monetary remedy or relief available to an individual under
              applicable law, the arbitral forum's rules, and these Terms
              (including the Arbitration Agreement). The arbitrator shall issue
              a written award and statement of decision describing the essential
              findings and conclusions on which the award is based, including
              the calculation of any damages awarded. The arbitrator has the
              same authority to award relief on an individual basis that a judge
              in a court of law would have. The award of the arbitrator is final
              and binding upon you and us.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Waiver of Jury Trial. YOU AND COMPANY HEREBY WAIVE ANY
              CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A
              TRIAL IN FRONT OF A JUDGE OR A JURY. You and Company are instead
              electing that all disputes, claims, or requests for relief shall
              be resolved by arbitration under this Arbitration Agreement,
              except as specified in Section 10.2(a) (Applicability of
              Arbitration Agreement) above. An arbitrator can award on an
              individual basis the same damages and relief as a court and must
              follow these Terms as a court would. However, there is no judge or
              jury in arbitration, and court review of an arbitration award is
              subject to very limited review.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Waiver of Class or Other Non-Individualized Relief. ALL DISPUTES,
              CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS
              ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS
              AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS
              AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
              ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR
              USER. If a decision is issued stating that applicable law
              precludes enforcement of any of this section's limitations as to a
              given dispute, claim, or request for relief, then such aspect must
              be severed from the arbitration and brought into the State or
              Federal Courts located in the State of California. All other
              disputes, claims, or requests for relief shall be arbitrated.
            </p>
            <br></br>
            <p className="text-regular text-white">
              30-Day Right to Opt Out. You have the right to opt out of the
              provisions of this Arbitration Agreement by sending written notice
              of your decision to opt out to: trynectarai@gmail.com, within
              thirty (30) days after first becoming subject to this Arbitration
              Agreement. Your notice must include your name and address, your
              Company username (if any), the email address you used to set up
              your Company account (if you have one), and an unequivocal
              statement that you want to opt out of this Arbitration Agreement.
              If you opt out of this Arbitration Agreement, all other parts of
              these Terms will continue to apply to you. Opting out of this
              Arbitration Agreement has no effect on any other arbitration
              agreements that you may currently have, or may enter in the
              future, with us.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Severability. Except as provided in Section 10.2(f) (Waiver of
              Class or Other Non-Individualized Relief), if any part or parts of
              this Arbitration Agreement are found under the law to be invalid
              or unenforceable, then such specific part or parts shall be of no
              force and effect and shall be severed and the remainder of the
              Arbitration Agreement shall continue in full force and effect.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Survival of Agreement. This Arbitration Agreement will survive the
              termination of your relationship with Company.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Modification. Notwithstanding any provision in these Terms to the
              contrary, we agree that if Company makes any future material
              change to this Arbitration Agreement, you may reject that change
              within thirty (30) days of such change becoming effective by
              writing Company at the following address: trynectarai@gmail.com.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Export. The Site may be subject to U.S. export control laws and
              may be subject to export or import regulations in other countries.
              You agree not to export, reexport, or transfer, directly or
              indirectly, any U.S. technical data acquired from Company, or any
              products utilizing such data, in violation of the United States
              export laws or regulations.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Disclosures. Company is located at the address in Section 10.8. If
              you are a California resident, you may report complaints to the
              Complaint Assistance Unit of the Division of Consumer Product of
              the California Department of Consumer Affairs by contacting them
              in writing at 400 R Street, Sacramento, CA 95814, or by telephone
              at (800) 952-5210.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Electronic Communications. The communications between you and
              Company use electronic means, whether you use the Site or send us
              emails, or whether Company posts notices on the Site or
              communicates with you via email. For contractual purposes, you (a)
              consent to receive communications from Company in an electronic
              form; and (b) agree that all terms and conditions, agreements,
              notices, disclosures, and other communications that Company
              provides to you electronically satisfy any legal requirement that
              such communications would satisfy if it were be in a hardcopy
              writing. The foregoing does not affect your non-waivable rights.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Entire Terms. These Terms constitute the entire agreement between
              you and us regarding the use of the Site. Our failure to exercise
              or enforce any right or provision of these Terms shall not operate
              as a waiver of such right or provision. The section titles in
              these Terms are for convenience only and have no legal or
              contractual effect. The word “including” means “including without
              limitation”. If any provision of these Terms is, for any reason,
              held to be invalid or unenforceable, the other provisions of these
              Terms will be unimpaired and the invalid or unenforceable
              provision will be deemed modified so that it is valid and
              enforceable to the maximum extent permitted by law. Your
              relationship to Company is that of an independent contractor, and
              neither party is an agent or partner of the other. These Terms,
              and your rights and obligations herein, may not be assigned,
              subcontracted, delegated, or otherwise transferred by you without
              Company's prior written consent, and any attempted assignment,
              subcontract, delegation, or transfer in violation of the foregoing
              will be null and void. Company may freely assign these Terms. The
              terms and conditions set forth in these Terms shall be binding
              upon assignees.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Copyright/Trademark Information. Copyright © 2023 Nectar AI, LLC.
              All rights reserved. All trademarks, logos and service marks
              (“Marks”) displayed on the Site are our property or the property
              of other third parties. You are not permitted to use these Marks
              without our prior written consent or the consent of such third
              party which may own the Marks.
            </p>
            <br></br>
            <p className="text-regular text-white">
              Contact Information: Email: trynectarai@gmail.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TosPage;
