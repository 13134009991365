import React from "react";
import { Helper } from "../../utils/helper";
import { AvatarFallback, Avatar, AvatarImage } from "../ui/avatar";
import TextPreprocess from "@nectar/utils/TextPreprocess";
import MessageBubble from "@/components/messages/MessagesBubble";

const MessagesGreeting = ({
  message,
  character,
  fantasyThumbnailImage,
  user,
}) => {
  const messageProcessed = Helper.preprocessMessage(
    message,
    character.name,
    user?.name || "user",
  )?.filter((fragment) => {
    // prevent "'" or "," in the message
    if (fragment?.content?.length < 2) {
      return false;
    }

    return true;
  });

  return (
    <div className="flex-grow">
      {/* <Avatar className="w-16 h-16 mx-auto">
        <AvatarImage src={character.profile_pic_url} />
        <AvatarFallback> {character.username.slice(0, 2)} </AvatarFallback>
      </Avatar> */}

      <img
        className="h-[250px] w-fit  mx-auto rounded object-cover object-center"
        src={fantasyThumbnailImage}
        alt="fantasy thumbnail"
      />

      <div className="text-center text-white/70 text-sm mt-4 mb-6">
        {messageProcessed?.map((fragment, i) => {
          if (fragment.isItalic) {
            // Render as italicized text
            return (
              <span key={`text-${i}`} className="italic">
                {fragment.content}
              </span>
            );
          } else {
            // Skip rendering here for non-italicized text
            return null;
          }
        })}
      </div>

      {messageProcessed?.map((fragment, i) => {
        if (!fragment.isItalic) {
          // Render as a message bubble if not italicized
          return (
            <MessageBubble
              key={`bubble-${i}`}
              isUserAuthor={false}
              message={fragment.content}
              timestamp={new Date().toISOString()}
              character={character}
              isTyping={false}
              isLatest={i === 0}
              regenerateMessage={undefined}
              user={user}
            />
          );
        } else {
          // Return null for now, we'll render italicized text separately
          return null;
        }
      })}
    </div>
  );
};

export default MessagesGreeting;
