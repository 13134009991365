import React, { useMemo } from "react";
import { Button } from "@nectar/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";

const CharacterSelectCarousel = ({ options, value, onChange }) => {
  const optionSelected = useMemo(
    () => options.find((option) => option.value === value),
    [value],
  );

  const handleNext = () => {
    const currentIndex = options.findIndex((option) => option.value === value);
    const nextIndex =
      options.length - 1 === currentIndex ? 0 : currentIndex + 1;
    const nextOption = options[nextIndex];
    if (nextOption) {
      onChange(nextOption.value);
    }
  };

  const handlePrev = () => {
    const currentIndex = options.findIndex((option) => option.value === value);
    const prevIndex =
      currentIndex === 0 ? options.length - 1 : currentIndex - 1;
    const prevOption = options[prevIndex];
    if (prevOption) {
      onChange(prevOption.value);
    }
  };

  return (
    <div className="flex-grow flex items-center flex-col justify-center gap-4">
      {optionSelected?.image ? (
        <img
          className="h-[50vh] aspect-[9/12] bg-zinc-900 object-cover object-center rounded-lg"
          src={optionSelected?.image}
        />
      ) : (
        <div className="h-[50vh] aspect-[9/12] bg-zinc-900 rounded-lg" />
      )}
      {value === "other" && (
        <div className="w-full max-w-[240px]">
          <label className="text-sm text-muted-foreground">Your option</label>
          <input
            placeholder="Write your own..."
            type="text"
            className="w-full h-10 bg-muted rounded px-3 py-2 mt-2"
          />
        </div>
      )}
      <div className="flex gap-2 items-center w-full max-w-[240px]">
        <Button
          onClick={handlePrev}
          size={"icon"}
          variant={"secondary"}
          className="flex-shrink-0"
        >
          <ChevronLeft />
        </Button>
        <div className="h-10 bg-muted w-full flex justify-center items-center rounded">
          {optionSelected?.label}
        </div>
        <Button
          onClick={handleNext}
          size={"icon"}
          variant={"secondary"}
          className="flex-shrink-0"
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default CharacterSelectCarousel;
