import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@nectar/components/ui/avatar";

import {
  ChevronLeft,
  Image,
  Info,
  MoreHorizontal,
  Sparkles,
  ChevronsUp,
} from "lucide-react";
import { Button, buttonVariants } from "@nectar/components/ui/button";

import { TooltipTrigger, TooltipContent, Tooltip } from "../ui/tooltip";
import { unsupportedUsername } from "./MessagesBox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { AlertDialogTrigger } from "../ui/alert-dialog";
import theme from "@/styles/theme";
import PaywallModal from "@/components/auth/PaywallModal";

const MESSAGE_PAYWALL_SOURCE = "message";
const MessagesBoxHeader = ({
  user,
  refreshUser,
  accountStatus,
  fantasy,
  showGallery,
  setShowGallery,
}) => {
  const characters = useMemo(() => {
    return fantasy.characters;
  }, [fantasy]);

  const DisabledImageGeneration = unsupportedUsername.has(
    characters[0].username,
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!accountStatus) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  const buttonStyle = {
    alignSelf: "center",
    backgroundColor: "#FF4343",
    background: "linear-gradient(180deg, #8B1B1B 0%, #E33333 92.19%)",
    borderRadius: "8px",
    border: "1px solid #E42929",
    textAlign: "center",
    width: "100%",
    minHeight: "20px",
  };

  return (
    <header className="relative h-[60px] bg-zinc-900 px-4 flex justify-between items-center h-[60px] flex-shrink-0">
      <div className="flex items-center gap-2">
        <Link className="md:hidden" to={"/messages"}>
          <ChevronLeft className="w-5 h-5" />
        </Link>
        <Avatar className="w-8 h-8">
          <AvatarImage src={characters[0].profile_pic_url} />
          <AvatarFallback>{characters[0].username.slice(0, 2)}</AvatarFallback>
        </Avatar>
        <p className="text-white/70 text-sm hidden md:block">
          {" "}
          {characters[0].username}{" "}
        </p>
      </div>
      <h1 className="absolute left-1/2 -translate-x-1/2 font-semibold flex-grow  text-center w-full px-24 whitespace-nowrap text-ellipsis overflow-hidden pointer-events-none ">
        {fantasy.title}
      </h1>
      <div className="flex items-center gap-2">
        {DisabledImageGeneration && (
          <Tooltip>
            <TooltipTrigger>
              <div className="">
                <Info className="w-5 h-5 text-yellow-400" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Photo Sending Not Yet Available For Fantasy</p>
            </TooltipContent>
          </Tooltip>
        )}
        {accountStatus?.plan_tier <= 3 && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className="text-xs py-1.5 hidden md:flex"
            style={{
              ...buttonStyle,
            }}
            onClick={() => handlePaywallModalOpen(MESSAGE_PAYWALL_SOURCE)}
          >
            <ChevronsUp className="w-4 h-4 mr-1" />
            Upgrade Experience
          </Button>
        )}

        {!DisabledImageGeneration && !showGallery && (
          <Button
            onClick={() => {
              setShowGallery(true);
            }}
            variant="ghost"
            size="icon"
            className="relative"
          >
            <Image className="w-5 h-5" />
          </Button>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger
            className={buttonVariants({
              variant: "ghost",
              size: "icon",
              className: "relative",
            })}
          >
            <MoreHorizontal className="w-5 h-5" />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex flex-col gap-2">
            {accountStatus?.plan_tier <= 3 && (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className="py-2 w-auto px-4 md:hidden"
                style={{
                  ...buttonStyle,
                }}
                onClick={() => handlePaywallModalOpen(MESSAGE_PAYWALL_SOURCE)}
              >
                <ChevronsUp className="w-4 h-4 mr-1" />
                Upgrade Experience
              </Button>
            )}
            <AlertDialogTrigger
              className={buttonVariants({
                variant: "destructive",
                className: "bg-transparent text-red-500 hover:text-red-200",
              })}
            >
              Reset Conversation
            </AlertDialogTrigger>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {showPaywallModal && (
        <div>
          <PaywallModal
            message={"Enhance your roleplay experience🔮! Select a plan below:"}
            open={showPaywallModal}
            onClose={handlePaywallModalClose}
            refreshUser={refreshUser}
            loggedInUser={user}
          />
        </div>
      )}
    </header>
  );
};

export default MessagesBoxHeader;
