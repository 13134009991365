import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Modal from "../ui/Modal";
import DetailedImageCard from "./DetailedImageCard";
import { imagesContext } from "./ImagesContext";

import close_icon from "../../assets/close_icon.svg";
import useCategory from "../../hooks/useCategory";

export const DetailedImageCardModal = ({ exploreImage, children }) => {
  const { setExploreImageFocused } = useContext(imagesContext);
  const category = useCategory();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => {
    setExploreImageFocused(null);
    if (location.pathname.startsWith("/images")) {
      navigate(`/images?category=${category}`, {
        replace: true,
      });
    }
  };

  return (
    <Modal handleClose={handleClose}>
      <div className="relative w-full max-w-6xl  overflow-y-auto max-h-[95svh] rounded-2xl animate-show-modal">
        <button
          onClick={() => handleClose()}
          className="p-1.5 rounded-full bg-zinc-800 absolute right-4 top-4 md:right-6 md:top-6"
        >
          <img
            src={close_icon}
            alt="close icon"
            className="h-5 w-5 opacity-80 hover:opacity-100"
          />
        </button>

        <DetailedImageCard exploreImage={exploreImage} />
      </div>
    </Modal>
  );
};
