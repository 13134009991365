import React, { useContext, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { ChevronDown } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import CharactersContainer from "../roleplay/character/CharactersContainer";
import { CreateContext } from "../CreateContext";

const CharactersSelect = () => {
  const [open, setOpen] = useState(false);
  const { character, setCharacter } = useContext(CreateContext);

  const handleSelect = (character) => {
    setCharacter(character);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div className="p-3 pt-4 border-y border-zinc-800 flex justify-between items-center hover:cursor-pointer hover:bg-zinc-900">
          <div className="flex items-center gap-2">
            {!character ? (
              <div className="w-12 h-12 rounded-full bg-zinc-800"></div>
            ) : (
              <Avatar className="w-12 h-12">
                <AvatarFallback>test</AvatarFallback>
                <AvatarImage
                  src={character?.profile_pic_url}
                  alt={`${character?.name} profile picture`}
                />
              </Avatar>
            )}

            <div>
              <p className="font-semibold">
                {character?.name || "Select a Character"}
              </p>
              {character && (
                <p className="text-sm text-white/70">@{character?.username}</p>
              )}
            </div>
          </div>

          <ChevronDown className="w-4 h-4 text-white/50" />
        </div>
      </DialogTrigger>
      <DialogContent
        size={"none"}
        className="h-[80svh] md:h-[80vh] w-full max-w-[1000px] overflow-auto scrollbar pb-2"
      >
        <CharactersContainer
          showCreateButton={false}
          isFullScreen={false}
          showOwnGirlsTab={true}
          showDropdown={false}
          onDiscoverProfileCardCLick={handleSelect}
          filterCallbackCharacter={(char) => {
            if (char.id === character?.id) {
              return false;
            }

            return char?.images_enabled || false;
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CharactersSelect;
