import React, { useContext, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Link,
  Navigate,
  redirect,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import MessagesInbox from "../components/messages/MessagesInbox";
import MessagesBox from "../components/messages/MessagesBox";
import TipsModal from "../components/roleplay/TipsModal";
import MessagesBoxWrapper from "@nectar/components/messages/MessagesBoxWrapper";
import useSWR from "swr";
import { roleplayService } from "@nectar/services/roleplay.service";
import { fetcher } from "@nectar/lib/fetcher";
import clsx from "clsx";
import { AppContext } from "@/components/AppContext";

const MessagesPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { data, isLoading, mutate } = useSWR(
    roleplayService.getConversationsUrl(),
    fetcher,
  );

  const { user } = useContext(AppContext)

  const conversations = data?.conversations ?? [];

  useEffect(() => {
    if (!params?.id && conversations?.length > 0) {
      navigate(`/messages/${conversations[0].fantasy.id}`);
    }
  }, [data]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Nectar - Messages </title>
        {/* TODO complete description */}
        <meta name="description" content="roleplay" />
        {/* set scrollbar to dark (and more) */}
        <meta name="color-scheme" content="dark" />
      </Helmet>
      <div className="flex h-[calc(100svh-69px)] md:h-[100svh] -mb-8 text-white bg-black font-quicksand">
        {/* Left - inbox*/}
        <div className={clsx([params?.id ? "hidden md:block" : ""])}>
          <MessagesInbox conversations={conversations} isLoading={isLoading} user={user} />
        </div>

        {!params.id && !isLoading && conversations?.length <= 0 && (
          <div className="h-full w-full flex-grow  flex items-center justify-center flex-col gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-12 h-12 opacity-20"
            >
              <path
                d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
              <path
                d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
            </svg>
            <div className="text-center">
              <p className="opacity-70">You have no conversations.</p>
              <Link to="/roleplay" className="text-primary-500 underline">
                Roleplay
              </Link>
            </div>
          </div>
        )}

        {/* Right - messageBox */}
        <div
          className={clsx([
            params?.id ? "w-full" : "hidden md:block",
            "flex-grow ",
          ])}
        >
          {params?.id && !isLoading && (
            <MessagesBoxWrapper
              mutate={mutate}
              conversations={conversations}
              isLoading={isLoading}
            >
              <MessagesBox
                key={`messagebox-${params?.id}`}
                id={params?.id} // This is the fantasy Id
                conversations={conversations}
              />
            </MessagesBoxWrapper>
          )}
        </div>
      </div>
      <TipsModal />
    </HelmetProvider>
  );
};

export default MessagesPage;
