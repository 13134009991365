import React, {
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  useMemo,
} from "react";
import { FantasyCreateDropdownCharacters } from "./FantasyCreateDropdownCharacters";
import FantasyCreateDialogExample from "./FantasyCreateDialogExample";

import useSWR from "swr";
import { roleplayService } from "@nectar/services/roleplay.service";
import { fetcher } from "@nectar/lib/fetcher";
import { useForm } from "react-hook-form";
import { useToast } from "@nectar/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import { AlertCircleIcon, ChevronLeft } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { Helper } from "@/utils/helper";
import { AppContext } from "@/components/AppContext";
import official from "@/assets/official.svg";
import CharactersContainer from "../character/CharactersContainer";
import { Tooltip, TooltipTrigger } from "@nectar/components/ui/tooltip";
import { cn } from "@nectar/lib/utils";
import { TooltipContent } from "@radix-ui/react-tooltip";

const officialIds = new Set([
  // PRODUCTION
  "ba3ea829-7ce6-4987-9006-05bcdc7c56f5",

  // STAGING
  "50e4290b-4b1b-4315-8cc4-92afe2fe38a1",
]);

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const FantasyCreateForm = ({ isEdit = false, fantasyData = null }) => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const getCharactersUrl = () => {
    if (selectedCategory === "own") {
      return roleplayService.getCharacterByUserUrl(1, 9999);
    }
    return roleplayService.getCharactersUrl(selectedCategory, 1, 9999);
  };

  // Use SWR with a dynamic key
  const { data, isLoading, error } = useSWR(getCharactersUrl(), fetcher);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      isPublic: false ,
    }
  });

  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { user } = useContext(AppContext);

  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const characters = data?.characters || [];
  const [characterSelected, setCharacterSelected] = React.useState(null);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  const [dialogues, setDialogues] = useState({
    "dialogue-1": "",
  });

  const invalidPublic = useMemo(() => {
    if (!characterSelected) return false;

    return (
      characterSelected.creator_id === user.id && !characterSelected.is_public
    );
  }, [characterSelected, user]);

  useEffect(() => {
    // set character by query
    const characterId = searchParams.get("char");

    const character = characters?.find(
      (character) => character.id === characterId,
    );

    if (character && !characterSelected) {
      setCharacterSelected(character);
      // reset scroll
    }
  }, [data]);

  useLayoutEffect(() => {
    if (characterSelected) {
      window.scrollTo(0, 0);
      clearErrors();
      setValue("isPublic", false);
    }
  }, [characterSelected]);

  useEffect(() => {
    // Redirect if not logged in or not on the correct plan
    if (!user) {
      navigate("/roleplay"); // Replace with the appropriate route
      return;
    }

    // ... rest of your useEffect logic for preloading data
  }, [user, navigate]);

  useEffect(() => {
    // Preload the form with existing fantasy data

    if (isEdit && fantasyData) {
      setCharacterSelected(fantasyData.characters[0]);

      setValue("title", fantasyData.title);
      setValue("description", fantasyData.description);
      setValue("greeting", fantasyData.greeting);
      setValue("isPublic", fantasyData.is_public);

      const dialoguesObject = Helper.parseDialogues(
        fantasyData.example_dialogue,
      );
      setDialogues(dialoguesObject);

      setIsInitialDataLoaded(true);
    }
  }, [isEdit, fantasyData]);

  const onSubmit = async ({ file, isPublic, ...textData }) => {
    try {
      if (isLoadingCreate) return;

      // validate character
      if (!characterSelected) {
        setError("root", {
          type: "manual",
          message: "Character is required",
        });

        return;
      }

      const dialoguesEntries = Object.entries(dialogues);

      if (dialoguesEntries.length < 4) {
        setError("root", {
          type: "manual",
          message: "Dialogue must be at least 4",
        });

        return;
      }

      for (let i = 0; i < dialoguesEntries.length; i++) {
        const [_, value] = dialoguesEntries[i];

        if (value.length < 4) {
          setError("root", {
            type: "manual",
            message: `Dialogue ${i + 1} must be at least 4 characters`,
          });

          return;
        }

        if (value.length > 1000) {
          setError("root", {
            type: "manual",
            message: `Dialogue ${i + 1} must be at most 1000 characters`,
          });

          return;
        }
      }

      const exampleDialogFormat = dialoguesEntries
        .map(([key, value], i) => {
          const isCharacterDialog = i % 2 === 0 ? characterSelected.id : 0;

          return `${isCharacterDialog ? "{char}" : "{user}"}: ${value} \n`;
        })
        .join(" ");

      let thumbnailBase64 = "";

      if (file && file.length > 0) {
        // validate file types
        const fileTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (!fileTypes.includes(file[0].type)) {
          setError("file", {
            type: "manual",
            message: "Thumbnail must be an image",
          });

          return;
        }

        // less that 5mb
        if (file[0].size > 5000000) {
          setError("file", {
            type: "manual",
            message: "Thumbnail must be less than 5mb",
          });
        }

        setIsLoadingCreate(true);

        thumbnailBase64 = (await toBase64(file[0])).replace(
          /^data:image\/[a-z]+;base64,/,
          "",
        );
      } else if (!isEdit) {
        // In create mode, file is mandatory
        setError("file", {
          type: "manual",
          message: "Thumbnail is required",
        });
        return;
      }

      const data = {
        ...textData,
        character_ids: [characterSelected.id],
        example_dialogue: exampleDialogFormat,
        thumbnail_img_str: thumbnailBase64,
        is_public: isPublic,
      };

      let res;
      if (isEdit) {
        res = await roleplayService.fantasyEdit(fantasyData.id, data);
      } else {
        res = await roleplayService.fantasyCreate(data);
      }

      toast({
        variant: "positive",
        title: isEdit ? "Edit Successful" : "Creation Successful",
        description: `Fantasy ${isEdit ? "updated" : "created"} successfully. ${
          isEdit
            ? "Changes have been saved. Reset conversation to see updated messages if necessary."
            : "View created fantasies in My Fantasies."
        }`,
        style: { backgroundColor: "green" },
      });

      setTimeout(() => {
        navigate(`/messages/${res.id}`);
      }, 1000);
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Something went wrong",
        description: "Failed to create/update fantasy. Please try again.",
      });
    } finally {
      setIsLoadingCreate(false);
    }
  };

  if (isLoading || isLoadingCreate || (isEdit && !isInitialDataLoaded)) {
    return (
      <div className="pt-12">
        {/* spinner */}
        <div className="h-8 w-8 border-4 rounded-full border-zinc-800 border-r-primary animate-spin mx-auto" />
      </div>
    );
  }

  if (!characterSelected) {
    return (
      <div className="text-white font-quicksand w-full container mx-auto py-8 px-6">
        <h1 className="text-lg font-semibold text-center">Create Fantasy</h1>
        <p className="text-center text-white/70">
          Select a character to start creating a fantasy.
        </p>

        <CharactersContainer
          showCreateButton={false}
          isFullScreen={false}
          onDiscoverProfileCardCLick={(character) => {
            setCharacterSelected(character);
          }}
        />
      </div>
    );
  }

  return (
    <div className="text-white font-quicksand w-full max-w-2xl mx-auto py-8 px-6 animate-show-modal">
      <div>
        <button
          onClick={() => setCharacterSelected(null)}
          className="flex gap-2 items-center text-muted-foreground"
        >
          <ChevronLeft className="text-white/70 w-6 h-6 " />
          Select Character
        </button>
        <h1 className="text-lg font-semibold text-center mb-4">
          {isEdit ? "Edit Fantasy" : "Create Fantasy"}
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <button onClick={() => setCharacterSelected(null)} className="group">
          <img
            src={characterSelected.profile_pic_url}
            alt=""
            className="w-32 h-32 rounded-full object-cover object-center shrink-0 mx-auto ring-2 ring-primary"
          />
          <p className="text-sm text-center mt-2 ">{characterSelected.name}</p>
        </button>

        <div>
          <p className=" mb-2">Thumbnail</p>
          {isEdit && fantasyData.thumbnail_image_url && (
            <div>
              <img
                src={fantasyData.thumbnail_image_url}
                alt="Current Thumbnail"
              />
              <p>
                Existing thumbnail. If needed, choose a different one below.
              </p>
            </div>
          )}
          <input
            type="file"
            className="input-control"
            {...register("file", {
              required: isEdit
                ? false
                : {
                    value: true,
                    message: "Thumbnail is required",
                  },
            })}
          />
          <p className="text-sm text-red-500">
            {errors.file && errors.file.message}
          </p>
        </div>

        {/*Title*/}
        <div>
          <p className=" mb-2">Title</p>
          <input
            type="text"
            placeholder="Title"
            className="input-control"
            {...register("title", {
              required: {
                value: true,
                message: "Title is required",
              },
              minLength: {
                value: 2,
                message: "Title must be at least 8 characters.",
              },
              maxLength: {
                value: 32,
                message: "Title must be at most 32 characters.",
              },
            })}
          />
          <p className="text-sm text-red-500">
            {errors.title && errors.title.message}
          </p>
        </div>

        {/* Description */}
        <div>
          <p className=" mb-2">Description</p>
          <textarea
            placeholder="Describe the fantasy. This will be shown to users as a short description of what happens in the fantasy. "
            rows={4}
            className="input-control"
            {...register("description", {
              required: {
                value: true,
                message: "Description is required",
              },
              minLength: {
                value: 16,
                message: "Description must be at least 16 characters.",
              },
              maxLength: {
                value: 2000,
                message: "Description must be at most 2000 characters.",
              },
            })}
          ></textarea>
          <p className="text-sm text-red-500">
            {errors.description && errors.description.message}
          </p>
        </div>

        {/*Public/Private Settings*/}
        <div className="flex items-center align-center justify-between w-full">
          <div>
            <p>Visible to Public?</p>
            {invalidPublic && (
              <div className="flex gap-1 items-center">
                <AlertCircleIcon className="w-3.5 h-3.5 text-white/70" />
                <p className="text-sm text-white/70">
                  Private girls cannot have public fantasies{" "}
                </p>
              </div>
            )}
          </div>
          <div className="flex gap-2 items-center">
            {invalidPublic && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <AlertCircleIcon className="w-6 h-6 text-amber-400" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Private girls cannot have public fantasies</p>
                </TooltipContent>
              </Tooltip>
            )}

            <label className={cn("switch", invalidPublic && "opacity-50")}>
              <input
                type="checkbox"
                {...register("isPublic")}
                disabled={invalidPublic}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>

        {/* Greeting */}
        <div>
          <p className=" mb-2">Greeting</p>
          <p className="text-sm text-white/70">
            This is the first message that starts the fantasy.
            Actions/Descriptions should be surrounded by asterisks(*) and
            dialogue in quotes (" ").
          </p>
          <textarea
            placeholder='*{char} beckons the {user} to follow her* "Ready for an unforgettable night?"'
            rows={2}
            className="input-control"
            {...register("greeting", {
              required: {
                value: true,
                message: "Greeting is required",
              },
              minLength: {
                value: 16,
                message: "Greeting must be at least 16 characters.",
              },
              maxLength: {
                value: 500,
                message: "Greeting must be at most 500 characters.",
              },
            })}
          ></textarea>
          <p className="text-sm text-red-500">
            {errors.greeting && errors.greeting.message}
          </p>
        </div>

        {dialogues && Object.keys(dialogues).length > 0 && (
          <FantasyCreateDialogExample
            characterSelected={characterSelected}
            dialogues={dialogues}
            setDialogues={setDialogues}
          />
        )}

        {errors.root && (
          <p className="p-3 bg-red-800/30 border border-red-500 rounded text-red-200 text-sm">
            {errors.root.message}
          </p>
        )}

        <button
          disabled={isLoading}
          className="btn btn-primary text-white btn-form justify-center"
        >
          {isEdit ? "Update" : "Create"}
        </button>
      </form>
    </div>
  );
};

export default FantasyCreateForm;
