import React, { useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { roleplayService } from "../../../services/roleplay.service";
import FantasyPreviewCard from "./FantasyPreviewCard";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import { fetcher } from "@nectar/lib/fetcher";
import { useOnScreen } from "@nectar/hooks/useOnScreen";
import useFantasyCategory, {
  DEFAULT_FANTASY_CATEGORY,
} from "@nectar/hooks/useFantasyCategory";

import { Button } from "@nectar/components/ui/button";
import { AppContext } from "@nectar/components/AppContext";
import { CreateContext } from "@nectar/components/CreateContext";

const FANTASIES_PER_PAGE = 12;

const FantasyDiscoverCard = ({
  category = DEFAULT_FANTASY_CATEGORY,
  sort = null,
}) => {
  const { searchInput } = useContext(CreateContext);
  const getKey = useMemo(() => {
    return (pageIndex, previousPageData) => {
      if (pageIndex !== 0 && !previousPageData?.fantasies?.length) return null;
      return roleplayService.getFantasiesUrl(
        "all",
        pageIndex + 1,
        FANTASIES_PER_PAGE,
        category,
        sort,
        searchInput
      );
    };
  }, [category, sort, searchInput]); // Dependencies

  const { data, isLoading, setSize, size } = useSWRInfinite(getKey, fetcher);


  const { user } = useContext(AppContext);
  const {
    data: LikedFantasiesData,
    isLoading: isLoadingLikedFantasies,
    mutate: mutateLikedFantasies,
  } = useSWR(
    user ? roleplayService.getFantasiesForUserUrl(user?.id, "liked") : null,
    fetcher,
  );

  const LikedFantasiesMap = useMemo(() => {
    return LikedFantasiesData?.reduce(
      (obj, item) => ((obj[item.id] = item), obj),
      {},
    );
  }, [LikedFantasiesData]);

  const [isVisible, containerRef] = useOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  const isEmpty = data?.[0]?.fantasies?.length === 0;

  const isReachingEnd =
    isEmpty ||
    (data && data[data.length - 1]?.fantasies?.length < FANTASIES_PER_PAGE);

  useEffect(() => {
    if (isVisible) {
      setSize((size) => size + 1);
    }
  }, [isVisible]);

  return (
    <div className="w-full mt-3.5">
      {/* Change this */}

      {/* Skeletons */}

      {/* Content */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 pb-8">
        {data &&
          data.map(({ fantasies }) => {
            return fantasies?.map((fantasy, index) => {
              return (
                <FantasyPreviewCard
                  LikedFantasiesMap={LikedFantasiesMap}
                  isLoadingLikedFantasies={isLoadingLikedFantasies}
                  mutateLikedFantasies={mutateLikedFantasies}
                  user={user}
                  key={fantasy.id}
                  fantasy={fantasy}
                />
              );
            });
          })}

        {isLoadingMore &&
          Array.from({ length: FANTASIES_PER_PAGE }).map((_, i) => {
            return (
              <div
                key={i}
                className="min-h-[500px] h-full w-full animate-pulse rounded-lg bg-zinc-900"
              />
            );
          })}
      </div>

      {!isLoadingMore && !isReachingEnd && (
        <div ref={containerRef} className="w-full h-[25px] mt-8 "></div>
      )}
    </div>
  );
};

export default FantasyDiscoverCard;
