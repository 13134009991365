import {profileService} from '../services/profile.service';
import MobileProfileCard from '../components/profiles/MobileProfileCard'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import MobileBundleCard from '../components/profiles/MobileBundleCard';
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ThemeProvider} from '@mui/material/styles';
import {Grid} from '@mui/material';
import theme from '../styles/theme';
import {roleplayService} from "../services/roleplay.service";

const MobileExploreProfilesPage = () => {
    const {username} = useParams();
    const [profile, setProfile] = useState(null);


    useEffect(() => {
        // profileService.getProfileByUsername(username).then(setProfile);
        roleplayService.getCharacterByUsername(username).then(setProfile);
    }, [username]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Nectar AI - @{profile ? profile.username : ''}</title>
                <meta name="description" content={`Hot AI Girls - ${profile ? profile.name : ''}`}/>
            </Helmet>
            <ThemeProvider theme={theme}>
                <div className="bg-black -mb-8 min-h-screen">
                    {profile && <MobileProfileCard profile={profile}/>}
                    {profile && <MobileBundleCard profile={profile}/>}
                </div>
            </ThemeProvider>
        </HelmetProvider>
    );
}
export default MobileExploreProfilesPage