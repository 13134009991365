import React from "react";

const Bullet = ({ icon, title, content }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="rounded flex items-center justify-center">
        <img src={icon} alt="bullet icon" />
      </div>

      <div>
        <h3 className="font-semibold">{title}</h3>
        <p className=" max-w-sm opacity-70 ">{content}</p>
      </div>
    </div>
  );
};

export default Bullet;
