import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { messagesContext } from "./MessagesContext";
import MessagesBoxHeader from "./MessagesBoxHeader";
import MessagesBubble from "./MessagesBubble";
import MessagesForm from "./MessagesForm";
import MessagesBubbleSkeleton from "./MessagesBubbleSkeleton";
import MessagesGreeting from "./MessagesGreeting";
import ResetMessagesModal from "./ResetMessagesModal";
import { AppContext } from "../AppContext";
import MessagesPhotoBubble from "./MessagesPhotoBubble";
import MessagesPhotoGrid from "./MessagesPhotoGrid";
import { useMediaQuery } from "@material-ui/core";
import theme from "../../styles/theme";
import useSWRInfinite from "swr/infinite";
import { roleplayService } from "@nectar/services/roleplay.service";
import { fetcher } from "@nectar/lib/fetcher";
import { useOnScreen } from "@nectar/hooks/useOnScreen";
import PaywallModal from "../auth/PaywallModal";
import { userService } from "@nectar/services/user.service";
import { useToast } from "../ui/use-toast";
import clsx from "clsx";
import { AlertDialog } from "../ui/alert-dialog";

export const unsupportedUsername = new Set([
  // PRODUCTION & STAGING
  "lady_sakura", // Sakura
  "madame_amelie", // Amelie
  "missxiaoyan_", // Huang
  "eunjii", // Eunji
  "katieehi", // Katie
]);

const MESSAGES_PER_PAGE = 10;
const MESSAGE_LIMIT_PAYWALL_SOURCE = "message_limit";

const MessagesBox = ({ id, conversations }) => {
  // get the conversation
  const conversation = useMemo(() => {
    const conversation = conversations.find((c) => c.fantasy?.id === id);
    return conversation ?? null;
  }, [conversations, id]);

  const getKey = (pageIndex, previousPageData) => {
    if (pageIndex !== 0 && !previousPageData?.messages?.length) return null;
    const url = roleplayService.getConversationHistoryUrl(
      conversation.id,
      pageIndex + 1,
      MESSAGES_PER_PAGE,
    );
    return url;
  };

  const { data, isLoading, setSize, size, mutate } = useSWRInfinite(
    getKey,
    fetcher,
  );

  const { toast } = useToast();

  const [isVisible, containerRef] = useOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  const {
    user,
    refreshUser,
    showLoginModal,
    setShowLoginModal,
    accountStatus,
  } = useContext(AppContext);
  const [showGallery, setShowGallery] = useState(false);
  const [isCharacterTyping, setIsCharacterTyping] = useState(false);
  const [showPaywallModal, setShowPaywallModal] = useState(false);

  // Utils data
  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  const messages = useMemo(
    () => data?.map(({ messages }) => messages.toReversed()).flat() || [],
    [data],
  );

  const isEnd = messages.at(-1)?.is_greeting ?? false;
  const isOnlyGreeting = messages.length === 1 && messages[0]?.is_greeting;

  useEffect(() => {
    if (isVisible) {
      setSize((s) => s + 1);
    }
  }, [isVisible]);

  // Handlers
  const handleSendMessage = async (message) => {
    if (message.trim() === "") return;

    const optimistMessage = {
      id: crypto.randomUUID(),
      message,
      sender: "{user}",
      timestamp: new Date().toISOString(),
      is_greeting: false,
    };

    mutate([{ messages: [optimistMessage] }, ...data], {
      revalidate: false,
    });

    try {
      setIsCharacterTyping(true);
      const response = await roleplayService.sendMessage(conversation.id, {
        message,
      });

      mutate([
        {
          messages: [
            {
              id: optimistMessage.id,
              sender: "{user}",
              message: message,
              timestamp: optimistMessage.timestamp, // Using the timestamp from the optimistic message
              is_greeting: false,
            },
            {
              id: crypto.randomUUID(),
              sender: "{char}",
              message: response.message,
              timestamp: response.timestamp,
              is_greeting: response.is_greeting || false,
              attachment: response.attachment || null,
            },
          ],
        },
        ...data,
      ]);
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        !userService.checkIsLoggedIn()
      ) {
        setShowLoginModal(true);
        return;
      }

      if (error.response && error.response.status === 401) {
        handlePaywallModalOpen(MESSAGE_LIMIT_PAYWALL_SOURCE);
        return;
      }

      mutate(data, {
        revalidate: false,
      });

      toast({
        variant: "destructive",
        title: "Something went wrong",
        description: "Failed to send message. Please try again.",
      });
    } finally {
      setIsCharacterTyping(false);
    }
  };

  const regenerateMessage = async () => {
    if (messages[0].sender === "{user}") return;

    setIsCharacterTyping(true);

    mutate(
      [
        {
          messages: [...data?.at(0)?.messages?.slice(0, -1)],
        },
        ...data.filter((messages, i) => {
          return i !== 0;
        }),
      ],
      {
        revalidate: false,
      },
    );

    try {
      const response = await roleplayService.regenerate(conversation.id);

      mutate([
        {
          messages: [
            ...data?.at(0)?.messages?.slice(0, -1),
            {
              id: crypto.randomUUID(),
              sender: "{char}",
              message: response.message,
              timestamp: response.timestamp,
              is_greeting: response.is_greeting || false,
              attachment: response.attachment || null,
            },
          ],
        },
        ...data.filter((messages, i) => {
          return i !== 0;
        }),
      ]);
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        !userService.checkIsLoggedIn()
      ) {
        setShowLoginModal(true);
        return;
      }

      if (error.response && error.response.status === 401) {
        handlePaywallModalOpen(MESSAGE_LIMIT_PAYWALL_SOURCE);
        return;
      }

      toast({
        variant: "destructive",
        title: "Something went wrong",
        description: "Failed to send message. Please try again.",
      });
    } finally {
      setIsCharacterTyping(false);
    }
  };

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!user) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handleClosePaywallModal = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  const onResetConversation = async () => {
    try {
      // Define the payload
      const payload = {
        fantasy_id: id,
        is_reset: true,
      };

      // Make the service call
      await roleplayService.createConversation(payload);
      mutate(
        [
          {
            messages: [
              {
                id: crypto.randomUUID(),
                sender: "{char}",
                message: conversation.fantasy.greeting,
                timestamp: new Date().toISOString(),
                is_greeting: true,
              },
            ],
          },
        ],
        {
          revalidate: false,
        },
      );
    } catch (error) {
      console.error("Failed to reset conversation:", error);
    }
  };

  const handleResetConfirmation = async () => {
    await onResetConversation();
  };

  return (
    <>
      <div className="h-[calc(100svh-69px)] md:h-full flex ">
        {/* Messages */}
        <section
          className={clsx([
            "h-full flex flex-grow w-full flex-col",
            showGallery ? "hidden md:flex" : "",
          ])}
        >
          <AlertDialog>
            {/* Messages - Header */}
            <MessagesBoxHeader
              isPhotoGridVisible={true}
              setIsPhotoGridVisible={() => {}}
              showGallery={showGallery}
              setShowGallery={setShowGallery}
              fantasy={conversation.fantasy}
              accountStatus={accountStatus}
              refreshUser={refreshUser}
              user={user}
            />

            {/* Messages - reset modal */}
            <ResetMessagesModal onConfirm={handleResetConfirmation} />
          </AlertDialog>

          {/* Messages - content */}
          <div className="flex flex-col-reverse flex-grow gap-4 px-4 py-4  overflow-y-scroll">
            {isCharacterTyping && (
              <MessagesBubble
                isTyping={true}
                isLatest={true}
                character={conversation.fantasy.characters[0]}
                isUserAuthor={false}
                message="Typing..."
                timestamp={new Date().toISOString()}
                user={user}
              />
            )}

            {/*{isOnlyGreeting && <MessagesTips />}*/}

            {messages.map((message, i) => {
              if (message.is_greeting) {
                return (
                  <MessagesGreeting
                    key={message.timestamp}
                    message={message.message}
                    character={conversation.fantasy.characters[0]}
                    fantasyThumbnailImage={
                      conversation?.fantasy.thumbnail_image_url
                    }
                    user={user}
                  />
                );
              }

              if (message.attachment) {
                return (
                  <MessagesPhotoBubble
                    key={message.timestamp}
                    character={conversation.fantasy.characters[0]}
                    attachment={message.attachment}
                    isLatest={i === 0}
                    isTyping={isCharacterTyping}
                    regenerateMessage={regenerateMessage}
                  />
                );
              }

              return (
                <MessagesBubble
                  key={message.timestamp}
                  character={conversation.fantasy.characters[0]}
                  isUserAuthor={message.sender === "{user}"}
                  message={message.message}
                  timestamp={message.timestamp}
                  isLatest={i === 0}
                  isTyping={isCharacterTyping}
                  regenerateMessage={regenerateMessage}
                  user={user}
                />
              );
            })}

            {isLoadingMore && (
              <>
                <MessagesBubbleSkeleton />
                <MessagesBubbleSkeleton isUserAuthor />
              </>
            )}

            {/* Trigger fetch */}
            {!isEnd && !isLoadingMore && (
              <div
                ref={containerRef}
                className="w-full h-[25px] flex-shrink-0"
              />
            )}
          </div>

          {/* Messages - form */}
          <MessagesForm
            handleSendMessage={handleSendMessage}
            key={`textarea-${conversation.id}`}
          />
        </section>
        {!unsupportedUsername.has(
          conversation.fantasy.characters[0].username,
        ) &&
          showGallery && (
            <section className=" md:max-w-[300px] w-full flex-grow ">
              <MessagesPhotoGrid
                conversation={conversation}
                showGallery={showGallery}
                setShowGallery={setShowGallery}
              />
            </section>
          )}
      </div>
      {!showLoginModal && showPaywallModal && (
        <PaywallModal
          message={"Enhance your roleplay experience🔮! Select a plan below:"}
          open={showPaywallModal}
          loggedInUser={user}
          refreshUser={refreshUser}
          onClose={handleClosePaywallModal}
        />
      )}
    </>
  );
};

export default MessagesBox;
