import React, { useContext, useEffect, useState } from "react";
import theme from "../styles/theme";
import { Helmet } from "react-helmet-async";
import { AppContext } from "../components/AppContext";
import { userService } from "../services/user.service";
import FantasyDiscoverCard from "../components/roleplay/fantasy/FantasyDiscoverCard";
import FantasyTabs from "../components/roleplay/fantasy/sort/FantasyTabs";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import useUTM from "../hooks/useUTM";
import RpHero from "../components/roleplay/hero/RpHero";
import RoleplayTags from "../components/roleplay/RoleplayTags";
import useFantasyCategory from "@nectar/hooks/useFantasyCategory";
import { Search, X } from "lucide-react";
import { CreateContext } from "@nectar/components/CreateContext";
import { Button } from "@nectar/components/ui/button";
import { cn } from "@nectar/lib/utils";
import PaywallModal from "@nectar/components/auth/PaywallModal";
import dropdown_lock_icon_white from "@/assets/dropdown_lock_icon_white.svg";

const PAYWALL_SOURCE_CREATE_FANTASY = "create_fantasy";

const RoleplayPage = () => {
  useUTM();

  const category = useFantasyCategory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [sort, setSort] = useState(null);
  const {
    user,
    refreshUser,
    accountStatus,
    showLoginModal,
    setShowLoginModal,
  } = useContext(AppContext);
  const { searchInput, setSearchInput } = useContext(CreateContext);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showPaywallModal, setShowPaywallModal] = useState(false);

  // We need this to close the modal after logging in
  useEffect(() => {
    const isLoggedIn = userService.checkIsLoggedIn();
    if (isLoggedIn && isInitialLoad) {
      setIsInitialLoad(false);
      refreshUser();
    }
  }, [user]);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!userService.checkIsLoggedIn()) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  return (
    <>
      <Helmet>
        <title>Nectar - Fantasies</title>
        <meta name="description" content="Live the fantasy of your dreams." />
      </Helmet>
      {/* Roleplay Page */}

      <div className="min-h-screen text-white px-6 pt-4 mx-auto ">
        <div className="max-w-[1920px] mx-auto">
          <RpHero />
        </div>

        <h2 className="text-xl pt-6"> Find Your Next Fantasy </h2>

        <div
          className={cn(
            "sticky top-[69px] md:top-0 z-10 py-3 bg-gradient-to-b bg-black/90 backdrop-blur flex  flex-col lg:flex-row gap-3 justify-between transition-all ease-out",
            showSearchBar && "pt-16 xl:pt-0",
          )}
        >
          <div className="flex justify-between gap-2 items-center">
            <FantasyTabs sort={sort} setSort={setSort} />
            {!showSearchBar && (
              <button
                onClick={() => {
                  setShowSearchBar(true);
                }}
                className="text-white/70 xl:hidden"
              >
                <Search />
              </button>
            )}
          </div>
          <div
            className={cn(
              "flex gap-2 items-center absolute left-1/2 -translate-x-1/2 h-fit w-full xl:w-96",
              showSearchBar ? " top-3 xl:top-auto" : "hidden xl:flex",
            )}
          >
            <span className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
              <Search className="text-white/70 w-4 h-4" />
            </span>
            <input
              type="text"
              value={searchInput}
              onChange={handleSearch}
              className="border rounded-lg focus:border-gray-300 text-slate-200 transition-all duration-500  bg-transparent pl-9 py-2 w-full text-md focus:outline-none"
              placeholder="Search all fantasies"
            />

            {showSearchBar && (
              <button
                className="text-white/70 xl:hidden"
                onClick={() => {
                  setShowSearchBar(false);
                  setSearchInput("");
                }}
              >
                <X />
              </button>
            )}
          </div>

          {user ? (
            accountStatus?.plan_tier >= 2 ? (
              <Button className={cn(showSearchBar && "hidden lg:flex")} asChild>
                <Link to="/fantasy/create">Create Fantasy ✨</Link>
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handlePaywallModalOpen(PAYWALL_SOURCE_CREATE_FANTASY);
                }}
                className={cn(showSearchBar && "hidden lg:flex")}
              >
                Create Fantasy ✨
              </Button>
            )
          ) : (
            <Button
              onClick={() => setShowLoginModal(true)}
              className={cn(showSearchBar && "hidden lg:flex")}
            >
              <img
                src={dropdown_lock_icon_white}
                className="h-5 w-5 mr-1"
                alt="Lock icon"
              />
              Create Fantasy ✨
            </Button>
          )}
        </div>
        <FantasyDiscoverCard category={category} sort={sort || null} />
      </div>
      {!showLoginModal && showPaywallModal && (
        <PaywallModal
          message={
            "You need to be a premium member to create a fantasy. Upgrade now to start creating!"
          }
          open={showPaywallModal}
          onClose={handlePaywallModalClose}
          refreshUser={refreshUser}
          loggedInUser={user}
        />
      )}
    </>
  );
};

export default RoleplayPage;
