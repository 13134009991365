import ImagesNavBar from "./components/navigation/ImagesNavBar";
import MobileNavBar from "./components/navigation/MobileNavBar";
import BottomAppBar from "./components/navigation/BottomAppBar";
import CreatePage from "./pages/CreatePage";

import ExplorePage from "./pages/ExplorePage";

import DetailedImagePage from "./pages/DetailedImagePage";
import LoginModal from "./components/auth/LoginModal";
import TermsAndConditionsModal from "./components/auth/TCModal";
import AboutPage from "./pages/AboutPage";
import PhotosetPage from "./pages/PhotosetPage";
import MobilePhotosetPage from "./pages/MobilePhotosetPage";
import PrivacyPage from "./pages/PrivacyPage";
import TosPage from "./pages/TosPage";
import ProfilePage from "./pages/ProfilePage";
import MobileProfilePage from "./pages/MobileProfilePage";
import MobileCreatePage from "./pages/MobileCreatePage";
import { FantasyEditPage } from "./pages/FantasyEditPage";
import MyFantasiesPage from "./pages/MyFantasiesPage";
import ExploreProfilesPage from "./pages/ExploreProfilesPage";
import MobileExploreProfilesPage from "./pages/MobileExploreProfilesPage";
import { ThemeProvider, useMediaQuery } from "@material-ui/core";
import theme from "./styles/theme";
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { AppProvider } from "./components/AppContext";
import { CreateProvider } from "./components/CreateContext";
import GuidesPage from "./pages/GuidesPage";
import Post from "./components/guides/Post";
import MobileBottomNavBar from "./components/navigation/MobileBottomNavBar";
import { HelmetProvider } from "react-helmet-async";
import RoleplayPage from "./pages/RoleplayPage";
import MobileDiscoverCard from "./components/roleplay/MobileDiscoverCard";
import MessagesPage from "./pages/MessagesPage";
import MessagesProvider from "./components/messages/MessagesContext";
import Alert from "@mui/material/Alert";
import HomePage from "./pages/HomePage";
import HomeNavBar from "./components/navigation/HomeNavBar";
import RoleplayNavBar from "./components/navigation/RoleplayNavBar";
import ImagesProvider from "./components/images/ImagesContext";
import ExploreImagesContainer from "./components/explore/ExploreImagesContainer";
import Layout from "./components/layouts/Layout";
import { FantasyCreatePage } from "./pages/FantasyCreatePage";
import ComponentsPage from "./pages/ComponentsPage";
import { TooltipProvider } from "./components/ui/tooltip";
import { Toaster } from "./components/ui/toaster";
import CharactersPage from "./pages/CharactersPage";
import { CharacterCreatePage } from "./pages/CharacterCreatePage";
import { DetailedFantasyPage } from "@/pages/DetailedFantasyPage";
import MyGirlsPage from "./pages/MyGirlsPage";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return null;
};

function App() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <BrowserRouter>
      <HelmetProvider>
        <div className="App">
          <TooltipProvider>
            <AppProvider>
              <MessagesProvider>
                <CreateProvider>
                  <ImagesProvider>
                    <Routes>
                      <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route
                          path="/components"
                          element={<ComponentsPage />}
                        />
                        <Route path="images" element={<ExplorePage />}>
                          <Route
                            path="view/:id"
                            element={<DetailedImagePage />}
                          />
                        </Route>
                        <Route path="/fantasies" element={<RoleplayPage />} />
                        <Route
                          path="create"
                          element={
                            isMobile ? <MobileCreatePage /> : <CreatePage />
                          }
                        />
                        <Route
                          path="/fantasy/create"
                          element={<FantasyCreatePage />}
                        />

                        <Route
                          path="/fantasy/:id/edit"
                          element={<FantasyEditPage />}
                        />

                        {/*TODO -- should this be a nested route?*/}
                        <Route
                          path="/fantasy/:id"
                          element={<DetailedFantasyPage />}
                        />

                        <Route
                          path="/fantasy/:id/edit"
                          element={<FantasyEditPage />}
                        />

                        {/*TODO -- should this be a nested route?*/}
                        <Route
                          path="/fantasy/:id"
                          element={<DetailedFantasyPage />}
                        />

                        <Route
                          path="/roleplay"
                          element={<CharactersPage />}
                        />
                        <Route 
                          path="/user/girls"
                          element={<MyGirlsPage />}
                        />

                        <Route
                          path="nudes"
                          element={
                            isMobile ? <MobilePhotosetPage /> : <PhotosetPage />
                          }
                        />
                        <Route path="/user/fantasies" element={<MyFantasiesPage />} />
                        <Route path="about" element={<AboutPage />} />
                        <Route path="guides/*" element={<GuidesPage />} />
                        <Route path="guides/:guideName" element={<Post />} />
                        <Route
                          path="profile"
                          element={
                            isMobile ? <MobileProfilePage /> : <ProfilePage />
                          }
                        />
                        <Route path="privacy" element={<PrivacyPage />} />
                        <Route path="tos" element={<TosPage />} />
                        <Route
                          path="profile/:username"
                          element={
                            isMobile ? (
                              <MobileExploreProfilesPage />
                            ) : (
                              <ExploreProfilesPage />
                            )
                          }
                        />

                        <Route
                          path="/characters/create"
                          element={<CharacterCreatePage />}
                        />

                        <Route path="/messages" element={<MessagesPage />} />
                        <Route
                          path="/messages/:id"
                          element={<MessagesPage />}
                        />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                    <Toaster />
                  </ImagesProvider>
                </CreateProvider>
              </MessagesProvider>
            </AppProvider>
          </TooltipProvider>
        </div>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
