import FantasyCreateForm from "../components/roleplay/fantasy/FantasyCreateForm";
import React from "react";

export const FantasyCreatePage = () => {
  return (
    <div>
      <FantasyCreateForm />
    </div>
  );
};
