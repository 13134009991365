import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CircularProgress, Grid } from "@mui/material";
import ExploreProfileCard from "../components/profiles/ExploreProfileCard";
import { exploreService } from "../services/explore.service.ts";
import { AppContext } from "../components/AppContext";
import { Link } from "react-router-dom";
import { userService } from "../services/user.service";
import { profileService } from "../services/profile.service";
import ProfilePhotoCard from "../components/profiles/ProfilePhotoCard";
import PhotosetBundleCard from "../components/profiles/PhotosetBundleCard";
import { roleplayService } from "../services/roleplay.service";

export const PROFILES_PER_PAGE = 12;

function PhotosetPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [characters, setCharacters] = useState([]);
  const { user, setUser, refreshUser, accountStatus, setAccountStatus } =
    useContext(AppContext);
  const [fetchedBundles, setFetchedBundles] = useState([]);
  const [statusFetched, setStatusFetched] = useState(false);
  const [bundleLoading, setBundleLoading] = useState(false);
  const ownedBundleIds = useMemo(
    () => accountStatus?.owned_bundle_ids,
    [accountStatus],
  );
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  let lastProfileId = null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadProfiles = async () => {
    try {
      setIsLoading(true);
      const data = await roleplayService.getCharacters(
        "official",
        1,
        PROFILES_PER_PAGE,
      );
      if (data) {
        setCharacters(data.characters);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadProfiles();
  }, []);

  // We need this to close the modal after logging in
  useEffect(() => {
    const isLoggedIn = userService.checkIsLoggedIn();
    if (isLoggedIn && isInitialLoad) {
      setIsInitialLoad(false);
      refreshUser();
    }
  }, [user]);

  useEffect(() => {
    const fetchBundles = async () => {
      try {
        setBundleLoading(true);
        const fetched = [];
        for (const bundleId of accountStatus?.owned_bundle_ids) {
          const bundle = await profileService.getBundleById(
            bundleId,
            userService.checkIsLoggedIn(),
          );
          if (bundle) {
            fetched.push(bundle);
          }
        }
        fetched.sort((a, b) => {
          // First, sort by profile_id
          if (a.profile_id !== b.profile_id) {
            // If profile_ids are not the same, sort by their name
            return a.profile.name.localeCompare(b.profile.name);
          } else {
            // If profile_ids are the same, then sort by price
            return b.price - a.price;
          }
        });

        setFetchedBundles(fetched);
      } catch (error) {
        console.error("Error fetching bundles: ", error);
      } finally {
        setBundleLoading(false);
      }
    };
    if (ownedBundleIds) {
      fetchBundles();
    }
  }, [ownedBundleIds]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Nectar - My Nudes</title>
        <meta name="description" content="Your private AI girl collection." />
      </Helmet>
      {/* Photoset Page */}
      <ThemeProvider theme={theme}>
        <div className="parent h-auto bg-black flex -mb-8 min-h-screen">
          <Container sx={{ maxWidth: "100%" }}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={8}>
                <Typography
                  variant="h1"
                  align="left"
                  sx={{
                    color: "white",
                    marginLeft: "14px",
                    paddingTop: "100px",
                  }}
                >
                  My Nudes
                </Typography>
                {bundleLoading && <CircularProgress color="secondary" />}
                {user && userService.checkIsLoggedIn() ? (
                  accountStatus &&
                  accountStatus.owned_bundle_ids &&
                  accountStatus.owned_bundle_ids.length > 0 &&
                  fetchedBundles ? (
                    fetchedBundles.map((bundle, bundleIndex) => {
                      // const showProfileName = bundle.profile_id !== lastProfileId;
                      // lastProfileId = bundle.profile_id;

                      return (
                        <Grid container spacing={2} key={bundleIndex}>
                          <PhotosetBundleCard
                            showProfileName=""
                            bundle={bundle}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <Typography
                      variant="h3"
                      align="left"
                      sx={{
                        color: "white",
                        marginLeft: "14px",
                        paddingTop: "100px",
                      }}
                    >
                      No purchased nudes.
                    </Typography>
                  )
                ) : (
                  <Typography
                    variant="h1"
                    align="left"
                    sx={{
                      color: "white",
                      marginLeft: "14px",
                      paddingTop: "100px",
                    }}
                  >
                    Please login to view your nudes.
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="h1"
                  sx={{
                    color: "white",
                    paddingTop: "100px",
                    marginLeft: "14px",
                  }}
                >
                  Featured Babes
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: "white",
                    paddingBottom: "10px",
                    marginLeft: "14px",
                  }}
                >
                  Tailored for you — go on dates, receive customized photos, and
                  flirt to your heart's content.
                </Typography>
                {isLoading && <CircularProgress color="secondary" />}
                {characters &&
                  characters.map((profile, index) => (
                    <Link to={`/profile/${profile.username}`} key={index}>
                      <ExploreProfileCard profile={profile} />
                    </Link>
                  ))}
              </Grid>
            </Grid>
          </Container>
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default PhotosetPage;
