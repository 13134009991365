import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { AppContext } from "../AppContext";
import Typography from "@material-ui/core/Typography";
import mobile_close_icon from "../../assets/mobile_close_icon.svg";
import PlansCard from "./PlansCard";
import PacksCard from "./PacksCard";
import MobilePlansCard from "./MobilePlansCard";
import { CircularProgress } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobilePacksCard from "./MobilePacksCard";

import theme from "../../styles/theme";
import { AlertCircle, X } from "lucide-react";
import { Dialog, DialogContent } from "../ui/dialog";
import { cn } from "@nectar/lib/utils";

function PaywallModal({
  message = "Unlock exclusive features today!",
  open,
  onClose,
  refreshUser,
  loggedInUser,
}) {
  const { tokenPlans, accountStatus } = useContext(AppContext);
  const [category, setCategory] = useState("subscriptions");

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        if (!value) {
          onClose();
        }
      }}
    >
      <DialogContent
        size={"none"}
        className="w-full max-w-[1000px] max-h-[80svh] overflow-auto min-h-[80svh]  p-4"
      >
        {/* tabs */}

        <div className="flex gap-2 items-center justify-center w-fit mx-auto">
          <button
            className={cn(
              "py-2 px-4 bg-zinc-900 rounded-full transition-colors ease-out",
              category === "subscriptions"
                ? "bg-secondary"
                : "bg-muted text-muted-foreground",
            )}
            onClick={() => {
              setCategory("subscriptions");
            }}
          >
            Subscriptions
          </button>
          <button
            className={cn(
              "py-2 px-4 bg-zinc-900 rounded-full transition-colors ease-out",
              category === "packs"
                ? "bg-secondary"
                : "bg-muted text-muted-foreground",
            )}
            onClick={() => {
              setCategory("packs");
            }}
          >
            Credit Packs
          </button>
        </div>

        <div className="flex mt-4 w-full bg-zinc-900 p-4 rounded-lg gap-2">
          <AlertCircle className="w-6 h-6 text-zinc-500" />
          <p>{message}</p>
        </div>

        <div className="mt-4 hidden md:block">
          {category === "subscriptions" ? (
            <PlansCard
              tokenPlans={tokenPlans}
              accountStatus={accountStatus}
              refreshUser={refreshUser}
              loggedInUser={loggedInUser}
            />
          ) : (
            <PacksCard />
          )}
        </div>

        <div className="mt-4 block md:hidden">
          {category === "subscriptions" ? (
            <MobilePlansCard
              tokenPlans={tokenPlans}
              accountStatus={accountStatus}
              refreshUser={refreshUser}
              loggedInUser={loggedInUser}
            />
          ) : (
            <MobilePacksCard />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PaywallModal;
