import React, { useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
} from "@nectar/components/ui/dialog";
import { cn } from "@nectar/lib/utils";
import { Filter, FilterX } from "lucide-react";
import { STEPS_REALISTIC } from "@/utils/stepsRealistic";
import { STEPS_ANIME } from "@/utils/stepsAnime";
import CharactersContainerFilterItem from "./CharactersContainerFilterItem";

const CATEGORIES = ["anime", "realistic"];

const CharactersContainerFilter = ({ filter, setFilter }) => {
  const isEmptyFilters = filter.race === null && filter.category === null;
  const ethnicityOptions = useMemo(() => {
    const realisticEthnicityValue = STEPS_REALISTIC.find((option) => {
      return option.title === "ethnicity";
    })?.options.map((opt) => {
      return opt.value;
    });

    const animeEthnicityValue = STEPS_ANIME.find((option) => {
      return option.title === "race";
    })?.options.map((opt) => {
      return opt.value;
    });

    if (filter.category === "realistic") {
      return realisticEthnicityValue;
    }

    if (filter.category === "anime") {
      return animeEthnicityValue;
    }

    return [...realisticEthnicityValue, ...animeEthnicityValue];
  }, [filter]);

  const handleChangeCategory = (category) => {
    if (filter.category === category) {
      setFilter((prev) => ({
        ...prev,
        category: null,
      }));
      return;
    }

    setFilter((prev) => ({
      race: null,
      category: category,
    }));
  };

  const handleChangeRace = (race) => {
    if (filter.race === race) {
      setFilter((prev) => ({
        ...prev,
        race: null,
      }));

      return;
    }

    setFilter((prev) => ({
      ...prev,
      race: race,
    }));
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button
          className={cn(
            "text-white/70 rounded-full hover:text-white bg-zinc-800  px-4 py-2",
            isEmptyFilters ? "opacity-60" : "opacity-100",
          )}
        >
          {isEmptyFilters ? (
            <Filter className="w-5 h-5" />
          ) : (
            <FilterX className="w-5 h-5" />
          )}
        </button>
      </DialogTrigger>
      <DialogContent className="min-h-[400px] md:min-h-[350px]">
        <div className="flex items-baseline gap-2 ">
          <h4 className="text-xl">Filters</h4>
          {!isEmptyFilters && (
            <button
              className="text-white/70 hover:text-white"
              onClick={() => {
                setFilter({
                  category: null,
                  race: null,
                });
              }}
            >
              Clear all
            </button>
          )}
        </div>
        <div className="mt-3 flex flex-col gap-3">
          <div className="">
            <h5 className="text-white/80">Category</h5>

            <div className="flex gap-2 mt-2">
              {CATEGORIES.map((category) => {
                const isSelected = filter.category === category;

                return (
                  <CharactersContainerFilterItem
                    key={category}
                    value={category}
                    title={category}
                    isSelected={isSelected}
                    handleClick={handleChangeCategory}
                  />
                );
              })}

              {filter.category !== null && (
                <button
                  className="text-sm text-white/70"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      category: null,
                    }));
                  }}
                >
                  clear
                </button>
              )}
            </div>
          </div>
          <div className="">
            <h5 className="text-white/80">Race</h5>
            <div className="flex flex-wrap gap-2 mt-2">
              {ethnicityOptions.map((ethnicity) => {
                const isSelected = filter.race === ethnicity;
                return (
                  <CharactersContainerFilterItem
                    key={ethnicity}
                    value={ethnicity}
                    title={ethnicity}
                    isSelected={isSelected}
                    handleClick={handleChangeRace}
                  />
                );
              })}

              {filter.race !== null && (
                <button
                  className="text-sm text-white/70"
                  onClick={() => {
                    setFilter((prev) => ({
                      ...prev,
                      race: null,
                    }));
                  }}
                >
                  clear
                </button>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CharactersContainerFilter;
