import React, { useEffect, useMemo } from "react";

import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

const useUTM = () => {
  const location = useLocation();
  const queries = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  );

  useEffect(() => {
    const cookies = new Cookies();
    let utmSource = queries.get("utm_source");
    let utmMedium = queries.get("utm_medium");
    let utmCampaign = queries.get("utm_campaign");

    if (utmSource) cookies.set("utmSource", utmSource);
    if (utmMedium) cookies.set("utmMedium", utmMedium);
    if (utmCampaign) cookies.set("utmCampaign", utmCampaign);

    window.dataLayer.push({
      event: "utm_detected",
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    });
  }, [queries]);

  return true;
};

export default useUTM;
