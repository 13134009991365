import React from "react";

import DetailedImageCardSection from "./DetailedImageCardSection";
import LinkButton from "./LinkButton";
import DownloadButton from "./DownloadButton";
import LikeButton from "./LikeButton";
import DetailedImageCardExtra from "./DetailedImageCardExtra";
import { Link } from "react-router-dom";

const DetailedImageCardContent = ({ exploreImage }) => {
  return (
    <div className="flex w-full  flex-1 flex-col text-white font-quicksand">
      {/* Header - model */}
      <div className="flex flex-shrink-0 items-center gap-2">
        <img
          src={exploreImage.checkpoint.image_urls[0]}
          className="h-12 w-12 flex-shrink-0 rounded-lg object-cover object-center"
        />
        <div className="leading-tight">
          <h2 className="text-white/70">Model</h2>
          <p className="font-semibold">{exploreImage.checkpoint.title}</p>
        </div>
      </div>

      <div className="mt-4 flex-grow">
        <DetailedImageCardSection
          title="Prompt"
          content={exploreImage.prompt}
        />
        <DetailedImageCardSection
          title="Icks"
          content={exploreImage.negative_prompt}
        />

        <DetailedImageCardExtra exploreImage={exploreImage} />
      </div>

      {/* Footer */}
      <div className="sticky bottom-0 flex-shrink-0 border-t border-zinc-800 bg-zinc-900 pb-6 pt-4">
        <div className="flex justify-between">
          <div className="rounded-full bg-zinc-800 px-4 py-2.5">
            <LikeButton exploreImage={exploreImage} />
          </div>
          <div className="flex gap-2">
            <DownloadButton exploreImage={exploreImage} />
            <LinkButton exploreImage={exploreImage} />
          </div>
        </div>

        {/* Not is a Link tag because i need remove the modal */}
        <Link
          to={`/create?id=${exploreImage.id}`}
          className="mt-3 block w-full rounded-full bg-primary-500 p-2.5 text-center"
        >
          Remix
        </Link>
      </div>
    </div>
  );
};

export default DetailedImageCardContent;
