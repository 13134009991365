import React, { useEffect, useState } from "react";
import RpHeroContent from "./RpHeroContent";
import RpHeroSkeleton from "./RpHeroSkeleton";
import { roleplayService } from "../../../services/roleplay.service";

import useSWR from "swr";
import { fetcher } from "@nectar/lib/fetcher";

const TIME_NEXT_HERO = 5000;
const NUM_FEATURED_FANTASIES = 4;
const FADE_DURATION = 500;

const RpHero = () => {
  // Fetching
  const [fantasySelected, setFantasySelected] = useState(null);

  const { data, isLoading } = useSWR(
    roleplayService.getFantasiesUrl(
      "featured",
      1,
      NUM_FEATURED_FANTASIES,
      "",
      "",
      "",
    ),
    fetcher,
  );

  const fantasies = data?.fantasies || null;

  useEffect(() => {
    if (fantasies) {
      setFantasySelected(fantasies?.[0] || null);
    }
    return () => {};
  }, [fantasies]);

  return (
    <div className="w-full mx-auto">
      {fantasySelected && !isLoading ? (
        <RpHeroContent key={fantasySelected?.id} fantasy={fantasySelected} />
      ) : (
        <RpHeroSkeleton />
      )}

      {/* Carousel controls */}
      {fantasies && !isLoading && (
        <div className="mt-4 flex gap-2">
          {fantasies?.map((fantasy, i) => {
            return (
              <button
                aria-label={`select fantasy ${fantasy.title}`}
                key={`step-${fantasy.id}`}
                disabled={fantasy.id === fantasySelected?.id}
                className="w-8 h-2 bg-zinc-800  hover:bg-zinc-700 rounded-full disabled:bg-zinc-600"
                onClick={() => {
                  setFantasySelected(fantasy);
                }}
              ></button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RpHero;
