import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const APPS = {
  IMAGES: {
    id: "IMAGES",
    name: "images",
    paths: ["/images", "/create", "/nudes", "/explore", "/view"],
  },
  ROLEPLAY: {
    id: "ROLEPLAY",
    name: "roleplay",
    paths: [
      "/roleplay",
      "/characters",
      "/fantasies",
      "/messages",
      "/profile/",
      "/fantasy/create",
      "/fantasy/", // for fantasy detailed view and also
      "/user/girls",
      "/user/fantasies",
    ],
  },
};

const useApp = (shouldPersist = false) => {
  const { pathname } = useLocation();
  const [persistData, setPersistData] = useState(null);

  const data = useMemo(
    () =>
      Object.keys(APPS).reduce(
        (prev, curr) => {
          if (pathname === "/") return prev;

          const result = APPS[curr].paths.find((path) => {
            return pathname.startsWith(path);
          });

          if (result) {
            setPersistData(APPS[curr]);
            return APPS[curr];
          }

          return prev;
        },
        !shouldPersist ? null : persistData,
      ),
    [pathname],
  );

  return data;
};

export default useApp;
