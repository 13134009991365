import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0C0C0C',
        },
        secondary: {
            // main: '#FF5959', #FF2F2F
            main: '#FF4343'
        },
    },
    typography: {
        fontFamily: 'Quicksand, sans-serif',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        h1: {
            fontWeight: 'bold',
            fontFamily: 'Quicksand, sans-serif',
            fontSize: 28,
        },
        h2: {
            fontWeight: 'regular',
            fontFamily: 'Quicksand, sans-serif',
            fontSize: 20,
        },
        h3: {
            fontWeight: 'regular',
            fontFamily: 'Quicksand, sans-serif',
            fontSize: 18,
        },
        h4: {
            fontWeight: 'regular',
            fontFamily: 'Quicksand, sans-serif',
            fontSize: 16,
        },
        h5: {
            fontWeight: 'regular',
            fontFamily: 'Quicksand, sans-serif',
            fontSize: 12,
        },
        p: {
            fontWeight: 'light',
            fontFamily: 'Quicksand, sans-serif',
            fontSize: 18,
        }
    },
    overrides: {
        MuiTab: {
            root: {
                textTransform: 'none',
                fontWeight: 500,
                fontSize: 20,
                fontFamily: 'Quicksand, sans-serif',
            },
        },
    },
});
export default theme;