import React, { useContext, useMemo } from "react";
import { cn } from "@nectar/lib/utils";
import { X } from "lucide-react";

import { CreateContext } from "../CreateContext";
import { AppContext } from "../AppContext";

const ExtraItem = ({ extra, onShowPaywall }) => {
  const { ownedExtras } = useContext(AppContext);
  const { selectedExtras, setSelectedExtras } = useContext(CreateContext);

  const isSelected = useMemo(
    () => selectedExtras.includes(extra.id),
    [selectedExtras, extra.id],
  );

  const isLocked = useMemo(
    () =>
      !ownedExtras.some((e) => e.id === extra.id) &&
      extra.tier_title !== "Basic" &&
      extra.tier_title !== "Free",
    [extra, ownedExtras],
  );

  const handleExtraClick = () => {
    if (isLocked) {
      onShowPaywall();
      return;
    }

    if (selectedExtras.includes(extra.id)) {
      setSelectedExtras(selectedExtras.filter((e) => e !== extra.id));
      return;
    }

    if (selectedExtras.length >= 3) {
      return;
    }

    setSelectedExtras([...selectedExtras, extra.id]);
  };

  const getExtraTierColor = (tier_title) => {
    const extraColorMap = {
      default: "#4743FF",
      Pro: "#FF4444",
      Premium: "#0788F5",
    };

    return extraColorMap?.[tier_title] || extraColorMap.default;
  };

  return (
    <button onClick={handleExtraClick} className="flex flex-col group">
      <div className="relative">
        <img
          className={cn(
            "w-full aspect-[1/1] object-cover rounded-lg opacity-70",
            isLocked && "grayscale",
            isSelected &&
              " opacity-100 ring-2 ring-primary ring-offset-1 ring-offset-black group-hover:opacity-60",
            !isLocked && !isSelected && "group-hover:opacity-100",
          )}
          src={extra.image_urls[0]}
          alt={extra.title}
        />

        <div
          className={cn(
            "absolute inset-0 bg-opacity-0 bg-black transition rounded-lg flex justify-center items-center",
            isSelected && "bg-opacity-30 md:opacity-0 md:group-hover:bg-opacity-70",
            isLocked &&
              " bg-opacity-70 md:bg-opacity-0 md:group-hover:bg-opacity-70 ",
          )}
        >
          {isLocked && (
            <div className=" flex md:group-hover:flex flex-col gap-1 items-center md:hidden ">
              <p className="text-xs font-semibold">
                Unlock with {extra.tier_title}
              </p>
            </div>
          )}

          {isSelected && (
            <div className="hidden md:group-hover:flex flex-col gap-1 items-center">
              <p className="text-xs font-semibold">Remove</p>
            </div>
          )}
        </div>

        {extra.tier_title !== "Free" && (
          <p
            className="text-sm absolute bottom-1 right-1 px-2 rounded-full"
            style={{
              backgroundColor: getExtraTierColor(extra.tier_title),
            }}
          >
            {extra.tier_title.substring(0, 3)}
          </p>
        )}

        {isSelected && (
          <X className="absolute top-1 right-1 w-4 h-4 text-white" />
        )}
      </div>
      <p className="text-center mt-2 text-white/80 group-hover:text-white text-xs">
        {extra.title}
      </p>
    </button>
  );
};

export default ExtraItem;
