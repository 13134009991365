import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "./GoogleLoginButton";
import LoginWithStytch from "./LoginWithStytch";
import { userService } from "../../services/user.service.ts";
import heart_logo from "../../assets/heart_logo.png";
import ConnectWallet from "./ConnectWallet";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import Typography from "@material-ui/core/Typography";
import Divider from "@mui/material/Divider";
import styles from "./styles/modal.css";
import login_header from "../../assets/signup_banner.png";
import feature_check from "../../assets/feature_check.png";
import { StytchUIClient } from "@stytch/vanilla-js";
import { useMediaQuery } from "@material-ui/core";
import StytchProvider from "./StytchProvider";
import LoginWithEmailCard from "./LoginWithEmailCard";

function LoginModal() {
  const context = useContext(AppContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const stytchClient = new StytchUIClient(
    process.env.REACT_APP_NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN,
  );

  const handleClose = (e) => {
    e.stopPropagation();
    context.setShowLoginModal(false);
  };

  const handleLoginWithGoogle = async (response) => {
    try {
      const user = await userService.loginWithGoogle(response.credential);
      if (user?.id) {
        sessionStorage.setItem("user", JSON.stringify(user));
        context.setUser(user);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login",
          user_id: user.id,
          user_email: user.email,
          // ... any additional data you want to send
        });
      }
    } catch (error) {
      console.log(error.message ?? "An error occurred while logging in.");
      throw error;
    }
  };

  const sendEmailMagiclink = async (email) => {
    const roleplayRoutes = ["messages", "profile"];
    const imagesRoute = ["images"];
    const genericRoute = ["guides"];

    // Email redirect. This can also be replaced with env vars
    let redirectDomain = "";
    if (typeof window !== "undefined") {
      let path = window.location.pathname;

      if (
        roleplayRoutes.some((route) => window.location.pathname.includes(route))
      ) {
        path = "/roleplay";
      } else if (
        imagesRoute.some((route) => window.location.pathname.includes(route))
      ) {
        path = "/images";
      } else if (
        genericRoute.some((route) => window.location.pathname.includes(route))
      ) {
        path = "";
      }

      redirectDomain = window.location.origin + path;
    }
    try {
      await stytchClient.magicLinks.email.loginOrCreate(email, {
        login_magic_link_url: redirectDomain,
        login_expiration_minutes: 60,
        signup_magic_link_url: redirectDomain,
        signup_expiration_minutes: 60,
      });
    } catch (error) {
      throw error;
    }
  };

  const toggleBodyScroll = (enable) => {
    document.body.style.overflow = enable ? "auto" : "hidden";
  };

  useEffect(() => {
    toggleBodyScroll(!context.showLoginModal);
    return () => {
      toggleBodyScroll(true);
    };
  }, [context.showLoginModal]);

  return (
    <>
      {context.showLoginModal && (
        <ThemeProvider theme={theme}>
          <div className="modal-container" onClick={handleClose}>
            <div
              className="flex flex-col md:flex-row inline-flex items-start gap-4 p-4 md:gap-8 md:p-8 max-h-[90vh] md:max-h-[85vh] w-4/5 md:w-4/5 rounded-md bg-black border border-gray-500 mx-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex flex-col w-full md:w-1/2 items-center justify-center">
                <div className="flex flex-row items-center justify-center mb-4">
                  <img
                    src={heart_logo}
                    alt="heart logo icon"
                    className="inline-block w-14"
                  />
                  <Typography
                    variant="h1"
                    color="white"
                    className="flex-grow-1"
                    style={{ paddingLeft: 16 }}
                  >
                    Nectar
                  </Typography>
                </div>
                <Typography
                  variant="h1"
                  style={{ color: "white", paddingBottom: 12 }}
                >
                  Create your dream girl in seconds.
                </Typography>
                <Typography
                  variant="h4"
                  style={{ color: "white", paddingBottom: 12, opacity: 0.7 }}
                >
                  Make an account or log in with an existing one to continue.
                </Typography>
                <div style={{ width: "100%", paddingBottom: 20 }}>
                  <Divider
                    style={{ backgroundColor: "#5A595D", height: "1px" }}
                  />
                </div>
                <GoogleLoginButton
                  onLogin={handleLoginWithGoogle}
                  shape="rect"
                  theme="filled_black"
                  text="continue_with"
                />
                <Typography
                  variant="h5"
                  style={{ color: "white", paddingTop: 10, paddingBottom: 10 }}
                >
                  - OR -
                </Typography>
                <LoginWithEmailCard
                  sendEmailMagiclinkCallback={sendEmailMagiclink}
                />

                {/* <Typography variant="body2" style={{ color: 'white', paddingTop: 16, paddingBottom: 16 }}>OR</Typography> */}

                {/* <ConnectWallet /> */}
              </div>
              {!isMobile && (
                <div className="flex flex-col w-full md:w-1/2 items-center justify-center">
                  <div className="flex flex-col items-center justify-center mb-4">
                    <img
                      src={login_header}
                      alt="Nectar Header"
                      className="w-full h-auto"
                      style={{ marginTop: 60 }}
                    />

                    {/* Bullet Points*/}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 28,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 4,
                        }}
                      >
                        <img
                          src={feature_check}
                          alt="feature checkmark"
                          className="mr-2 h-5 w-5"
                        />
                        <Typography
                          variant="h5"
                          style={{ color: "white", opacity: 0.7 }}
                        >
                          Fast generation time — less waiting, more creating.
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 4,
                        }}
                      >
                        <img
                          src={feature_check}
                          alt="feature checkmark"
                          className="mr-2 h-5 w-5"
                        />
                        <Typography
                          variant="h5"
                          style={{ color: "white", opacity: 0.7 }}
                        >
                          80+ customizations for characters, poses, outfits, and
                          effects.
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 4,
                        }}
                      >
                        <img
                          src={feature_check}
                          alt="feature checkmark"
                          className="mr-2 h-5 w-5"
                        />
                        <Typography
                          variant="h5"
                          style={{ color: "white", opacity: 0.7 }}
                        >
                          Specialized anime and realistic models.
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 4,
                        }}
                      >
                        <img
                          src={feature_check}
                          alt="feature checkmark"
                          className="mr-2 h-5 w-5"
                        />
                        <Typography
                          variant="h5"
                          style={{ color: "white", opacity: 0.7 }}
                        >
                          Unfiltered chat with photo messages from featured
                          babes.
                        </Typography>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 24,
                      }}
                    >
                      <Typography
                        variant="h2"
                        align="center"
                        style={{ color: "white" }}
                      >
                        Join over 60,000 people in bringing your fantasies to
                        life!
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ThemeProvider>
      )}
    </>
  );
}

export default LoginModal;
