import React, { useState } from "react";

import useCategory, { CATEGORIES } from "../hooks/useCategory";
import ExploreTabs from "../components/explore/ExploreTabs";
import ExploreImagesContainer from "../components/explore/ExploreImagesContainer";
import useUTM from "../hooks/useUTM";
import useToken from "../hooks/useToken";
import useModel, { MODELS } from "../hooks/useModel";

const ExplorePage = ({}) => {
  const utm = useUTM();
  const token = useToken();
  const category = useCategory();
  const [model, setModel] = useState(null);

  return (
    <div className="min-h-screen bg-black pb-6">
      {/* Top */}
      <ExploreTabs  model={model} setModel={setModel} />

      {/* Explore */}
      <ExploreImagesContainer
        key={`container`}
        category={category}
        model={model || null}
      />
    </div>
  );
};

export default ExplorePage;
