import React from "react";
import { NavLink } from "react-router-dom";

const SidebarLink = ({
  text,
  path,
  icon,
  isCollapsed = false,
}) => {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        `p-3 w-full rounded capitalize font-quicksand flex gap-2 items-center ${
          isActive
            ? "text-primary-500 bg-zinc-900"
            : "text-white/70 hover:text-white"
        } ${isCollapsed ? "justify-center" : ""}`
      }
    >
      <span className={`${!isCollapsed ? "w-4 h-4" : "w-5 h-5"}`}>
        {icon && icon}
      </span>
      {!isCollapsed && <span> {text} </span>}
    </NavLink>
  );
};

export default SidebarLink;
