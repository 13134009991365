import React from "react";
import { Button } from "@material-ui/core";
import heart_logo from "../../assets/heart_logo.png";
import { Link, useNavigate } from "react-router-dom";

const BannerSection = ({ title, backgroundUrl, content, link }) => {
  const navigate = useNavigate();
  return (
    <div className="flex-1 relative group/section">
      <Link to={link.url}>
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-black z-10  group-hover/section:to-primary-500/60 transition-all ease-out"></div>
          <img
            src={backgroundUrl}
            alt={`${title} background`}
            className="w-full h-full opacity-20 z-0 object-cover object-center"
          />
        </div>
        <div className="flex items-center flex-col py-20 lg:pt-32 lg:pb-48 font-quicksand relative z-10">
          <div className="flex items-center gap-2">
            <img src={heart_logo} alt="heart logo icon" className="w-4 h-4" />
            <p className="opacity-70 font-quicksand text-lg font-bold text-white">
              Nectar
            </p>
          </div>

          <h2 className="text-2xl text-white mt-2 font-semibold">{title}</h2>
          <p className="text-white opacity-70 max-w-sm text-center mt-1 mb-6">
            {content}
          </p>

          <Button
            onClick={() => navigate(link.url)}
            variant="contained"
            color="secondary"
          >
            {link.label}
          </Button>
        </div>
      </Link>
    </div>
  );
};

const Banner = () => {
  return (
    <div className="flex flex-col lg:flex-row">
      <BannerSection
        title={"Image Creator"}
        content={
          "Explore and create images using the most powerful AI photo generator"
        }
        link={{
          label: "Create Now",
          url: "/create",
        }}
        backgroundUrl={"/banner-generator2.png"}
      />

      {/*TODO -- CHANGE THIS*/}
      <BannerSection
        title={"Roleplay"}
        content={
          "Create your own AI Girlfriend and explore the fantasies of your dreams"
        }
        link={{
          label: "Start Now",
          url: "/roleplay",
        }}
        backgroundUrl={"/banner-roleplay.jpg"}
      />
    </div>
  );
};

export default Banner;
