import React from "react";

const MessagesBubbleSkeleton = ({ isUserAuthor }) => {
  return (
    <div
      className={`flex gap-2 ${isUserAuthor ? "justify-end" : "justify-start"}`}
    >
      {!isUserAuthor && (
        <div className="flex-shrink-0 w-8 h-8 rounded-full animate-pulse bg-zinc-800" />
      )}

      <div
        className={`max-w-[60%] w-full animate-pulse rounded-2xl bg-zinc-800 p-3 ${
          isUserAuthor ? "h-14" : "h-36"
        }`}
      ></div>
    </div>
  );
};

export default MessagesBubbleSkeleton;
