import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { createContext } from "react";
import { generateService } from "../services/generate.service";
import { modelService } from "../services/model.service";
import { AppContext } from "./AppContext";

export const CreateContext = createContext();

export const CreateProvider = ({ children }) => {
  const [generations, setGenerations] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [negativePrompt, setNegativePrompt] = useState("");
  const [ckpts, setCkpts] = useState([]);
  const [extras, setExtras] = useState([]);
  const [enableHighRes, setEnableHighRes] = useState(false);
  const [aspectRatio, setAspectRatio] = useState("");
  const [selectedCkpt, setSelectedCkpt] = useState(null);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const { refreshAccountStatus } = useContext(AppContext);
  const [isLoadingFields, setIsLoadingFields] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [taggedExtras, setTaggedExtras] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [character, setCharacter] = useState(null);

  const create = () => {
    setIsGenerating(true);
    generateService
      .create(
        prompt,
        negativePrompt,
        selectedCkpt?.title,
        selectedExtras,
        enableHighRes,
        aspectRatio,
        character?.id,
        character?.category,
      )
      .then((result) => {
        setGenerations((prevGenerations) => [result, ...prevGenerations]);
        refreshAccountStatus();
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    modelService.getCheckpoints({ page: 1, pageSize: 1000 }).then((res) => {
      setCkpts(res);
    });
    modelService.getExtras({ page: 1, pageSize: 1000 }).then((res) => {
      setExtras(res);
    });
  }, []);

  return (
    <CreateContext.Provider
      value={{
        ckpts,
        extras,
        generations,
        setGenerations,
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,
        enableHighRes,
        setEnableHighRes,
        aspectRatio,
        setAspectRatio,
        selectedCkpt,
        setSelectedCkpt,
        selectedExtras,
        setSelectedExtras,
        create,
        isGenerating,
        isLoadingFields,
        setIsLoadingFields,
        activeTab,
        setActiveTab,
        taggedExtras,
        setTaggedExtras,
        searchInput,
        setSearchInput,
        character,
        setCharacter,
      }}
    >
      {children}
    </CreateContext.Provider>
  );
};
