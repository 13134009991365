import React, { useEffect, useContext } from "react";
import { userService } from "../services/user.service";
import { useLocation } from "react-router-dom";
// import { AppContext } from "@nectar/components/AppContext";
import { AppContext } from "../components/AppContext";

const useCheckEmailRedirect = () => {
  const appCtx = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    checkIfEmailRedirect();
  }, []);

  const checkIfEmailRedirect = async () => {
    if (userService.checkIsLoggedIn()) {
      return;
    }

    const queries = new URLSearchParams(location.search);
    const token = queries.get("token");
    if (token == null) {
      return;
    }

    appCtx.setShowTcModal(false);

    try {
      const user = await userService.loginWithStytch(token);
      if (user?.id) {
        sessionStorage.setItem("user", JSON.stringify(user));
        appCtx.setUser(user);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login",
          user_id: user.id,
          user_email: user.email,
          // ... any additional data you want to send
        });
      }
    } catch (error) {
      console.log(error.message ?? "An error occurred while logging in.");
      throw error;
    }
  };

  return true;
};

export default useCheckEmailRedirect;
