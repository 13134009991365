import React from "react";

const Suggestion = ({ word, onAddSuggestion }) => {
  return (
    <button
      className="text-sm px-2 py-0.5 text-white/80 bg-zinc-900 hover:bg-zinc-800 rounded-full "
      onClick={() => {
        onAddSuggestion(word);
      }}
    >
      + {word}
    </button>
  );
};

export default Suggestion;
