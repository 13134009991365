import React, { useContext, useEffect, useState } from "react";
import useSWRInfinite from "swr/infinite";
import { Link, useNavigate } from "react-router-dom";

import { fetcher } from "@nectar/lib/fetcher";
import { useOnScreen } from "@nectar/hooks/useOnScreen";
import { roleplayService } from "@nectar/services/roleplay.service";
import { userService } from "@/services/user.service";
import { AppContext } from "@nectar/components/AppContext";
import PaywallModal from "@/components/auth/PaywallModal";
import DiscoverProfileCard from "@nectar/components/profiles/DiscoverProfileCard";
import DiscoverProfileCardWrapper from "@nectar/components/profiles/DiscoverProfileCardWrapper";
import { Button } from "@nectar/components/ui/button";
import dropdown_lock_icon_white from "@/assets/dropdown_lock_icon_white.svg";
import { cn } from "@nectar/lib/utils";
import { Filter, Search } from "lucide-react";
import { useDebounce } from "@nectar/hooks/useDebounce";

import CharactersContainerFilter from "./CharactersContainerFilter";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@nectar/components/ui/select";
import CharactersContainerDropdown from "./CharactersContainerDropdown";
import useFantasyCategory from "@nectar/hooks/useFantasyCategory";

const CHARACTERS_PER_PAGE = 12;
const CHARACTER_CREATE_PAYWALL_SOURCE = "character_create";

const CharactersContainer = ({
  showCreateButton = true,
  onDiscoverProfileCardCLick = null,
  isFullScreen = true,
  filterCallbackCharacter = null,
  showDropdown = true,
  showOwnGirlsTab = true,
  showAllGirlsTab = true,
}) => {
  const [tab, setTab] = useState(showAllGirlsTab ? "all" : "own");
  const [searchInput, setSearchInput] = useState("");
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const navigate = useNavigate();
  const searchQuery = useDebounce(searchInput, 200);
  const categoryQuery = useFantasyCategory();
  const [filter, setFilter] = useState({
    category: null,
    race: null,
  });

  const generateKey = (tabSelected, search = "", filters, category) => {
    return (pageIndex, previousPageData) => {
      if (pageIndex !== 0 && !previousPageData?.characters?.length) return null;

      if (tabSelected === "own") {
        return roleplayService.getCharacterByUserUrl(
          pageIndex + 1,
          CHARACTERS_PER_PAGE,
          search,
          {
            model: filters.category,
            race: filters.race,
          },
        );
      }

      return roleplayService.getCharactersUrl(
        category,
        pageIndex + 1,
        CHARACTERS_PER_PAGE,
        search,
        {
          model: filters.category,
          race: filters.race,
        },
      );
    };
  };

  const { data, isLoading, setSize, size } = useSWRInfinite(
    generateKey(tab, searchQuery, filter, categoryQuery),
    fetcher,
  );

  const [isVisible, containerRef] = useOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  const {
    user,
    setShowLoginModal,
    accountStatus,
    refreshUser,
    messageCredits,
    eligible,
  } = useContext(AppContext);

  const characters = data?.reduce(
    (acc, val) => [...acc, ...val.characters],
    [],
  );

  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  const isEmpty = data?.[0]?.characters?.length === 0;

  const isReachingEnd =
    isEmpty ||
    (data && data[data.length - 1]?.characters?.length < CHARACTERS_PER_PAGE);

  const showPaywallWithSource = (source) => {
    setShowPaywallModal(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "show_paywall",
      paywall_source: source,
      // ... any additional data you want to send
    });
  };

  const handlePaywallModalOpen = (source) => {
    if (!userService.checkIsLoggedIn()) {
      return;
    }
    showPaywallWithSource(source);
  };

  const handlePaywallModalClose = () => {
    setShowPaywallModal(false);
    refreshUser();
  };

  const handleCreateGirlClick = async () => {
    if (eligible) {
      navigate(`/characters/create`);
    } else {
      handlePaywallModalOpen(CHARACTER_CREATE_PAYWALL_SOURCE);
    }
  };

  useEffect(() => {
    if (isVisible) {
      setSize((size) => size + 1);
    }
  }, [isVisible]);

  useEffect(() => {
    setSize(1);
    window.scrollTo(0, 0);
  }, [tab]);

  return (
    <div className="">
      <div
        className={cn(
          "flex items-center justify-between gap-2 py-3 px-6 sticky z-10 pt-16 lg:pt-3 bg-black/90 backdrop-blur",
          isFullScreen ? "top-[69px] md:top-0" : "top-0",
        )}
      >
        <div className="flex items-center gap-2">
          {!showDropdown ? (
            showAllGirlsTab ? (
              <button
                onClick={() => setTab("all")}
                className={`rounded-full text-sm px-4 py-2 flex-shrink-0 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
                  tab === "all" ? " text-white" : "text-white opacity-60"
                }`}
              >
                All Girls
              </button>
            ) : null
          ) : (
            <CharactersContainerDropdown />
          )}
          {user && showOwnGirlsTab && !showDropdown && (
            <button
              onClick={() => setTab("own")}
              className={`rounded-full text-sm px-4 py-2 flex-shrink-0 font-quicksand  transition-colors ease-out bg-zinc-800 whitespace-nowrap flex gap-2 items-center ${
                tab === "own" ? " text-white" : "text-white opacity-60"
              }`}
            >
              My Girls
            </button>
          )}

          <CharactersContainerFilter filter={filter} setFilter={setFilter} />
        </div>

        <div
          className={cn(
            "absolute h-fit top-3 lg:top-auto",
            showCreateButton
              ? "left-1/2 -translate-x-1/2 w-full px-4 lg:px-0 lg:w-96"
              : "left-1/2 -translate-x-1/2 lg:left-auto lg:translate-x-0 lg:right-4 w-full px-4 lg:w-96 lg:px-0",
          )}
        >
          <span className="absolute inset-y-0  flex items-center pl-3 pointer-events-none">
            <Search />
          </span>
          <input
            type="text"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            className="border rounded-lg focus:border-gray-300 text-slate-200 transition-all duration-500  bg-transparent pl-12 py-2 w-full text-md focus:outline-none"
            placeholder="Search all characters"
          />
        </div>

        {showCreateButton && (
          <div>
            {user ? (
              <Button
                className="text-sm text-white"
                onClick={handleCreateGirlClick}
                // asChild={isEligible}
                size={"sm"}
              >
                + CREATE GIRL
              </Button>
            ) : (
              <Button
                className="text-sm text-white"
                onClick={() => setShowLoginModal(true)}
                size={"sm"}
              >
                <img
                  src={dropdown_lock_icon_white}
                  className="h-5 w-5 mr-1"
                  alt="Lock icon"
                />
                CREATE GIRL 👩🏼
              </Button>
            )}
          </div>
        )}
      </div>

      {characters?.length === 0 && !isLoadingMore && (
        <div>
          <div className="flex flex-col items-center justify-center h-[400px]">
            <h2 className="text-2xl font-semibold">No Girls Created.</h2>
            <p className="text-white/70 mt-2">
              {tab === "all"
                ? "There are no girls yet."
                : "Create your first girl above!"}
            </p>
          </div>
        </div>
      )}

      <div
        className={cn(
          "grid  gap-4 px-6 pb-8",
          isFullScreen
            ? "grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4 px-6 pb-8"
            : "grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 px-6 pb-8",
        )}
      >
        {characters
          ?.filter((character) => {
            if (filterCallbackCharacter) {
              return filterCallbackCharacter(character);
            }

            return true;
          })
          ?.map((character) => {
            if (onDiscoverProfileCardCLick) {
              return (
                <div
                  className="cursor-pointer"
                  key={character.id}
                  onClick={() => onDiscoverProfileCardCLick(character)}
                >
                  <DiscoverProfileCard profile={character} />
                </div>
              );
            }

            return (
              <DiscoverProfileCardWrapper
                key={character.id}
                profile={character}
              >
                <DiscoverProfileCard profile={character} />
              </DiscoverProfileCardWrapper>
            );
          })}

        {/* trigger */}
        {!isLoadingMore && !isReachingEnd && (
          <div
            ref={containerRef}
            className="h-12  w-full animate-pulse rounded-lg bg-zinc-900"
          ></div>
        )}

        {/* show skeletons */}
        {isLoadingMore &&
          Array.from({ length: CHARACTERS_PER_PAGE }).map((_, i) => {
            return (
              <div
                key={i}
                className="aspect-[9/12] h-full w-full animate-pulse rounded-lg bg-zinc-900"
              />
            );
          })}
      </div>

      {/* paywall */}
      {showPaywallModal && (
        <div>
          <PaywallModal
            message={
              "Max number of girls created🙁 Upgrade now to Pro+ to create multiple girls!"
            }
            open={showPaywallModal}
            onClose={handlePaywallModalClose}
            refreshUser={refreshUser}
            loggedInUser={user}
          />
        </div>
      )}
    </div>
  );
};

export default CharactersContainer;
