import React, { useState } from "react";
import { restrictedDomainSet } from "./Domains";
import Typography from "@material-ui/core/Typography";

function LoginWithEmailCard({ sendEmailMagiclinkCallback }) {
  const [email, setEmailForLoginCard] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [shouldShowEmailField, setShowEmailField] = useState(true);
  const [shouldShowMessageSendSuccessField, setShowMessageSendSuccessField] = useState(false);
  const [shouldShowMessageSendFailureField, setShowMessageSendFailureField] = useState(false);
  const [shouldShowInvalidFormatErrorField, setShowInvalidFormatErrorField] = useState(false);
  const [shouldShowRestrictedDomainErorField, setShowRestrictedDomainErorField] = useState(false);
  const [shouldShowGmailErrorField, setShowGmailErrorField] = useState(false);

  // Handle the input change
  function handleInputChange(e) {
    const newEmail = e.target.value;
    setEmailForLoginCard(newEmail);
  }

  function validateEmail(email) {
    const regexPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmailRegex = regexPattern.test(email);
    setShowInvalidFormatErrorField(!isValidEmailRegex);

    if (!email) {
      setIsValid(isValidEmailRegex);
      return;
    }

    // Extract the domain from the email
    const domain = email.split("@")[1];
    const isRestrictedDomain = restrictedDomainSet.has(domain);
    setShowRestrictedDomainErorField(isRestrictedDomain);

    const isGmailDomain = domain === "gmail.com";
    setShowGmailErrorField(isGmailDomain);

    setIsValid(isValidEmailRegex && !isRestrictedDomain && !isGmailDomain);
    return isValidEmailRegex && !isRestrictedDomain && !isGmailDomain;
  }

  // Handle the button click
  async function handleButtonClick() {
    setShowMessageSendFailureField(false);
    const currentValidity = validateEmail(email);
    if (currentValidity) {
      setIsSendingEmail(true);
      try {
        await sendEmailMagiclinkCallback(email); // Callback to parent with the valid email
        setShowEmailField(false);
        setShowMessageSendSuccessField(true);
      } catch (error) {
        setShowMessageSendFailureField(true);
      } finally {
        setIsSendingEmail(false);
      }
    } else {
      /* Invalid Email Input. Email should not send. */
    }
  }

  const invalidEmailFormatErrorText = "Invalid email format. ";
  const restrictedDomainErrorText = "Emails from that domain are not allowed. ";
  const gmailErrorText = "Please use the gmail button for gmail accounts. ";
  const messageSendFailureText = "There was a problem sending the email. Please try again later. ";

  const inputErrorField = (
    <div className="flex flex-col rounded">
      <Typography
        variant="body1"
        align="center"
        style={{ color: "#FF0000" }}
      >
        {shouldShowInvalidFormatErrorField && invalidEmailFormatErrorText}
        {shouldShowGmailErrorField && gmailErrorText}
        {shouldShowRestrictedDomainErorField && restrictedDomainErrorText}
        {shouldShowMessageSendFailureField && messageSendFailureText}
      </Typography>
    </div>
  );

  const emailField = (
    <div className="flex flex-col rounded border border-gray-500">
      <Typography
        variant="body1"
        align="center"
        style={{ color: "#FFFFFF", paddingTop: 10, paddingBottom: 10 }}
      >
        Sign up or log in
      </Typography>
      <input
        type="email"
        placeholder="email@example.com"
        value={email}
        onChange={handleInputChange}
        style={{
          textAlign: "center",
          borderRadius: "5px",
          paddingHorizontal: 10,
          marginLeft: 10,
          marginRight: 10,
          color: "black",
          backgroundColor: "white",
        }}
      />
      <button onClick={handleButtonClick}
              style={{
                backgroundColor: "#19303D",
                color: "white",
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 10,
                marginRight: 10,
                border: "white",
                borderRadius: "5px"
              }}
              disabled={isSendingEmail}>
        Continue with email
      </button>
      {inputErrorField}
    </div>);

  const successField = (
    <div className="flex flex-col rounded border border-gray-500">
      <Typography
        variant="body1"
        align="center"
        style={{ color: "#FFFFFF", paddingTop: 10, paddingBottom: 10 }}
      >
        Email sent successfully! ✅
        <Typography display="block" style={{ opacity: 0.7 }}>
          Please check your email to continue logging in.
        </Typography>
      </Typography>
    </div>
  );

  if (shouldShowEmailField) {
    return emailField;
  } else if (shouldShowMessageSendSuccessField) {
    return successField;
  }

  return;
}

export default LoginWithEmailCard;