// import { useSearchParams } from "next/navigation";
import { useLocation } from "react-router-dom";

export const CATEGORIES = {
  REALISTIC: "realistic",
  ANIME: "anime",
};

export const DEFAULT_CATEGORY = CATEGORIES.REALISTIC;

const useCategory = () => {
  const { search } = useLocation();
  const searchCategory = new URLSearchParams(search).get("category") || "";

  const category = Object.values(CATEGORIES).includes(searchCategory)
    ? searchCategory
    : DEFAULT_CATEGORY;

  return category;
};

export default useCategory;
