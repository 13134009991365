import axios from "axios";
import { Suggestion } from "../models/suggestion.model";


export class SuggestionService {

    public async getSuggestions(): Promise<Suggestion[]> {
        try {
            const response = await axios.get<Suggestion[]>(`${process.env.REACT_APP_API_URL}/suggestions`);
            if (response.status === 200 && response.data) {
                const suggestions = response.data.map((item: Suggestion) => {
                    return item as Suggestion;
                });
                return suggestions;
            }
            return []

        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
export const suggestionService = new SuggestionService();