import React from "react";
import back_arrow from "../../assets/arrow_backward_ios.svg";
import forward_arrow from "../../assets/arrow_forward_ios.svg";
import { Typography, useMediaQuery } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "lucide-react";
import theme from "@/styles/theme";

const MessagesPhotoGridHeader = ({ onClose }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // small change here
  return (
    <header className="flex items-center justify-between flex-shrink-0 px-4 h-[60px] bg-zinc-900">
      <div className="flex items-center gap-2 h-10">
        <button onClick={onClose}>
          {isMobile ? (
            <ChevronLeft className="w-5 h-5" /> // Mobile
          ) : (
            <ChevronRight className="w-5 h-5" /> // Desktop
          )}{" "}
        </button>
        <h3 className="text-xl font-semibold"> Media </h3>
      </div>
    </header>
  );
};

export default MessagesPhotoGridHeader;
