import React, { useState, useEffect, useContext, useRef } from "react";
import { CreateContext } from "../CreateContext";
import { AppContext } from "../AppContext";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";
import { Check, Lock } from "lucide-react";

const ModelDropDown = ({ onShowPaywall, isCharacterModelSelected }) => {
  const { ownedCkpts } = useContext(AppContext);
  const { ckpts, selectedCkpt, setSelectedCkpt, setCharacter } =
    useContext(CreateContext);
  const [valueSelect, setValueSelect] = useState("");

  useEffect(() => {
    if (valueSelect) {
      const ckpt = ckpts.find((ckpt) => ckpt.id === valueSelect);

      // validate is owned
      const isOwned =
        ckpt.tier_title === "Free" ||
        ownedCkpts.some((ownedCkpt) => ownedCkpt.title === ckpt.title);

      if (!isOwned) {
        onShowPaywall();
        return;
      }
      if (isCharacterModelSelected) {
        setCharacter(null);
      }

      if (ckpt) {
        setSelectedCkpt(ckpt);
      }
    }
  }, [valueSelect]);

  return (
    <Select
      onValueChange={(value) => {
        setValueSelect(value);
      }}
    >
      <SelectTrigger className="h-auto p-3 rounded-none border-none hover:bg-zinc-900 ">
        <div className="flex items-center gap-2">
          <img
            src={selectedCkpt.image_urls[0]}
            alt={`${selectedCkpt.title} preview`}
            className="h-12 w-12 flex-shrink-0 rounded-lg object-cover object-center "
          />

          <div className="text-left">
            <h2 className="text-white/70">Model</h2>
            <p className="font-semibold ">{selectedCkpt?.title}</p>
          </div>
        </div>

        <SelectContent>
          {ckpts
            .sort((a, b) => {
              // Compare tiers first
              if (a.tier_title < b.tier_title) {
                return -1; // if a.tier is less than b.tier, a comes first
              }
              if (a.tier_title > b.tier_title) {
                return 1; // if a.tier is greater than b.tier, b comes first
              }
              // If tiers are equal, compare titles
              return a.title.localeCompare(b.title);
            })
            .map((ckpt) => {
              const isOwned =
                ckpt.tier_title === "Free" ||
                ownedCkpts.some((ownedCkpt) => ownedCkpt.title === ckpt.title);

              return (
                <SelectItem key={ckpt.id} asChild value={ckpt.id}>
                  <button className="flex justify-between items-center w-full p-1.5 hover:bg-zinc-800 rounded">
                    <div className="flex items-center gap-2">
                      <img
                        src={ckpt.image_urls[0]}
                        alt={`${ckpt.title} preview`}
                        className="w-8 h-8 object-cover rounded-md"
                      />
                      {ckpt.title}
                    </div>
                    {selectedCkpt.title === ckpt.title && (
                      <Check className="w-4 h-4 opacity-50" />
                    )}

                    {!isOwned && (
                      <div className="flex items-center gap-2 text-primary">
                        <Lock className="w-4 h-4 opacity-70" />
                        <p className=" uppercase">{ckpt.tier_title}</p>
                      </div>
                    )}
                  </button>
                </SelectItem>
              );
            })}

          {/* <SelectItem  asChild value={CHAR_CKPT.id}>
            <button className="flex justify-between items-center w-full p-1.5 hover:bg-zinc-800 rounded">
              <div className="flex items-center gap-2">
                <img
                  src={"https://imagedelivery.net/E9jTnsG9warNlQK5dUFRKA/1d4c5acf-2933-4033-cf54-80aaf21dcb00/public"}
                  alt={`char preview`}
                  className="w-8 h-8 object-cover rounded-md"
                />
                { CHAR_CKPT.title}
              </div>
              {selectedCkpt.id === CHAR_CKPT.id && (
                <Check className="w-4 h-4 opacity-50" />
              )} */}
          {/*
              {!true && (
                <div className="flex items-center gap-2 text-primary">
                  <Lock className="w-4 h-4 opacity-70" />
                  <p className=" uppercase">{FREE}</p>
                </div>
              )} */}
          {/* </button>
          </SelectItem> */}
        </SelectContent>
      </SelectTrigger>
    </Select>
  );
};

export default ModelDropDown;
