import React, { useContext, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { Helper } from "../../utils/helper";
import { Modal } from "@material-ui/core";
import refresh_icon from "../../assets/refresh_icon.svg";
import mobile_close_icon from "../../assets/mobile_close_icon.svg";
import theme from "../../styles/theme";
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
import { RefreshCcw } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";

const MessagesPhotoBubble = ({
  character,
  attachment,
  timestamp,
  isTyping = false,
  isLatest = false,
  regenerateMessage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePhotoClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className={`flex gap-2 "justify-start`}>
      <Avatar className="w-8 h-8">
        <AvatarImage src={character.profile_pic_url} />
        <AvatarFallback> {character?.username.slice(0, 2)} </AvatarFallback>
      </Avatar>

      <div className={`max-w-[60%] md:max-w-[45%] flex items-center`}>
        <Dialog>
          <DialogTrigger>
            <img
              src={attachment}
              alt="Message Attachment"
              className="w-full h-full object-cover rounded-2xl"
            />
          </DialogTrigger>
          <DialogContent>
            <img src={attachment} alt="Message Attachment" />
          </DialogContent>
        </Dialog>
      </div>

      {isLatest && !isTyping && (
        <button
          onClick={() => {
            regenerateMessage();
          }}
          className="self-end p-2 border rounded-full border-zinc-700 bg-zinc-800 hover:bg-zinc-700"
        >
          <RefreshCcw className="w-4 h-4 text-white/70" />
        </button>
      )}
    </div>
  );
};

export default MessagesPhotoBubble;
