import React from 'react';
import {Modal, Box, Backdrop} from '@material-ui/core';
import close_icon from "../../assets/close_icon.svg"
import Typography from "@material-ui/core/Typography";
import DownloadButton from "../../components/images/DownloadButton";

const DetailedProfilePhotoModal = ({bundle, profilePhoto, closeModal, onDownloadClick}) => {

    return (
        <Modal
            open={true}
            onClose={closeModal}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <Box bgcolor="#0f0f0f" borderColor="#0f0f0f" borderRadius="10px" p={3} display="flex" flexDirection="column"
                 alignItems="center"
                 maxWidth="90vw" maxHeight="90vh" position="relative">
                <button onClick={closeModal} className="absolute top-1 right-1 w-4">
                    <img src={close_icon} alt="Close icon"/>
                </button>
                <img src={profilePhoto.image_url} style={{maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain'}}
                     alt={bundle.title}/>
                <div className="flex w-full items-center justify-between text-white font-quicksand mt-2">
                    <Typography>{bundle.title}</Typography>
                    <DownloadButton key={profilePhoto.id} exploreImage={profilePhoto}
                                    onClick={onDownloadClick}/>
                </div>
            </Box>
        </Modal>
    );
};

export default DetailedProfilePhotoModal;